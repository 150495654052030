import * as j from '@mojotech/json-type-validation';

export enum DiallerType {
  Connect = 'connect',
  SIP = 'sip',
}

export enum CampaignType {
  Preview = 'preview',
  Predictive = 'predictive',
}

export const typeIdsToDisplayText = {
  [DiallerType.Connect]: 'Connect',
  [DiallerType.SIP]: 'SIP',
  [CampaignType.Preview]: 'Preview',
  [CampaignType.Predictive]: 'Predictive',
};

export enum LeadStatusType {
  Contacted = 'contacted',
  Washed = 'washed',
  Filtered = 'filtered',
  OptOut = 'opt-out',
  NoEndpoints = 'no-endpoints',
  Excluded = 'excluded',
  Duplicate = 'duplicate',
  Building = 'building',
  AwaitingRetry = 'awaiting-retry',
  AwaitingStart = 'awaiting-start',
  Ready = 'ready',
  OutOfHours = 'out-of-hours',
  Finished = 'finished',
  FinishedToday = 'finished-today',
  Callback = 'callback',
  NoSkilledAgents = 'no-skilled-agents',
  Assigned = 'assigned',
  Initiated = 'initiated',
  Connected = 'connected',
  InvalidEndpoint = 'invalid-endpoint',
  Disconnected = 'disconnected',
  Removed = 'removed',
  Expired = 'expired',
  AwaitingSMS = 'awaiting-sms',
  InactiveList = 'inactive-list',
  MissedCallback = 'missed-callback',
  InQueue = 'in-queue',
  AwaitingCallback = 'awaiting-callback',
  Replaced = 'replaced',
}

export enum OutcomeType {
  AnsweringMachine = 'answering-machine',
  AssignmentExpired = 'assignment-expired',
  Callback = 'callback',
  Contacted = 'contacted',
  Error = 'error',
  Engaged = 'engaged',
  Fatal = 'fatal',
  HoldDropped = 'hold-dropped',
  NoAnswer = 'no-answer',
  Skipped = 'skipped',
  InvalidEndpoint = 'invalid-endpoint',
  RequiredAgentUnavailable = 'required-agent-unavailable',
  Excluded = 'excluded',
  Removed = 'removed',
  AwaitingCallback = 'awaiting-callback',
}

// Campaign list view item
export interface CampaignListItem {
  id: number;
  name: string;
  diallerType: DiallerType;
  campaignType: CampaignType;
  progress: number;
  numLists: number;
  numAgents: number;
  numLeads: number;
  numAttempts: number;
  finishedLeads: number;
  archived: string | null;
}

export interface UpdateCampaignGeneralSettings {
  name: string;
  description: string;
  defaultListId: string;
  enableManualOutbound: boolean;
  manualOutboundRequireDetails: boolean;
  enablePreparedOutboundHangup: boolean;
  enableManualOutboundWhenOffline: boolean;
  routingProfiles: string[];
}

// Campaign details
export interface Campaign {
  id: number;
  name: string;
  description: string;
  defaultListId: number;
  enableManualOutbound: boolean;
  manualOutboundRequireDetails: boolean;
  enablePreparedOutboundHangup: boolean;
  enableManualOutboundWhenOffline: boolean;
  numLists: number;
  numCompleted: number;
  progress: number;
  numAgents: number;
  archived: string;
  diallerType: DiallerType;
  campaignType: CampaignType;
  requiredSkills: string[];
  routingProfiles: string[];
  lists: LeadListItem[];
  agents: CampaignAgent[];
  previewSettings?: PreviewSettings;
  predictiveSettings?: PredictiveSettings;
  leadEngineSettings?: LeadEngineSettings;
  smsSettings?: SmsSettings;
  campaignTrunks?: CampaignTrunk[];
  dispositions: Disposition[];
}

export type CreateCampaign = Pick<
  Campaign,
  'name' | 'description' | 'diallerType' | 'campaignType' | 'routingProfiles'
>;

/**
 * CampaignAssignRecord is a campaign record that utilized by the frontend for assigning
 * agents to a campaign + routing profile
 */
export interface CampaignAssignRecord extends Pick<Campaign, 'id' | 'name'> {
  routingProfiles: RoutingProfile[];
}

// Lead List, list view item
export interface LeadListItem {
  id: number;
  name: string;
  isActive: boolean;
  isDefaultList: boolean;
  totalLeads: number;
  numCompleted: number;
  progress: number;
  startTime: string;
  expiryTime: string;
  numAttempts: number;
  statusCounts: Dictionary<string>;
  aht: number;
  penetrationRate: number;
}

export interface CreateLeadList {
  name: string;
  description: string;
  priority: number;
  startTime?: string;
  endTime?: string;
}

export interface UpdateLeadListGeneralSettings {
  name: string;
  description: string;
  priority: string;
  isActive: boolean;
  startTime?: string;
  endTime?: string;
}

// TODO: Update with lead item based information
// Lead List details
export interface LeadList {
  id: number;
  name: string;
  description: string;
  priority: number;
  isActive: boolean;
  isDefaultList: boolean;
  totalLeads: number;
  numCompleted: number;
  progress: number;
  startTime: string;
  endTime: string;
  numAttempts: number;
  statusCounts: Dictionary<string>;
  aht: number;
  penetrationRate: number;
  requiredSkills: string[];
}

export interface Lead {
  id: number;
  externalId: string;
  name: string;
  leadStatus: LeadStatusType;
  leadStatusDetail: string;
  priority: number;
  timezone: string;
  addedTimestamp: string;
  expirationTimestamp: string;
  endpoints: string[];
  numAttempts: number;
  attempts: LeadAttempt[];
  requiredSkills: string[];
  attributes: Dictionary<string>;
}

export interface LeadAttempt {
  timestamp: string;
  outcome: string | null;
}

export interface LeadListUploadResponse {
  importId: number;
  importErrorCount: number;
  importWarningCount: number;
  parseErrorCount: number;
  recordCount: number;
  validCount: number;
}

export interface ListImportItem {
  importId: number;
  importRecordNumber: number;
  importError: string | null;
  importWarning: string | null;
  originalData: string | null;
  parseError: string | null;
  parsed: string | null;
}

export interface UnallocatedAgent {
  name: string;
  username: string;
}

export interface AssignCampaignAgent {
  agents: string[];
  routingProfile: string;
}

export interface CampaignAgent {
  username: string;
  fullName: string;
  disabled?: string;
  skills: string[];
}

export interface PreviewSettings {
  pushPreviewSeconds: number;
  agentAssignedTimeoutSeconds: number;
  ringOutSeconds: number;
}

export interface PredictiveSettings {
  ringTimeSeconds: number;
  holdDropSeconds: number;
  maxLinesPerAgent: number;
  lookaheadTimeSeconds: number;
  numReservedAgents: number;
  ivrAgentsCallId: string;
  ivrAgentsNoCallId: string;
  ivrNoAgents: string;
  ivrCallback: string;
  ivrMsgBank: string;
  leaveMessageOnAttempt: string;
  answeringMachineMessage: string;
  allCallbacksAsPreview: boolean;
  trunk: string | null;
}

export interface CampaignTrunk {
  timezone: string;
  trunk: string;
}

export interface LeadEngineSettings {
  callbackRescheduleSeconds: number;
  callbackMaxReschedules: number;
  reassignMissedCallbackToGroup: boolean;
  duplicateSearchAllCampaigns: boolean;
  washEndpointOnly: boolean;
  diallingHourProfile: string;
  diallingHours?: DiallingHours;
  duplicateSearchDays: number;
  endpointAttemptIntervalSeconds: number;
  endpointMachineIntervalSeconds: number;
  endpointEngagedIntervalSeconds: number;
  endpointNoAnswerIntervalSeconds: number;
  endpointDailyMaxAttempts: number;
  externalIdAttemptIntervalSeconds: number;
  externalIdDailyMaxAttempts: number;
  leadEndpointTotalMaxAttempts: number;
  leadTotalMaxAttempts: number;
  allowContactedDuplication: boolean;
  washLists: string[];
  exclusionLists: string[];
  filters: CampaignFilter[];
}

export interface SmsSettings {
  enabled: boolean;
  smsProvider?: string;
  replyEndpoint?: string;
  fromEndpoint?: string;
  replyGraceTimeSeconds?: number;
  midJourneyAttempts?: number;
  initialTemplate?: string;
  midJourneyTemplate?: string;
}

export interface DispositionAttributeItem {
  attribute: string;
  value: string;
}

export interface Disposition {
  code: string;
  subCode: string;
  title: string;
  outcome: OutcomeType;
  description: string;
  attributes: DispositionAttributeItem[];
}

export interface UpdateDisposition {
  originalDispositionCode: string;
  originalDispositionSubCode: string;
  disposition: Disposition;
}

export interface CampaignFilter {
  name: string;
  field: string;
  match: string;
}

export interface Callback {
  callbackId: number;
  scheduled: string;
  forAgent: string | null;
  endpoint: string;
  notes: string;
  timezone: string;
  leadName: string;
  attemptId: number | null;
  disabled: string | null;
}

export interface CreateCallback extends Pick<Callback, 'forAgent' | 'scheduled' | 'endpoint' | 'notes'> {
  leadId: number;
}
export type UpdateCallback = Pick<Callback, 'scheduled' | 'forAgent'>;

export interface RoutingProfile {
  id: string;
  arn: string;
  name: string;
}

export interface DiallingHourProfile {
  label: string;
  value: string;
}
export interface WashList {
  label: string;
  value: string;
}

export interface PublicHoliday {
  name: string;
  day: number;
  month: number;
  year: number;
}

export interface DiallingDayTimes {
  startTimeHour: number;
  startTimeMin: number;
  endTimeHour: number;
  endTimeMin: number;
}

export interface DiallingHours {
  diallingDays: { [key: string]: DiallingDayTimes };
  allowPublicHolidays: boolean;
}

export const WashListResponseDecoder: j.Decoder<WashList[]> = j
  .object({
    wash_lists: j.array(
      j.object({
        label: j.string(),
        value: j.string(),
      }),
    ),
  })
  .map((resp) => resp.wash_lists || []);

export const DiallingHourProfileResponseDecoder: j.Decoder<DiallingHourProfile[]> = j
  .object({
    dialling_hour_profiles: j.array(
      j.object({
        label: j.string(),
        value: j.string(),
      }),
    ),
  })
  .map((resp) => resp.dialling_hour_profiles || []);

export const RoutingProfileDecoder = j.object({
  name: j.string(),
  id: j.string(),
  arn: j.string(),
});

export const ListRoutingProfileResponseDecoder: j.Decoder<RoutingProfile[]> = j
  .object({
    routing_profiles: j.union(j.array(RoutingProfileDecoder), j.constant(null)),
  })
  .map((resp) => resp.routing_profiles || []);

export const GetCampaignResponseDecoder: j.Decoder<Campaign> = j
  .object({
    campaign_id: j.number(),
    name: j.string(),
    description: j.string(),
    default_list_id: j.number(),
    enable_manual_outbound: j.boolean(),
    manual_outbound_require_details: j.boolean(),
    enable_prepared_outbound_hangup: j.boolean(),
    enable_manual_outbound_when_offline: j.boolean(),
    num_lists: j.number(),
    num_completed: j.number(),
    progress: j.number(),
    num_agents: j.number(),
    archived: j.union(j.string(), j.constant(null)),
    dialler_type: j.oneOf(j.constant(DiallerType.Connect), j.constant(DiallerType.SIP)),
    campaign_type: j.oneOf(j.constant(CampaignType.Preview), j.constant(CampaignType.Predictive)),
    required_skills: j.array(j.string()),
    routing_profiles: j.array(j.string()),
    lists: j.array(
      j.object({
        list_id: j.number(),
        name: j.string(),
        is_active: j.boolean(),
        is_default_list: j.boolean(),
        total_leads: j.number(),
        num_completed: j.number(),
        progress: j.number(),
        start_time: j.union(j.string(), j.constant(null)),
        expiry_time: j.union(j.string(), j.constant(null)),
        num_attempts: j.number(),
        status_counts: j.dict(j.number()),
        aht: j.union(j.number(), j.constant(null)),
        penetration_rate: j.number(),
      }),
    ),
    agents: j.array(
      j.object({
        username: j.string(),
        full_name: j.string(),
        disabled: j.union(j.string(), j.constant(null)),
        skills: j.array(j.string()),
      }),
    ),
    preview_settings: j.union(
      j.object({
        push_preview_seconds: j.number(),
        agent_assigned_timeout_seconds: j.number(),
        ring_out_seconds: j.number(),
      }),
      j.constant(null),
    ),
    campaign_trunks: j.array(
      j.union(
        j.object({
          timezone: j.string(),
          trunk: j.string(),
        }),
        j.constant(null),
      ),
    ),
    predictive_settings: j.union(
      j.object({
        ring_time_seconds: j.number(),
        hold_drop_seconds: j.number(),
        max_lines_per_agent: j.number(),
        lookahead_time_seconds: j.number(),
        num_reserved_agents: j.number(),
        ivr_agents_call_id: j.union(j.string(), j.constant(null)),
        ivr_agents_no_call_id: j.union(j.string(), j.constant(null)),
        ivr_no_agents: j.union(j.string(), j.constant(null)),
        ivr_callback: j.union(j.string(), j.constant(null)),
        ivr_msg_bank: j.union(j.string(), j.constant(null)),
        leave_message_on_attempt: j.union(j.string(), j.constant(null)),
        answering_machine_message: j.union(j.string(), j.constant(null)),
        all_callbacks_as_preview: j.boolean(),
        trunk: j.union(j.string(), j.constant(null)),
      }),
      j.constant(null),
    ),
    lead_engine_settings: j.union(
      j.object({
        callback_reschedule_seconds: j.number(),
        callback_max_reschedules: j.number(),
        reassign_missed_callback_to_group: j.boolean(),
        duplicate_search_all_campaigns: j.boolean(),
        wash_endpoint_only: j.boolean(),
        dialling_hour_profile: j.string(),
        dialling_hours: j.optional(
          j.object({
            allow_public_holidays: j.boolean(),
            dialling_days: j.dict(
              j.union(
                j.object({
                  start_time_hour: j.number(),
                  start_time_min: j.number(),
                  end_time_hour: j.number(),
                  end_time_min: j.number(),
                }),
                j.constant(null),
              ),
            ),
          }),
        ),
        duplicate_search_days: j.number(),
        endpoint_attempt_interval_seconds: j.number(),
        endpoint_machine_interval_seconds: j.number(),
        endpoint_engaged_interval_seconds: j.number(),
        endpoint_no_answer_interval_seconds: j.number(),
        endpoint_daily_max_attempts: j.number(),
        external_id_attempt_interval_seconds: j.number(),
        external_id_daily_max_attempts: j.number(),
        lead_endpoint_total_max_attempts: j.number(),
        lead_total_max_attempts: j.number(),
        allow_contacted_duplication: j.boolean(),
        wash_lists: j.array(j.string()),
        exclusion_lists: j.array(j.string()),
        filters: j.array(
          j.object({
            name: j.string(),
            field: j.string(),
            match: j.string(),
          }),
        ),
      }),
      j.constant(null),
    ),
    sms_settings: j.union(
      j.object({
        enabled: j.boolean(),
        sms_provider: j.string(),
        reply_endpoint: j.string(),
        from_endpoint: j.string(),
        reply_grace_time_seconds: j.number(),
        mid_journey_attempts: j.number(),
        initial_template: j.string(),
        mid_journey_template: j.string(),
      }),
      j.constant(null),
    ),
    dispositions: j.array(
      j.object({
        title: j.string(),
        description: j.string(),
        outcome: j.oneOf(
          j.constant(OutcomeType.Contacted),
          j.constant(OutcomeType.Callback),
          j.constant(OutcomeType.NoAnswer),
          j.constant(OutcomeType.AnsweringMachine),
          j.constant(OutcomeType.Engaged),
          j.constant(OutcomeType.InvalidEndpoint),
          j.constant(OutcomeType.HoldDropped),
          j.constant(OutcomeType.Excluded),
          j.constant(OutcomeType.Skipped),
          j.constant(OutcomeType.Removed),
          j.constant(OutcomeType.RequiredAgentUnavailable),
          j.constant(OutcomeType.AssignmentExpired),
          j.constant(OutcomeType.Error),
          j.constant(OutcomeType.Fatal),
          j.constant(OutcomeType.AwaitingCallback),
        ),
        code: j.string(),
        sub_code: j.string(),
        attributes: j.array(
          j.object({
            attribute: j.string(),
            value: j.string(),
          }),
        ),
      }),
    ),
  })
  .map((item: any) => ({
    id: item.campaign_id,
    name: item.name,
    description: item.description,
    defaultListId: item.default_list_id,
    enableManualOutbound: item.enable_manual_outbound,
    manualOutboundRequireDetails: item.manual_outbound_require_details,
    enablePreparedOutboundHangup: item.enable_prepared_outbound_hangup,
    enableManualOutboundWhenOffline: item.enable_manual_outbound_when_offline,
    numLists: item.num_lists,
    numCompleted: item.num_completed,
    progress: item.progress * 100,
    numAgents: item.num_agents,
    archived: item.archived,
    diallerType: item.dialler_type,
    campaignType: item.campaign_type,
    requiredSkills: item.required_skills,
    routingProfiles: item.routing_profiles,
    lists: item.lists.map(
      (list: any): LeadListItem => ({
        id: list.list_id,
        name: list.name,
        isActive: list.is_active,
        isDefaultList: list.is_default_list,
        totalLeads: list.total_leads,
        numCompleted: list.num_completed,
        progress: list.progress * 100,
        startTime: list.start_time,
        expiryTime: list.expiry_time,
        numAttempts: list.num_attempts,
        statusCounts: list.status_counts,
        aht: list.aht,
        penetrationRate: list.penetration_rate * 100,
      }),
    ),
    agents: item.agents.map(
      (agent: any): CampaignAgent => ({
        username: agent.username,
        fullName: agent.full_name,
        disabled: agent.disabled,
        skills: agent.skills,
      }),
    ),
    previewSettings: item.preview_settings
      ? {
          pushPreviewSeconds: item.preview_settings.push_preview_seconds,
          agentAssignedTimeoutSeconds: item.preview_settings.agent_assigned_timeout_seconds,
          ringOutSeconds: item.preview_settings.ring_out_seconds,
        }
      : undefined,
    predictiveSettings: item.predictive_settings
      ? {
          ringTimeSeconds: item.predictive_settings.ring_time_seconds,
          holdDropSeconds: item.predictive_settings.hold_drop_seconds,
          maxLinesPerAgent: item.predictive_settings.max_lines_per_agent,
          lookaheadTimeSeconds: item.predictive_settings.lookahead_time_seconds,
          numReservedAgents: item.predictive_settings.num_reserved_agents,
          ivrAgentsCallId: item.predictive_settings.ivr_agents_call_id,
          ivrAgentsNoCallId: item.predictive_settings.ivr_agents_no_call_id,
          ivrNoAgents: item.predictive_settings.ivr_no_agents,
          ivrCallback: item.predictive_settings.ivr_callback,
          ivrMsgBank: item.predictive_settings.ivr_msg_bank,
          leaveMessageOnAttempt: item.predictive_settings.leave_message_on_attempt,
          answeringMachineMessage: item.predictive_settings.answering_machine_message,
          allCallbacksAsPreview: item.predictive_settings.all_callbacks_as_preview,
          trunk: item.predictive_settings.trunk || '',
        }
      : undefined,
    campaignTrunks: (item.campaign_trunks || []).map(
      (trunk: any): CampaignTrunk => ({
        timezone: trunk.timezone,
        trunk: trunk.trunk,
      }),
    ),
    leadEngineSettings: item.lead_engine_settings
      ? {
          callbackRescheduleSeconds: item.lead_engine_settings.callback_reschedule_seconds || 0,
          callbackMaxReschedules: item.lead_engine_settings.callback_max_reschedules || 0,
          reassignMissedCallbackToGroup: item.lead_engine_settings.reassign_missed_callback_to_group || false,
          duplicateSearchAllCampaigns: item.lead_engine_settings.duplicate_search_all_campaigns,
          washEndpointOnly: item.lead_engine_settings.wash_endpoint_only,
          diallingHourProfile: item.lead_engine_settings.dialling_hour_profile,
          diallingHours: item.lead_engine_settings.dialling_hours
            ? {
                allowPublicHolidays: item.lead_engine_settings.dialling_hours.allow_public_holidays,
                diallingDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].reduce(
                  (out, day) => {
                    const hrs = item.lead_engine_settings.dialling_hours.dialling_days[day];
                    return {
                      ...out,
                      [day]: hrs
                        ? {
                            startTimeHour: hrs.start_time_hour,
                            startTimeMin: hrs.start_time_min,
                            endTimeHour: hrs.end_time_hour,
                            endTimeMin: hrs.end_time_min,
                          }
                        : null,
                    };
                  },
                  {},
                ),
              }
            : undefined,
          duplicateSearchDays: item.lead_engine_settings.duplicate_search_days,
          endpointAttemptIntervalSeconds: item.lead_engine_settings.endpoint_attempt_interval_seconds,
          endpointMachineIntervalSeconds: item.lead_engine_settings.endpoint_machine_interval_seconds,
          endpointEngagedIntervalSeconds: item.lead_engine_settings.endpoint_engaged_interval_seconds,
          endpointNoAnswerIntervalSeconds: item.lead_engine_settings.endpoint_no_answer_interval_seconds,
          endpointDailyMaxAttempts: item.lead_engine_settings.endpoint_daily_max_attempts,
          externalIdAttemptIntervalSeconds: item.lead_engine_settings.external_id_attempt_interval_seconds,
          externalIdDailyMaxAttempts: item.lead_engine_settings.external_id_daily_max_attempts,
          leadEndpointTotalMaxAttempts: item.lead_engine_settings.lead_endpoint_total_max_attempts,
          leadTotalMaxAttempts: item.lead_engine_settings.lead_total_max_attempts,
          allowContactedDuplication: item.lead_engine_settings.allow_contacted_duplication,
          washLists: item.lead_engine_settings.wash_lists,
          exclusionLists: item.lead_engine_settings.exclusion_lists,
          filters: item.lead_engine_settings.filters.map((f: any) => ({
            name: f.name,
            field: f.field,
            match: f.match,
          })),
        }
      : undefined,
    smsSettings: item.sms_settings
      ? {
          enabled: item.sms_settings.enabled,
          smsProvider: item.sms_settings.sms_provider,
          replyEndpoint: item.sms_settings.reply_endpoint,
          fromEndpoint: item.sms_settings.from_endpoint,
          replyGraceTimeSeconds: item.sms_settings.reply_grace_time_seconds,
          midJourneyAttempts: item.sms_settings.mid_journey_attempts,
          initialTemplate: item.sms_settings.initial_template,
          midJourneyTemplate: item.sms_settings.mid_journey_template,
        }
      : undefined,
    dispositions: item.dispositions.map((disposition: any) => ({
      title: disposition.title,
      description: disposition.description,
      outcome: disposition.outcome || undefined,
      code: disposition.code,
      subCode: disposition.sub_code,
      attributes: disposition.attributes.map((attr: any) => ({
        attribute: attr.attribute,
        value: attr.value,
      })),
    })),
  }));

const GetCampaignListItemResponseDecoder: j.Decoder<CampaignListItem> = j
  .object({
    id: j.number(),
    name: j.string(),
    dialler_type: j.oneOf(j.constant(DiallerType.Connect), j.constant(DiallerType.SIP)),
    campaign_type: j.oneOf(j.constant(CampaignType.Preview), j.constant(CampaignType.Predictive)),
    progress: j.number(),
    num_lists: j.number(),
    num_agents: j.number(),
    num_leads: j.number(),
    num_attempts: j.number(),
    finished_leads: j.number(),
    archived: j.union(j.string(), j.constant(null)),
  })
  .map((item: any) => ({
    id: item.id,
    name: item.name,
    diallerType: item.dialler_type,
    campaignType: item.campaign_type,
    progress: item.progress * 100,
    numLists: item.num_lists,
    numAgents: item.num_agents,
    numLeads: item.num_leads,
    numAttempts: item.num_attempts,
    finishedLeads: item.finished_leads,
    archived: item.archived,
  }));

export const GetCampaignsResponseDecoder: j.Decoder<{ campaigns: CampaignListItem[] }> = j.object({
  campaigns: j.array(GetCampaignListItemResponseDecoder),
});

const GetUnallocatedAgentItemResponseDecoder: j.Decoder<UnallocatedAgent> = j
  .object({
    full_name: j.string(),
    username: j.string(),
  })
  .map((item: any) => ({
    name: item.full_name,
    username: item.username,
  }));

export const GetUnallocatedAgentsResponseDecoder: j.Decoder<UnallocatedAgent[]> = j
  .object({
    agents: j.union(j.array(GetUnallocatedAgentItemResponseDecoder), j.constant(null)),
  })
  .map((resp) => resp.agents ?? []);

export const GetLeadListResponseDecoder: j.Decoder<LeadList> = j
  .object({
    list_id: j.number(),
    name: j.string(),
    description: j.string(),
    priority: j.number(),
    is_active: j.boolean(),
    is_default_list: j.boolean(),
    total_leads: j.number(),
    num_completed: j.number(),
    progress: j.number(),
    start_time: j.union(j.string(), j.constant(null)),
    expiry_time: j.union(j.string(), j.constant(null)),
    num_attempts: j.number(),
    status_counts: j.dict(j.number()),
    aht: j.union(j.number(), j.constant(null)),
    penetration_rate: j.number(),
    required_skills: j.array(j.string()),
  })
  .map((item: any) => ({
    id: item.list_id,
    name: item.name,
    description: item.description,
    priority: item.priority,
    isActive: item.is_active,
    isDefaultList: item.is_default_list,
    totalLeads: item.total_leads,
    numCompleted: item.num_completed,
    progress: item.progress * 100,
    startTime: item.start_time,
    endTime: item.expiry_time,
    numAttempts: item.num_attempts,
    statusCounts: item.status_counts,
    aht: item.aht,
    penetrationRate: item.penetration_rate * 100,
    requiredSkills: item.required_skills,
  }));

export const GetUploadLeadListResponseDecoder: j.Decoder<LeadListUploadResponse> = j
  .object({
    import_id: j.number(),
    import_error_count: j.number(),
    import_warning_count: j.number(),
    parse_error_count: j.number(),
    record_count: j.number(),
    valid_count: j.number(),
  })
  .map((item: any) => ({
    importId: item.import_id,
    importErrorCount: item.import_error_count,
    importWarningCount: item.import_warning_count,
    parseErrorCount: item.parse_error_count,
    recordCount: item.record_count,
    validCount: item.valid_count,
  }));

export const GetListImportResponseDecoder: j.Decoder<ListImportItem[]> = j.array(
  j
    .object({
      import_id: j.number(),
      import_record_number: j.number(),
      import_error: j.union(j.string(), j.constant(null)),
      import_warning: j.union(j.string(), j.constant(null)),
      original_data: j.union(j.string(), j.constant(null)),
      parse_error: j.union(j.string(), j.constant(null)),
      parsed: j.union(j.string(), j.constant(null)),
    })
    .map((item: any) => ({
      importId: item.import_id,
      importRecordNumber: item.import_record_number,
      importError: item.import_error,
      importWarning: item.import_warning,
      originalData: item.original_data,
      parseError: item.parse_error,
      parsed: item.parsed,
    })),
);

const GetCampaignAgentItemResponseDecoder: j.Decoder<CampaignAgent> = j
  .object({
    username: j.string(),
    full_name: j.string(),
    disabled: j.union(j.string(), j.constant(null)),
    skills: j.array(j.string()),
  })
  .map((item: any) => ({
    username: item.username,
    fullName: item.full_name,
    skills: item.skills,
    disabled: item.disabled,
  }));

export const GetCampaignAgentsResponseDecoder: j.Decoder<CampaignAgent[]> = j
  .object({
    agents: j.array(GetCampaignAgentItemResponseDecoder),
  })
  .map((item: any) => item.agents);

const GetListCallbackItemResponseDecoder: j.Decoder<Callback> = j
  .object({
    callback_id: j.number(),
    scheduled: j.string(),
    for_agent: j.union(j.string(), j.constant(null)),
    endpoint: j.string(),
    notes: j.string(),
    timezone: j.string(),
    lead_name: j.string(),
    attempt_id: j.union(j.number(), j.constant(null)),
    disabled: j.union(j.string(), j.constant(null)),
  })
  .map((item: any) => ({
    callbackId: item.callback_id,
    scheduled: item.scheduled,
    forAgent: item.for_agent,
    endpoint: item.endpoint,
    notes: item.notes,
    timezone: item.timezone,
    leadName: item.lead_name,
    attemptId: item.attempt_id,
    disabled: item.disabled,
  }));

export const GetListCallbacksResponseDecoder: j.Decoder<Callback[]> = j
  .object({
    callbacks: j.array(GetListCallbackItemResponseDecoder),
  })
  .map((item: any) => item.callbacks);

const GetPublicHolidayItemResponseDecoder: j.Decoder<PublicHoliday> = j
  .object({
    name: j.string(),
    day: j.number(),
    month: j.number(),
    year: j.number(),
  })
  .map((item: any) => ({
    name: item.name,
    day: item.day,
    month: item.month,
    year: item.year,
  }));

export const GetPublicHolidaysResponseDecoder: j.Decoder<PublicHoliday[]> = j
  .object({
    public_holidays: j.array(GetPublicHolidayItemResponseDecoder),
  })
  .map((item: any) => item.public_holidays);

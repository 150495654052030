import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import axios, { CancelTokenSource } from 'axios';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import EmptyState from '~components/EmptyState';
import { createAsyncQueue, getAsyncQueues, updateAsyncQueue } from '~pages/AsyncManagement/api';
import CreateEditQueueModal from '~pages/AsyncManagement/QueueList/CreateEditQueueModal';
import QueueCard from '~pages/AsyncManagement/QueueList/QueueCard';
import Routes from '~providers/RouteProvider/Routes';

import { AsyncQueue, CreateEditAsyncQueue } from '../domain';

const QueueList = () => {
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [editableRef, setEditableRef] = useState<string | undefined>(undefined);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [asyncQueues, setAsyncQueues] = useState<AsyncQueue[]>([]);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());
  const editableQueue = useMemo(() => asyncQueues.find((item) => item.queue === editableRef), [editableRef]);

  const loadQueue = async () => {
    setLoading(true);
    setError(false);

    let resp: AsyncQueue[] | undefined;
    try {
      axiosCancelRef.current = axios.CancelToken.source();
      resp = await getAsyncQueues(axiosCancelRef.current);
    } catch (e) {
      setError(true);
      setLoading(false);
      return;
    }

    // Returns undefined if request is canceled
    if (resp === undefined) return;

    setAsyncQueues(resp);
    setLoading(false);
  };

  useEffect(() => {
    loadQueue();

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, []);

  const openCreateModal = () => {
    setCreateModalOpen(true);
  };

  const closeCreateEditModal = () => {
    setCreateModalOpen(false);
    setEditableRef(undefined);
  };

  const onAccept = useCallback(
    async (queue: CreateEditAsyncQueue) => {
      setSubmittingData(true);
      try {
        if (editableQueue) {
          await updateAsyncQueue(editableQueue.queue, queue);
        } else {
          await createAsyncQueue(queue);
        }
      } catch (e) {
        setSubmittingData(false);
        // Modal catches error to prevent form reset on create failure
        return Promise.reject();
      }

      setSubmittingData(false);
      closeCreateEditModal();
      loadQueue();
    },
    [editableQueue],
  );

  const displayList = useMemo(
    () =>
      asyncQueues.map((item, index) => {
        return (
          <QueueCard
            key={item.queue}
            to={generatePath(Routes.asyncQueueDetails.path, { queue: item.queue })}
            queue={item}
            onEdit={() => setEditableRef(item.queue)}
          />
        );
      }),
    [asyncQueues],
  );

  return (
    <>
      <Grid sx={{ marginBottom: 2 }} container spacing={1} alignContent='center'>
        <Hidden smDown>
          <Grid item sm={9}></Grid>
        </Hidden>

        <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} sm={3}>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            fullWidth
            startIcon={<AddIcon />}
            onClick={openCreateModal}>
            Create Queue
          </Button>
        </Grid>
      </Grid>

      <AsyncLoader isLoading={loading && asyncQueues.length === 0}>
        <Grid container spacing={1} alignContent='center'>
          {asyncQueues.length === 0 && <EmptyState type='no-items-2' text='No queues available' />}

          {asyncQueues.length > 0 && (
            <Grid item xs={12}>
              <List>{displayList}</List>
              {loading && asyncQueues.length > 0 && <DotLoader align='center' />}
              {!loading && (
                <Typography variant='body2' align='center' color='textSecondary'>
                  No more results to display
                </Typography>
              )}
              {error && asyncQueues.length > 0 && (
                <Typography variant='body2' align='center' color='textSecondary'>
                  Failed to load queues
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </AsyncLoader>

      <CreateEditQueueModal
        open={createModalOpen || Boolean(editableQueue)}
        queue={editableQueue}
        submitting={submittingData}
        onAccept={onAccept}
        onClose={closeCreateEditModal}
      />
    </>
  );
};

export default QueueList;

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import CircularProgressWithLabel from '~components/CircularProgressWithLabel';
import EmptyState from '~components/EmptyState';
import ControlledFileUpload from '~components/Form/ControlledFileUpload';
import InfoCard from '~components/InfoCard';
import OberonDialog from '~components/OberonDialog';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { ExclusionListUploadResponse } from '../../domain';

interface Form {
  file: File | null;
}

// Used by upload step
interface Progress {
  isUploading: boolean;
  uploadProgress: number;
  isProcessing: boolean;
}

interface Actions {
  uploadProgress: Progress;
}

interface Error {
  text: string;
  subText: string;
}

interface BulkAddEndpointsModalProps {
  open: boolean;
  submitting: boolean;
  onAccept: (
    file: File,
    onUploadProgress: (progress: number) => void,
  ) => Promise<ExclusionListUploadResponse | undefined>;
  onClose: () => void;
}

const BulkAddEndpointsModal = ({ open, submitting, onAccept, onClose }: BulkAddEndpointsModalProps) => {
  const isLoading = submitting;
  const [uploadResults, setUploadResults] = useState<ExclusionListUploadResponse | undefined>(undefined);
  const [uploadError, setUploadError] = useState<Error | undefined>(undefined);
  const [actions, setActions] = useState<Actions>({
    uploadProgress: {
      isUploading: false,
      uploadProgress: 0,
      isProcessing: false,
    },
  });
  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm<Form>({
    defaultValues: {
      file: null,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  // Handles all async based actions
  useEffect(() => {
    // Reset form on close
    return function cleanupCreateExclusionListModal() {
      setUploadResults(undefined);
      setUploadError(undefined);
      reset();
    };
  }, [open]);

  const handleProgress = (progress: number) => {
    if (progress < 100) {
      setActions((prev) => ({
        ...prev,
        uploadProgress: { ...prev.uploadProgress, isUploading: true, uploadProgress: progress },
      }));
      return;
    }

    setActions((prev) => ({
      ...prev,
      uploadProgress: { ...prev.uploadProgress, isUploading: false, uploadProgress: 0, isProcessing: true },
    }));
  };

  const handleError = (e: any) => {
    if (e instanceof APIError) {
      setUploadError({ text: 'Unable to request data from backend', subText: e.message });
    }

    if (e instanceof UnsupportedStructureError) {
      setUploadError({ text: 'Data from backend Invalid', subText: 'Unable to decode response' });
    }
  };

  const onSubmit = handleSubmit(async (data: Form) => {
    let validationResults;

    setActions((prev) => ({
      ...prev,
      uploadProgress: { ...prev.uploadProgress, isUploading: true },
    }));
    setUploadError(undefined);
    setUploadResults(undefined);

    try {
      // file should never be null at this point as it is a required field
      validationResults = await onAccept(data.file!, handleProgress);
    } catch (e) {
      handleError(e);
      return;
    } finally {
      setActions((prev) => ({
        ...prev,
        uploadProgress: { ...prev.uploadProgress, isUploading: false, uploadProgress: 0, isProcessing: false },
      }));
    }

    setUploadResults(validationResults);
  });

  const errorResultDisplay =
    uploadResults !== undefined
      ? uploadResults.badEndpoints.map((endpoint, index) => (
          <li key={index}>
            <Typography variant='body1' component='p' color='textPrimary'>
              {endpoint}
            </Typography>
          </li>
        ))
      : [];

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title='Bulk Add Endpoints into Exclusion List'
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ControlledFileUpload
              control={control}
              rules={{ required: 'File is required.' }}
              name='file'
              label='File'
              disabled={isLoading}
              required={true}
              error={Boolean(errors.file)}
              helperText={errors.file?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              type='submit'
              variant='contained'
              disableElevation
              color='primary'
              disabled={isLoading}
              loading={isLoading}>
              Upload
            </LoadingButton>
          </Grid>

          {uploadError && <EmptyState type='error' text={uploadError.text} subText={uploadError.subText} />}

          {!uploadError && (
            <>
              {actions.uploadProgress.isUploading && (
                <Grid style={{ marginTop: 40, textAlign: 'center' }} item xs={12}>
                  <CircularProgressWithLabel value={actions.uploadProgress.uploadProgress} />
                  <Typography variant='body1' component='p' color='textSecondary'>
                    Uploading Endpoints
                  </Typography>
                </Grid>
              )}

              {actions.uploadProgress.isProcessing && (
                <Grid style={{ marginTop: 40, textAlign: 'center' }} item xs={12}>
                  <CircularProgress />
                  <Typography variant='body1' component='p' color='textSecondary'>
                    Processing Endpoints
                  </Typography>
                </Grid>
              )}

              {!actions.uploadProgress.isProcessing && !actions.uploadProgress.isUploading && uploadResults && (
                <Grid style={{ marginTop: 16 }} item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <InfoCard
                        variant='success'
                        icon={CheckCircleIcon}
                        text={uploadResults.validCount}
                        subText='Valid Rows'
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <InfoCard
                        variant='danger'
                        icon={CancelIcon}
                        text={uploadResults.importErrorCount}
                        subText='Rows with Import Errors'
                      />
                    </Grid>
                  </Grid>

                  {errorResultDisplay.length > 0 && (
                    <Grid style={{ marginTop: 16 }} item xs={12}>
                      <Typography variant='h4' component='h1' gutterBottom>
                        Errors
                      </Typography>

                      <Typography variant='body1' component='p' paragraph>
                        We were unable to add the following endpoints to the exclusion lists as they failed parsing.
                      </Typography>

                      <ul>{errorResultDisplay}</ul>
                    </Grid>
                  )}
                </Grid>
              )}
            </>
          )}
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>
        </>
      }
    />
  );
};

export default BulkAddEndpointsModal;

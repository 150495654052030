import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';

import ActionDialog from '~components/ActionDialog';
import AgentCard from '~components/AgentCard';
import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import EmptyState from '~components/EmptyState';
import useDebounce from '~hooks/useDebounce';
import { CampaignAssignRecord } from '~pages/CampaignManagement/domain';
import useAgentSearch from '~pages/SystemManagement/AgentList/useAgentSearch';
import { useNotification } from '~providers/NotificationProvider';
import Routes from '~providers/RouteProvider/Routes';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { createAgent, deleteAgentByUsername, getCampaignAssignList, updateAgentByUsername } from '../api';
import { Agent, AgentUpdateRequest } from '../domain';
import CreateEditAgentModal from './CreateEditAgentModal';

interface Query {
  search: string;
  filter: string;
}

const AgentList = () => {
  const { pushNotification } = useNotification();
  const [removeConfirmOpen, setRemoveConfirmOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const [editableAgentRef, setEditableAgentRef] = useState<string | undefined>(undefined);
  const [deletableAgentRef, setDeletableAgentRef] = useState<string | undefined>(undefined);
  const [query, setQuery] = useState<Query>({ search: '', filter: '' });
  const [assignableCampaigns, setAssignableCampaigns] = useState<CampaignAssignRecord[]>([]);
  const [loadingCampaigns, setLoadingCampaigns] = useState<boolean>(true);
  const { campaignId } = useParams() as { campaignId: string };
  const debouncedSearch = useDebounce(query.search, 500);
  const { loading, error, agents, hasMore, getNextPage, reload } = useAgentSearch(
    +campaignId,
    debouncedSearch,
    query.filter,
  );
  const observer = useRef<IntersectionObserver | undefined>(undefined);
  const editableAgent = useMemo(() => {
    return agents.find((item) => item.username === editableAgentRef);
  }, [editableAgentRef]);
  const deletableAgent = useMemo(() => {
    return agents.find((item) => item.username === deletableAgentRef);
  }, [deletableAgentRef]);
  const noSearchOrFilterSet = query.search === '' && query.filter === '';
  const lastDataElement = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          getNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, getNextPage],
  );

  const onQueryChange = useCallback(async (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleRemoveConfirmationOpen = (username: string) => () => {
    setDeletableAgentRef(username);
    setRemoveConfirmOpen(true);
  };

  const handleRemoveConfirmationClose = () => {
    setDeletableAgentRef(undefined);
    setRemoveConfirmOpen(false);
  };

  const toggleCreateAgentModal = () => {
    setCreateModalOpen((prev) => !prev);
  };

  const setEditAgentData = (username: string) => () => {
    setEditableAgentRef(username);
  };

  const removeEditAgentData = () => {
    setEditableAgentRef(undefined);
  };

  const addAgent = async (data: AgentUpdateRequest) => {
    setSubmittingData(true);

    try {
      await createAgent(data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Created agent ${data.firstName} ${data.lastName}`);
    setCreateModalOpen(false);
    reload();
  };

  const onRemoveAgent = (data: Agent | undefined) => async () => {
    if (data != undefined) {
      try {
        await deleteAgentByUsername(data.username);
      } catch (e) {
        pushNotification('error', (e as APIError | UnsupportedStructureError).message);
        return;
      }

      pushNotification('success', `Deleted agent ${data.firstName} ${data.lastName}`);
    }
  };

  useEffect(() => {
    const loadCampaignData = async () => {
      let campaigns;

      try {
        campaigns = await getCampaignAssignList();
      } catch (e) {
        pushNotification('error', 'unable to load campaigns');
        return;
      } finally {
        //setInitialDataLoad(false);
      }

      setAssignableCampaigns(campaigns);
      setLoadingCampaigns(false);
    };
    if (loadingCampaigns) {
      loadCampaignData();
    }
  });

  const updateAgent = async (data: AgentUpdateRequest) => {
    setSubmittingData(true);

    try {
      await updateAgentByUsername(data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Updated agent ${data.firstName} ${data.lastName}`);
    removeEditAgentData();
    reload();
  };

  const deleteAgent = (data: Agent | undefined) => async () => {
    if (data != undefined) {
      try {
        await deleteAgentByUsername(data.username);
      } catch (e) {
        pushNotification('error', (e as APIError | UnsupportedStructureError).message);
        return;
      }

      pushNotification('success', `Deleted agent ${data.firstName} ${data.lastName}`);
      handleRemoveConfirmationClose();
      reload();
    }
  };

  const displayList = useMemo(
    () =>
      agents.map((item: Agent, index: number) => {
        const viewLink = generatePath(Routes.agentDetails.path, { username: item.username });
        let props: any = {
          key: item.username,
          fullname: item.fullName,
          username: item.username,
          campaign: item.campaignName || 'N/A',
          to: viewLink,
          menuItems: [
            { name: 'Edit', action: setEditAgentData(item.username) },
            { name: 'Delete', action: handleRemoveConfirmationOpen(item.username) },
          ],
        };

        if (index === agents.length - 1) {
          props = { ...props, ref: lastDataElement };
        }

        return <AgentCard key={item.username} {...props} />;
      }),
    [agents, lastDataElement, onRemoveAgent],
  );

  let campaignListDisplay = assignableCampaigns.map((item, index) => (
    <MenuItem key={index} value={item.id}>
      {item.name}
    </MenuItem>
  ));

  // Add None selection as agents can exist without having an assigned campaign
  campaignListDisplay = [
    <MenuItem key='none' value=''>
      None
    </MenuItem>,
    ...campaignListDisplay,
  ];

  return (
    <>
      <Grid sx={{ marginBottom: 2 }} container spacing={1} alignContent='center'>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            variant='outlined'
            label='Search'
            id='search'
            name='search'
            defaultValue={query.search}
            onChange={onQueryChange}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            select
            variant='outlined'
            id='filter'
            name='filter'
            label='Assigned to Campaign'
            value={query.filter}
            onChange={onQueryChange}>
            {campaignListDisplay}
          </TextField>
        </Grid>

        <Hidden smDown>
          <Grid item md={4}></Grid>
        </Hidden>

        <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={2}>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            fullWidth
            startIcon={<AddIcon />}
            onClick={toggleCreateAgentModal}>
            Create Agent
          </Button>
        </Grid>
      </Grid>

      <AsyncLoader isLoading={loading && agents.length === 0}>
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12}>
            {agents.length > 0 && (
              <>
                <List>{displayList}</List>
                {loading && agents.length > 0 && <DotLoader align='center' />}

                {!loading && !hasMore && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    No more results to display
                  </Typography>
                )}

                {error && agents.length > 0 && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    Failed to load agents
                  </Typography>
                )}
              </>
            )}

            {agents.length === 0 && !noSearchOrFilterSet && (
              <EmptyState
                type='no-records-found'
                text='No agents found matching your search criteria'
                subText='Try selecting a different campaign or entering a different search name.'
              />
            )}

            {agents.length === 0 && noSearchOrFilterSet && (
              <EmptyState type='no-items-3' text='No agents currently exist' />
            )}
          </Grid>
        </Grid>

        <CreateEditAgentModal
          open={createModalOpen || Boolean(editableAgent)}
          submitting={submittingData}
          agentUsername={editableAgent?.username || undefined}
          onClose={editableAgent !== undefined ? removeEditAgentData : toggleCreateAgentModal}
          onAccept={editableAgent !== undefined ? updateAgent : addAgent}
        />

        <ActionDialog
          open={removeConfirmOpen}
          title='Are you sure you want to do this?'
          content={`You are about to delete ${deletableAgent?.username}, once you have completed this action it cannot be undone.`}
          onClose={handleRemoveConfirmationClose}
          onAccept={deleteAgent(deletableAgent)}
          primaryActionTitle='Remove'
        />
      </AsyncLoader>
    </>
  );
};

export default AgentList;

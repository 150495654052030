import LoadingButton from '@mui/lab/LoadingButton';
import green from '@mui/material/colors/green';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DataItem } from '~components/DataItem';
import { Contact } from '~providers/ConnectProvider/domain';
import { useLogRocket } from '~providers/LogRocketProvider';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { getPaymentDetails } from '../api';

interface PaymentGatewayProps {
  contact?: Contact;
  maxConnectionCapacityReached: boolean;
  onCloseTransferModal: () => void;
}

interface LoadingStates {
  page: boolean;
  fetchAction: boolean;
  initiateAction: boolean;
}

interface Form {
  paymentId: string;
}

const InitiatePaymentFlowButton = styled(LoadingButton)(({ theme }) => ({
  'color': '#ffffff',
  'backgroundColor': green[600],
  ':hover': {
    color: '#ffffff',
    backgroundColor: green[800],
  },
  ':disabled': {
    color: '#ffffff80',
    backgroundColor: `${green[600]}80`,
  },
}));

const PaymentGateway = ({ contact, maxConnectionCapacityReached, onCloseTransferModal }: PaymentGatewayProps) => {
  const { pushNotification } = useNotification();
  const logRocket = useLogRocket();
  const [paymentDetails, setPaymentDetails] = useState<Dictionary<string> | undefined>(undefined);
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({
    page: false,
    fetchAction: false,
    initiateAction: false,
  });
  // We do this rather than getValues from the form as the agent could accidentally change something input wise
  // but then hit the initial payment flow button and we want to make sure we are passing the correct value on that action
  const [paymentId, setPaymentId] = useState<string | undefined>(undefined);
  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
    setError,
    clearErrors,
  } = useForm<Form>({
    defaultValues: {
      paymentId: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  // Resets all properties to default values
  const defaultReset = () => {
    setPaymentDetails(undefined);
    setPaymentId(undefined);
    reset();
  };

  const onSubmit = handleSubmit(async (data: Form) => {
    setLoadingStates((prev) => ({ ...prev, page: true, fetchAction: true }));
    // Make sure we reset this properties default so we only
    // have it set if we successfully get details.
    setPaymentId(undefined);
    // If existing data existed and a user entered a new payment id that errored
    // we want to clear old data as to not confuse them
    setPaymentDetails(undefined);
    clearErrors('paymentId');

    try {
      setPaymentDetails(await getPaymentDetails(data.paymentId));
      setPaymentId(data.paymentId);
    } catch (e) {
      setError('paymentId', {
        type: 'manual',
        message: (e as APIError | UnsupportedStructureError).message,
      });
      return;
    } finally {
      setLoadingStates((prev) => ({ ...prev, page: false, fetchAction: false }));
    }
  });

  const initiatePaymentFlow = async () => {
    if (contact === undefined) {
      console.error('initiatePaymentFlow: Contact does not exist');
      pushNotification('error', 'Unable to initiate payment flow.');
      return;
    }

    if (maxConnectionCapacityReached) {
      console.error('Conference at capacity');
      pushNotification('error', 'Conference at capacity');
      return;
    }

    if (contact.initiatePaymentConference === undefined) {
      console.error('initiatePaymentFlow: initiatePaymentConference function does not exist');
      pushNotification('error', 'Unable to initiate payment flow.');
      return;
    }

    if (paymentId === undefined) {
      console.error('initiatePaymentFlow: paymentId is not set');
      return;
    }

    setLoadingStates((prev) => ({ ...prev, page: true, initiateAction: true }));

    try {
      await contact.initiatePaymentConference(paymentId);
    } catch (e) {
      pushNotification('error', 'Unable to initiate payment flow.');
      return;
    } finally {
      setLoadingStates((prev) => ({ ...prev, page: false, initiateAction: false }));
    }

    logRocket.trackEvent('transfer');
    logRocket.trackEvent('payment_gateway');

    onCloseTransferModal();
    defaultReset();
  };

  const paymentDetailsList =
    paymentDetails === undefined
      ? []
      : Object.keys(paymentDetails).map((key, index) => (
          <Grid key={index} item xs={12}>
            <DataItem disableMargin title={key} value={paymentDetails[key]} />
          </Grid>
        ));

  return (
    <>
      <form onSubmit={onSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='paymentId'
              control={control}
              rules={{ required: 'Payment ID is required.' }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Payment ID'
                  disabled={loadingStates.page}
                  required={true}
                  error={Boolean(errors?.paymentId)}
                  helperText={errors?.paymentId?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              type='submit'
              variant='contained'
              disableElevation
              color='primary'
              disabled={loadingStates.page}
              loading={loadingStates.fetchAction}>
              Get Payment Details
            </LoadingButton>
          </Grid>

          {paymentDetailsList.length > 0 && (
            <>
              <Grid item xs={12}>
                <InitiatePaymentFlowButton
                  fullWidth
                  variant='contained'
                  disableElevation
                  color='primary'
                  onClick={initiatePaymentFlow}
                  disabled={loadingStates.page}
                  loading={loadingStates.initiateAction}>
                  Initiate Payment Flow
                </InitiatePaymentFlowButton>
              </Grid>

              {paymentDetailsList}
            </>
          )}
        </Grid>
      </form>
    </>
  );
};

export default PaymentGateway;

import 'amazon-connect-streams';

import * as j from '@mojotech/json-type-validation';

export interface ChatHistoryItem {
  content: string;
  contentType: string;
  date: string;
  name: string;
  role: string;
  type: string;
}

export interface ChatHistoryResponse {
  list: ChatHistoryItem[] | undefined;
}

export enum RemoteStatusType {
  Busy = 'Busy',
  Available = 'Available',
  Offline = 'Offline',
  // If endpoint does not exist
  Misconfigured = 'Misconfigured',
}

/**
 * Cancelable was copy-pasted from "lodash"
 */
interface Cancelable {
  cancel(): void;
  flush(): void;
}

export const enum ContactDirection {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
}

export enum SoftphoneStreamType {
  AudioAgentToDialler = 'audio-agent-to-dialler',
  AudioDiallerToAgent = 'audio-dialler-to-agent',
}

export enum ContactAttributeType {
  Text = 'text',
  Link = 'link',
  Img = 'img',
}

// We define the agent state type here as string so we can get proper enum type coverage
// while also being able to extend the enum as types defined in streams is not 100% accurate.
// Refer to connect.AgentStateType for all documented references.
export const enum AgentStateType {
  Init = 'init',
  Routable = 'routable',
  NotRoutable = 'not_routable',
  Offline = 'offline',
  System = 'system',
  Error = 'error',
}

// These are all available states an agent can be in when the AgentStateType within connect is system.
export const enum AgentAvailableStates {
  Init = connect.AgentAvailStates.INIT,
  AfterCallWork = connect.AgentAvailStates.AFTER_CALL_WORK,
  Busy = connect.AgentAvailStates.BUSY,
  CallingCustomer = connect.AgentAvailStates.CALLING_CUSTOMER,
  Dialing = connect.AgentAvailStates.DIALING,
  Joining = connect.AgentAvailStates.JOINING,
  PendingAvailable = connect.AgentAvailStates.PENDING_AVAILABLE,
  PendingBusy = connect.AgentAvailStates.PENDING_BUSY,
}

// These are all possible error status for an agent
export const enum AgentErrorStates {
  Error = connect.AgentErrorStates.ERROR,
  AgentHungUp = connect.AgentErrorStates.AGENT_HUNG_UP,
  BadAddressAgent = connect.AgentErrorStates.BAD_ADDRESS_AGENT,
  BadAddressCustomer = connect.AgentErrorStates.BAD_ADDRESS_CUSTOMER,
  Default = connect.AgentErrorStates.DEFAULT,
  FailedConnectAgent = connect.AgentErrorStates.FAILED_CONNECT_AGENT,
  FailedConnectCustomer = connect.AgentErrorStates.FAILED_CONNECT_CUSTOMER,
  LineEngagedAgent = connect.AgentErrorStates.LINE_ENGAGED_AGENT,
  LineEngagedCustomer = connect.AgentErrorStates.LINE_ENGAGED_CUSTOMER,
  MissedCallAgent = connect.AgentErrorStates.MISSED_CALL_AGENT,
  MissedCallCustomer = connect.AgentErrorStates.MISSED_CALL_CUSTOMER,
  MultipleCCPWindows = connect.AgentErrorStates.MULTIPLE_CCP_WINDOWS,
  RealtimeCommunicationError = connect.AgentErrorStates.REALTIME_COMMUNICATION_ERROR,
}

// This does not include custom agent states as we wont know them until runtime
export type AgentState = AgentAvailableStates | AgentErrorStates;

// Exportable aws defined enums as they do not have proper exportable enums,
export const enum ContactStateType {
  // When would this ever be set?/ should we remove if there is no usecase
  Init = connect.ConnectionStateType.INIT,
  Incoming = connect.ContactStateType.INCOMING,
  Pending = connect.ContactStateType.PENDING,
  Connecting = connect.ContactStateType.CONNECTING,
  Connected = connect.ContactStateType.CONNECTED,
  Missed = connect.ContactStateType.MISSED,
  Error = connect.ContactStateType.ERROR,
  Ended = connect.ContactStateType.ENDED,
}

export const enum ContactType {
  Connect = 'connect',
  TSAChat = 'tsa_chat',
  // NOTE(Jae): 2020-01-28
  // Chat may be extended in the future to have various communication
  // channels, for example:
  // SMS = 'sms',
  // Email = 'email',
  // Facebook = 'facebook',
}

export const enum ConnectContactType {
  Chat = 'chat',
  QueueCallback = 'queue_callback',
  Task = 'task',
  Voice = 'voice',
}

export const enum ConnectionType {
  AGENT = connect.ConnectionType.AGENT,
  INBOUND = connect.ConnectionType.INBOUND,
  OUTBOUND = connect.ConnectionType.OUTBOUND,
  MONITORING = connect.ConnectionType.MONITORING,
}

/**
 * ContactMethods are the implementations shared across all contact types
 */
export interface ContactMethods {
  /**
   * endSession will end the voice or chat session.
   */
  endSession: () => Promise<void>;
  complete: () => Promise<void>;
  addThirdPartyConnection: (phoneNumberOrEndpoint: string | connect.Endpoint) => Promise<void>;
  destroyThirdPartyConnection: () => Promise<void>;
  initiateInternalTransfer: ((transferTargetAgent: string) => Promise<void>) | undefined;
  initiateExternalTransfer: ((phoneNumber: string) => Promise<void>) | undefined;
  cancelInternalTransfer: ((onlyClearTransferTarget?: boolean) => Promise<void>) | undefined;
  conferenceTransfer: ((quickConnectARN: string) => Promise<void>) | undefined;
  sendMessage: ((message: string) => Promise<void>) | undefined;
  sendIsTypingEvent: ((() => Promise<void>) & Cancelable) | undefined;
  accept: (() => Promise<void>) | undefined;
  putOnHold: (() => Promise<void>) | undefined;
  takeOffHold: (() => Promise<void>) | undefined;
  sendDTMF: ((digit: string, isThirdPartyConnection: boolean) => Promise<void>) | undefined;
  initiatePaymentConference: ((paymentId: string) => Promise<void>) | undefined;
}

/**
 * CommonContact is the interface that contains all the common variables
 * shared across the contact types.
 */
export interface CommonContact extends ContactMethods {
  type: ContactType;
  /**
   * id is a tuple made-up of type and id of the contact.
   * ie. "connect__5061d7bc-4341-41cb-88f6-e8bf8eb336b9"
   */
  id: string;
  campaignId: number | undefined;
  messages: Message[];
  /**
   * lastViewedMessageTime is the last time the contact was viewed.
   * This is used to compute how many unread messages there are for a contact
   */
  lastViewedMessageTime: number;
  lastTypeEventTime: number;
}

export interface ContactAttribute {
  type: ContactAttributeType;
  key: string;
  value: string;
}

interface ConnectContact {
  contactType: ConnectContactType;
  contactId: string;
  attemptId: number | undefined;
  leadId: number | undefined;
  initialContactId: string;
  // Used for voice only
  phoneNumber: string;
  type: string;
  isMultiPartyCallEnabled?: boolean;
  isRecordingPaused: boolean;
  // Used for chat only
  name: string | null;
  statusType: ContactStateType;
  statusTimestamp: Date;
  direction: ContactDirection;
  // Stores the username of the agent being transferred too. Set dynamically by core-api transfer endpoints.
  transferTargetAgent: string | undefined;
  // Used for voice only, and has no relation with chat at all
  isOnHold: boolean;
  hasActiveContactConnection: boolean;
  hasActiveThirdPartyConnection: boolean;
  // Dictates whether contact flow has contact lens and real time analytics enabled
  hasRealtimeAnalytics: boolean;
  // Optional dependant on client organisation
  brand: string | null;
  // Any other values from contact.getAttributes() that are not expected by default
  attributes: ContactAttribute[];
  queueARN: string;
  queue: string;
  queueTimestamp: Date;
  // all voice connections
  connections: ConnectVoiceConnection[];
}

export interface ConnectVoiceConnection {
  // connectionId's are not unique references to connections (If a warm transfer is performed connectionIds will be different for this transfer agent)
  connectionId: string;
  type: ConnectionType;
  endpoint: string;
  isActive: boolean;
  isConnected: boolean;
  isConnecting: boolean;
  isInitialConnection: boolean;
  onHold: boolean;
  onMute: boolean;
  state: string;
  stateTimestamp: Date;
  // connection level API
  putOnHold: () => Promise<void>;
  takeOffHold: () => Promise<void>;
  mute: () => Promise<void>;
  unmute: () => Promise<void>;
  sendDTMF: (digit: string) => Promise<void>;
  endConnection: () => Promise<void>;
}

export type Contact = CommonContact & ConnectContact;

export interface ConnectAgentStatus {
  startTimestamp: string | null;
  status: AgentState;
  type: AgentStateType;
}

export type ConnectAgentStateDefinition = Omit<ConnectAgentStatus, 'startTimestamp'>;

export interface ConnectAgent {
  name: string;
  routingProfile: string;
  defaultOutboundQueue: string;
  defaultOutboundQueueARN: string;
  status: AgentState;
  // TODO: refactor status/ statusType/ Timestamp to be a single status object of type ConnectAgentStatus
  statusType: AgentStateType;
  statusTimestamp: Date;
  softphoneAutoAnswerEnabled: boolean;
  // Should only be used for voice functionality else ignored
  isMuted: boolean;
  // Tells us that the agent object is initialised and ready to use
  initialized: boolean;
  changeStatus: (stateName: string) => Promise<ConnectAgentStateDefinition>;
  mute: () => Promise<void>;
  unmute: () => Promise<void>;
  dial: (phoneNumber: string, outboundQueueARN?: string) => Promise<void>;
  setOnline: () => Promise<void>;
  setOffline: () => Promise<void>;
  /**
   * ONLY USE IF needing visual state updates. Will be removed once connect streams bug is fixed
   *
   * This function exists as a workaround to make sure the agent states routing profile property gets updated ASAP.
   * Currently, there is a bug in connect streams, and we cannot get this update until 30 seconds after it has been made.
   *
   * Reference: https://github.com/amazon-connect/amazon-connect-streams/issues/459 */
  updateRoutingProfile: (routingProfileName: string) => void;
}

export interface ConnectOnTypingEvent {
  chatDetails: {
    initialContactId: string;
    contactId: string;
  };
  data: {
    ParticipantRole: MessageRoleType;
    ContentType: 'application/vnd.amazonaws.connect.event.typing';
    AbsoluteTime: string;
  };
}

export enum MessageRoleType {
  Agent = 'AGENT',
  System = 'SYSTEM',
  Customer = 'CUSTOMER',
}

export enum MessageType {
  Message = 'MESSAGE',
  Event = 'EVENT',
}

export enum MessageContentType {
  TextPlain = 'text/plain',
  ConnectionAcknowledged = 'application/vnd.amazonaws.connect.event.connection.acknowledged',
  Typing = 'application/vnd.amazonaws.connect.event.typing',
  ParticipantJoined = 'application/vnd.amazonaws.connect.event.participant.joined',
  ParticipantLeft = 'application/vnd.amazonaws.connect.event.participant.left',
  TransferSucceeded = 'application/vnd.amazonaws.connect.event.transfer.succeeded',
  TransferFailed = 'application/vnd.amazonaws.connect.event.transfer.failed',
  ChatEnded = 'application/vnd.amazonaws.connect.event.chat.ended',
}

export interface Message {
  role: MessageRoleType;
  content: string;
  name: string;
  date: string;
  type: MessageType;
  contentType: MessageContentType;
}

export interface TypingEvent {
  role: MessageRoleType;
  contactId: string;
  type: MessageContentType.Typing;
  absoluteTime: string;
}

export interface TimeSeriesStats {
  timestamp: string;
  packetsLost: number;
  packetsLostDelta: number;
  packetsCount: number;
  packetsCountDelta: number;
  softphoneStreamType: SoftphoneStreamType;
  jitterBufferMillis: number;
  roundTripTimeMillis: number;
}

export const ChatHistoryDecoder: j.Decoder<ChatHistoryResponse> = j.object({
  list: j.optional(
    j.array(
      j.object({
        content: j.string(),
        contentType: j.string(),
        date: j.string(),
        name: j.string(),
        role: j.string(),
        type: j.string(),
      }),
    ),
  ),
});

import CallIcon from '@mui/icons-material/Call';
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import Timeline from '@mui/lab/Timeline/Timeline';
import Chip from '@mui/material/Chip';
import amber from '@mui/material/colors/amber';
import blue from '@mui/material/colors/blue';
import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import red from '@mui/material/colors/red';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateTime } from 'luxon';
import React from 'react';

import { DataItem } from '~components/DataItem';
import OberonDialog from '~components/OberonDialog';

import { Lead, LeadAttempt, LeadStatusType } from '../../../domain';

interface LeadDetailsModalProps {
  open: boolean;
  lead?: Lead;
  onClose: () => void;
}

interface AttributeItem {
  key: string;
  value: string;
}

const labelColors: { [key in LeadStatusType]: string } = {
  [LeadStatusType.Assigned]: green['600'],
  [LeadStatusType.AwaitingRetry]: amber['600'],
  [LeadStatusType.AwaitingStart]: amber['600'],
  [LeadStatusType.Building]: blue['600'],
  [LeadStatusType.Callback]: amber['600'],
  [LeadStatusType.Connected]: green['600'],
  [LeadStatusType.Contacted]: grey['600'],
  [LeadStatusType.Duplicate]: red['600'],
  [LeadStatusType.Excluded]: grey['600'],
  [LeadStatusType.Filtered]: grey['600'],
  [LeadStatusType.Finished]: grey['600'],
  [LeadStatusType.FinishedToday]: grey['600'],
  [LeadStatusType.Initiated]: green['600'],
  [LeadStatusType.NoEndpoints]: red['600'],
  [LeadStatusType.NoSkilledAgents]: red['600'],
  [LeadStatusType.OptOut]: grey['600'],
  [LeadStatusType.OutOfHours]: amber['600'],
  [LeadStatusType.Ready]: green['600'],
  [LeadStatusType.Washed]: grey['600'],
  [LeadStatusType.InvalidEndpoint]: red['600'],
  [LeadStatusType.Disconnected]: red['600'],
  [LeadStatusType.Removed]: grey['600'],
  [LeadStatusType.Expired]: grey['600'],
  [LeadStatusType.AwaitingSMS]: grey['600'],
  [LeadStatusType.InactiveList]: grey['600'],
  [LeadStatusType.MissedCallback]: red['600'],
  [LeadStatusType.InQueue]: green['600'],
  [LeadStatusType.AwaitingCallback]: green['600'],
  [LeadStatusType.Replaced]: grey['600'],
};

const LeadDetailsModal = ({ open, lead, onClose }: LeadDetailsModalProps) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const name = lead?.name || '';
  const id = lead?.id;
  const externalId = lead?.externalId;
  const priority = lead?.priority;
  const addedTimestamp = lead?.addedTimestamp ? DateTime.fromISO(lead?.addedTimestamp).toFormat('FFF') : null;
  const expirationTimestamp = lead?.expirationTimestamp
    ? DateTime.fromISO(lead?.expirationTimestamp).toFormat('FFF')
    : null;
  const timezone = lead?.timezone;
  const endpoints = lead?.endpoints || [];
  const attributes = lead?.attributes
    ? Object.keys(lead.attributes).map((key: string): AttributeItem => ({ key: key, value: lead.attributes[key] }))
    : [];
  const requiredSkills = lead?.requiredSkills || [];
  const attempts = lead?.attempts || [];
  // If color unknown default to grey
  const statusBackgroundColor = lead?.leadStatus ? labelColors[lead.leadStatus] : grey['600'];
  const statusName = lead?.leadStatus?.replace(/-/gi, ' ');
  const leadStatusDetail = lead?.leadStatusDetail;

  const endpointItems = endpoints.map((endpoint: string, index: number) => (
    <Grid key={index} item xs={12} sm={6}>
      <Typography variant='body2' component='h1' color='textSecondary'>
        {endpoint}
      </Typography>
    </Grid>
  ));

  const attributeItems = attributes.map((attribute: AttributeItem, index: number) => (
    <Grid key={index} item xs={12} sm={6}>
      <DataItem
        stacked={true}
        disableMargin={!isExtraSmall && index < 2}
        title={attribute.key}
        value={attribute.value}
      />
    </Grid>
  ));

  const requiredSkillItems = requiredSkills.map((skill: string, index: number) => (
    <Grid key={index} item xs={12} sm={6}>
      <Typography variant='body2' component='h1' color='textSecondary'>
        {skill}
      </Typography>
    </Grid>
  ));

  const attemptItems = attempts.map((attempt: LeadAttempt, index: number) => (
    <TimelineItem
      key={index}
      sx={{
        ':before': {
          content: '""',
          flex: '0',
          padding: 0,
        },
      }}>
      <TimelineSeparator>
        <TimelineDot>
          <CallIcon />
        </TimelineDot>
        {index !== attempts.length - 1 && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Paper elevation={3} sx={{ padding: '6px 16px' }}>
          <DataItem
            stacked
            disableMargin
            title={DateTime.fromISO(attempt.timestamp).toFormat('FFF')}
            value={attempt.outcome}
          />
        </Paper>
      </TimelineContent>
    </TimelineItem>
  ));

  return (
    <OberonDialog
      open={open}
      closeOnBackdropClick
      onClose={onClose}
      title={name}
      subHeader={
        <>
          <Chip
            style={{ backgroundColor: statusBackgroundColor }}
            sx={{
              textTransform: 'uppercase',
              borderRadius: 0.5,
              paddingTop: 0,
              paddingBottom: 0,
              color: 'white',
              fontSize: 10,
              fontWeight: theme.typography.fontWeightBold,
              height: 20,
              lineHeight: '21px',
            }}
            label={statusName}
          />
          {leadStatusDetail && (
            <Typography variant='caption' component='h1' color='textSecondary'>
              * {leadStatusDetail}
            </Typography>
          )}
        </>
      }
      content={
        <>
          <Grid container sx={{ marginBottom: `calc(32px - ${theme.spacing(2)})` }} spacing={0}>
            <Grid item xs={12} sm={6}>
              <DataItem stacked disableMargin={!isExtraSmall} title='Lead ID' value={id} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DataItem stacked disableMargin={!isExtraSmall} title='Lead External ID' value={externalId} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DataItem stacked title='Added Timestamp' value={addedTimestamp} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DataItem stacked title='Expiration Timestamp' value={expirationTimestamp} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DataItem stacked title='Timezone' value={timezone} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DataItem stacked title='Lead Priority' value={priority} />
            </Grid>
          </Grid>

          <Typography variant='h6' component='h1' gutterBottom>
            <b>Endpoints</b>
          </Typography>

          <Grid container sx={{ marginBottom: `calc(32px - ${theme.spacing(2)})` }} spacing={0}>
            {endpointItems}
          </Grid>

          <Typography variant='h6' component='h1' gutterBottom>
            <b>Attributes</b>
          </Typography>

          <Grid container sx={{ marginBottom: `calc(32px - ${theme.spacing(2)})` }} spacing={0}>
            {attributeItems}
          </Grid>

          <Typography variant='h6' component='h1' gutterBottom>
            <b>Required Skills</b>
          </Typography>

          {requiredSkillItems.length === 0 && (
            <Typography variant='body2' component='h1' gutterBottom color='textSecondary'>
              There are no required agent skills needed for this lead.
            </Typography>
          )}

          {requiredSkillItems.length > 0 && (
            <Grid container sx={{ marginBottom: `calc(32px - ${theme.spacing(2)})` }} spacing={0}>
              {requiredSkillItems}
            </Grid>
          )}

          <Typography variant='h6' component='h1' gutterBottom>
            <b>Attempt Timeline</b>
          </Typography>

          {attemptItems.length === 0 && (
            <Typography variant='body2' component='h1' gutterBottom color='textSecondary'>
              There have been no attempts to contact this lead.
            </Typography>
          )}

          {attemptItems.length > 0 && <Timeline>{attemptItems}</Timeline>}
        </>
      }
    />
  );
};

export default LeadDetailsModal;

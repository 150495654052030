import axios, { AxiosResponse } from 'axios';

import { APIError, UnsupportedStructureError } from '~/services/Errors';
import { DiallerType } from '~pages/CampaignManagement/domain';

import { ChatHistoryDecoder, ChatHistoryItem, TimeSeriesStats } from './domain';

export const postCallMetrics = async (
  contactId: string,
  data: TimeSeriesStats,
  diallerType: DiallerType,
): Promise<void> => {
  const path = '/api/webrtc-metrics/';

  const body = {
    contact_id: contactId,
    snapshot_timestamp: data.timestamp,
    jitter_buffer_milliseconds: data.jitterBufferMillis,
    round_trip_time_milliseconds: data.roundTripTimeMillis,
    packets_lost: data.packetsLostDelta,
    packets_count: data.packetsCountDelta,
    stream_type: data.softphoneStreamType,
    dialler_type: diallerType,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const postPaymentCTR = async (contactId: string, paymentId: string): Promise<void> => {
  const path = `/api/payment-gateway/payment/${paymentId}`;

  const body = {
    contact_id: contactId,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const postInternalTransferTarget = async (contactId: string, transferTargetAgent: string): Promise<void> => {
  const path = `/api/internal-transfer/${contactId}/transfer-to`;

  const body = {
    transfer_target_agent: transferTargetAgent,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const clearInternalTransferTarget = async (contactId: string) => {
  const path = `/api/internal-transfer/${contactId}/clear-transfer-to`;

  try {
    await axios.request({
      method: 'POST',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const getChatHistory = async (sessionId: string): Promise<ChatHistoryItem[]> => {
  let resp: AxiosResponse;
  try {
    resp = await axios({
      method: 'GET',
      url: `/api/chat/history`,
      params: {
        session_id: sessionId,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = ChatHistoryDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);
    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  const chatHistory = decoded.result;
  if (chatHistory.list === null || chatHistory.list === undefined) {
    // Set to empty array if no data returned
    // ie. Golang slice can return "null" if not populated
    return [];
  }
  return chatHistory.list;
};

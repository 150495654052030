import React, { ReactNode, createContext, useCallback, useContext, useLayoutEffect, useRef, useState } from 'react';

import EmptyState from '~components/EmptyState';

import { getAssignedCampaign } from './api';
import { AssignedCampaign } from './domains';

interface AssignedCampaignProvider {
  children: ReactNode;
}

type AssignedCampaignContext = {
  campaign: AssignedCampaign;
  configuredCampaign: AssignedCampaign | undefined;
  switchCampaign: () => void;
};

export const AssignedCampaignContext = createContext<AssignedCampaignContext | undefined>(undefined);

const pollingFrequency = 5_000;

export const useAssignedCampaign = (): AssignedCampaignContext => {
  return useContext(AssignedCampaignContext) as AssignedCampaignContext;
};

const AssignedCampaignProvider = ({ children }: AssignedCampaignProvider) => {
  const [campaign, setCampaign] = useState<AssignedCampaign | undefined>(undefined);
  const [configuredCampaign, setConfiguredCampaign] = useState<AssignedCampaign | undefined>(undefined);
  const pendingActionRef = useRef<boolean>(false);

  const fetchAssignedCampaignOrUndefined = async (): Promise<AssignedCampaign | undefined> => {
    try {
      return await getAssignedCampaign();
    } catch (e) {
      return undefined;
    }
  };

  // Manages initial run if campaign fetch
  useLayoutEffect(() => {
    (async () => {
      const assignedCampaign = await fetchAssignedCampaignOrUndefined();
      setCampaign(assignedCampaign);
      setConfiguredCampaign(assignedCampaign);
    })();
  }, []);

  // Manages the event loop and triggering of action
  useLayoutEffect(() => {
    let currentLocalIntervalRef = window.setInterval(async () => {
      // We want to skip any backend calls if a pending action is still underway
      // This is helpful when it comes to spotty or slow internet connections
      // as we then won't spam and wait for multiple requests
      if (pendingActionRef.current === false) {
        pendingActionRef.current = true;
        const assignedCampaign = await fetchAssignedCampaignOrUndefined();
        pendingActionRef.current = false;

        if (campaign === undefined) {
          setCampaign(assignedCampaign);
        }

        setConfiguredCampaign(assignedCampaign);
      }
    }, pollingFrequency);

    return function useAssignedCampaignCleanup() {
      // If current local timer ref is undefined we assume it is the first run of this use effect
      // and we clear the interval based off of the intervalRef.current instead. This is so that
      // we do not keep running the interval behind the scenes IF it is unmounted during the initial loop
      clearInterval(currentLocalIntervalRef);
    };
  }, [campaign]);

  const switchCampaign = useCallback(() => {
    setCampaign(configuredCampaign);
  }, [configuredCampaign]);

  if (campaign === undefined) {
    return (
      <EmptyState
        type='puzzle'
        text='User Configuration Error'
        subText='You are not assigned to a dialling campaign.'
      />
    );
  }

  const context = {
    campaign,
    configuredCampaign,
    switchCampaign,
  };

  return <AssignedCampaignContext.Provider value={context}>{children}</AssignedCampaignContext.Provider>;
};

export default AssignedCampaignProvider;

import * as j from '@mojotech/json-type-validation';

import { Agent } from '~pages/SystemManagement/domain';

export interface AgentsResponse {
  nextPageUrl: string | null;
  agents: Agent[];
}

const GetAgentItemResponseDecoder: j.Decoder<Agent> = j
  .object({
    full_name: j.string(),
    username: j.string(),
    disabled: j.union(j.string(), j.constant(null)),
    skills: j.array(j.string()),
    campaign_id: j.union(j.number(), j.constant(null)),
    campaign_name: j.union(j.string(), j.constant(null)),
    routing_profile_id: j.union(j.string(), j.constant(null)),
    async_queues: j.array(j.string()),
  })
  .map((item): Agent => {
    const nameSplit = item.full_name.split(' ');

    return {
      fullName: item.full_name,
      firstName: nameSplit[0],
      lastName: nameSplit[1],
      username: item.username,
      skills: item.skills,
      disabled: item.disabled,
      campaignName: item.campaign_name ?? '',
      campaignId: item.campaign_id ?? 0,
      routingProfileId: item.routing_profile_id,
      asyncQueues: item.async_queues,
    };
  });

export const GetAgentsResponseDecoder: j.Decoder<AgentsResponse> = j
  .object({
    next_page_url: j.union(j.string(), j.constant(null)),
    agents: j.array(GetAgentItemResponseDecoder),
  })
  .map((item: any) => ({
    nextPageUrl: item.next_page_url,
    agents: item.agents,
  }));

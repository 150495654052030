import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import blue from '@mui/material/colors/blue';
import grey from '@mui/material/colors/grey';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { MUIStyledCommonProps, Theme } from '@mui/system';
import { DateTime } from 'luxon';
import React from 'react';

interface NotificationCardProps {
  message: string;
  createdAt: DateTime;
  requestedAcknowledgement: boolean;
  acknowledgedAt: string | null;
  onAcknowledge: () => void;
}

type StyledDivProps = MUIStyledCommonProps<Theme> &
  React.ClassAttributes<HTMLDivElement> &
  React.HTMLAttributes<HTMLDivElement> & {
    isAcknowledged: boolean;
  };

const Notification = styled('div', { shouldForwardProp: (prop) => prop !== 'isAcknowledged' })<StyledDivProps>(
  ({ theme, isAcknowledged }) => ({
    'position': 'relative',
    'padding': '8px 16px',
    'transition': theme.transitions.create(['background'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    ':hover': {
      background: isAcknowledged ? grey[200] : blue[50],
    },
  }),
);

const NotificationIndicator = styled('div', { shouldForwardProp: (prop) => prop !== 'isAcknowledged' })<StyledDivProps>(
  ({ isAcknowledged }) => ({
    position: 'absolute',
    width: 10,
    height: '80%',
    top: '50%',
    left: -5,
    bottom: 8,
    borderRadius: 4,
    backgroundColor: isAcknowledged ? grey[600] : blue[600],
    transform: 'translateY(-50%)',
  }),
);

const NotificationCard = ({
  message,
  createdAt,
  requestedAcknowledgement,
  acknowledgedAt,
  onAcknowledge,
}: NotificationCardProps) => {
  return (
    <Notification isAcknowledged={!requestedAcknowledgement || (requestedAcknowledgement && acknowledgedAt !== null)}>
      <NotificationIndicator
        isAcknowledged={!requestedAcknowledgement || (requestedAcknowledgement && acknowledgedAt !== null)}
      />

      <Box sx={{ marginBottom: 0.5 }}>
        <Typography display='block' overflow='hidden' variant='body2' color='textPrimary'>
          {message}
        </Typography>

        <Typography display='block' overflow='hidden' variant='caption' color='textSecondary'>
          {createdAt.toRelative()}
        </Typography>
      </Box>

      {requestedAcknowledgement && acknowledgedAt === null && onAcknowledge && (
        <Button
          sx={{
            'fontSize': 12,
            'lineHeight': 0,
            'textTransform': 'none',
            '& .MuiButton-startIcon': {
              'marginRight': 0.5,
              '& *:first-of-type': {
                fontSize: 14,
              },
            },
          }}
          variant='contained'
          disableElevation
          onClick={onAcknowledge}
          startIcon={<PriorityHighIcon />}
          color='primary'>
          Acknowledge
        </Button>
      )}
    </Notification>
  );
};

export default NotificationCard;

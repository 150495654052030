import React, { ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useNotification } from '~providers/NotificationProvider';
import { AsyncManager } from '~services/AsyncManager';
import { Agent, AuthorRole, Customer } from '~services/AsyncManager/domain';
import { exponentialDelay, mergeDeep } from '~utils/Functions';

import messageChime from './chime.wav';

interface AsyncContext {
  agent: Agent;
  customers: { [key: string]: Customer };
  selectedCustomerKey: string;
  asyncManager: AsyncManager;
  sessionActive: boolean;
  setActiveCustomer: (customerKey: string) => void;
  disableMessageChimeRef: React.MutableRefObject<boolean>;
}

interface AsyncProviderProps {
  enabled: boolean;
  children: ReactNode;
}

const sendMessageChime = () => {
  const audio = new Audio(messageChime);
  audio.play();
};

const AsyncContext = createContext<AsyncContext | undefined>(undefined);

export const useAsync = (): AsyncContext => {
  return useContext(AsyncContext) as AsyncContext;
};

const getDeliveredMessageIds = (customers: { [key: string]: Customer }): number[] => {
  let deliveredMessageIds: number[] = [];

  // TODO: we need a better way of looping through this structure
  // go through each customer. This is a very expensive way to work out what has been
  // changed
  for (const customerId in customers) {
    const customer = customers[customerId];
    // for each conversation
    for (const conversationId in customer.conversations) {
      const conversation = customer.conversations[conversationId];
      // for each message
      for (const messageId in conversation.messages) {
        // mark the message as read if it came from the customer
        const message = conversation.messages[messageId];
        if (message.deliveredTimestamp === undefined && message.authorRole === AuthorRole.Customer) {
          deliveredMessageIds = [...deliveredMessageIds, message.id];
        }
      }
    }
  }

  return deliveredMessageIds;
};

const defaultAgentData = Object.freeze({
  username: '',
  maxConcurrency: 0,
  desiredConcurrency: 0,
  state: '',
  stateTimestamp: '',
  onVoiceCall: false,
  requestedState: undefined,
  requestedStateTimestamp: undefined,
});

export const AsyncProvider = ({ enabled, children }: AsyncProviderProps) => {
  const { pushNotificationWithReferenceId } = useNotification();
  const [agent, setAgent] = useState<Agent>(defaultAgentData);
  const [customers, setCustomers] = useState<{ [key: string]: Customer }>({});
  const [selectedCustomerKey, setSelectedCustomerKey] = useState<string>('');
  const [sessionActive, setSessionActive] = useState<boolean>(false);
  const retryTimeoutRef = useRef<number | undefined>(undefined);
  const retryCount = useRef<number>(0);
  const disableMessageChimeRef = useRef<boolean>(false);

  const setActiveCustomer = (customerKey: string) => {
    setSelectedCustomerKey(customerKey);
  };

  const asyncManager = useMemo(() => {
    const url = new URL(window.document.location.href);
    const proto = url.protocol === 'https:' ? 'wss' : 'ws';
    const socketUrl = `${proto}://${url.host}/api/async/`;

    const manager = new AsyncManager(socketUrl, {
      onConnected: () => {
        clearTimeout(retryTimeoutRef.current);
        retryCount.current = 0;
        setSessionActive(true);
      },
      onMessage: (socketMessage) => {
        if (socketMessage.agent) {
          setAgent(socketMessage.agent);
        }

        if (socketMessage.disposedCustomers) {
          const removeList = socketMessage.disposedCustomers;
          setCustomers((prev) => {
            let newState = {};
            for (let key in prev) {
              if (removeList.indexOf(key) === -1) {
                newState = { ...newState, [key]: prev[key] };
              }
            }

            return { ...newState };
          });
        }

        if (socketMessage.customers) {
          setCustomers((prev) => {
            let shouldChime = false;
            const currentCustomerKeys = Object.keys(prev);
            const newStateCustomerKeys = Object.keys(socketMessage.customers!);

            // TODO: Should possibly also look for messages from the most resent conversation so it doesnt ding on socket
            //       reconnect
            for (let key of newStateCustomerKeys) {
              if (!currentCustomerKeys.includes(key)) {
                shouldChime = true;
                break;
              }
            }

            // Note: React is dumb and the mergeDeep new object isn't enough for it to know there is a new object
            // to force a rerender (as they do shallow comparisons). So we destructure the new complete object
            // into a new object which tricks react into thinking it is a brand-new state and triggers a rerender
            const newState = { ...mergeDeep<{ [key: string]: Customer }>(prev, socketMessage.customers) };

            const deliveredMessageIds = getDeliveredMessageIds(newState);

            if (deliveredMessageIds.length > 0) {
              shouldChime = true;
            }

            if (shouldChime && !disableMessageChimeRef.current) {
              sendMessageChime();
            }

            return newState;
          });
        }

        if (socketMessage.error && socketMessage.error.messages.length > 0) {
          console.table(socketMessage.error.messages);
          pushNotificationWithReferenceId(
            'error',
            socketMessage.error.messages[0].referenceId,
            socketMessage.error.messages[0].message,
          );
        }
      },
      onDisconnected: (closeCode) => {
        clearTimeout(retryTimeoutRef.current);
        setSessionActive(false);
        setAgent(defaultAgentData);
        setCustomers({});
        setSelectedCustomerKey('');

        // Attempt to reconnect the socket at an exponential delay
        if (closeCode > 1000) {
          retryTimeoutRef.current = exponentialDelay(retryCount.current, () => {
            retryCount.current += 1;
            manager.connect();
          });
        }
      },
    });

    return manager;
  }, []);

  // Handle the connection and disconnection of the socket
  useEffect(() => {
    // We want to be able to set the asyncManager class, but not auto run the socket unless the manager is enabled
    if (enabled === true) {
      asyncManager.connect();

      return () => {
        clearTimeout(retryTimeoutRef.current);
        asyncManager.disconnect();
      };
    }
  }, [enabled]);

  // Mark any new message as delivered
  useEffect(() => {
    if (enabled === true) {
      const deliveredMessageIds = getDeliveredMessageIds(customers);
      if (deliveredMessageIds.length > 0) {
        asyncManager.markMessagesAsDelivered(deliveredMessageIds);
      }
    }
  }, [enabled, customers]);

  // Logic to attempt a resend of any potential messages not send due to a network error
  useEffect(() => {
    if (enabled === true) {
      const ref = setInterval(() => {
        asyncManager.sendQueuedMessages();
      }, 1_000);

      return () => {
        clearInterval(ref);
      };
    }
  }, [enabled]);

  const context = {
    agent,
    customers,
    selectedCustomerKey,
    asyncManager,
    sessionActive,
    setActiveCustomer,
    disableMessageChimeRef,
  };

  return <AsyncContext.Provider value={context}>{children}</AsyncContext.Provider>;
};

import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Chip from '@mui/material/Chip';
import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React from 'react';
import { generatePath } from 'react-router-dom';

import OberonCard from '~components/OberonCard';
import Routes from '~providers/RouteProvider/Routes';
import { pluraliseWord } from '~utils/Functions';

import { CampaignListItem, typeIdsToDisplayText } from '../../domain';

const CampaignCard = (campaign: CampaignListItem) => {
  const viewLink = generatePath(Routes.viewCampaign.path, { campaignId: campaign.id.toString() });
  const borderColor = campaign.archived ? grey[600] : green[600];
  const statusBackgroundColor = campaign.archived ? grey[600] : green[600];
  const statusName = campaign.archived ? 'Archived' : 'Active';

  return (
    <OberonCard
      to={viewLink}
      titleFontWeight={400}
      title={campaign.name}
      subHeader={
        <Chip
          sx={{
            marginTop: 2,
            textTransform: 'uppercase',
            fontSize: 10,
            borderRadius: 1,
            height: 20,
            lineHeight: '21px',
            color: '#ffffff',
            fontWeight: 700,
            backgroundColor: statusBackgroundColor,
          }}
          label={statusName}
        />
      }
      footerBorderColor={borderColor}
      footer={
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              marginRight={1}
              display='flex'
              justifyContent='flex-start'
              alignItems='center'
              variant='caption'
              color='textSecondary'>
              <HeadsetMicIcon sx={{ marginRight: 0.5 }} fontSize='small' />
              {`${campaign.numAgents} ${pluraliseWord(campaign.numAgents, 'agent')}`}
            </Typography>

            <Typography
              marginLeft={1}
              marginRight={1}
              display='flex'
              justifyContent='flex-start'
              alignItems='center'
              variant='caption'
              color='textSecondary'>
              <DonutLargeIcon sx={{ marginRight: 0.5 }} fontSize='small' />
              {`${campaign.progress.toFixed(2)}%`}
            </Typography>

            <Typography
              marginLeft={1}
              display='flex'
              justifyContent='flex-start'
              alignItems='center'
              variant='caption'
              color='textSecondary'>
              <ListAltIcon sx={{ marginRight: 0.5 }} fontSize='small' />
              {`${campaign.numLists} ${pluraliseWord(campaign.numLists, 'list')}`}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              display='flex'
              justifyContent='flex-start'
              alignItems='center'
              variant='caption'
              color='textSecondary'>
              {typeIdsToDisplayText[campaign.campaignType]}
            </Typography>
          </Box>
        </Box>
      }
    />
  );
};

export default CampaignCard;

import * as j from '@mojotech/json-type-validation';

import { CampaignType, DiallerType } from '~pages/CampaignManagement/domain';

export interface AssignedCampaign {
  campaignId: number;
  name: string;
  diallerType: DiallerType;
  campaignType: CampaignType;
  enableManualOutbound: boolean;
  manualOutboundRequireDetails: boolean;
  enablePreparedOutboundHangup: boolean;
  enableManualOutboundWhenOffline: boolean;
  description: string;
  hasAsyncQueues: boolean;
}

export const AssignedCampaignDecoder: j.Decoder<AssignedCampaign> = j
  .object({
    campaign_id: j.number(),
    name: j.string(),
    dialler_type: j.oneOf(j.constant(DiallerType.Connect), j.constant(DiallerType.SIP)),
    campaign_type: j.oneOf(j.constant(CampaignType.Preview), j.constant(CampaignType.Predictive)),
    enable_manual_outbound: j.boolean(),
    manual_outbound_require_details: j.boolean(),
    enable_prepared_outbound_hangup: j.boolean(),
    enable_manual_outbound_when_offline: j.boolean(),
    description: j.string(),
    has_async_queues: j.boolean(),
  })
  .map((item: any) => ({
    campaignId: item.campaign_id,
    name: item.name,
    diallerType: item.dialler_type,
    campaignType: item.campaign_type,
    enableManualOutbound: item.enable_manual_outbound,
    manualOutboundRequireDetails: item.manual_outbound_require_details,
    enablePreparedOutboundHangup: item.enable_prepared_outbound_hangup,
    enableManualOutboundWhenOffline: item.enable_manual_outbound_when_offline,
    description: item.description,
    hasAsyncQueues: item.has_async_queues,
  }));

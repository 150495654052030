import LoadingButton from '@mui/lab/LoadingButton';
import { FilterOptionsState } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import React, {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { DotLoader } from '~components/DotLoader';
import OberonDialog from '~components/OberonDialog';
import useDebounce from '~hooks/useDebounce';
import useLeadSearch from '~pages/CampaignManagement/LeadListDetails/useLeadSearch';

import { getCampaignAgentsById, getCampaignById, getPublicHolidays } from '../../../api';
import { CampaignAgent, CreateCallback, DiallingHours, Lead, LeadStatusType, PublicHoliday } from '../../../domain';

const enum EndpointType {
  New = 'new',
  Existing = 'existing',
}

interface Form {
  lead: Lead | null | undefined;
  endpointType: EndpointType;
  endpoint: string | null;
  forAgent: CampaignAgent | null | undefined;
  scheduled: string;
  notes: string;
}

interface CreateCallbackModalProps {
  open: boolean;
  submitting: boolean;
  onAccept: (data: CreateCallback) => void;
  onClose: () => void;
}

const fetchCampaignAssignedAgentsOrEmptyArray = async (campaignId: number) => {
  try {
    return await getCampaignAgentsById(campaignId);
  } catch (e) {
    return [];
  }
};

const fetchDiallingHoursOrUndefined = async (campaignId: number) => {
  try {
    const campaign = await getCampaignById(campaignId);

    if (
      campaign !== undefined &&
      campaign.leadEngineSettings !== undefined &&
      campaign.leadEngineSettings.diallingHours !== undefined
    ) {
      return campaign.leadEngineSettings.diallingHours;
    }

    return undefined;
  } catch (e) {
    return undefined;
  }
};

const fetchPublicHolidaysOrEmptyArray = async (timezone: string) => {
  try {
    return await getPublicHolidays(timezone);
  } catch (e) {
    return [];
  }
};

const blockCreationList = [LeadStatusType.Excluded];

const endpointTypeList = [
  {
    label: 'Existing',
    value: EndpointType.Existing,
  },
  {
    label: 'New',
    value: EndpointType.New,
  },
];

// Used for disabling dates within disableDates function
const dayToWeekdayNumber: { [key: string]: number } = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

// Used for showing error messages for selected date range within onValidation function
const weekdayNumberToDay: { [key: number]: string } = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

interface ListBoxProps extends React.HTMLAttributes<HTMLUListElement> {}
type NullableUlElement = HTMLUListElement | null;

// Hack component to fix Mui bug around list scrolling to top unnecessarily on data set change for
// autocomplete components
// https://github.com/mui/material-ui/issues/30249
const ListBox = forwardRef(function ListBoxBase(props: ListBoxProps, ref: ForwardedRef<HTMLUListElement>) {
  const { children, ...rest } = props;

  const innerRef = useRef<HTMLUListElement>(null);

  useImperativeHandle<NullableUlElement, NullableUlElement>(ref, () => innerRef.current);

  return (
    <ul {...rest} ref={innerRef} role='list-box'>
      {children}
    </ul>
  );
});

const REQUIRED_CHARACTER_LIMIT = 2;

const CreateCallbackModal = ({ open, submitting, onAccept, onClose }: CreateCallbackModalProps) => {
  const { campaignId, listId } = useParams() as { campaignId: string; listId: string };
  const [agents, setAgents] = useState<CampaignAgent[]>([]);
  const [publicHolidays, setPublicHolidays] = useState<PublicHoliday[]>([]);
  const [diallingHours, setDiallingHours] = useState<DiallingHours | undefined>(undefined);
  const [fetchingFormData, setFetchingFormData] = useState<boolean>(false);
  const [searchConditionMet, setSearchConditionMet] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 500);
  const isLoading = submitting || fetchingFormData;
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<Form>({
    defaultValues: {
      lead: null,
      endpointType: EndpointType.Existing,
      endpoint: null,
      forAgent: null,
      scheduled: '',
      notes: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const watchLead = watch('lead');
  const watchEndpointType = watch('endpointType');
  const endpointsList = watchLead !== null && watchLead !== undefined ? watchLead.endpoints : [];
  const { loading, error, leads, hasMore, getNextPage } = useLeadSearch(+campaignId, +listId, debouncedSearch, '');
  const observer = useRef<IntersectionObserver | undefined>(undefined);
  const lastDataElement = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          getNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, getNextPage],
  );

  useEffect(() => {
    (async () => {
      if (open) {
        setFetchingFormData(true);

        setAgents(await fetchCampaignAssignedAgentsOrEmptyArray(+campaignId));
        setDiallingHours(await fetchDiallingHoursOrUndefined(+campaignId));

        setFetchingFormData(false);
      }
    })();

    // Reset form on close
    return function cleanupCreateCallbackModal() {
      reset();
    };
  }, [open]);

  // Fetch updated public holiday on lead selection
  useEffect(() => {
    (async () => {
      if (watchLead !== null && watchLead !== undefined) {
        setPublicHolidays(await fetchPublicHolidaysOrEmptyArray(watchLead.timezone));
      }
    })();
  }, [watchLead]);

  // Reset endpoint default on endpoint type change
  useEffect(() => {
    setValue('endpoint', null);
  }, [watchLead, watchEndpointType]);

  const disableDates = (date: DateTime | null) => {
    if (diallingHours !== undefined) {
      // Disable public holidays if not allowed
      if (diallingHours.allowPublicHolidays === false) {
        if (publicHolidays.length === 0) return false;

        for (const ph of publicHolidays) {
          if (date && ph.year === date.year && ph.month === date.month && ph.day === date.day) {
            return true;
          }
        }
      }

      // Disable specific day of the week if no dialling day hours exist
      for (const day in diallingHours?.diallingDays) {
        if (diallingHours?.diallingDays[day] === null && dayToWeekdayNumber[day] === date?.weekday) {
          return true;
        }
      }
    }

    return false;
  };

  const invalidTimeSelection = (value: string): string | undefined => {
    if (value === null || value === undefined) {
      return undefined;
    }

    // If this is undefined we assume everything is fair game (even if an error occurs fetching this :/)
    if (diallingHours === undefined) {
      return undefined;
    }

    const date = DateTime.fromISO(value as string);
    const diallingDay = diallingHours.diallingDays[weekdayNumberToDay[date.weekday]];

    // As this value is null the day should already be disabled within the disableDates function.
    // If for any reason that function bugs out, this logic here should serve as a secondary
    // stop gap to prevent selecting this day/ time
    if (diallingDay === null) {
      return undefined;
    }

    // If select time is between the hours blocks we allow anything and say its valid
    if (date.hour > diallingDay.startTimeHour && date.hour < diallingDay.endTimeHour) {
      return undefined;
    }

    // Start and end edge cases
    if (
      (date.hour === diallingDay.startTimeHour && date.minute >= diallingDay.startTimeMin) ||
      (date.hour === diallingDay.endTimeHour && date.minute < diallingDay.endTimeMin)
    ) {
      return undefined;
    }

    const startHour = diallingDay.startTimeHour < 10 ? `0${diallingDay.startTimeHour}` : diallingDay.startTimeHour;
    const endHour = diallingDay.endTimeHour < 10 ? `0${diallingDay.endTimeHour}` : diallingDay.endTimeHour;
    const startMinute = diallingDay.startTimeMin < 10 ? `0${diallingDay.startTimeMin}` : diallingDay.startTimeMin;
    const endMinute = diallingDay.endTimeMin < 10 ? `0${diallingDay.endTimeMin}` : diallingDay.endTimeMin;
    const startTime = `${startHour}:${startMinute}`;
    const endTime = `${endHour}:${endMinute}`;

    return `Callback schedule time must be between ${startTime} and ${endTime}`;
  };

  // Updates list display state based on Character limit query
  const filterOptionCharacterLimit = useCallback((option: Lead[], state: FilterOptionsState<Lead>) => {
    if (state.inputValue.length < REQUIRED_CHARACTER_LIMIT) {
      return [];
    }

    return option;
  }, []);

  // Updates search state as well as manages when it should be updated
  const onSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length < REQUIRED_CHARACTER_LIMIT) {
      setSearchConditionMet(false);
      return;
    }

    setSearchConditionMet(true);
    setSearch(e.target.value);
  }, []);

  const onSubmit = handleSubmit(async (data: Form) => {
    // data.lead should not be null at this point as it is marked as required on the form schema
    const leadId = data.lead!.id;
    const timezone = data.lead!.timezone;
    const username = data.forAgent !== null && data.forAgent !== undefined ? data.forAgent.username : null;
    // Should not be null at this point as it is marked as required on the form schema
    const endpoint = data.endpoint!;

    try {
      await onAccept({
        leadId: leadId,
        forAgent: username,
        scheduled: DateTime.fromISO(data.scheduled, { zone: timezone }).toISO(),
        endpoint: endpoint,
        notes: data.notes,
      });
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
      return;
    }

    reset();
  });

  const endpointTypeListDisplay = endpointTypeList.map((item, index) => (
    <MenuItem key={index} value={item.value}>
      {item.label}
    </MenuItem>
  ));

  const scheduledHelpTextDefault =
    watchLead !== null && watchLead !== undefined ? `(${watchLead.name}'s time in ${watchLead.timezone})` : undefined;
  const scheduledHelperText = errors.scheduled ? errors.scheduled?.message : scheduledHelpTextDefault;

  const leadSearchNoOptionsText = useMemo(() => {
    if (!searchConditionMet) {
      return `Please enter ${REQUIRED_CHARACTER_LIMIT} characters to begin searching`;
    }

    if (loading) {
      return <DotLoader align='center' />;
    }

    if (error) {
      return (
        <Typography variant='body2' align='center' color='textSecondary'>
          Failed to load leads
        </Typography>
      );
    }

    return undefined;
  }, [loading, error, searchConditionMet]);

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title='Create Callback'
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='lead'
              control={control}
              rules={{
                required: 'Lead is required.',
                validate: (value): string | undefined => {
                  if (value === null || value === undefined) {
                    return undefined;
                  }

                  if (blockCreationList.includes(value.leadStatus)) {
                    return `Cannot create a callback for this lead as its in the following state ${value.leadStatus}`;
                  }

                  return undefined;
                },
              }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  onChange={(e, data) => field.onChange(data)}
                  fullWidth
                  options={leads}
                  filterSelectedOptions
                  disabled={isLoading}
                  filterOptions={filterOptionCharacterLimit}
                  noOptionsText={leadSearchNoOptionsText}
                  ListboxComponent={ListBox}
                  getOptionLabel={(option) => option?.name || ''}
                  renderOption={(props, option) => (
                    <MenuItem {...props} ref={lastDataElement} key={option.id}>
                      {option.name}
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label='Lead'
                      required={true}
                      error={Boolean(errors.lead)}
                      helperText={errors.lead?.message}
                      variant='outlined'
                      {...params}
                      onChange={onSearchChange}
                    />
                  )}
                />
              )}
            />
          </Grid>

          {watchLead && (
            <>
              <Grid item xs={12}>
                <Controller
                  name='endpointType'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      select
                      variant='outlined'
                      label='Endpoint Type'
                      disabled={isLoading}
                      required={true}
                      error={Boolean(errors.endpointType)}
                      helperText={errors.endpointType?.message}
                      {...field}>
                      {endpointTypeListDisplay}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name='endpoint'
                  control={control}
                  rules={{
                    required: 'Endpoint is required.',
                  }}
                  render={({ field }) => (
                    <>
                      {watchEndpointType === EndpointType.Existing && (
                        <Autocomplete
                          {...field}
                          onChange={(e, data) => field.onChange(data)}
                          fullWidth
                          options={endpointsList}
                          filterSelectedOptions
                          disabled={isLoading}
                          renderInput={(params) => (
                            <TextField
                              label='Endpoint'
                              required={true}
                              error={Boolean(errors.endpoint)}
                              helperText={errors.endpoint?.message}
                              variant='outlined'
                              {...params}
                            />
                          )}
                        />
                      )}

                      {watchEndpointType === EndpointType.New && (
                        <TextField
                          {...field}
                          value={field.value || ''}
                          fullWidth
                          variant='outlined'
                          label='Endpoint'
                          disabled={isLoading}
                          required={true}
                          error={Boolean(errors.endpoint)}
                          helperText={errors.endpoint?.message}
                        />
                      )}
                    </>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name='scheduled'
                  control={control}
                  rules={{
                    required: 'A callback date/ time is required',
                    validate: invalidTimeSelection,
                  }}
                  render={({ field }) => (
                    <DateTimePicker
                      disableMaskedInput
                      label='Callback Schedule'
                      inputFormat='dd/MM/yyyy hh:mm a'
                      disabled={isLoading}
                      disablePast={true}
                      shouldDisableDate={disableDates}
                      ampm={false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant='outlined'
                          required={true}
                          error={Boolean(errors.scheduled)}
                          helperText={scheduledHelperText}
                        />
                      )}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Controller
              name='forAgent'
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  value={field.value}
                  onChange={(e, data) => field.onChange(data)}
                  fullWidth
                  options={agents}
                  filterSelectedOptions
                  disabled={isLoading}
                  getOptionLabel={(option) => option?.fullName || 'None'}
                  renderOption={(props, option) => <MenuItem {...props}>{option.fullName}</MenuItem>}
                  renderInput={(params) => <TextField label='For Agent' variant='outlined' {...params} />}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='notes'
              control={control}
              rules={{
                required: 'Notes is required',
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  variant='outlined'
                  label='Notes'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.notes)}
                  helperText={errors.notes?.message}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            Create
          </LoadingButton>
        </>
      }
    />
  );
};

export default CreateCallbackModal;

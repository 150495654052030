import 'amazon-connect-streams';
import 'amazon-connect-chatjs';

import { throttle } from 'lodash';
import React, { ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';

import AsyncLoader from '~/components/AsyncLoader';
import EmptyState from '~/components/EmptyState';
import { useAppConfiguration } from '~/providers/AppConfigurationProvider';
import { useContact } from '~/providers/ContactProvider';
import { DiallerType } from '~pages/CampaignManagement/domain';
import { useLogRocket } from '~providers/LogRocketProvider';

import {
  clearInternalTransferTarget,
  getChatHistory,
  postCallMetrics,
  postInternalTransferTarget,
  postPaymentCTR,
} from './api';
import {
  AgentAvailableStates,
  AgentState,
  AgentStateType,
  ConnectAgent,
  Contact,
  ContactStateType,
  Message,
  MessageContentType,
  MessageRoleType,
  SoftphoneStreamType,
  TimeSeriesStats,
} from './domain';
import {
  acceptContact,
  addThirdPartyConnection,
  destroyAgentSession,
  destroyThirdPartyConnection,
  dialPhoneNumber,
  downloadSessionLogs,
  getActiveAgentConnection,
  getChatController,
  getEndpointByARN,
  getIDForConnectContact,
  getTimeSeriesStats,
  mergeMessagesAndAvoidDuplication,
  messageModel,
  muteAgent,
  newContactModel,
  sendChatMessage,
  sendDtmfKeyInput,
  setAgentOffline,
  setAgentOnline,
  setAgentState,
  setContactOnHold,
  setContactResume,
  unmuteAgent,
  updateContactModel,
} from './helpers';

interface ConnectContext {
  agent: ConnectAgent;
  agentStateList: string[];
  callStatistics: TimeSeriesStats | undefined;
  downloadSessionLogs: () => void;
}

interface ConnectProviderProps {
  children: ReactNode;
}

export const ConnectContext = createContext<ConnectContext | undefined>(undefined);

export const useConnect = (): ConnectContext => {
  return useContext(ConnectContext) as ConnectContext;
};

// Reference to required connect elements
const connectContainerId = 'connect-container';
// Reference to the connect iframe used for all streams based interactions
const connectIframeContainerId = 'connect-iframe';

const agentDefault: ConnectAgent = {
  name: '',
  routingProfile: '',
  defaultOutboundQueue: '',
  defaultOutboundQueueARN: '',
  status: AgentAvailableStates.Init,
  statusType: AgentStateType.Offline,
  statusTimestamp: new Date(),
  softphoneAutoAnswerEnabled: false,
  // Voice-only
  isMuted: false,
  initialized: false,
  changeStatus: async (stateName: string) => {
    return { status: AgentAvailableStates.Init, type: AgentStateType.Init };
  },
  mute: async () => {
    alert('Not Implemented');
  },
  unmute: async () => {
    alert('Not Implemented');
  },
  dial: async (phoneNumber: string) => {
    alert('Not Implemented');
  },
  setOnline: async () => {
    alert('Not Implemented');
  },
  setOffline: async () => {
    alert('Not Implemented');
  },
  updateRoutingProfile: async (routingProfileName: string) => {
    alert('Not Implemented');
  },
};

const ConnectProvider = ({ children }: ConnectProviderProps) => {
  const logRocket = useLogRocket();
  const appConfig = useAppConfiguration();
  const { setCurrentContactById, insertOrUpdateContact, updateContactById, removeContactById } = useContact();
  // Global connect agent reference, used for agent actions we do not want exposed out of this provider
  const ccpAgent = useRef<connect.Agent | undefined>(undefined);
  // Our agent reference object
  const [agent, setAgent] = useState<ConnectAgent>(agentDefault);
  const [callStatistics, setCallStatistics] = useState<TimeSeriesStats | undefined>(undefined);
  const [agentStateList, setAgentStateList] = useState<string[]>([]);
  const [connectCoreError, setConnectCoreError] = useState<string>('');
  // Used for storing rtc stat posting internal
  const rtcInterval = useRef<number | undefined>(undefined);
  // Used for storing the cold internal transfer queue endpoint
  const internalTransferEndpoint = useRef<connect.Endpoint | undefined>(undefined);
  const paymentGatewayEndpoint = useRef<connect.Endpoint | undefined>(undefined);
  // Stores the most recent agent audio status
  const agentToDiallerAudioStats = useRef<connect.AudioStats | undefined>(undefined);
  // Stores the most recent contact audio status
  const diallerToAgentAudioStats = useRef<connect.AudioStats | undefined>(undefined);
  // Lets us know if the connect iframe has already been added to the dom or not. This is used to tell us
  // when to rehydrate contacts from the agent connection vs let connect do everything.
  // TODO: remove in future when we move towards a separate tab for the dialler
  const iframeAlreadyExists = useRef<boolean>(false);
  const [initialRun, setInitialRun] = useState<boolean>(true);

  // Connect connection setup
  useEffect(() => {
    // Gross but it fixes the issue where connect fails to reconnect on dev StrictMode second pass due to them not being
    // smart with generating and reconnecting existing iframes
    // TODO: Revisit this flow when refactoring out all chat related logic
    if (initialRun) {
      setInitialRun(false);
      return;
    }
    /**
     * Used to set agent offline and terminate connect session where possible.
     * The only time this doesnt work is if this action is triggered when an agent is within
     * one of the following states (this also blocks connect termination on agent set offline failure):
     * - Connected
     */
    const cleanupConnect = async () => {
      if (ccpAgent.current === undefined) {
        // If not initialized, do nothing
        return;
      }

      // This will destroy the agents session and set them offline IF they are not currently
      // connected to a contact i.e. contact connected states
      await destroyAgentSession(ccpAgent.current);

      // Remove the connect container from the DOM if it exists
      const container = document.getElementById(connectContainerId);
      if (container !== null) {
        document.body.removeChild(container);
      }
    };

    const handleAccessDenied = (): void => {
      setConnectCoreError(
        'You have been Authenticated successfully but do not have authorization to use this application.',
      );
    };

    const handleViewContact = (event: { contactId: string }): void => {
      console.log('+ View contact event', event);

      setCurrentContactById(getIDForConnectContact(event.contactId));
    };

    /**
     * Inject connect container related elements into the DOM if they do not exist
     *
     * The reason for doing it this way is to prevent react from cleaning it up before
     * we are really done with it. For reference if we are using the jsx block with a ref,
     * the dom on unmount is cleaned up before the useEffects cleanup function
     * leading to the iframe being removed before we have performed specific streams related cleanup.
     * i.e. setting agent to offline and termination the connect session
     *
     * We also check if its defined as an admin user might be in a call and if they navigate
     * away from this page, we might not be able to destroy the session due to a contact bei.ng connected
     * and we want to avoid duplicate injections of the same div
     */
    if (document.getElementById(connectContainerId) === null) {
      iframeAlreadyExists.current = false;

      const element = (
        <div id={connectContainerId}>
          <audio id='remote-audio' autoPlay></audio>
          <div
            id={connectIframeContainerId}
            style={{
              position: 'fixed',
              left: 16,
              bottom: 16,
              display: 'none',
              width: 0,
              height: 0,
              zIndex: 1201,
            }}
          />
        </div>
      );

      document.body.insertAdjacentHTML('beforeend', ReactDOMServer.renderToStaticMarkup(element));
    } else {
      iframeAlreadyExists.current = true;
    }

    connect.core.initCCP(document.getElementById(connectIframeContainerId)!, {
      ccpUrl: appConfig.aws.amazonConnectCcpUrl,
      // NOTE(Jae): 2021-02-09
      // The "region" option is only required for the chat channel.
      // (If you read the docs)
      region: appConfig.aws.amazonConnectRegion,
      loginPopup: true,
      loginUrl: appConfig.aws.amazonConnectLoginUrl,
      loginOptions: {
        autoClose: true,
        left: 100,
        top: 100,
        width: 150,
        height: 150,
      },
      softphone: {
        allowFramedSoftphone: false,
        disableRingtone: false,
      },
    });

    // Connect Core Error handling
    connect.core.onAccessDenied(handleAccessDenied);

    // Updates active contact id on view contact event fire
    connect.core.onViewContact(handleViewContact);

    /**
     * NOTE(christian): 2021-01-27
     *
     * Metric Reporting
     *
     * Since the rtc library does not expose types at the moment we have extended them in our own declaration.d.ts file in the interim
     * to pass type checking build logic
     *
     * Softphone related metric logic uses undocumented functionality. This logic  uses the following link as a guide
     * @see {@link https://github.com/amazon-connect/amazon-connect-call-quality-monitoring/blob/f8e175da7808b9bd9a161801191850cf1d5f69e1/monitoring-stack-cdk/resources/frontend/connect-custom-implementation.js#L298}
     *
     */
    connect.core.initSoftphoneManager({ allowFramedSoftphone: true });
    connect.core.onSoftphoneSessionInit(({ connectionId }: { connectionId: string }) => {
      const onRtcSessionFailed = (rtcSession: connect.RtcSession, reason: string) => {
        console.error(`! Event RTC Session: Failed for Contact ID: ${rtcSession.callId}`);
        console.error(`! Failed due to ${reason}`);
      };

      // Called when the call is established (handshaked and media stream should be flowing)
      const onRtcSessionConnected = (rtcSession: connect.RtcSession) => {
        const contactId = rtcSession.callId;
        console.log(`+ Event RTC Session: Connected for Contact ID: ${contactId}`);

        rtcInterval.current = window.setInterval(() => {
          console.log(`+ Event RTC Session: Call Metrics Send Request for Contact ID: ${contactId}`);
          updateAndPostAudioStatesFromRtcSession(rtcSession);
        }, 5_000);
      };

      // Called when hangup is initiated (implies the call was successfully established).
      const onRtcSessionCompleted = (rtcSession: connect.RtcSession) => {
        console.log(`+ Event RTC Session: Completed for Contact ID: ${rtcSession.callId}`);

        clearInterval(rtcInterval.current);
        setCallStatistics(undefined);
        agentToDiallerAudioStats.current = undefined;
        diallerToAgentAudioStats.current = undefined;
      };

      const updateAndPostAudioStatesFromRtcSession = async (rtcSession: connect.RtcSession) => {
        const contactId = rtcSession.callId;
        const prevAgentToDiallerAudioStats = agentToDiallerAudioStats.current;
        const prevDiallerToAgentAudioStats = diallerToAgentAudioStats.current;
        let newStats;

        try {
          newStats = await rtcSession.getStats();
        } catch (e) {
          console.error('! unable fetch call metrics: ', e);
          return;
        }

        // NOTE(RJ): Input == from agent to connect. Output == from connect to agent
        // https://github.com/aws/connect-rtc-js/blob/6a3c5cf0a17939e0af31fde833cf1a355bcab401/src/js/rtc_session.js#L930
        // I disagree with Amazon here, as all stats are collected by the client, they should be using
        // client (agent) oriented naming. Connect may see things differently to these stats!
        agentToDiallerAudioStats.current = newStats.audio.input[0];
        diallerToAgentAudioStats.current = newStats.audio.output[0];

        if (agentToDiallerAudioStats.current !== undefined) {
          const stats = getTimeSeriesStats(
            agentToDiallerAudioStats.current,
            prevAgentToDiallerAudioStats,
            SoftphoneStreamType.AudioAgentToDialler,
          );

          // Get current stats on agents call conectivity
          setCallStatistics(stats);

          try {
            await postCallMetrics(contactId, stats, DiallerType.Connect);
          } catch (e) {
            console.error('! Unable to post agent to dialler audio stats due to error: ', e);
            // Return not required here as we want to fall through to the next post request if this one fails
          }
        } else {
          console.error('! Unable to post agent to dialler audio stats as they do not exist');
        }

        if (diallerToAgentAudioStats.current !== undefined) {
          const stats = getTimeSeriesStats(
            diallerToAgentAudioStats.current,
            prevDiallerToAgentAudioStats,
            SoftphoneStreamType.AudioDiallerToAgent,
          );

          try {
            await postCallMetrics(contactId, stats, DiallerType.Connect);
          } catch (e) {
            console.error('! Unable to post dialler to agent audio stats due to error: ', e);
            // Return not required here as we want to fall through if this one fails
          }
        } else {
          console.error('! Unable to post dialler to agent audio stats as they do not exist');
        }
      };

      const softphoneManager = connect.core.getSoftphoneManager();

      if (!softphoneManager) {
        return;
      }
      const session = softphoneManager.getSession(connectionId);

      session.onSessionFailed = onRtcSessionFailed;
      session.onSessionConnected = onRtcSessionConnected;
      session.onSessionCompleted = onRtcSessionCompleted;
    });

    connect.agent((agent) => {
      const onMuteToggle = (obj: connect.AgentMutedStatus): void => {
        console.log(`+ Event onMuteToggle: Agent update`);

        setAgent((prev) => ({
          ...prev,
          isMuted: obj.muted,
        }));
      };

      const onAgentUpdate = async (agent: connect.Agent): Promise<void> => {
        console.log(`+ Event onAgentUpdate: Agent update`);

        const state = agent.getState();
        const statusName = state.name as unknown as AgentState;
        const statusType = state.type as unknown as AgentStateType;
        const statusTimestamp = state.startTimestamp;

        let connectionMuted = false;
        const agentConnection = getActiveAgentConnection(agent);
        if (agentConnection) {
          connectionMuted = agentConnection.isMute();
        }

        setAgent((prev) => ({
          ...prev,
          status: statusName,
          statusType: statusType,
          statusTimestamp: statusTimestamp,
          isMuted: prev.isMuted || connectionMuted,
          changeStatus: setAgentState(agent),
          mute: muteAgent(agent),
          unmute: unmuteAgent(agent),
          dial: dialPhoneNumber(agent),
          setOnline: setAgentOnline(agent),
          setOffline: setAgentOffline(agent),
          updateRoutingProfile: updateRoutingProfile,
        }));

        ccpAgent.current = agent;
      };

      initializeAgent(agent);

      // As agent.onStateChange() does not do its job we use agent.onRefresh() instead.
      agent.onRefresh(onAgentUpdate);
      agent.onMuteToggle(onMuteToggle);
    });

    connect.contact((contact) => {
      try {
        initializeConnectContact(contact);
      } catch (e) {
        // AWS connect functions swallow JavaScript errors,
        // so I catch and throw errors here.
        console.error(e);
        throw e;
      }
    });

    return function ConnectProviderCleanup() {
      console.log('+ Connect Provider Cleanup (hook cleanup)');
      // Sets agent offline and terminate connect session if possible
      cleanupConnect();

      // TODO: should we maybe remove this from here as if a call is connected we shouldnt kill it,
      // let existing events kill it???
      // Cleanup rtc metric posting if navigation occurs when active
      clearInterval(rtcInterval.current);
    };
  }, [initialRun]);

  const initializeConnectContact = (contact: connect.Contact): void => {
    console.log(`+ Initialize Contact ${contact.getContactId()}`);

    const newContact = newContactModel(contact);
    const contactWithMethods = updateContactWithOptionalMethods(contact, newContact);

    insertOrUpdateContact(contactWithMethods);

    contact.onRefresh(onContactUpdate('onRefresh'));
    contact.onIncoming(onContactUpdate('onIncoming'));
    contact.onPending(onContactUpdate('onPending'));
    contact.onConnecting(onContactUpdate('onConnecting'));
    contact.onAccepted(onContactUpdate('onAccepted'));
    contact.onConnected(onContactConnected);
    contact.onMissed(onContactMissed);
    contact.onACW(onContactUpdate('onACW'));
    contact.onDestroy(onContactDestroy('onDestroy'));
    contact.onError(onContactDestroy('onError'));
  };

  const rehydrateConnectContact = (contact: connect.Contact): void => {
    console.log(`+ Rehydrate Contact ${contact.getContactId()} for view`);

    const newContact = newContactModel(contact);
    const contactWithMethods = updateContactWithOptionalMethods(contact, newContact);

    insertOrUpdateContact(contactWithMethods);

    contact.onRefresh(onContactUpdate('onRefresh'));
    contact.onIncoming(onContactUpdate('onIncoming'));
    contact.onPending(onContactUpdate('onPending'));
    contact.onConnecting(onContactUpdate('onConnecting'));
    contact.onAccepted(onContactUpdate('onAccepted'));
    contact.onConnected(onContactConnected);
    contact.onMissed(onContactMissed);
    contact.onACW(onContactUpdate('onACW'));
    contact.onDestroy(onContactDestroy('onDestroy'));
    contact.onError(onContactDestroy('onError'));

    // If the chat contact is already connected none of the above events will initialise the chat view at all.
    // This block of code accounts for that
    if (
      contact.getStatus().type === connect.ContactStateType.CONNECTED &&
      contact.getType() === connect.ContactType.CHAT
    ) {
      onContactConnected(contact);
    }
  };

  /**
   * CONNECT CORE HANDLERS END
   */

  // TODO: Christian (2022-04-07) This function is hacky and should be removed once the follow connect issue has been resolved.
  // https://github.com/amazon-connect/amazon-connect-streams/issues/459
  // For a bit of context it takes about 30s for streams to get the routing profile update from connect,
  // even thought the agent is updated in the upstream and already dialling as part of that routing profile.
  const updateRoutingProfile = (routingProfileName: string) => {
    setAgent((prev) => ({ ...prev, routingProfile: routingProfileName }));
  };

  const initializeAgent = async (agent: connect.Agent): Promise<void> => {
    console.log(`+ Initialize Agent ${agent.getName()}`);

    const name = agent.getName();
    const config = agent.getConfiguration();
    const softphoneAutoAnswerEnabled = config.softphoneAutoAccept;
    const routingProfile = config.routingProfile.name;
    const defaultOutboundQueue = config.routingProfile.defaultOutboundQueue.name;
    const defaultOutboundQueueARN = config.routingProfile.defaultOutboundQueue.queueARN;
    const state = agent.getState();
    const statusName = state.name;
    const statusType = state.type;
    const statusTimestamp = state.startTimestamp;
    const agentStatesArray = agent.getAgentStates().map((i) => i.name);

    // We only want to rehydrate if the iframe already existed when this provider is run
    if (iframeAlreadyExists.current === true) {
      // This handles the case where you navigate away mid call and come back
      // when you have connected contact (voice/ chat). The connect.contact,
      // will not rerun contact initializations, leading to a delay in view
      // being update with contact as we are waiting for the contact.onRefresh event to fire
      agent.getContacts().map((contact) => {
        try {
          rehydrateConnectContact(contact);
        } catch (e) {
          // AWS connect functions swallow JavaScript errors,
          // so I catch and throw errors here.
          console.error('Unable to reinitialise contact due to error: ', e);
          throw e;
        }
      });
    }

    setAgentStateList(agentStatesArray);

    ccpAgent.current = agent;

    if (appConfig.extensions.internalTransfer) {
      const internalTransferARN = appConfig.extensions.internalTransfer.quickConnectARN;
      let endpoint: connect.Endpoint | undefined;

      try {
        endpoint = await getEndpointByARN(agent)(internalTransferARN);
      } catch (e) {
        // do nothing with given error, we handle below
      }

      if (endpoint === undefined) {
        console.error(
          `+ unable to find quick connect endpoint with ARN: `,
          internalTransferARN,
          ' is your Queue configured with this Quick Connect item?',
        );
      } else {
        if (internalTransferEndpoint.current !== undefined) {
          throw new Error(
            'Unexpected error. Expected internalTransferEndpoint to be undefined, was it cleaned up in a useEffect function?',
          );
        }
        internalTransferEndpoint.current = endpoint;
        // NOTE(jae): 2021-02-10
        // We need to update our contact objects after
        // "internalTransferEndpoint" is set so that "contact.internalTransfer" is set
        // on each of our contacts
        updateConnectContactList(agent);
      }
    }

    if (appConfig.extensions.paymentGateway) {
      const paymentGatewayARN = appConfig.extensions.paymentGateway.quickConnectARN;
      let paymentEndpoint: connect.Endpoint | undefined;

      try {
        paymentEndpoint = await getEndpointByARN(agent)(paymentGatewayARN);
      } catch (e) {
        // do nothing with given error, we handle below
      }

      if (paymentEndpoint === undefined) {
        console.error(`! Failed to find payment gateway endpoint: `, paymentGatewayARN);
      } else {
        if (paymentGatewayEndpoint.current !== undefined) {
          throw new Error(
            'Unexpected error. Expected paymentGatewayEndpoint to be undefined, was it cleaned up in a useEffect function?',
          );
        }

        paymentGatewayEndpoint.current = paymentEndpoint;
        // NOTE(jae): 2021-02-10
        // We need to update our contact objects after
        // "paymentGatewayEndpoint" is set so that "contact.initiatePaymentConference" is set
        // on each of our contacts
        updateConnectContactList(agent);
      }
    }

    setAgent(() => ({
      name: name,
      routingProfile: routingProfile,
      defaultOutboundQueue: defaultOutboundQueue,
      defaultOutboundQueueARN: defaultOutboundQueueARN,
      status: statusName as unknown as AgentState,
      statusType: statusType as unknown as AgentStateType,
      statusTimestamp: statusTimestamp,
      // Voice-only
      isMuted: false,
      initialized: true,
      softphoneAutoAnswerEnabled: softphoneAutoAnswerEnabled,
      changeStatus: setAgentState(agent),
      mute: muteAgent(agent),
      unmute: unmuteAgent(agent),
      dial: dialPhoneNumber(agent),
      setOnline: setAgentOnline(agent),
      setOffline: setAgentOffline(agent),
      updateRoutingProfile: updateRoutingProfile,
    }));
  };

  const internalTransfer =
    (contact: connect.Contact) =>
    async (transferTargetAgent: string): Promise<void> => {
      if (internalTransferEndpoint.current === undefined) {
        throw new Error('Unexpected error. internalTransferEndpoint is undefined');
      }

      // Initial ID will only be set if the contact has been an internal connect transfer.
      // So we use the current id til it is set.
      const initialContactId = contact.getInitialContactId() || contact.getContactId();

      try {
        await postInternalTransferTarget(initialContactId, transferTargetAgent);
        await addThirdPartyConnection(contact)(internalTransferEndpoint.current);
      } catch (e) {
        if (typeof e === 'string') {
          throw new Error(e);
        } else {
          throw new Error(
            'Unexpected error. Unable to initiate connection with third party contact (transfer target agent).',
          );
        }
      }

      logRocket.trackEvent('transfer');
      logRocket.trackEvent('internal_transfer');
    };

  const cancelInternalTransfer =
    (contact: connect.Contact) =>
    async (onlyClearTransferTarget?: boolean): Promise<void> => {
      const initialContactId = contact.getInitialContactId();

      try {
        await clearInternalTransferTarget(initialContactId);
        // We only want this to run if this property is false
        if (Boolean(onlyClearTransferTarget) === false) {
          await destroyThirdPartyConnection(contact)();
        }
      } catch (e) {
        if (typeof e === 'string') {
          throw e;
        } else {
          throw 'Unexpected error. Unable to transfer.';
        }
      }
    };

  const conferenceTransfer =
    (contact: connect.Contact) =>
    async (quickConnectARN: string): Promise<void> => {
      if (ccpAgent.current === undefined) {
        throw new Error('conferenceTransfer: Unexpected error. Agent is undefined');
      }

      try {
        const endpoint = await getEndpointByARN(ccpAgent.current)(quickConnectARN);
        await addThirdPartyConnection(contact)(endpoint);
      } catch (e) {
        if (typeof e === 'string') {
          throw new Error(e);
        } else {
          throw new Error(
            'Unexpected error. Unable to initiate connection with third party contact (transfer target agent).',
          );
        }
      }
    };

  const externalTransfer =
    (contact: connect.Contact) =>
    async (phoneNumber: string): Promise<void> => {
      if (ccpAgent.current === undefined) {
        throw new Error('conferenceTransfer: Unexpected error. Agent is undefined');
      }

      try {
        let endpoint: string | connect.Endpoint = phoneNumber;
        if (phoneNumber.startsWith('arn:')) {
          endpoint = await getEndpointByARN(ccpAgent.current)(phoneNumber);
        }
        await addThirdPartyConnection(contact)(endpoint);
      } catch (e) {
        if (typeof e === 'string') {
          throw new Error(e);
        } else {
          throw new Error(
            'Unexpected error. Unable to initiate connection with third party contact (transfer target agent).',
          );
        }
      }
    };

  const conferencePaymentGateway =
    (contact: connect.Contact) =>
    async (paymentId: string): Promise<void> => {
      if (paymentGatewayEndpoint.current === undefined) {
        throw new Error('conferencePaymentGateway: Unexpected error. paymentGatewayEndpoint is undefined');
      }

      const contactId = contact.getContactId();

      try {
        await postPaymentCTR(contactId, paymentId);
        await addThirdPartyConnection(contact)(paymentGatewayEndpoint.current);
      } catch (e) {
        if (typeof e === 'string') {
          throw e;
        } else {
          throw 'Unexpected error. Unable to transfer.';
        }
      }

      logRocket.trackEvent('transfer');
      logRocket.trackEvent('payment_gateway');
    };

  const contactSendIsTypingEvent = (contact: connect.Contact) =>
    throttle(async (): Promise<void> => {
      if (ccpAgent === undefined) {
        throw new Error('Unexpected error. ccpAgent is undefined');
      }
      const chatController = await getChatController(contact);
      await chatController.sendEvent({ contentType: MessageContentType.Typing });
    }, 3000);

  const updateConnectContactList = (agent: connect.Agent): void => {
    for (const contact of agent.getContacts()) {
      updateConnectContact(contact);
    }
  };

  const updateConnectContact = (contact: connect.Contact): void => {
    const contactId = contact.getContactId();

    updateContactById(getIDForConnectContact(contactId), (prevContact) => {
      const updatedContact = updateContactModel(prevContact, contact);
      const contactWithMethods = updateContactWithOptionalMethods(contact, updatedContact);

      return contactWithMethods;
    });
  };

  const updateContactWithOptionalMethods = (contact: connect.Contact, updatedContact: Contact): Contact => {
    const contactType = contact.getType();

    const updatedContactWithMethods: Contact = {
      ...updatedContact,
      initiateInternalTransfer: internalTransferEndpoint.current ? internalTransfer(contact) : undefined,
      initiateExternalTransfer: externalTransfer(contact),
      cancelInternalTransfer: internalTransferEndpoint.current ? cancelInternalTransfer(contact) : undefined,
      conferenceTransfer: conferenceTransfer(contact),
      accept: updatedContact.statusType === ContactStateType.Connecting ? acceptContact(contact) : undefined,
      sendMessage: contactType === connect.ContactType.CHAT ? sendChatMessage(contact) : undefined,
      sendIsTypingEvent: contactType === connect.ContactType.CHAT ? contactSendIsTypingEvent(contact) : undefined,
      putOnHold: contactType === connect.ContactType.VOICE ? setContactOnHold(contact) : undefined,
      takeOffHold: contactType === connect.ContactType.VOICE ? setContactResume(contact) : undefined,
      initiatePaymentConference: paymentGatewayEndpoint.current ? conferencePaymentGateway(contact) : undefined,
      sendDTMF: contactType === connect.ContactType.VOICE ? sendDtmfKeyInput(contact) : undefined,
    };

    return updatedContactWithMethods;
  };

  const onContactUpdate =
    (eventType: string) =>
    (contact: connect.Contact): void => {
      console.log(`+ Event ${eventType}: Contact update`);

      updateConnectContact(contact);
    };

  const onContactConnected = async (contact: connect.Contact): Promise<void> => {
    console.log(`+ Event onConnected: Contact update`);

    const id = getIDForConnectContact(contact.getContactId());
    updateConnectContact(contact);

    logRocket.trackKeyValue('aws_connect_queue_name', contact.getQueue().name);
    logRocket.trackKeyValue('aws_connect_queue_arn', contact.getQueue().queueARN);

    if (contact.getType() !== connect.ContactType.CHAT) {
      return;
    }

    const connection = contact
      .getConnections()
      .find((c) => c.getType() === connect.ConnectionType.AGENT) as connect.ChatConnection;

    if (!connection) {
      return;
    }

    const chatSession: connect.ChatSession = await connection.getMediaController();
    if (!chatSession) {
      return;
    }

    {
      const contactId = contact.getContactId();
      chatSession.onMessage(onMessage(contactId));
      chatSession.onTyping(onTyping(contactId));
    }

    // Get and update active/current transcript
    {
      const res = await chatSession.getTranscript({});
      // NOTE(Jae): 2020-02-11
      // Typings for "res.data" are typical AWS quality typings.
      // ie. nonsense that doesn't work, res.data is "void | connect.GetTranscriptResult"
      const chatTranscript: Message[] = (res.data as connect.GetTranscriptResult).Transcript.map(messageModel);
      updateContactById(id, {
        messages: chatTranscript,
      });
    }

    // Query backend for old chat history from Oberon DB using sessionID
    {
      const attributes = contact.getAttributes();
      const sessionKeyValue = attributes['session_id'];
      if (sessionKeyValue !== undefined) {
        const chatHistoryRecordList = await getChatHistory(sessionKeyValue.value);

        updateContactById(id, (prevContact) => {
          return {
            ...prevContact,
            messages: mergeMessagesAndAvoidDuplication(prevContact.messages, chatHistoryRecordList as Message[]),
          };
        });
      }
    }
  };

  const onContactMissed = (contact: connect.Contact): void => {
    console.log(`+ Event onMissed: Contact missed`);

    // Handle our logic in an async event so that errors aren't swallowed by the event handler
    // It also stops errors like the following from appearing in console:
    // - 'contact::destroyed::CONTACT_ID_HERE' event handler failed.
    (async () => {
      contact.clear({
        success: () => {
          console.log('+ Cleared missed contact');
        },
        failure: () => {
          console.error('! Unable to clear missed contact.');
        },
      });
    })();
  };

  const onContactDestroy =
    (eventType: string) =>
    (contact: connect.Contact): void => {
      console.log(`+ Event ${eventType}: Contact destroy`);

      const contactId = contact.getContactId();
      removeContactById(getIDForConnectContact(contactId));
    };

  const onMessage =
    (contactId: string) =>
    (res: connect.ChatMessageEvent): void => {
      console.log(`+ Event onMessage: Message update`);

      updateContactById(getIDForConnectContact(contactId), (prevContact) => {
        return {
          ...prevContact,
          messages: [...prevContact.messages, messageModel(res.data)],
          lastTypeEventTime: 0,
        };
      });
    };

  const onTyping =
    (contactId: string) =>
    (e: connect.ChatTypingEvent): void => {
      console.log(`+ Event: Typing update`);

      // Only save customer typing events
      if (e.data.ParticipantRole === MessageRoleType.Customer) {
        updateContactById(getIDForConnectContact(contactId), {
          lastTypeEventTime: Date.parse(e.data.AbsoluteTime),
        });
      }
    };

  // NOTE(christian)
  // Checking if agent is initialized here so we dont have to mark it's inner
  // properties as "any (whatever property) | undefined". We also do this so we
  // do not expose dummy default state data to the agent UI

  if (agent.initialized === false) {
    return <AsyncLoader isLoading={true} children={children} />;
  }

  const context: ConnectContext = {
    agent,
    agentStateList,
    callStatistics,
    downloadSessionLogs,
  };

  return (
    <>
      {connectCoreError && <EmptyState text='User Configuration Error' subText={connectCoreError} />}
      {!connectCoreError && <ConnectContext.Provider value={context}>{children}</ConnectContext.Provider>}
    </>
  );
};

export default ConnectProvider;

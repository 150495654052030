import AddIcon from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';

import { DataItem } from '~components/DataItem';
import SectionCard from '~components/SectionCard';
import useForm, { ValidatorType } from '~hooks/useForm';
import { Campaign, SmsSettings } from '~pages/CampaignManagement/domain';

interface SMSSettingsFormProps {
  campaign: Campaign;
  isEdit: boolean;
  submitting: boolean;
  toggleEdit: () => void;
  onSubmit: (data: SmsSettings) => Promise<void>;
  onRemove: () => Promise<void>;
}

const SMSSettingsForm = ({ campaign, isEdit, submitting, toggleEdit, onSubmit, onRemove }: SMSSettingsFormProps) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { fields, errors, handleInputChange, handleUnconventionalInputChange, handleSubmit, resetForm } = useForm({
    // Hidden field
    enabled: {
      validators: [],
      // If set use the existing value else default to false
      value: campaign.smsSettings?.enabled ?? false,
    },
    smsProvider: {
      validators: [{ type: ValidatorType.Required, message: 'SMS provider is required.' }],
      // Since twilio is the only provider right now we hard code it
      // if the provider isnt set this field will be read only for now
      value: campaign.smsSettings?.smsProvider || 'twilio',
    },
    replyEndpoint: {
      validators: [{ type: ValidatorType.Required, message: 'reply endpoint is required.' }],
      value: campaign.smsSettings?.replyEndpoint || '',
    },
    fromEndpoint: {
      validators: [{ type: ValidatorType.Required, message: 'from endpoint is required.' }],
      value: campaign.smsSettings?.fromEndpoint || '',
    },
    replyGraceTimeSeconds: {
      validators: [{ type: ValidatorType.Required, message: 'reply grace time seconds is required.' }],
      value: campaign.smsSettings?.replyGraceTimeSeconds || 0,
    },
    midJourneyAttempts: {
      validators: [{ type: ValidatorType.Required, message: 'mid journey attempts is required.' }],
      value: campaign.smsSettings?.midJourneyAttempts || 0,
    },
    initialTemplate: {
      validators: [{ type: ValidatorType.Required, message: 'initial template is required.' }],
      value: campaign.smsSettings?.initialTemplate || '',
    },
    midJourneyTemplate: {
      validators: [{ type: ValidatorType.Required, message: 'mid journey template is required.' }],
      value: campaign.smsSettings?.midJourneyTemplate || '',
    },
  });
  const smsSettingsEnabled = fields.enabled.value;

  const onSubmitFn = handleSubmit((formData: any) => {
    const data: SmsSettings = formData;

    onSubmit(data);
  });

  const onRemoveFn = () => {
    resetForm();
    onRemove();
  };

  const onEnableSMSSettingsFn = () => {
    handleUnconventionalInputChange('enabled', true);
    toggleEdit();
  };

  const onCloseSMSSettingsFn = () => {
    // We only want to run this field change IF sms settings havent existed yet
    // (or enabled was false, with defaults coming through)
    if (campaign.smsSettings === null || !campaign.smsSettings?.enabled) {
      resetForm();
    }

    toggleEdit();
  };

  return (
    <SectionCard title='Precall SMS Settings' onEdit={campaign.smsSettings?.enabled ? toggleEdit : undefined}>
      {!smsSettingsEnabled && (
        <Button
          fullWidth
          variant='contained'
          disableElevation
          color='primary'
          startIcon={<AddIcon />}
          onClick={onEnableSMSSettingsFn}>
          Enable Settings
        </Button>
      )}

      {smsSettingsEnabled && !isEdit && (
        <>
          <DataItem
            stacked={isExtraSmall}
            disableMargin
            title='SMS Provider'
            tooltip="The provider used to send out precall SMS's to leads. Twilio is currently the only supported SMS Provider."
            value={campaign.smsSettings?.smsProvider}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Reply Endpoint'
            tooltip='This is the endpoint that is used when a lead responds to the precall SMS.'
            value={campaign.smsSettings?.replyEndpoint}
          />
          <DataItem
            stacked={isExtraSmall}
            title='From Endpoint'
            tooltip='This is what the lead will see as the sender of the SMS.'
            value={campaign.smsSettings?.fromEndpoint}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Reply Grace Time Seconds'
            tooltip='The amount of time between sending the precall SMS and adding them to the dial queue.'
            value={campaign.smsSettings?.replyGraceTimeSeconds}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Mid Journey Attempts'
            tooltip='The number of call attempts that will be made before sending the mid-journey SMS.'
            value={campaign.smsSettings?.midJourneyAttempts}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Inital Template'
            tooltip='The template of the initial SMS sent to a lead.'
            value={campaign.smsSettings?.initialTemplate}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Mid Journey Template'
            tooltip='The template of the mid-journey SMS sent to a lead.'
            value={campaign.smsSettings?.midJourneyTemplate}
          />
        </>
      )}

      {smsSettingsEnabled && isEdit && (
        <form onSubmit={onSubmitFn} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant='outlined'
                id='smsProvider'
                name='smsProvider'
                label='SMS Provider'
                disabled={true}
                required={true}
                defaultValue={fields.smsProvider.value}
                error={Boolean(errors.smsProvider)}
                helperText={errors.smsProvider && errors.smsProvider[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant='outlined'
                id='replyEndpoint'
                name='replyEndpoint'
                label='Reply Endpoint'
                disabled={submitting}
                required={true}
                defaultValue={fields.replyEndpoint.value}
                error={Boolean(errors.replyEndpoint)}
                helperText={errors.replyEndpoint && errors.replyEndpoint[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant='outlined'
                id='fromEndpoint'
                name='fromEndpoint'
                label='From Endpoint'
                disabled={submitting}
                required={true}
                defaultValue={fields.fromEndpoint.value}
                error={Boolean(errors.fromEndpoint)}
                helperText={errors.fromEndpoint && errors.fromEndpoint[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type='number'
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 0 } }}
                variant='outlined'
                id='replyGraceTimeSeconds'
                name='replyGraceTimeSeconds'
                label='Reply Grace Time Seconds'
                disabled={submitting}
                required={true}
                defaultValue={fields.replyGraceTimeSeconds.value}
                error={Boolean(errors.replyGraceTimeSeconds)}
                helperText={errors.replyGraceTimeSeconds && errors.replyGraceTimeSeconds[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type='number'
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 0 } }}
                variant='outlined'
                id='midJourneyAttempts'
                name='midJourneyAttempts'
                label='Mid Journey Attempts'
                disabled={submitting}
                required={true}
                defaultValue={fields.midJourneyAttempts.value}
                error={Boolean(errors.midJourneyAttempts)}
                helperText={errors.midJourneyAttempts && errors.midJourneyAttempts[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant='outlined'
                id='initialTemplate'
                name='initialTemplate'
                label='Initial Template'
                disabled={submitting}
                required={true}
                defaultValue={fields.initialTemplate.value}
                error={Boolean(errors.initialTemplate)}
                helperText={errors.initialTemplate && errors.initialTemplate[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant='outlined'
                id='midJourneyTemplate'
                name='midJourneyTemplate'
                label='Mid Journey Template'
                disabled={submitting}
                required={true}
                defaultValue={fields.midJourneyTemplate.value}
                error={Boolean(errors.midJourneyTemplate)}
                helperText={errors.midJourneyTemplate && errors.midJourneyTemplate[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} item xs={12}>
              {campaign.smsSettings?.enabled && (
                <div>
                  <LoadingButton
                    variant='contained'
                    disableElevation
                    loading={submitting}
                    onClick={onRemoveFn}
                    color='secondary'>
                    Remove
                  </LoadingButton>
                </div>
              )}

              {/* Used to maintain flex space-between when the remove button isnt present */}
              {!campaign.smsSettings?.enabled && <div></div>}

              <div>
                <Button onClick={onCloseSMSSettingsFn}>Cancel</Button>

                <LoadingButton
                  sx={{ marginLeft: 1 }}
                  type='submit'
                  variant='contained'
                  disableElevation
                  loading={submitting}
                  color='primary'>
                  {campaign.smsSettings === null ? 'Add' : 'Update'}
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    </SectionCard>
  );
};

export default SMSSettingsForm;

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import EmptyState from '~components/EmptyState';
import { createCallback, getCallbacks, removeCallback, updateCallback } from '~pages/CampaignManagement/api';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { Callback, CreateCallback, UpdateCallback } from '../../domain';
import CallbackCard from './CallbackCard';
import CallbackDetailsModal from './CallbackDetailsModal';
import CreateCallbackModal from './CreateCallbackModal';
import EditCallbackModal from './EditCallbackModal';

const enum FilterType {
  Empty = '',
  Pending = 'pending',
  Attempted = 'attempted',
  Removed = 'removed',
}

interface Query {
  search: string;
  page: number;
  filter: FilterType;
  rowsPerPage: number;
}

interface Error {
  text: string;
  subText: string;
}

const filterList = [
  {
    label: 'None',
    value: FilterType.Empty,
  },
  {
    label: 'Pending',
    value: FilterType.Pending,
  },
  {
    label: 'Attempted',
    value: FilterType.Attempted,
  },
  {
    label: 'Removed',
    value: FilterType.Removed,
  },
];

const rowsPerPageList = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
  {
    label: '200',
    value: 200,
  },
];

const LeadListCallbacks = () => {
  const { campaignId, listId } = useParams() as { campaignId: string; listId: string };
  const { pushNotification } = useNotification();
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [callbacks, setCallbacks] = useState<Callback[]>([]);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [selectedCallback, setSelectedCallback] = useState<Callback | undefined>(undefined);
  const [editableCallback, setEditableCallback] = useState<Callback | undefined>(undefined);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [query, setQuery] = useState<Query>({
    search: '',
    page: 1,
    filter: FilterType.Empty,
    rowsPerPage: rowsPerPageList[0].value,
  });

  useEffect(() => {
    (async () => {
      try {
        setCallbacks(await getCallbacks(+campaignId, +listId));
      } catch (e) {
        handleError(e);
      } finally {
        setPageLoaded(true);
      }
    })();
  }, []);

  const handleError = (e: any) => {
    if (e instanceof APIError) {
      setError({ text: 'Unable to request data from backend', subText: e.message });
    }

    if (e instanceof UnsupportedStructureError) {
      setError({ text: 'Data from backend Invalid', subText: 'Unable to decode response' });
    }
  };

  const toggleCreateCallbackModal = () => {
    setCreateModalOpen((prev) => !prev);
  };

  const onQueryChange = async (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value, page: 1 }));
  };

  const onPageChange = (e: ChangeEvent<unknown>, value: number) => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });

    setQuery((prev) => ({ ...prev, page: value }));
  };

  const setSelectedCallbackData = (callback: Callback) => () => {
    setSelectedCallback(callback);
  };

  const removeSelectedCallback = () => {
    setSelectedCallback(undefined);
  };

  const setEditableCallbackData = (callback: Callback) => () => {
    setEditableCallback(callback);
  };

  const removeEditableCallback = () => {
    setEditableCallback(undefined);
  };

  const onCallbackCreate = async (data: CreateCallback) => {
    setSubmittingData(true);

    try {
      await createCallback(+campaignId, +listId, data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', 'You have successfully create a callback.');

    try {
      setCallbacks(await getCallbacks(+campaignId, +listId));
    } catch (e) {
      handleError(e);
    } finally {
      setCreateModalOpen(false);
    }
  };

  const onCallbackUpdate = async (callbackId: number, data: UpdateCallback) => {
    setSubmittingData(true);

    try {
      await updateCallback(+campaignId, +listId, callbackId, data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', 'You have successfully updated the callback.');

    try {
      setCallbacks(await getCallbacks(+campaignId, +listId));
    } catch (e) {
      handleError(e);
    } finally {
      setEditableCallback(undefined);
    }
  };

  const onCallbackRemove = (callbackId: number) => async () => {
    try {
      await removeCallback(+campaignId, +listId, callbackId);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return;
    }

    pushNotification('success', 'You have successfully removed the callback.');

    try {
      setCallbacks(await getCallbacks(+campaignId, +listId));
    } catch (e) {
      handleError(e);
    }
  };

  const searchQuery = query.search.toLowerCase().split(/\s+/);

  const filteredDisplay = callbacks
    // Filter by filter value
    .filter((item) => {
      // Return everything if filter empty
      if (query.filter === FilterType.Empty) return true;
      if (query.filter === FilterType.Pending && item.attemptId === null && item.disabled === null) {
        return true;
      }
      if (query.filter === FilterType.Attempted && item.attemptId !== null) {
        return true;
      }
      if (query.filter === FilterType.Removed && item.disabled !== null) {
        return true;
      }
      return false;
    })
    // Search Fuzzy matching on lead name and endpoint
    // TODO: server-side search
    .filter((item) => {
      return searchQuery.every((search) => {
        if (item.leadName.toLowerCase().includes(search)) return true;
        if (item.endpoint.toLowerCase().includes(search)) return true;
        return false;
      });
    });

  const displayList = filteredDisplay
    // Get the current set of page results
    .slice((query.page - 1) * query.rowsPerPage, (query.page - 1) * query.rowsPerPage + query.rowsPerPage)
    // Component generation
    .map((item: Callback, index: number) => (
      <CallbackCard
        key={index}
        callback={item}
        onClick={setSelectedCallbackData(item)}
        onEdit={setEditableCallbackData(item)}
        onRemove={onCallbackRemove(item.callbackId)}
      />
    ));

  const noCallbacks = callbacks.length === 0;
  // If any filter property is set and displayList is empty we assume no relevant search results
  const noSearchOrFilterResults = (query.search || query.filter !== FilterType.Empty) && displayList.length === 0;
  // Generates count of data pages we can navigate through
  const paginationCount = Math.ceil(filteredDisplay.length / query.rowsPerPage);
  // Information on current results being shown
  const resultsShowing = `Showing ${query.page === 1 ? 1 : (query.page - 1) * query.rowsPerPage + 1}-${
    query.page * query.rowsPerPage > filteredDisplay.length ? filteredDisplay.length : query.page * query.rowsPerPage
  } of ${filteredDisplay.length}`;

  const errorDisplay = error ? <EmptyState type='error' text={error.text} subText={error.subText} /> : null;

  const rowsPerPageListDisplay = rowsPerPageList.map((item, index) => (
    <MenuItem key={index} value={item.value}>
      {item.label}
    </MenuItem>
  ));

  const filterListDisplay = filterList.map((item, index) => (
    <MenuItem key={index} value={item.value}>
      {item.label}
    </MenuItem>
  ));

  return (
    <AsyncLoader isLoading={!pageLoaded} error={errorDisplay}>
      {noCallbacks && (
        <EmptyState
          type='no-items-2'
          text='No callbacks currently exist'
          subText='Create a callback by clicking the button below'
          action={toggleCreateCallbackModal}
          actionText='Create Callback'
        />
      )}
      {!noCallbacks && (
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              variant='outlined'
              label='Search'
              id='search'
              name='search'
              defaultValue={query.search}
              onChange={onQueryChange}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              select
              variant='outlined'
              id='filter'
              name='filter'
              label='Filter'
              value={query.filter}
              onChange={onQueryChange}>
              {filterListDisplay}
            </TextField>
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              select
              variant='outlined'
              id='rowsPerPage'
              name='rowsPerPage'
              label='Rows per Page'
              value={query.rowsPerPage}
              onChange={onQueryChange}>
              {rowsPerPageListDisplay}
            </TextField>
          </Grid>

          <Hidden smDown>
            <Grid item md={1}></Grid>
          </Hidden>

          <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              fullWidth
              startIcon={<AddIcon />}
              onClick={toggleCreateCallbackModal}>
              Create Callback
            </Button>
          </Grid>

          <Grid item xs={12}>
            {!noSearchOrFilterResults && (
              <>
                <Typography variant='body1' component='p' align='right' color='textSecondary'>
                  {resultsShowing}
                </Typography>
                <List>{displayList}</List>

                <Pagination
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 1,
                  }}
                  color='primary'
                  count={paginationCount}
                  page={query.page}
                  onChange={onPageChange}
                />
              </>
            )}
            {noSearchOrFilterResults && (
              <EmptyState
                type='no-records-found'
                text='No callbacks found matching your search criteria'
                subText='Try alternate words or selections.'
              />
            )}
          </Grid>
        </Grid>
      )}

      <CallbackDetailsModal
        open={Boolean(selectedCallback)}
        callback={selectedCallback}
        onClose={removeSelectedCallback}
      />

      <CreateCallbackModal
        open={createModalOpen}
        submitting={submittingData}
        onAccept={onCallbackCreate}
        onClose={toggleCreateCallbackModal}
      />

      <EditCallbackModal
        open={Boolean(editableCallback)}
        submitting={submittingData}
        callback={editableCallback}
        onAccept={onCallbackUpdate}
        onClose={removeEditableCallback}
      />
    </AsyncLoader>
  );
};

export default LeadListCallbacks;

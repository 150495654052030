import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Agent } from '~pages/SystemManagement/domain';

import { getAgents, getAgentsByNextUrl } from './api';
import { AgentsResponse } from './domain';

const useAgentSearch = (campaignId: number, search: string, filter: string) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());

  const getNextPage = useCallback(async () => {
    if (nextUrl !== null) {
      setLoading(true);
      setError(false);

      let resp: AgentsResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getAgentsByNextUrl(nextUrl, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setAgents((prev) => [...prev, ...resp!.agents]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    }
  }, [nextUrl]);

  const reload = useCallback(async () => {
    setLoading(true);
    setError(false);

    let resp: AgentsResponse | undefined;
    try {
      axiosCancelRef.current = axios.CancelToken.source();
      resp = await getAgents(campaignId, search, filter, axiosCancelRef.current);
    } catch (e) {
      setError(true);
      setLoading(false);
      return;
    }

    // Returns undefined if request is canceled
    if (resp === undefined) return;

    setAgents(resp.agents);
    setHasMore(resp.nextPageUrl !== null);
    setNextUrl(resp.nextPageUrl);
    setLoading(false);
  }, []);

  useEffect(() => {
    setAgents([]);
  }, [search, filter]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setError(false);

      let resp: AgentsResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getAgents(campaignId, search, filter, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setAgents((prev) => [...prev, ...resp!.agents]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    })();

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, [search, filter]);

  return { loading, error, agents, hasMore, getNextPage, reload };
};

export default useAgentSearch;

import * as j from '@mojotech/json-type-validation';

/**
 * ExternalTransferItem maps directly to our Golang struct
 *
 * @source {@link https://gitlab.com/tsagroup/oberon/-/blob/production/core-api/externaltransfer/externaltransfer.go}
 */
export interface ExternalTransferItem {
  name: string;
  address: string;
  phoneNumber: string;
}
export interface ConferenceTransferItem {
  name: string;
  quickConnectARN: string;
}

export const ExternalTransferListDecoder: j.Decoder<ExternalTransferItem[]> = j
  .object({
    list: j.union(
      j.array(
        j.object({
          name: j.string(),
          address: j.string(),
          phone_number: j.string(),
        }),
      ),
      j.constant(null),
    ),
  })
  .map((item) =>
    item.list
      ? item.list.map((l) => ({
          name: l.name,
          address: l.address,
          phoneNumber: l.phone_number,
        }))
      : [],
  );

export const PaymentDetailsDecoder: j.Decoder<Dictionary<string>> = j.dict(j.string());

export const ConferenceTransferListDecoder: j.Decoder<ConferenceTransferItem[]> = j
  .object({
    list: j.array(
      j.object({
        name: j.string(),
        quick_connect_arn: j.string(),
      }),
    ),
  })
  .map((item) =>
    item.list.map((l) => ({
      name: l.name,
      quickConnectARN: l.quick_connect_arn,
    })),
  );

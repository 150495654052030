import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import ResizeObserver from 'resize-observer-polyfill';
import { v4 as uuidv4 } from 'uuid';

/**
 * This custom hook is designed to replace the `<CellMeasurer />` component in `react-virtualized`
 * which is used in conjunction with `react-window` (same author, but react-virtualized was dropped and is no longer
 * maintained)
 *
 * This hook returns props to be given to the `<VariableSizeList />` component in `react-window`
 *
 * `items` are react elements
 * `visible` is a boolean that dictates if the element is in view. i.e. this will render required
 *  hidden sizing elements when the view is open
 */
export default function useCellMeasurer({ items, visible }: { items: any; visible?: boolean }) {
  // create a ref to get the `div` element the `VariableSizeList` uses
  const innerRef = useRef<HTMLDivElement>(null);

  // create an id unique to this cell measurer instance
  const id = useMemo(() => uuidv4(), []);

  // create a "hidden sizing element" in state.
  //
  // when the innerRef element mounts, the width of the innerRef will be used for width of
  // the hidden sizing element
  const [hiddenSizingEl, setHiddenSizingEl] = useState<HTMLDivElement | null>(null);

  // this width is used to determine whether or not the list needs to be re-rendered due to a resize
  const [width, setWidth] = useState(0);
  const classId = `hidden-sizing-element-${id}`;

  // `itemSize` is a function required by `VariableSizeList`. This function is called when it needs
  // get the height of the item inside it.
  // note: the result of this function is memoized by `react-window` so it will only be called once
  // to get the item size
  const itemSize = useCallback(
    (index: number) => {
      if (!hiddenSizingEl) return 0;

      // get the item (which is a react node)
      const item = items[index];
      // then render the react node to a string synchronously with `react-dom/server`
      hiddenSizingEl.innerHTML = renderToString(item);

      // get and return the size of the hidden sizing element
      return hiddenSizingEl.clientHeight || 0;
    },
    [hiddenSizingEl, items],
  );

  // this effects adds the hidden sizing element to the DOM
  useEffect(() => {
    /** @type {HTMLElement} */
    const innerEl = innerRef.current;
    if (!innerEl) return;
    if (hiddenSizingEl) return;
    if (visible !== undefined && visible === false) return;

    const newHiddenSizingEl = document.createElement('div');

    const width = innerEl.clientWidth;
    newHiddenSizingEl.classList.add(classId);
    newHiddenSizingEl.style.position = 'absolute';
    newHiddenSizingEl.style.top = '0';
    newHiddenSizingEl.style.width = `${width}px`;
    newHiddenSizingEl.style.pointerEvents = 'none';
    newHiddenSizingEl.style.visibility = 'hidden';

    setHiddenSizingEl(newHiddenSizingEl);

    document.body.appendChild(newHiddenSizingEl);
  }, [hiddenSizingEl, id, visible]);

  // this removes all hidden sizing elements on unmount
  useEffect(() => {
    // returning a function from `useEffect` is the "clean-up" phase
    return () => {
      const hiddenSizingElement = document.querySelector(`.${classId}`);

      if (!hiddenSizingElement) return;

      document.body.removeChild(hiddenSizingElement);
    };
  }, [id]);

  // this is used to watch for changes in the size of the list element and sets the width
  useEffect(() => {
    const el = innerRef.current;
    if (!el) return;

    function handleResize() {
      const { width } = el!.getBoundingClientRect();
      setWidth(width);
    }

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(el);

    return () => resizeObserver.disconnect();
  }, [visible]);

  // this key is used to re-render the list when the dependencies array changes
  const key = useMemo(() => uuidv4(), [itemSize, hiddenSizingEl, width, items]);

  // while there is no hidden sizing element, hide the list element
  const style: CSSProperties | undefined = hiddenSizingEl
    ? undefined
    : {
        visibility: 'hidden',
      };

  return {
    innerRef,
    itemSize,
    itemCount: items.length,
    key,
    style,
  };
}

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import ControlledCheckbox from '~components/Form/ControlledCheckbox';
import OberonDialog from '~components/OberonDialog';
import { AsyncQueue, CreateEditAsyncQueue } from '~pages/AsyncManagement/domain';
import { ChannelType } from '~services/AsyncManager/domain';

interface CreateEditQueueModalProps {
  open: boolean;
  submitting: boolean;
  queue?: AsyncQueue;
  onAccept: (data: CreateEditAsyncQueue) => Promise<void>;
  onClose: () => void;
}

interface Form {
  queue: string | null;
  title: string;
  channelType: string | null;
  description: string;
  isPublic: boolean;
  systemEndpoint: string | null;
}

const channelTypeList = [
  // TODO: uncomment as each channel is added to async
  // TODO: For each channel that supports system endpoint, we should have some really loose validation
  //       i.e. if channel type email, have some email validation etc
  // {
  //   label: 'Android RCS',
  //   value: ChannelType.AndroidRCS,
  // },
  // {
  //   label: 'Apple Business Chat',
  //   value: ChannelType.AppleBusinessChat,
  // },
  // {
  //   label: 'Email',
  //   value: ChannelType.Email,
  // },
  // {
  //   label: 'SMS',
  //   value: ChannelType.SMS,
  // },
  {
    label: 'Webchat',
    value: ChannelType.WebChat,
  },
];

const CreateEditQueueModal = ({ open, submitting, queue, onAccept, onClose }: CreateEditQueueModalProps) => {
  const isLoading = submitting;
  const isEdit = Boolean(queue);
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<Form>({
    defaultValues: {
      queue: null,
      title: '',
      channelType: null,
      description: '',
      isPublic: false,
      systemEndpoint: null,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const channelTypeWatch = watch('channelType');
  const systemEndpointVisible =
    (queue && queue.channelType !== ChannelType.WebChat) ||
    (channelTypeWatch && channelTypeWatch !== ChannelType.WebChat);

  const channelTypeListDisplay = channelTypeList.map((item, index) => (
    <MenuItem key={index} value={item.value}>
      {item.label}
    </MenuItem>
  ));

  // Handles reset and editable display
  useEffect(() => {
    if (open) {
      if (queue !== undefined) {
        setValue('title', queue.title);
        setValue('description', queue.description);
        setValue('isPublic', queue.isPublic);

        if (queue.channelType !== ChannelType.WebChat) {
          setValue('systemEndpoint', queue.systemEndpoint);
        }
      }
    }

    // Reset form on close
    return () => {
      reset();
    };
  }, [open, queue]);

  const onSubmit = handleSubmit(async (data: Form) => {
    try {
      await onAccept({
        queue: data.queue || undefined,
        title: data.title,
        description: data.description,
        channelType: data.channelType || undefined,
        isPublic: data.isPublic,
        systemEndpoint: data.systemEndpoint || undefined,
      });
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
      return;
    }

    reset();
  });

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : 'Create'} Queue`}
      content={
        <Grid container spacing={2}>
          {!isEdit && (
            <Grid item xs={12}>
              <Controller
                name='queue'
                control={control}
                rules={{
                  required: 'Queue is required.',
                  pattern: {
                    value: /^[0-9a-z-]+$/,
                    message: 'Queue can only be lowercase, alphanumeric and contain hyphens.',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant='outlined'
                    label='Queue'
                    disabled={isLoading}
                    required={true}
                    error={Boolean(errors.queue)}
                    helperText={errors.queue?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Controller
              name='title'
              control={control}
              rules={{ required: 'Title is required.' }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Title'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.title)}
                  helperText={errors.title?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          {!isEdit && (
            <Grid item xs={12}>
              <Controller
                name='channelType'
                rules={{ required: 'Channel type is required.' }}
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    select
                    variant='outlined'
                    label='Channel Type'
                    disabled={isLoading}
                    required={true}
                    error={Boolean(errors.channelType)}
                    helperText={errors.channelType?.message}
                    {...field}>
                    {channelTypeListDisplay}
                  </TextField>
                )}
              />
            </Grid>
          )}

          {systemEndpointVisible && (
            <Grid item xs={12}>
              <Controller
                name='systemEndpoint'
                control={control}
                rules={{ required: 'System endpoint is required.' }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant='outlined'
                    label='System Endpoint'
                    disabled={isLoading}
                    required={true}
                    error={Boolean(errors.systemEndpoint)}
                    helperText={errors.systemEndpoint?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <ControlledCheckbox name='isPublic' control={control} label='Visible to customer?' disabled={isLoading} />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='description'
              control={control}
              rules={{ required: 'Description is required.' }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant='outlined'
                  label='Description'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            {isEdit ? 'Update' : 'Create'}
          </LoadingButton>
        </>
      }
    />
  );
};

export default CreateEditQueueModal;

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FilterListIcon from '@mui/icons-material/FilterList';
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import blueGrey from '@mui/material/colors/blueGrey';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import queryString from 'query-string';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { generatePath, useNavigate } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import { BasicTable, ColumnData, RowData } from '~components/BasicTable';
import EmptyState from '~components/EmptyState';
import Selectbox from '~components/Form/Selectbox';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useSetPageTitleProps } from '~providers/PageTitleProvider';
import Routes from '~providers/RouteProvider/Routes';
import { PolicyType, isAllowedRole, useUserProfile } from '~providers/UserProfileProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';
import { getDurationFromSeconds, isEmpty } from '~utils/Functions';

import { getContactLogFilterOptions, getContactLogs } from './api';
import { ContactLog } from './domain';

const DATE_FORMAT = 'FFF';

const formatDate = (date: string, formatString: string) => {
  if (date) {
    return DateTime.fromISO(date).toFormat(formatString);
  }
  return '';
};

enum SortBy {
  Campaign = 'campaign_name',
  Channel = 'channel',
  DispositionCode = 'disposition_code',
  DispositionSubCode = 'disposition_sub_code',
  DispositionTitle = 'disposition_title',
  Agent = 'agent_username',
  Initiation = 'initiation_timestamp',
  InitiationMethod = 'initiation_method',
  ConnectedToAgent = 'connected_to_agent_timestamp',
  CallDuration = 'connected_to_agent_duration',
  DisconnectReason = 'disconnect_reason',
}

interface Error {
  text: string;
  subText: string;
}

type Filter = { [key: string]: any };

const filterDefault: Filter = {
  dateFrom: DateTime.fromJSDate(new Date()).minus({ days: 7 }).startOf('day').toISO(),
  dateTo: DateTime.fromJSDate(new Date()).endOf('day').toISO(),
  sortBy: SortBy.Initiation,
  sortOrder: 'desc',
};

const FilterButton = styled(Button)(({ theme }) => ({
  'color': theme.palette.getContrastText(blueGrey[900]),
  'backgroundColor': blueGrey[900],
  ':hover': {
    backgroundColor: blueGrey[800],
  },
}));

const URLSearchParamsToDict = (searchParams: URLSearchParams): Filter => {
  let obj = {};
  searchParams.forEach((value, key) => {
    obj = { ...obj, [key]: value };
  });

  return obj as Filter;
};

const ContactLogs = () => {
  const appConfig = useAppConfiguration();
  const { policies } = useUserProfile();
  const navigate = useNavigate();
  const setPageTitleProps = useSetPageTitleProps();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [sortChange, setSortChange] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [contactLogs, setContactLogs] = useState<ContactLog[]>([]);
  const [searchParams, setSearchParams] = useSearchParams(filterDefault);
  const [filter, setFilter] = useState<Filter>(URLSearchParamsToDict(searchParams));
  const [campaigns, setCampaigns] = useState<string[]>([]);
  const [agents, setAgents] = useState<string[]>([]);
  const [disconnectReasons, setDisconnectReasons] = useState<string[]>([]);
  const [initiationMethods, setInitiationMethods] = useState<string[]>([]);
  const [channels, setChannels] = useState<string[]>([]);
  const [dispositions, setDispositions] = useState<Dictionary<string[]>>({});
  const [dateToValidationError, setDateToValidationError] = useState<string | undefined>(undefined);
  const canDownload = isAllowedRole([PolicyType.Manager, PolicyType.QualityAnalyst, PolicyType.DiallerAdmin], policies);
  const downloadLink = useMemo(() => {
    // We use searchParams to generate this link as we want the download to be an
    // exact representation of what is currently shown on the screen,
    // not whats selected in the filters drawer before a user has even clicked on search
    const searchQuery = queryString.stringify({
      endpoint: searchParams.get('endpoint') || undefined,
      external_id: searchParams.get('externalId') || undefined,
      contact_id: searchParams.get('contactId') || undefined,
      campaign_name: searchParams.get('campaign') || undefined,
      channel: searchParams.get('channel') || undefined,
      disposition_code: searchParams.get('disposition') || undefined,
      disposition_sub_code: searchParams.get('dispositionSubCode') || undefined,
      agent_username: searchParams.get('agent') || undefined,
      date_from: searchParams.get('dateFrom') || undefined,
      date_to: searchParams.get('dateTo') || undefined,
      sort_by: searchParams.get('sortBy') || undefined,
      sort_order: searchParams.get('sortOrder') || undefined,
      disconnect_reason: searchParams.get('disconnectReason') || undefined,
      initiationMethod: searchParams.get('initiationMethod') || undefined,
    });

    return `/api/contacts/download?${searchQuery}`;
  }, [searchParams]);
  const filterDateToMinDate = useMemo(() => DateTime.fromISO(filter.dateFrom), [filter.dateFrom]);
  // Equivalent to css calc(100vh - 330px)
  // Note: 330 is a culmination of padding/ margins/ heights of elements defined above the table
  const tableHeight = document.body.clientHeight - 330;

  // optional attribute search fields
  const attributeNameList = appConfig.web.searchableContactLogAttributes.map((attr) => ({
    label: attr.label,
    value: attr.key,
  }));

  // Set page title
  useEffect(() => {
    setPageTitleProps({ pageName: 'Contact Logs' });
  }, []);

  // Fetches all dynamic contact log filters on initial load
  useEffect(() => {
    (async () => {
      let data;

      try {
        data = await getContactLogFilterOptions();
      } catch (e) {
        handleError(e);
        return;
      }

      setCampaigns(data.campaigns);
      setAgents(data.agents);
      setChannels(data.channelType);
      setDispositions(data.dispositions);
      setDisconnectReasons(data.disconnectReasons);
      setInitiationMethods(data.initiationMethods);
    })();
  }, []);

  // Fetches contact log records on Initial load
  useEffect(() => {
    (async () => {
      setLoading(true);
      await getContactLogData();
      setLoading(false);
    })();
  }, []);

  // Fetches contact log records when a sort action has been fired
  useEffect(() => {
    (async () => {
      if (sortChange) {
        // Change completed so we want to turn it off
        setSortChange(false);

        await getContactLogData();
        // Updates searchParam's object with sort by/ sort order properties
        // Note: The hook does not provide previous which is annoying, so we update the existing state object hoping
        //       nothing has changed and then update the full state.
        searchParams.set('sortBy', filter.sortBy);
        searchParams.set('sortOrder', filter.sortOrder);
        setSearchParams(searchParams);
      }
    })();
  }, [sortChange]);

  const getContactLogData = async () => {
    // Reset error from previous query
    setError(null);

    let data;

    try {
      data = await getContactLogs(filter);
    } catch (e) {
      handleError(e);
      return;
    }

    setContactLogs(data);
  };

  const handleError = (e: any) => {
    console.error(e);
    if (e instanceof APIError) {
      setError({ text: 'Unable to request data from backend', subText: e.message });
    }
    if (e instanceof UnsupportedStructureError) {
      setError({ text: 'Data from backend Invalid', subText: 'Unable to decode response' });
    }
  };

  const handleAutoCompleteDispositionFilterChange = (event: ChangeEvent<{}>, value: string | null) => {
    setFilter((prev) => ({
      ...prev,
      disposition: value || '',
      dispositionSubCode: '',
    }));
  };

  const handleAutoCompleteFilterChange = (name: string) => (event: ChangeEvent<{}>, value: string | null) => {
    setFilter((prev) => ({
      ...prev,
      [name]: value || '',
    }));
  };

  const handleFilterChange = (name: string, value: any) => {
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Hack of applying <DateTime> generic type on the DatePicker components as then it
  // inforces correct typing for this onChange function so it's typing is not messed up. This breaks visible
  // typing however even though it passed type checking. i.e. defers TDate generic from input value which is wrong
  // as minDate then also overrides this with its DateTime generic
  const handleDatepickerChange = (fieldName: string) => (dateObj: DateTime | null) => {
    handleFilterChange(fieldName, dateObj === null ? null : dateObj.toISO());
  };

  const handleColumnSort = (column: SortBy) => () => {
    if (column === filter.sortBy) {
      setFilter((prev) => ({ ...prev, sortOrder: prev.sortOrder === 'asc' ? 'desc' : 'asc' }));
    } else {
      setFilter((prev) => ({ ...prev, sortBy: column, sortOrder: 'asc' }));
    }

    // Mark as changed so we trigger the auto fetch of contact results
    setSortChange(true);
  };

  const onRowClick = (data: RowData) => {
    const url = generatePath(Routes.contactLogDetails.path, { contactId: data.contactId });
    navigate(url);
  };

  const toggleFilterBar = () => {
    setShowFilters((prev) => !prev);
  };

  const resetFilter = () => setFilter(filterDefault);

  const onSearch = async () => {
    setLoading(true);
    setSearchLoading(true);
    await getContactLogData();
    setSearchParams(filter);
    setLoading(false);
    setSearchLoading(false);
  };

  const dispositionsSelectList = !isEmpty(dispositions) ? Object.keys(dispositions) : [];
  const subDispositionsSelectList =
    Boolean(filter.disposition) && !isEmpty(dispositions) ? dispositions[filter.disposition] : [];
  const errorDisplay = error ? <EmptyState type='error' text={error.text} subText={error.subText} /> : null;

  // Should never re-validate after initial load
  const columns: ColumnData[] = useMemo(() => {
    return [
      { width: 331, label: 'Contact ID', dataKey: 'contactId', dataTooltip: true, copyToClipboardIcon: true },
      { width: 310, label: 'External ID', dataKey: 'externalId', dataTooltip: true },
      { width: 180, label: 'Endpoint', dataKey: 'customerCli', dataTooltip: true },
      {
        width: 120,
        label: 'Method',
        dataKey: 'initiationMethod',
        sortDirection: filter.sortOrder,
        sortActive: filter.sortBy === SortBy.InitiationMethod,
        onColumnSort: handleColumnSort(SortBy.InitiationMethod),
        dataTooltip: true,
      },
      {
        width: 120,
        label: 'Channel',
        dataKey: 'channel',
        sortDirection: filter.sortOrder,
        sortActive: filter.sortBy === SortBy.Channel,
        onColumnSort: handleColumnSort(SortBy.Channel),
        dataTooltip: true,
      },
      {
        width: 300,
        label: 'Initiation',
        dataKey: 'initiationTimestamp',
        sortDirection: filter.sortOrder,
        sortActive: filter.sortBy === SortBy.Initiation,
        onColumnSort: handleColumnSort(SortBy.Initiation),
        dataTooltip: true,
      },
      {
        width: 300,
        label: 'Connected To Agent',
        dataKey: 'connectedToAgentTimestamp',
        sortDirection: filter.sortOrder,
        sortActive: filter.sortBy === SortBy.ConnectedToAgent,
        onColumnSort: handleColumnSort(SortBy.ConnectedToAgent),
        dataTooltip: true,
      },
      {
        width: 300,
        label: 'Agent',
        dataKey: 'agentUsername',
        sortDirection: filter.sortOrder,
        sortActive: filter.sortBy === SortBy.Agent,
        onColumnSort: handleColumnSort(SortBy.Agent),
        dataTooltip: true,
      },
      {
        width: 200,
        label: 'Campaign',
        dataKey: 'campaign',
        sortDirection: filter.sortOrder,
        sortActive: filter.sortBy === SortBy.Campaign,
        onColumnSort: handleColumnSort(SortBy.Campaign),
        dataTooltip: true,
      },
      {
        width: 200,
        label: 'Disposition Code',
        dataKey: 'dispositionCode',
        sortDirection: filter.sortOrder,
        sortActive: filter.sortBy === SortBy.DispositionCode,
        onColumnSort: handleColumnSort(SortBy.DispositionCode),
        dataTooltip: true,
      },
      {
        width: 200,
        label: 'Disposition Sub Code',
        dataKey: 'dispositionSubCode',
        sortDirection: filter.sortOrder,
        sortActive: filter.sortBy === SortBy.DispositionSubCode,
        onColumnSort: handleColumnSort(SortBy.DispositionSubCode),
        dataTooltip: true,
      },
      {
        width: 200,
        label: 'Disposition Title',
        dataKey: 'dispositionTitle',
        sortDirection: filter.sortOrder,
        sortActive: filter.sortBy === SortBy.DispositionTitle,
        onColumnSort: handleColumnSort(SortBy.DispositionTitle),
        dataTooltip: true,
      },
      {
        width: 200,
        label: 'Disconnect Reason',
        dataKey: 'disconnectReason',
        sortDirection: filter.sortOrder,
        sortActive: filter.sortBy === SortBy.DisconnectReason,
        onColumnSort: handleColumnSort(SortBy.DisconnectReason),
        dataTooltip: true,
      },
      {
        width: 120,
        label: 'Duration',
        dataKey: 'connectedToAgentDuration',
        sortDirection: filter.sortOrder,
        sortActive: filter.sortBy === SortBy.CallDuration,
        onColumnSort: handleColumnSort(SortBy.CallDuration),
        dataTooltip: true,
      },
    ];
  }, [filter.sortBy, filter.sortOrder]);

  // Should only revalidate IF the dataset changes
  const rows = useMemo(() => {
    return contactLogs.map((item) => ({
      contactId: item.contactId,
      externalId: item.externalId,
      customerCli: item.customerCli,
      initiationMethod: item.initiationMethod,
      channel: item.channel,
      initiationTimestamp: formatDate(item.initiationTimestamp, DATE_FORMAT),
      connectedToAgentTimestamp: item.connectedToAgentTimestamp
        ? formatDate(item.connectedToAgentTimestamp, DATE_FORMAT)
        : '-',
      agentUsername: item.agentUsername,
      campaign: item.campaign,
      dispositionCode: item.dispositionCode || '-',
      dispositionSubCode: item.dispositionSubCode || '-',
      dispositionTitle: item.dispositionTitle || '-',
      connectedToAgentDuration: getDurationFromSeconds(item.connectedToAgentDuration),
      disconnectReason: item.disconnectReason,
    }));
  }, [contactLogs]);
  const hasRecords = rows.length > 0;

  return (
    <>
      <Typography variant='h4' component='h1' gutterBottom>
        Contact Logs
      </Typography>

      <Grid sx={{ marginTop: 2, marginBottom: 2 }} container spacing={1}>
        <Grid item xs={12} md={3}>
          <FilterButton
            fullWidth
            disableElevation
            variant='contained'
            size='small'
            startIcon={<FilterListIcon />}
            onClick={toggleFilterBar}
            title='Filter'>
            {showFilters ? 'Hide' : 'Show'} Filters
          </FilterButton>
        </Grid>

        {canDownload && (
          <Grid item xs={12} md={3}>
            <Button
              disabled={!canDownload}
              fullWidth
              disableElevation
              variant='contained'
              size='small'
              href={downloadLink}
              startIcon={<CloudDownloadIcon />}
              color='primary'
              title='Download'>
              Download
            </Button>
          </Grid>
        )}
      </Grid>

      <Collapse in={showFilters}>
        <Paper sx={{ padding: 2, marginBottom: 3 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <DatePicker
                disableMaskedInput
                disableFuture
                inputFormat='DD'
                label='Date From'
                value={filter.dateFrom}
                onChange={handleDatepickerChange('dateFrom')}
                renderInput={(params) => <TextField {...params} fullWidth variant='outlined' size='small' />}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <DatePicker
                disableMaskedInput
                minDate={filterDateToMinDate}
                onError={(reason) => {
                  if (reason === 'minDate') {
                    setDateToValidationError('Date To should not be before Date From.');
                    return;
                  }

                  setDateToValidationError(undefined);
                }}
                inputFormat='DD'
                label='Date To'
                value={filter.dateTo}
                onChange={handleDatepickerChange('dateTo')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant='outlined'
                    size='small'
                    error={Boolean(dateToValidationError)}
                    helperText={dateToValidationError}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                size='small'
                onChange={handleAutoCompleteFilterChange('campaign')}
                value={filter.campaign || null}
                options={campaigns}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField {...params} variant='outlined' id='campaign' name='campaign' label='Campaign' />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                size='small'
                onChange={handleAutoCompleteFilterChange('initiationMethod')}
                value={filter.initiationMethod || null}
                options={initiationMethods}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    id='initiationMethod'
                    name='initiationMethod'
                    label='Method'
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                size='small'
                onChange={handleAutoCompleteFilterChange('channel')}
                value={filter.channel || null}
                options={channels}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField {...params} variant='outlined' id='channel' name='channel' label='Channel' />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Autocomplete
                fullWidth
                size='small'
                onChange={handleAutoCompleteDispositionFilterChange}
                value={filter.disposition || null}
                options={dispositionsSelectList}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField {...params} variant='outlined' id='disposition' name='disposition' label='Disposition' />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Autocomplete
                fullWidth
                size='small'
                onChange={handleAutoCompleteFilterChange('dispositionSubCode')}
                value={filter.dispositionSubCode || null}
                options={subDispositionsSelectList}
                disabled={!Boolean(filter.disposition)}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    id='dispositionSubCode'
                    name='dispositionSubCode'
                    label='Sub Disposition'
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                size='small'
                onChange={handleAutoCompleteFilterChange('disconnectReason')}
                value={filter.disconnectReason || null}
                options={disconnectReasons}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    id='disconnectReason'
                    name='disconnectReason'
                    label='Disconnect Reason'
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                size='small'
                onChange={handleAutoCompleteFilterChange('agent')}
                value={filter.agent || null}
                options={agents}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField {...params} variant='outlined' id='agent' name='agent' label='Agent' />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                size='small'
                variant='outlined'
                id='endpoint'
                name='endpoint'
                label='Endpoint'
                value={filter.endpoint || ''}
                onChange={(e) => handleFilterChange('endpoint', e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                size='small'
                variant='outlined'
                id='externalId'
                name='externalId'
                label='External ID'
                value={filter.externalId || ''}
                onChange={(e) => handleFilterChange('externalId', e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                size='small'
                variant='outlined'
                id='contactId'
                name='contactId'
                label='Contact ID'
                value={filter.contactId || ''}
                onChange={(e) => handleFilterChange('contactId', e.target.value)}
              />
            </Grid>

            {attributeNameList && attributeNameList.length > 0 && (
              <>
                <Grid item xs={12} md={6}>
                  <Selectbox
                    id='attributeName'
                    size='small'
                    name='attributeName'
                    title='Attribute'
                    items={attributeNameList}
                    value={filter.attributeName || ''}
                    onChange={(e) => handleFilterChange('attributeName', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    size='small'
                    variant='outlined'
                    id='attributeValue'
                    name='attributeValue'
                    label='Attribute Value'
                    value={filter.attributeValue || ''}
                    onChange={(e) => handleFilterChange('attributeValue', e.target.value)}
                  />
                </Grid>
              </>
            )}

            <Hidden smDown>
              <Grid item md={8}></Grid>
            </Hidden>

            <Grid item xs={12} md={2}>
              <LoadingButton fullWidth variant='text' disabled={loading} loading={searchLoading} onClick={resetFilter}>
                Reset
              </LoadingButton>
            </Grid>

            <Grid item xs={12} md={2}>
              <LoadingButton
                fullWidth
                disableElevation
                variant='contained'
                color='primary'
                disabled={loading}
                loading={searchLoading}
                onClick={onSearch}>
                Search
              </LoadingButton>
            </Grid>
          </Grid>
        </Paper>
      </Collapse>

      <AsyncLoader isLoading={loading} error={errorDisplay}>
        <Typography style={{ whiteSpace: 'pre-line' }} variant='body2' component='p' color='textSecondary' paragraph>
          {rows.length >= 300 &&
            'Showing 300 most recent contacts for filter results.\n For a full view of results download the full list by clicking the button above.'}
          {rows.length < 300 && `Showing ${rows.length} contacts for filter results.`}
        </Typography>

        {!hasRecords && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <EmptyState
                type='no-records-found'
                text='No contact logs found matching your filter criteria'
                subText='Try alternate selections or date ranges.'
              />
            </Grid>
          </Grid>
        )}

        {hasRecords && <BasicTable height={tableHeight} rows={rows} onRowClick={onRowClick} columns={columns} />}
      </AsyncLoader>
    </>
  );
};

export default ContactLogs;

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingButton from '@mui/lab/LoadingButton';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import blue from '@mui/material/colors/blue';
import grey from '@mui/material/colors/grey';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import React, { Fragment, useMemo, useState } from 'react';
import { Control, Controller, FieldErrors, useForm } from 'react-hook-form';

import ContentSpacer from '~components/ContentSpacer';
import { DataItem } from '~components/DataItem';
import organisations from '~organisations';
import {
  AuthorRole,
  Conversation as ConversationDomain,
  MessageType,
  QueueDisposition,
} from '~services/AsyncManager/domain';
import { getOrderedMessageKeysByMessageIdAndCreatedTimestamp } from '~services/AsyncManager/helpers';
import { convertDictValuesToString, flattenObject, generatePathNoErrorThrow } from '~utils/Functions';

import MessageBubble from './MessageBubble';

interface ConversationProps {
  conversation: ConversationDomain;
  orgReference: string;
  attributeConfiguration: { key: string; label: string }[];
  contactPopLinks: { [key: string]: string };
  expanded?: boolean;
  // Tells us if collapsing of according is disabled
  disabled?: boolean;
  // Tells us where to put the new Message line above
  newMessagePos?: string;
  onDispose: (dispositionCode: string, dispositionSubCode: string, attributes: { [key: string]: string }) => void;
}

interface DispositionFormData {
  disposition: QueueDisposition;
  // Note: This is used to support all additional dynamic outcome capture fields defined with the organisation's module
  //       without having to worry about typing issues. Note: This union take over the whole interface
  //       so QueueDisposition also needs to be defined here else the disposition's property will error.
  [key: string]: QueueDisposition | string | null;
}

// Note: This is used by the organisation's module to generically define additional form elements
export interface AdditionalFieldsProps {
  control: Control<FormDefaults>;
  errors: FieldErrors<FormDefaults>;
  selectedDisposition: QueueDisposition | null;
  formSubmitting: boolean;
}

// We do this as we need the value of disposition to be able to be null by default for the material UI auto select component to
// stop having a fit over "" does not exist in list as a selectable option
interface FormDefaults extends Omit<DispositionFormData, 'disposition'> {
  disposition: QueueDisposition | null;
}

const formatDate = (timestamp: string | undefined) => {
  if (timestamp === undefined) return '';

  const dateTime = new Date(timestamp);

  // Anything not a date timestamp will be caught here
  if (dateTime.toString() === 'Invalid Date') return '';

  const now = new Date();
  const time = DateTime.fromISO(timestamp).toFormat('h:mm a');
  const isToday =
    now.getDate() == dateTime.getDate() &&
    now.getMonth() == dateTime.getMonth() &&
    now.getFullYear() == dateTime.getFullYear();

  return isToday ? `Today at ${time}` : `${DateTime.fromISO(timestamp).toFormat('yyyy/MM/dd')} at ${time}`;
};

const ATTRIBUTES_POS_INCREMENT = 3;

// These are all known conversation attributes that are set by the backend on conversation creation.
// If a value is not found within this dict, then it wont be displayed.
//
// Note: This should not include dynamic attributes that can be customised by clients. That should be configured
// within the toml file under additional_conversation_attributes.
const KNOWN_ATTRIBUTE_DISPLAY_NAMES: { [key: string]: string } = {
  queue: 'Queue Identifier',
  queue_title: 'Queue Name',
  email: 'User Entered Email',
};

const Conversation = ({
  conversation,
  orgReference,
  attributeConfiguration,
  contactPopLinks,
  expanded,
  disabled,
  newMessagePos,
  onDispose,
}: ConversationProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(Boolean(expanded));
  // Note: we only set this to true on submit, it will stay forever loading on first submit click
  // this is because we have no way of knowing if the backend request has failed or not. If an agent is suffering from
  // a slow connection, this will prevent them from mas spamming requests leading to other potential issues
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  // Wer want to display the first 3 attributes by default, with more being displayed when this position counter
  // increments.
  const [attributesIndexPos, setAttributesIndexPos] = useState<number>(ATTRIBUTES_POS_INCREMENT - 1);
  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
  } = useForm<FormDefaults>({
    defaultValues: {
      disposition: null,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const dispositionWatch: QueueDisposition | null = watch('disposition');
  const orderedMessageKeys = getOrderedMessageKeysByMessageIdAndCreatedTimestamp(conversation.messages);
  const displayableAttributes = useMemo(() => {
    let allowedKeys = { ...KNOWN_ATTRIBUTE_DISPLAY_NAMES };

    // let's add configured additional attribute keys
    for (let item of attributeConfiguration) {
      allowedKeys = { ...allowedKeys, [item.key]: item.label };
    }

    // Let's add all global attribute keys
    const contactPopLinkKeys = Object.keys(contactPopLinks);
    for (let key of contactPopLinkKeys) {
      allowedKeys = { ...allowedKeys, [key]: key };
    }

    const allAttributes = { ...conversation.attributes, ...contactPopLinks };
    return Object.keys(allAttributes)
      .filter((key) => Boolean(allowedKeys[key]))
      .map((key) => ({
        label: allowedKeys[key],
        value: allAttributes[key],
      }));
  }, [attributeConfiguration, contactPopLinks, conversation.attributes]);
  // We add 1 as index's start at zero
  const hasMoreAttributesToShow = attributesIndexPos + 1 < Object.keys(displayableAttributes).length;
  const AdditionalFields =
    organisations[orgReference]?.asyncAdditionalOutcomeCaptureFields[conversation.queue] || undefined;

  const onChange = () => {
    setIsExpanded((prev) => !prev);
  };

  const submitDisposition = handleSubmit((data: FormDefaults) => {
    const { disposition, ...others } = data;
    setIsSubmitting(true);

    // Should not ever be null as the constraint on this field is required. However, to support the clearing of selection
    // we had to make this field optional in the interface, leading to this check being needed to make typescript happy.
    if (disposition === null) {
      setIsSubmitting(false);
      return;
    }

    onDispose(disposition.code, disposition.subCode, convertDictValuesToString(others));
    setIsSubmitting(false);
  });

  const loadMoreAttributes = () => {
    setAttributesIndexPos((prev) => prev + ATTRIBUTES_POS_INCREMENT);
  };

  const messageDisplay = orderedMessageKeys.map((key) => {
    let messageContent;

    if (conversation.messages[key].type === MessageType.Message) {
      messageContent = (
        <MessageBubble
          role={conversation.messages[key].authorRole === AuthorRole.Agent ? 'agent' : 'customer'}
          date={conversation.messages[key].sentTimestamp}
          name={conversation.messages[key].name}
          content={conversation.messages[key].text}
        />
      );
    }

    if (conversation.messages[key].type === MessageType.Event) {
      messageContent = (
        <Typography
          sx={{ wordBreak: 'break-word' }}
          margin='8px 0'
          color='#8c8c8c'
          fontSize={12}
          fontWeight={400}
          textAlign='center'
          component='p'>
          {conversation.messages[key].text}
        </Typography>
      );
    }

    return (
      <Fragment key={key}>
        {newMessagePos === key && (
          <Divider
            sx={{
              'borderColor': blue[500],
              '&:after, &:before': { borderTop: `thin solid ${blue[500]}` },
              '.MuiDivider-wrapper': { fontSize: 12, color: blue[500] },
            }}>
            New Messages
          </Divider>
        )}
        {messageContent}
      </Fragment>
    );
  });

  const attributeItems = displayableAttributes
    // Filter out items that are greater than the current display position
    .filter((item, index) => index <= attributesIndexPos)
    // Loop and display DataItem in relevant format
    .map((item, index) => {
      let value: any;
      // Is image url
      if (/^https?:\/\/.+\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(item.value)) {
        value = (
          <div
            style={{
              height: 60,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              overflow: 'hidden',
            }}>
            <img style={{ objectFit: 'cover', height: '100%' }} src={item.value} alt={item.label} />
          </div>
        );
      } else if (/^https?:\/\/.+$/.test(item.value)) {
        // Strip out unneeded props and flatten object
        const flattenedConversation = flattenObject(
          Object.fromEntries(Object.entries(conversation).filter(([key]) => !key.includes('messages'))),
        );

        // Updated url params with value if found within the flattened conversation
        const url = generatePathNoErrorThrow(item.value, flattenedConversation);

        value = (
          <Link underline='hover' href={url} target='_blank'>
            {item.label}
          </Link>
        );
      } else {
        value = item.value;
      }

      return (
        <Box key={`attribute-${index}`} sx={{ flex: '1 1 33.33%' }}>
          <DataItem title={item.label} value={value} stacked />
        </Box>
      );
    });

  return (
    <ContentSpacer spacing={2}>
      <Accordion
        sx={{
          border: '1px solid rgba(0, 0, 0, 0.12)',
          overflow: 'hidden',
          boxShadow: 'none',
          borderRadius: '4px 4px 0 0',
        }}
        expanded={isExpanded}
        onChange={disabled ? undefined : onChange}>
        <AccordionSummary
          sx={{
            'backgroundColor': grey[100],
            'borderBottom': isExpanded ? '1px solid rgba(0, 0, 0, 0.12)' : 'none',
            '&.MuiAccordionSummary-root.Mui-expanded': {
              cursor: disabled ? 'default' : 'pointer',
            },
            '.MuiAccordionSummary-content.Mui-expanded': {
              cursor: 'inherit',
            },
          }}
          expandIcon={disabled === true ? undefined : <ExpandMoreIcon />}>
          {conversation.disposedTimestamp === undefined && (
            <b>Conversation starting {formatDate(conversation.createdTimestamp)}</b>
          )}
          {conversation.disposedTimestamp !== undefined && (
            <b>Conversation Ended {formatDate(conversation.disposedTimestamp)}</b>
          )}
        </AccordionSummary>

        <AccordionDetails sx={{ padding: 0 }}>
          {attributeItems.length > 0 && (
            <Box sx={{ padding: 2, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>{attributeItems}</Box>

              {hasMoreAttributesToShow && (
                <Button
                  sx={{
                    'marginTop': 1,
                    'textTransform': 'none',
                    'padding': 0,
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  disableRipple
                  variant='text'
                  color='primary'
                  onClick={loadMoreAttributes}>
                  Show more...
                </Button>
              )}
            </Box>
          )}

          <Box sx={{ padding: 3 }}>{messageDisplay}</Box>
        </AccordionDetails>

        {conversation.disposedTimestamp !== undefined && (
          <AccordionActions
            sx={{
              padding: 1,
              display: 'block',
              borderTop: '1px solid rgba(0, 0, 0, 0.12)',
              backgroundColor: grey[100],
              textAlign: 'right',
            }}>
            <Typography fontSize={12} fontWeight='700'>
              Conversation disposed of as {conversation.dispositionCode} / {conversation.dispositionSubCode}
            </Typography>
          </AccordionActions>
        )}

        {conversation.canDispose === true && (
          <AccordionActions
            sx={{
              padding: 2,
              display: 'block',
              borderTop: '1px solid rgba(0, 0, 0, 0.12)',
              backgroundColor: grey[100],
            }}>
            <form onSubmit={submitDisposition} noValidate>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography fontSize={14} fontWeight='400'>
                    It has been some time since this conversation was active. Would you like to dispose of it?
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name='disposition'
                    control={control}
                    rules={{ required: 'Disposition is required.' }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        onChange={(e, data) => {
                          field.onChange(data);
                        }}
                        fullWidth
                        options={conversation.queueDispositions}
                        filterSelectedOptions
                        disabled={isSubmitting}
                        isOptionEqualToValue={(option, value) =>
                          option.code === value.code && option.subCode === value.subCode
                        }
                        getOptionLabel={(option) => option?.title || ''}
                        renderOption={(props, option, state) => (
                          <li {...props}>
                            <div>
                              <Typography variant='body2' color='textPrimary' component='p'>
                                {option.title}
                              </Typography>

                              <Typography variant='caption' color='textSecondary' component='p'>
                                {option.description}
                              </Typography>
                            </div>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Dispositions'
                            required={true}
                            error={Boolean(errors.disposition)}
                            helperText={errors.disposition?.message}
                            variant='outlined'
                          />
                        )}
                      />
                    )}
                  />
                </Grid>

                {AdditionalFields && (
                  <AdditionalFields
                    control={control}
                    formSubmitting={isSubmitting}
                    selectedDisposition={dispositionWatch}
                    errors={errors}
                  />
                )}

                <Grid item xs={12}>
                  <LoadingButton fullWidth type='submit' variant='contained' disableRipple loading={isSubmitting}>
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </AccordionActions>
        )}
      </Accordion>
    </ContentSpacer>
  );
};

export default Conversation;

import axios from 'axios';

import { CampaignAssignRecord } from '~pages/CampaignManagement/domain';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import {
  Agent,
  AgentUpdateRequest,
  CreateExclusionList,
  DispositionAttribute,
  ExclusionEntry,
  ExclusionList,
  ExclusionListListedEndpoint,
  ExclusionListUploadResponse,
  GetAgentResponseDecoder,
  GetCampaignAssignListResponseDecoder,
  GetDispositionAttributesResponseDecoder,
  GetExclusionListListedEndpointResponseDecoder,
  GetExclusionListUploadResponseDecoder,
  GetExclusionListsResponseDecoder,
  GetPredictiveAgentStatusesDecoder,
  GetRecordingInfoListResponseDecoder,
  GetSkillTypesResponseDecoder,
  PredictiveAgentStatus,
  RecordingInfo,
  SkillType,
} from './domain';

/**
 * getCampaignAssignList returns a list of campaigns with routing profiles.
 * This is used for assigning a user to a new campaign / routing profile.
 */
export const getCampaignAssignList = async (): Promise<CampaignAssignRecord[]> => {
  const path = '/api/campaign/assign-list';
  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetCampaignAssignListResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);
    console.error(decoded.error);
    console.error(err);
    throw err;
  }
  return decoded.result;
};

export const getAgentByUsername = async (username: string): Promise<Agent | undefined> => {
  const path = `/api/agents/${username}`;
  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      if (e.response!.status === 404) {
        return undefined;
      } else {
        // Response should always be defined if axios error
        throw new APIError(e.response!.status, e.message);
      }
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetAgentResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const createAgent = async (data: AgentUpdateRequest): Promise<void> => {
  const path = '/api/agents/';

  const body = {
    first_name: data.firstName,
    last_name: data.lastName,
    username: data.username,
    campaign_id: data.campaignId,
    routing_profile_id: data.routingProfileId,
    skills: data.skills,
    async_queues: data.asyncQueues,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const updateAgentByUsername = async (data: AgentUpdateRequest): Promise<void> => {
  const path = `/api/agents/${data.username}`;

  const body = {
    first_name: data.firstName,
    last_name: data.lastName,
    campaign_id: data.campaignId,
    routing_profile_id: data.routingProfileId,
    skills: data.skills,
    async_queues: data.asyncQueues,
  };

  try {
    await axios.request({
      method: 'PUT',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const deleteAgentByUsername = async (username: string): Promise<void> => {
  const path = `/api/agents/${username}`;

  try {
    await axios.request({
      method: 'DELETE',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const getSkillTypes = async (): Promise<SkillType[]> => {
  const path = '/api/skills/';
  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetSkillTypesResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const createSkillType = async (data: SkillType): Promise<void> => {
  const path = '/api/skills/';

  const body = {
    skill_type: data.skillType,
    description: data.description,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const getExclusionLists = async (): Promise<ExclusionList[]> => {
  const path = '/api/exclusion-list/';
  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetExclusionListsResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const createExclusionList = async (data: CreateExclusionList): Promise<void> => {
  const path = '/api/exclusion-list/';

  const body = {
    name: data.name,
    description: data.description,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const addEndpointToExclusionList = async (listName: string, data: ExclusionEntry): Promise<void> => {
  const path = `/api/exclusion-list/${listName}`;

  const body = {
    number: data.number,
    reason: data.reason,
  };

  try {
    await axios.request({
      method: 'PUT',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const bulkAddEndpointsToExclusionList = async (
  listName: string,
  file: File,
  onUploadProgress: (progress: number) => void,
): Promise<ExclusionListUploadResponse> => {
  const path = `/api/exclusion-list/${listName}/upload`;
  let resp;

  const formData = new FormData();
  formData.append('file', file);

  try {
    resp = await axios.request({
      method: 'POST',
      url: path,
      data: formData,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onUploadProgress(percentCompleted);
      },
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetExclusionListUploadResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const checkEndpointExistsInExclusionList = async (
  listName: string,
  endpoint: string,
): Promise<ExclusionListListedEndpoint> => {
  const path = `/api/exclusion-list/${listName}/phone/${endpoint}`;
  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetExclusionListListedEndpointResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const removeEndpointFromExclusionList = async (listName: string, endpoint: string): Promise<void> => {
  const path = `/api/exclusion-list/${listName}/phone/${endpoint}`;

  try {
    await axios.request({
      method: 'DELETE',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const getAgentRecordingInfo = async (agentUsername: string): Promise<RecordingInfo[]> => {
  const path = `/api/screen-recordings/agent/${agentUsername}`;
  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetRecordingInfoListResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getDispositionAttributes = async (): Promise<DispositionAttribute[]> => {
  const path = '/api/disposition-attributes/';
  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetDispositionAttributesResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const upsertDispositionAttribute = async (isUpdate: boolean, data: DispositionAttribute): Promise<void> => {
  const path = '/api/disposition-attributes/';
  const method = isUpdate ? 'PUT' : 'POST';

  const body = {
    attribute: data.attribute,
    values: data.values,
  };

  try {
    await axios.request({
      method: method,
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const deleteDispositionAttribute = async (attribute: string): Promise<void> => {
  const path = `/api/disposition-attributes/${attribute}`;

  try {
    await axios.request({
      method: 'DELETE',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const getPredictiveAgentStatuses = async (): Promise<PredictiveAgentStatus[]> => {
  const path = `/api/predictive-agent-statuses`;
  let resp;
  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetPredictiveAgentStatusesDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const putPredictiveAgentStatuses = async (statuses: PredictiveAgentStatus[]): Promise<void> => {
  const path = `/api/predictive-agent-statuses`;
  const body = {
    statuses: statuses.map((status) => ({
      agent_status: status.agentStatus,
      position: status.position,
      description: status.description,
    })),
  };
  try {
    await axios.request({
      method: 'PUT',
      url: path,
      headers: {
        Accept: 'application/json',
      },
      data: body,
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const postPredictiveAgentStatus = async (status: PredictiveAgentStatus): Promise<void> => {
  const path = `/api/predictive-agent-statuses`;
  const data = {
    agent_status: status.agentStatus,
    description: status.description,
    position: status.position,
  };

  try {
    await axios.request({
      data,
      method: 'POST',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const deletePredictiveAgentStatus = async (status: string): Promise<void> => {
  const path = `/api/predictive-agent-statuses/${status}`;
  try {
    await axios.request({
      method: 'DELETE',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

import { CancelTokenSource } from 'axios';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { Contact } from '~providers/ConnectProvider/domain';

import {
  createLeadAttemptDetails,
  getAttemptDetails,
  getInternalTransferAttemptDetails,
  getReturnCallAttemptDetails,
} from './api';
import { Attempt, AttemptCreationContext, AttemptDetails } from './domain';

interface AttemptContext {
  attempt: Attempt | undefined;
  startAutoDialTimer: () => void;
  clearAutoDialTimer: () => void;
  startAutoHangUpTimer: () => void;
  clearAutoHangUpTimer: () => void;
  setAttemptDialled: () => void;
  setAttemptInitiated: () => void;
  setAttemptConnected: () => void;
  setAttemptDisconnected: () => void;
  createLeadAttempt: (
    campaignId: number,
    endpoint: string,
    leadName: string,
    externalId: string,
    timezone: string,
  ) => Promise<void>;
  presentUndisposedAttempt: (campaignId: number) => Promise<void>;
  fetchNextAttempt: (campaignId: number, cancelToken?: CancelTokenSource) => Promise<void>;
  fetchInboundAttempt: (contact: Contact) => Promise<void>;
  checkIfValidAttempt: (campaignId: number, attemptId: number) => Promise<boolean>;
  clearAttempt: () => void;
}

interface AttemptProviderProps {
  loggedInUserUsername: string;
  children: ReactNode;
}

const AttemptContext = createContext<AttemptContext | undefined>(undefined);

export const useAttempt = (): AttemptContext => {
  return useContext(AttemptContext) as AttemptContext;
};

export const AttemptProvider = ({ loggedInUserUsername, children }: AttemptProviderProps) => {
  const [attempt, setAttempt] = useState<Attempt | undefined>(undefined);

  // Decrements the auto dial timer from Nth number to 0
  useEffect(() => {
    if (
      attempt !== undefined &&
      attempt.autoDialEnabled === true &&
      attempt.autoDialTimer !== undefined &&
      attempt.autoDialRunning === true
    ) {
      if (attempt.autoDialTimer > 0) {
        const autoDialTimerRef = window.setTimeout(() => {
          setAttempt((prev) => {
            // Handles the case where attempt is set to undefined from a previous rerender action before this
            // setTimeout action has been cleared
            if (prev === undefined) {
              return undefined;
            }
            console.log('+++ hello');
            // Handles the case were autoDialTimer is cleared from a previous rerender but this
            // setTimeout event started before it could be cleared
            return {
              ...prev,
              autoDialTimer: prev.autoDialTimer === undefined ? prev.autoDialTimer : prev.autoDialTimer - 1,
            };
          });
        }, 1_000);

        return function useAutoDialTimerCountdownCleanup() {
          clearTimeout(autoDialTimerRef);
        };
      } else {
        setAttempt((prev) => {
          // Handles the case where attempt is undefined from a previous action before this
          // setTimeout action has been cleared
          if (prev === undefined) {
            return undefined;
          }

          return { ...prev, autoDialRunning: false };
        });
      }
    }
  }, [attempt]);

  // Decrements the auto hang up timer from Nth number to 0
  useEffect(() => {
    if (
      attempt !== undefined &&
      attempt.autoHangUpEnabled === true &&
      attempt.autoHangUpTimer !== undefined &&
      attempt.autoHangUpRunning === true
    ) {
      if (attempt.autoHangUpTimer > 0) {
        const autoHangUpTimerRef = window.setTimeout(() => {
          setAttempt((prev) => {
            // Handles the case where attempt is set to undefined from a previous rerender action before this
            // setTimeout action has been cleared
            if (prev === undefined) {
              return undefined;
            }

            // Handles the case were autoHangUpTimer is cleared from a previous rerender but this
            // setTimeout event started before it could be cleared
            return {
              ...prev,
              autoHangUpTimer: prev.autoHangUpTimer === undefined ? prev.autoHangUpTimer : prev.autoHangUpTimer - 1,
            };
          });
        }, 1_000);

        return function useAutoHangUpTimerCountdownCleanup() {
          clearTimeout(autoHangUpTimerRef);
        };
      } else {
        setAttempt((prev) => {
          // Handles the case where attempt is undefined from a previous action before this
          // setTimeout action has been cleared
          if (prev === undefined) {
            return undefined;
          }

          return { ...prev, autoHangUpRunning: false };
        });
      }
    }
  }, [attempt]);

  const resetDefaults = (): void => {
    setAttempt(undefined);
  };

  const startAutoDialTimer = (): void => {
    // We only want to set this if timer is not zero or undefined. pushPreviewSeconds equalling zero
    // means no auto dialling.
    if (attempt !== undefined && attempt.autoDialTimer !== undefined && attempt.autoDialTimer > 0) {
      setAttempt((prev) => {
        // Handles the case where attempt is undefined from a previous action before this
        // setTimeout action has been cleared
        if (prev === undefined) {
          return undefined;
        }

        return { ...prev, autoDialRunning: true };
      });
    } else {
      console.error("! Unable to start auto dial timer as settings state it shouldn't run.");
    }
  };

  const clearAutoDialTimer = (): void => {
    if (attempt !== undefined && attempt.autoDialRunning === true) {
      setAttempt((prev) => {
        // Handles the case where attempt is undefined from a previous action before this
        // setTimeout action has been cleared
        if (prev === undefined) {
          return undefined;
        }

        return { ...prev, autoDialRunning: false, autoDialTimer: undefined };
      });
    } else {
      console.error('! Unable to clear auto dial timer as it is not currently started.');
    }
  };

  const startAutoHangUpTimer = (): void => {
    // We only want to set this if timer is not zero or undefined. pushPreviewSeconds equalling zero
    // means no auto dialling.
    if (attempt !== undefined && attempt.autoHangUpTimer !== undefined && attempt.autoHangUpTimer > 0) {
      setAttempt((prev) => {
        // Handles the case where attempt is undefined from a previous action before this
        // setTimeout action has been cleared
        if (prev === undefined) {
          return undefined;
        }

        return { ...prev, autoHangUpRunning: true };
      });
    } else {
      console.error("! Unable to start auto hang up timer as settings state it shouldn't run.");
    }
  };

  const clearAutoHangUpTimer = (): void => {
    if (attempt !== undefined && attempt.autoHangUpRunning === true) {
      setAttempt((prev) => {
        // Handles the case where attempt is undefined from a previous action before this
        // setTimeout action has been cleared
        if (prev === undefined) {
          return undefined;
        }

        return { ...prev, autoHangUpRunning: false, autoHangUpTimer: undefined };
      });
    } else {
      console.error('! Unable to clear auto dial timer as it is not currently started.');
    }
  };

  const createLeadAttempt = async (
    campaignId: number,
    endpoint: string,
    leadName: string,
    externalId: string,
    timezone: string,
  ): Promise<void> => {
    let atmp: AttemptDetails;

    try {
      atmp = await createLeadAttemptDetails(campaignId, endpoint, leadName, externalId, timezone);
    } catch (e) {
      console.error('! createLeadAttempt: unable to create attempt due to error ', e);
      return Promise.reject();
    }

    // TODO: even thought type checking isn't freaking out we should remove the preview settings property from
    // atmp when using it here
    const atmpBase: Attempt = {
      ...atmp,
      dialled: false,
      initiated: false,
      connected: false,
      disconnected: false,
      isInboundReturnCall: false,
      isUnknownInbound: false,
      isInternalTransfer: false,
      autoDialEnabled: false,
      autoHangUpEnabled: false,
      autoDialTimer: undefined,
      autoDialRunning: false,
      autoHangUpTimer: undefined,
      autoHangUpRunning: false,
    };

    // Note: We do not set auto dialling or hangup here since it's a manual outbound call the agent has full
    // control over.

    setAttempt(atmpBase);
  };

  const checkIfValidAttempt = async (campaignId: number, attemptId: number): Promise<boolean> => {
    let atmp: AttemptDetails;

    try {
      atmp = await getAttemptDetails(campaignId, 'next');
    } catch (e) {
      // If this API call fails something bigger is wrong, so we should mark as invalid to block the
      // UI from performing actions associated with this check.
      console.error('! checkIfValidAttempt: unable to check if attempt is valid or not', e);
      return false;
    }

    return atmp.attemptId === attemptId;
  };

  /* Used to check for undisposed of attempts on initial page render */
  const presentUndisposedAttempt = async (campaignId: number, cancelToken?: CancelTokenSource): Promise<void> => {
    let atmp: AttemptDetails;

    try {
      atmp = await getAttemptDetails(campaignId, 'next', cancelToken);
    } catch (e) {
      console.error('! fetchNextAttempt: unable to fetch attempt due to error ', e);
      return;
    }

    // For this function call we should never return an attempt object unless a lead is defined
    if (atmp.lead === null) {
      console.log('+ Attempt fetch succeeded but no lead associated. Assume no more leads in campaign.');
      setAttempt(undefined);
      return;
    }

    const dialled =
      atmp.initiatedTimestamp !== null || atmp.connectedTimestamp !== null || atmp.disconnectedTimestamp !== null;
    const initiated = dialled;
    const connected = atmp.connectedTimestamp !== null || atmp.disconnectedTimestamp !== null;
    const disconnect = atmp.disconnectedTimestamp !== null;

    // TODO: even thought type checking isn't freaking out we should remove the preview settings property from
    // atmp when using it here
    const atmpBase: Attempt = {
      ...atmp,
      dialled: dialled,
      initiated: initiated,
      connected: connected,
      disconnected: disconnect,
      isInboundReturnCall: false,
      isUnknownInbound: false,
      isInternalTransfer: false,
      autoDialEnabled: false,
      autoHangUpEnabled: false,
      autoDialTimer: undefined,
      autoDialRunning: false,
      autoHangUpTimer: undefined,
      autoHangUpRunning: false,
    };

    // If an attempt has had an interaction, but is undisposed, lets set it here
    if (dialled || initiated || connected || disconnect) {
      setAttempt(atmpBase);
    }
  };

  const fetchNextAttempt = async (campaignId: number, cancelToken?: CancelTokenSource): Promise<void> => {
    let atmp: AttemptDetails;

    try {
      atmp = await getAttemptDetails(campaignId, 'next', cancelToken);
    } catch (e) {
      console.error('! fetchNextAttempt: unable to fetch attempt due to error ', e);
      return;
    }

    // For this function call we should never return an attempt object unless a lead is defined
    if (atmp.lead === null) {
      console.log('+ Attempt fetch succeeded but no lead associated. Assume no more leads in campaign.');
      setAttempt(undefined);
      return;
    }

    const dialled =
      atmp.initiatedTimestamp !== null || atmp.connectedTimestamp !== null || atmp.disconnectedTimestamp !== null;
    const initiated = dialled;
    const connected = atmp.connectedTimestamp !== null || atmp.disconnectedTimestamp !== null;
    const disconnect = atmp.disconnectedTimestamp !== null;

    // TODO: even thought type checking isn't freaking out we should remove the preview settings property from
    // atmp when using it here
    const atmpBase: Attempt = {
      ...atmp,
      dialled: dialled,
      initiated: initiated,
      connected: connected,
      disconnected: disconnect,
      isInboundReturnCall: false,
      isUnknownInbound: false,
      isInternalTransfer: false,
      autoDialEnabled: false,
      autoHangUpEnabled: false,
      autoDialTimer: undefined,
      autoDialRunning: false,
      autoHangUpTimer: undefined,
      autoHangUpRunning: false,
    };

    // Note: We check to make sure that the attempt presented from the next endpoint is not a manual prepared attempt,
    // reason being that an agent might have refreshed the page since initially creating the lead attempt. As a result
    // we still want to preserve the no auto dialling or hangup will occur as the agent has full control over this type
    // of attempt interaction.
    if (atmp.creationContext !== AttemptCreationContext.ManualPrepared) {
      if (atmp.previewSettings !== undefined && atmp.previewSettings.pushPreviewSeconds > 0) {
        atmpBase.autoDialEnabled = true;
        atmpBase.autoDialTimer = atmp.previewSettings.pushPreviewSeconds;
      }

      if (atmp.previewSettings !== undefined && atmp.previewSettings.ringOutSeconds > 0) {
        atmpBase.autoHangUpEnabled = true;
        atmpBase.autoHangUpTimer = atmp.previewSettings.ringOutSeconds;
      }
    }

    // If it's the same attempt just return prev version else update with new version
    // We do this, so we don't end up resetting auto dial/ hang up timers.
    // this also reduces unnecessary re-renders caused by polling of this function
    setAttempt((prev) => (prev?.attemptId === atmpBase.attemptId ? prev : atmpBase));
  };

  const fetchReturnCallAttemptByEndpoint = async (
    campaignId: number,
    endpoint: string,
    contactId: string,
  ): Promise<void> => {
    let atmp: AttemptDetails;

    try {
      atmp = await getReturnCallAttemptDetails(campaignId, endpoint, contactId);
    } catch (e) {
      console.error('! fetchReturnCallAttemptByEndpoint: unable to fetch attempt due to error ', e);
      return;
    }

    // TODO: even thought type checking isnt freaking out we should remove the preview settings property from
    // atmp when using it here
    const atmpBase: Attempt = {
      ...atmp,
      // Should always be false for return calls as we have technically never dialled it
      dialled: false,
      initiated: false,
      connected: false,
      disconnected: false,
      // Not zero dictates that there is an associated attempt
      isInboundReturnCall: atmp.attemptId !== 0,
      // zero dictates that there is no associated attempt and that this is an unknown inbound call
      isUnknownInbound: atmp.attemptId === 0,
      isInternalTransfer: false,
      autoDialEnabled: false,
      autoHangUpEnabled: false,
      autoDialTimer: undefined,
      autoDialRunning: false,
      autoHangUpTimer: undefined,
      autoHangUpRunning: false,
    };

    setAttempt(atmpBase);
  };

  const fetchInternalTransferAttemptByEndpoint = async (campaignId: number, endpoint: string): Promise<void> => {
    let atmp: AttemptDetails;

    try {
      atmp = await getInternalTransferAttemptDetails(campaignId, endpoint);
    } catch (e) {
      console.error('! fetchInternalTransferAttemptByEndpoint: unable to fetch attempt due to error ', e);
      return;
    }

    // We let the UI functionality manage the flags initiated | connected | disconnected and use it as a source of truth
    // as an internal transfer call can already be connected for one agent but not the other, so the lead status can be
    // misleading from this sense
    // TODO: even thought type checking isn't freaking out we should remove the preview settings property from
    // atmp when using it here
    const atmpBase: Attempt = {
      ...atmp,
      // Should always be false for return calls as we have technically never dialled it
      dialled: false,
      initiated: false,
      connected: false,
      disconnected: false,
      // Not zero dictates that there is an associated attempt
      isInboundReturnCall: atmp.attemptId !== 0,
      // zero dictates that there is no associated attempt and that this is an unknown inbound call
      isUnknownInbound: atmp.attemptId === 0,
      isInternalTransfer: true,
      autoDialEnabled: false,
      autoHangUpEnabled: false,
      autoDialTimer: undefined,
      autoDialRunning: false,
      autoHangUpTimer: undefined,
      autoHangUpRunning: false,
    };

    setAttempt(atmpBase);
  };

  const fetchInboundAttempt = async (contact: Contact): Promise<void> => {
    if (contact.campaignId === undefined) {
      console.error('! Campaign ID is not set on inbound contact. Was this missed in the contact flow setup?');
      return;
    }

    const isTransferTargetAgent =
      contact.transferTargetAgent !== undefined && contact.transferTargetAgent === loggedInUserUsername;
    if (contact.attemptId !== undefined && isTransferTargetAgent === true) {
      fetchInternalTransferAttemptByEndpoint(contact.campaignId, contact.phoneNumber);
    } else {
      fetchReturnCallAttemptByEndpoint(contact.campaignId, contact.phoneNumber, contact.contactId);
    }
  };

  const clearAttempt = (): void => {
    resetDefaults();
  };

  const setAttemptDialled = (): void => {
    setAttempt((prev) => {
      if (prev === undefined) {
        console.error('! Unable to update attempt because its undefined.');
      } else {
        return { ...prev, dialled: true };
      }
    });
  };

  const setAttemptInitiated = (): void => {
    setAttempt((prev) => {
      if (prev === undefined) {
        console.error('! Unable to update attempt because its undefined.');
      } else {
        return { ...prev, initiated: true };
      }
    });
  };

  const setAttemptConnected = (): void => {
    setAttempt((prev) => {
      if (prev === undefined) {
        console.error('! Unable to update attempt because its undefined.');
      } else {
        return { ...prev, connected: true };
      }
    });
  };

  const setAttemptDisconnected = (): void => {
    setAttempt((prev) => {
      if (prev === undefined) {
        console.error('! Unable to update attempt because its undefined.');
      } else {
        return { ...prev, disconnected: true };
      }
    });
  };

  const context = {
    attempt,
    startAutoDialTimer,
    clearAutoDialTimer,
    startAutoHangUpTimer,
    clearAutoHangUpTimer,
    setAttemptDialled,
    setAttemptInitiated,
    setAttemptConnected,
    setAttemptDisconnected,
    createLeadAttempt,
    presentUndisposedAttempt,
    fetchNextAttempt,
    fetchInboundAttempt,
    checkIfValidAttempt,
    clearAttempt,
  };
  return <AttemptContext.Provider value={context}>{children}</AttemptContext.Provider>;
};

import React from 'react';

import Audio from '~components/Audio';
import SectionCard from '~components/SectionCard';

interface ContactRecordingProps {
  recordingLocation: string;
  canDownload: boolean;
  canView: boolean;
}

const ContactRecording = ({ recordingLocation, canDownload, canView }: ContactRecordingProps) => {
  // We want to skip fast, do none of the below logic, and not display anything
  if (!canView) return null;

  return (
    <SectionCard title='Call Recording'>
      <Audio id='recording' src={recordingLocation} canDownload={canDownload} />
    </SectionCard>
  );
};

export default ContactRecording;

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';

import AgentCard from '~components/AgentCard';
import EmptyState from '~components/EmptyState';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { assignAgentsToCampaignById, removeAssignedAgentFromCampaign } from '../../api';
import { AssignCampaignAgent, Campaign } from '../../domain';
import AssignEditAgentModal from './AssignAgentModal';

interface Query {
  search: string;
}

interface CampaignAgentsProps {
  campaign: Campaign;
  triggerCampaignRefresh: () => Promise<void>;
}

const CampaignAgents = ({ campaign, triggerCampaignRefresh }: CampaignAgentsProps) => {
  const { pushNotification } = useNotification();
  const { campaignId } = useParams() as { campaignId: string };
  const [assignModalOpen, setAssignModalOpen] = useState<boolean>(false);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const noAgents = !campaign?.agents || campaign?.agents.length === 0;
  const list = campaign?.agents;
  const [query, setQuery] = useState<Query>({
    search: '',
  });

  const toggleAssignAgentModal = () => {
    setAssignModalOpen((prev) => !prev);
  };

  const onQueryChange = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value }));
  };

  const assignAgents = async (data: AssignCampaignAgent) => {
    setSubmittingData(true);

    try {
      await assignAgentsToCampaignById(+campaignId, data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', 'Assigned Agents to campaign');

    // TODO: do we need to even await, parent component handles state display errors if this refresh fails
    await triggerCampaignRefresh();
    setAssignModalOpen(false);
  };

  const removeAssignedAgent = (username: string) => async () => {
    try {
      await removeAssignedAgentFromCampaign(+campaignId, username);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return;
    }

    pushNotification('success', `removed agent ${username} from campaign`);

    // TODO: do we need to even await, parent component handles state display errors if this refresh fails
    await triggerCampaignRefresh();
  };

  const displayList = !noAgents
    ? list
        // Search Fuzzy matching
        .filter((item) => {
          if (query.search) {
            return item.fullName.toLowerCase().includes(query.search.toLowerCase());
          }

          return true;
        })
        // Component generation
        .map((agent, index) => (
          <AgentCard
            key={index}
            fullname={agent.fullName}
            username={agent.username}
            menuItems={[{ name: 'Remove', action: removeAssignedAgent(agent.username) }]}
          />
        ))
    : [];

  // If any filter property is set and displayList is empty we assume no relevant search results
  const noSearchOrFilterResults = query.search && displayList.length === 0;

  return (
    <>
      {noAgents && (
        <EmptyState
          type='no-items-1'
          text='No agents are currently assigned to this campaign'
          subText='Click the button below to start adding agents'
          action={toggleAssignAgentModal}
          actionText='Assign Agents'
        />
      )}

      {!noAgents && (
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='Search'
              id='search'
              name='search'
              defaultValue={query.search}
              onChange={onQueryChange}
            />
          </Grid>

          <Hidden smDown>
            <Grid item md={5}></Grid>
          </Hidden>

          <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              fullWidth
              startIcon={<AddIcon />}
              onClick={toggleAssignAgentModal}>
              Assign Agents
            </Button>
          </Grid>
          <Grid item xs={12}>
            {!noSearchOrFilterResults && <List>{displayList}</List>}
            {noSearchOrFilterResults && (
              <EmptyState
                type='no-records-found'
                text='No agents found matching your search criteria'
                subText='Try alternate words or selections.'
              />
            )}
          </Grid>
        </Grid>
      )}

      <AssignEditAgentModal
        open={assignModalOpen}
        submitting={submittingData}
        campaignRoutingProfileIDList={campaign?.routingProfiles}
        onClose={toggleAssignAgentModal}
        onAccept={assignAgents}
      />
    </>
  );
};

export default CampaignAgents;

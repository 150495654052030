import React, { useMemo } from 'react';
import { useState } from 'react';

import { ConnectContactType, Contact, ContactStateType, ContactType } from '~/providers/ConnectProvider/domain';

interface ContactProviderProps {
  currentVoiceContact: Contact | undefined;
  currentContact: Contact | undefined;
  /**
   * hasActiveContact is true if the agent is currently connected to a client
   * or in the ended call.
   *
   * It's used to disable the agent status bar so the agent cannot change their
   * state if they're working with a customer or doing disposition/end of call work.
   */
  hasActiveContact: boolean;
  contacts: Contact[];
  contactMap: { [id: string]: Contact | undefined };
  setCurrentContactById: (id: string) => void;
  insertOrUpdateContact: (contact: Contact) => void;
  updateContactsByFilter: (filterCallback: (contact: Contact) => boolean, data: Partial<Contact>) => void;
  updateContactById: (
    id: string,
    partialOrCallback: ((previousContact: Contact) => Contact) | Partial<Contact>,
  ) => void;
  getContactById: (id: string) => Contact | undefined;
  removeContactById: (id: string) => void;
}

const ContactContext = React.createContext<ContactProviderProps | undefined>(undefined);

export const useContact = (): ContactProviderProps => {
  const r = React.useContext(ContactContext);

  if (r === undefined) {
    throw new Error('Missing "ContactProvider" context');
  }

  return r;
};

export const ContactProvider = ({ children }: { children: React.ReactNode }) => {
  // _setCurrentUid should only be called by "setCurrentContactById"
  const [currentUid, _setCurrentUid] = useState<string>('');
  const [contactMap, setContactMap] = useState<{ [id: string]: Contact }>({});
  const currentContact = contactMap[currentUid];

  if (currentContact && currentContact.id !== currentUid) {
    throw new Error('Invalid state. Contact object id does not match lookup id in map, data is inconsistent.');
  }

  let currentVoiceContact = undefined;

  for (const id in contactMap) {
    if (!contactMap.hasOwnProperty(id)) {
      continue;
    }

    if (contactMap[id].contactType === ConnectContactType.Voice) {
      currentVoiceContact = contactMap[id];
    }
  }

  const contacts = useMemo((): Contact[] => {
    const newContactList = [];

    for (const id in contactMap) {
      if (!contactMap.hasOwnProperty(id)) {
        continue;
      }

      const contact = contactMap[id];
      newContactList.push(contact);
    }

    return newContactList;
  }, [contactMap]);

  const hasActiveContact = useMemo((): boolean => {
    let result = false;

    for (let contact of contacts) {
      result =
        result ||
        (contact.type === ContactType.Connect &&
          (contact.statusType === ContactStateType.Connected || contact.statusType === ContactStateType.Ended));
    }

    return !result;
  }, [contacts]);

  const throwInvalidId = (id: string): void => {
    if (!id) {
      throw new Error('Cannot use blank or empty id.');
    }

    if (id.indexOf('__') === -1) {
      throw new Error('Invalid id given. Expected format like "type__{id_here}", but got: ' + id);
    }
  };

  const setCurrentContactById = (id: string): void => {
    throwInvalidId(id);

    if (currentContact && currentContact.id === id) {
      return;
    }

    // Update lastViewedMessageTime
    const lastViewedMessageTime = Date.now();

    updateContactById(id, {
      lastViewedMessageTime: lastViewedMessageTime,
    });

    // Update to current id
    _setCurrentUid(id);
  };

  /**
   * insertOrUpdateContact will put a new contact entry in the contacts map or update an existing entry with
   * a full copy IContact.
   */
  const insertOrUpdateContact = (contact: Contact): void => {
    const id = contact.id;

    throwInvalidId(id);
    setContactMap((prevContactMap) => {
      const prevContact = prevContactMap[id];
      if (prevContact === undefined || prevContact === null) {
        // If new contact entry
        return {
          ...prevContactMap,
          [id]: initNewContact({ ...contact }),
        };
      }

      // If updating existing entry
      const computedContact = computeContact(prevContact, contact);
      if (prevContact === computedContact) {
        return prevContactMap;
      }

      return {
        ...prevContactMap,
        [id]: computedContact,
      };
    });
  };

  const initNewContact = (contact: Contact): Contact => {
    const id = contact.id;
    let lastViewedTimeAsString: string | null = null;

    try {
      lastViewedTimeAsString = window.sessionStorage.getItem('contact_last_viewed_time[' + id + ']');
    } catch (e) {
      // If failed loading this data from session storage, ignore it.
      //
      // We keep "lastViewedMessageTime" in current state anyway, it just means if an agent
      // has multiple windows/tabs, they might have phantom unread messages if sessionStorage
      // is not working for whatever reason. (Old browser, lowly configured cache by IT, etc)
    }

    if (lastViewedTimeAsString !== null) {
      contact.lastViewedMessageTime = Number(lastViewedTimeAsString);
    }

    return contact;
  };

  const computeContact = (prevContact: Contact, newContact: Partial<Contact>): Contact => {
    // If updating existing entry
    const computedContact: Contact = {
      ...prevContact,
    };

    // NOTE(Jae): 2020-11-18
    // We may want to do a shallow compare against prevContact and newContact
    // and if they're equal, just give back "prevContact" so we can utilize
    // this variable to avoid re-renders
    for (let prop in newContact) {
      if (!newContact.hasOwnProperty(prop)) {
        continue;
      }
      const value = (newContact as any)[prop];
      (computedContact as any)[prop] = value;
    }

    return computedContact;
  };

  const updateContactsByFilter = (filterCallback: (contact: Contact) => boolean, partialContact: Partial<Contact>) => {
    setContactMap((prevContactMap) => {
      const newContactMap = { ...prevContactMap };
      let hasChangedValue = false;

      for (let id in prevContactMap) {
        if (!prevContactMap.hasOwnProperty(id)) {
          continue;
        }

        const contact = prevContactMap[id];
        if (contact === undefined || contact === null || !filterCallback(contact)) {
          continue;
        }

        const computedContact = computeContact(contact, partialContact);
        if (contact === computedContact) {
          // if no values changes, the object reference will be the same
          continue;
        }

        newContactMap[id] = computedContact;
        hasChangedValue = true;
      }

      if (!hasChangedValue) {
        // If no values changed then do not trigger re-render.
        return prevContactMap;
      }

      return newContactMap;
    });
  };

  const updateContactById = (
    id: string,
    partialOrCallback: ((previousContact: Contact) => Contact) | Partial<Contact>,
  ): void => {
    throwInvalidId(id);
    setContactMap((prevContactMap) => {
      const contact = prevContactMap[id];
      if (contact === undefined || contact === null) {
        console.error(
          'ContactProvider:',
          'Called "updateContactById" on contact object that does not exist. This is a no-op that has no affect.',
        );
        return prevContactMap;
      }

      let newContact: Contact;
      if (typeof partialOrCallback === 'function') {
        newContact = partialOrCallback(contact);
      } else {
        newContact = computeContact(contact, partialOrCallback);
      }

      if (contact === newContact) {
        // If new object wasn't created, do not create a new contact map
        // to avoid re-render.
        return prevContactMap;
      }

      // NOTE(Jae): 2020-01-14
      // Not a very clean way to update session storage but I think it's good
      // enough for just this. If we need to serialize more data out to local or session
      // storage, we may need to make this more robust.
      if (contact && newContact && contact.lastViewedMessageTime !== newContact.lastViewedMessageTime) {
        // Store in session storage if the value changed
        try {
          window.sessionStorage.setItem(
            'contact_last_viewed_time[' + newContact.id + ']',
            String(newContact.lastViewedMessageTime),
          );
        } catch (e) {
          // If failed storing this data in session storage, ignore it.
          //
          // We keep "lastViewedMessageTime" in current state anyway, it just means if an agent
          // has multiple windows/tabs, they might have phantom unread messages if sessionStorage
          // is not working for whatever reason. (Old browser, lowly configured cache by IT, etc)
        }
      }

      return {
        ...prevContactMap,
        [id]: newContact,
      };
    });
  };

  const getContactById = (id: string): Contact | undefined => {
    throwInvalidId(id);

    return contactMap[id];
  };

  const removeContactById = (id: string): void => {
    throwInvalidId(id);
    setContactMap((prevContactMap) => {
      if (prevContactMap[id] === undefined) {
        // If contact isn't in map or has already been deleted
        // keep the same map to avoid re-render. No-op.
        return prevContactMap;
      }

      const r = {
        ...prevContactMap,
      };

      delete r[id];
      return r;
    });
  };

  const context: ContactProviderProps = {
    currentVoiceContact: currentVoiceContact,
    currentContact: currentContact ?? undefined,
    hasActiveContact,
    contacts,
    contactMap,
    setCurrentContactById,
    insertOrUpdateContact,
    updateContactsByFilter,
    updateContactById,
    getContactById,
    removeContactById,
  };

  return <ContactContext.Provider value={context}>{children}</ContactContext.Provider>;
};

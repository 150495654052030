import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Card from '@mui/material/Card';
import CardHeader, { CardHeaderProps } from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { MUIStyledCommonProps, Theme } from '@mui/system';
import React, { CSSProperties, ForwardedRef, ReactNode, forwardRef } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { Link as RouterLink } from 'react-router-dom';

import CardFooter from '~components/CardFooter';
import ContentSpacer from '~components/ContentSpacer';

interface OberonCardProps {
  title: string | ReactNode;
  titleFontWeight?: CSSProperties['fontWeight'];
  reverseTitles?: boolean;
  subHeader?: string | ReactNode;
  avatar?: JSX.Element & ReactNode;
  to?: string;
  action?: ReactNode;
  footer?: ReactNode;
  footerBorderColor?: string;
  draggableProvided?: DraggableProvided;
  isDragDisabled?: boolean;
  onClick?: () => void;
}

interface MaybeLinkWrapperProps {
  title?: string;
  to?: string;
  children: ReactNode;
}

const MaybeLinkWrapper = ({ title, children, to }: MaybeLinkWrapperProps) => {
  if (to === undefined) return <>{children}</>;

  return (
    <Link component={RouterLink} to={to} underline='none' color='inherit' title={title}>
      {children}
    </Link>
  );
};

type CustomizedHeaderProps = MUIStyledCommonProps<Theme> &
  CardHeaderProps & {
    reverseTitles: boolean;
    titleFontWeight?: CSSProperties['fontWeight'];
  };

const CustomizedHeader = styled(CardHeader, {
  shouldForwardProp: (prop) => prop !== 'reverseTitles' && prop !== 'titleFontWeight',
})<CustomizedHeaderProps>(({ reverseTitles, titleFontWeight }) => ({
  'width': '100%',

  '& .MuiCardHeader-title': {
    fontWeight: titleFontWeight || 400,
  },
  '& .MuiCardHeader-content': {
    flexDirection: reverseTitles && 'column-reverse',
    display: reverseTitles && 'flex',
  },
}));

const OberonCard = forwardRef(
  (
    {
      title,
      titleFontWeight,
      reverseTitles,
      subHeader,
      avatar,
      to,
      action,
      footer,
      footerBorderColor,
      draggableProvided,
      isDragDisabled,
      onClick,
    }: OberonCardProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    let cardProps: { [key: string]: any } = {};

    if (onClick !== undefined) {
      cardProps = {
        ...cardProps,
        role: 'button',
        onKeyPress: onClick,
        onClick: onClick,
        tabIndex: 0,
      };
    }

    if (draggableProvided !== undefined) {
      cardProps = {
        ...cardProps,
        ...draggableProvided.draggableProps,
        ref: draggableProvided.innerRef,
      };
    }

    return (
      <ContentSpacer ref={ref} spacing={1}>
        <Card
          {...cardProps}
          sx={{
            display: draggableProvided ? 'flex' : 'block',
            cursor: onClick ? 'pointer' : 'default',
            border: '1px solid rgba(7,9,25,.125)',
            borderBottomWidth: footerBorderColor ? 4 : 1,
            borderBottomColor: footerBorderColor || 'rgba(7,9,25,.125)',
          }}>
          <MaybeLinkWrapper to={to} title={typeof title === 'string' ? title : to}>
            {draggableProvided && (
              <IconButton
                sx={{
                  ':hover': {
                    background: 'none',
                    backgroundColor: 'none',
                  },
                }}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                disabled={isDragDisabled}
                {...draggableProvided.dragHandleProps}>
                <DragIndicatorIcon />
              </IconButton>
            )}

            <CustomizedHeader
              reverseTitles={Boolean(reverseTitles)}
              titleFontWeight={titleFontWeight}
              title={title}
              avatar={avatar}
              subheader={subHeader}
              action={action}
            />

            {footer && (
              <>
                <Divider />

                <CardFooter spacing={1}>{footer}</CardFooter>
              </>
            )}
          </MaybeLinkWrapper>
        </Card>
      </ContentSpacer>
    );
  },
);

export default OberonCard;

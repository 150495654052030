import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';

import { OutcomesFormProps } from '~components/OutcomeCapture';

const OutcomesForm = ({ form, formSubmitting }: OutcomesFormProps) => {
  useEffect(() => {
    form.addSchemaProperties({
      task_id: {
        value: '',
        validators: [],
      },
    });

    return () => {
      form.removeSchemaProperties(['task_id']);
    };
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant='outlined'
          id='task_id'
          name='task_id'
          label='Task ID'
          disabled={formSubmitting}
          value={form.fields?.task_id?.value || ''}
          error={Boolean(form.errors.task_id)}
          helperText={form.errors.task_id && form.errors.task_id[0]!}
          onChange={form.handleInputChange}
        />
      </Grid>
    </>
  );
};

export default OutcomesForm;

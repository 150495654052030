import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';

import { OutcomesFormProps } from '~components/OutcomeCapture';
import { ValidatorType } from '~hooks/useForm';

const OutcomesForm = ({ form, formSubmitting }: OutcomesFormProps) => {
  // URL of the g8 CRM. CRM ID Gets append on during crm_id on change event useEffect
  const crmBaseUrl = 'https://live.childcarecrm.com.au/manageLead.php?leadId=';

  useEffect(() => {
    form.addSchemaProperties({
      crm_id: {
        value: '',
        validators: [
          {
            type: ValidatorType.Regex,
            pattern: /^\d{4,8}$/,
            message: 'CRM ID must be numeric and between 4 and 8 characters.',
          },
        ],
      },
      crm_customer_url: {
        value: '',
        validators: [],
      },
    });

    return () => {
      form.removeSchemaProperties(['crm_id']);
    };
  }, []);

  // Builds CRM Customer URL for storage within contact attributes
  useEffect(() => {
    if (form.fields.crm_id?.value !== undefined) {
      form.handleUnconventionalInputChange('crm_customer_url', `${crmBaseUrl}${form.fields.crm_id.value}`);
      return;
    }

    form.handleUnconventionalInputChange('crm_customer_url', '');
  }, [form.fields.crm_id?.value]);

  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant='outlined'
          id='crm_id'
          name='crm_id'
          label='CRM ID'
          disabled={formSubmitting}
          value={form.fields?.crm_id?.value || ''}
          error={Boolean(form.errors.crm_id)}
          helperText={form.errors.crm_id && form.errors.crm_id[0]!}
          onChange={form.handleInputChange}
        />
      </Grid>
    </>
  );
};

export default OutcomesForm;

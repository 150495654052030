import NotificationsIcon from '@mui/icons-material/Notifications';
import SyncIcon from '@mui/icons-material/Sync';
import WarningIcon from '@mui/icons-material/Warning';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import yellow from '@mui/material/colors/yellow';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { VariableSizeList } from 'react-window';

import useCellMeasurer from '~hooks/useCellMeasurer';
import { useSetPageTitleProps } from '~providers/PageTitleProvider';
import { UserNotificationManager } from '~services/UserNotificationManager';
import { UserNotification } from '~services/UserNotificationManager/domain';
import { exponentialDelay, findIndexByProperty } from '~utils/Functions';

import emptyInboxSvg from './emptyInbox.svg';
import NotificationCard from './NotificationCard';

// This is here as we want to keep the width of the drawer and List component the same.
// That and width is a mandatory property for the List component
const drawerWidth = 300;
const attemptsBeforeManualRetryOption = 10;
// used for fetching storage notification count
const unseenCountLocalStorageId = 'cmi-notification-unseen-count';

const NotificationDrawer = () => {
  const setPageTitleProps = useSetPageTitleProps();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = useState<UserNotification[]>([]);
  const retryTimeoutRef = useRef<number | undefined>(undefined);
  const retryCount = useRef<number>(0);
  // We make use of the retryCount ref as the statue manager for this state object, this is because we cannot
  // use the state value within a callback as it will never register its update. So we use the retryCount ref as a way
  // to end the exponential backoff logic, while using retryState as a way to force the rerender of the component for
  // displaying of the manual retry button
  const [retryState, setRetryState] = useState<number>(retryCount.current);
  // True for the initial pass to prevent a flicker between error state to connected state
  // if this is connected it will become true else if socket has closed false
  const [socketConnected, setSocketConnected] = useState<boolean>(true);
  const open = Boolean(anchorEl);
  const [unseenCount, setUnseenCount] = useState<number>(0);

  const onAcknowledge = (id: number) => () => {
    // If not defined we don't want to do anything
    if (userNotificationManager === undefined) {
      return;
    }

    userNotificationManager.markNotificationAsAcknowledged(id);
  };

  const notificationsNodeList = notifications.map((item) => (
    <NotificationCard
      message={item.message}
      createdAt={DateTime.fromISO(item.createdAt)}
      requestedAcknowledgement={item.requestAcknowledge}
      acknowledgedAt={item.acknowledgedAt}
      onAcknowledge={onAcknowledge(item.id)}
    />
  ));
  const cellMeasurerProps = useCellMeasurer({
    items: notificationsNodeList,
    visible: open,
  });

  // First load connection attempt
  const userNotificationManager = useMemo(() => {
    const url = new URL(window.document.location.href);
    const proto = url.protocol === 'https:' ? 'wss' : 'ws';
    const socketUrl = `${proto}://${url.host}/api/notification/`;

    const manager = new UserNotificationManager(socketUrl, {
      onConnected: () => {
        clearTimeout(retryTimeoutRef.current);
        retryCount.current = 0;
        setRetryState(retryCount.current);
        setSocketConnected(true);
      },
      onInit: (messages) => {
        const countInLocalStorage = localStorage.getItem(unseenCountLocalStorageId);
        let count: number;

        if (countInLocalStorage == null) {
          count = messages.reduce((accumulator: number, item) => {
            if (item.requestAcknowledge && item.acknowledgedAt === null) {
              return accumulator + 1;
            }

            return accumulator;
          }, 0);

          localStorage.setItem(unseenCountLocalStorageId, count.toString());
        } else {
          count = parseInt(countInLocalStorage, 10);
        }

        setUnseenCount(count);
        setNotifications(messages);
      },
      onNewNotification: (message) => {
        // If open the user will see the notification come in so no point increasing counter
        if (!open) {
          setUnseenCount((prev) => {
            const count = prev + 1;

            localStorage.setItem(unseenCountLocalStorageId, count.toString());
            return count;
          });
        }

        setNotifications((prev) => [message, ...prev]);
      },
      onAcknowledgedNotification: (message) => {
        // Remove 1 from the count since this notification has now been acknowledged and seen
        setUnseenCount((prev) => {
          const count = prev - 1;

          localStorage.setItem(unseenCountLocalStorageId, count.toString());
          return count;
        });

        setNotifications((prev) => {
          const index = findIndexByProperty(prev, 'id', message.id);
          // Clone current state
          const newList = JSON.parse(JSON.stringify(prev));
          // Replace existing copy of notification
          newList[index] = message;
          return newList;
        });
      },
      onDisconnected: (closeCode) => {
        clearTimeout(retryTimeoutRef.current);
        setSocketConnected(false);

        // Attempt to reconnect the socket at an exponential delay
        if (closeCode > 1000 && retryCount.current <= attemptsBeforeManualRetryOption) {
          retryTimeoutRef.current = exponentialDelay(retryCount.current, () => {
            retryCount.current += 1;
            setRetryState(retryCount.current);
            manager.connect();
          });
        }
      },
    });

    return manager;
  }, []);

  // Handle the connection and disconnection of the socket
  useEffect(() => {
    userNotificationManager.connect();

    return () => {
      clearTimeout(retryTimeoutRef.current);
      userNotificationManager.disconnect();
    };
  }, []);

  // Resets unseen count to be only notifications requiring acknowledgement,
  // as any new non acknowledgement based notifications have now been "seen"
  // since notification panel has been opened
  useEffect(() => {
    if (open) {
      const count = notifications.reduce((accumulator: number, item) => {
        if (item.requestAcknowledge && item.acknowledgedAt === null) {
          return accumulator + 1;
        }

        return accumulator;
      }, 0);

      localStorage.setItem(unseenCountLocalStorageId, count.toString());
      setUnseenCount(count);
    }
  }, [open]);

  // Appends notification count to the start of the page title
  useEffect(() => {
    // If we lose the socket connection we do not want to append the notification count
    // to the page title
    if (!socketConnected) {
      setPageTitleProps({ notificationCount: 0 });
      return;
    }

    // Set page title
    setPageTitleProps({ notificationCount: unseenCount });
  }, [unseenCount, socketConnected]);

  // Logic to attempt a resend of any potential messages not send due to a network error
  useEffect(() => {
    const ref = setInterval(() => {
      userNotificationManager.sendQueuedMessages();
    }, 1_000);

    return () => {
      clearInterval(ref);
    };
  }, []);

  const toggleDrawer = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl((prev) => (prev === null ? e.currentTarget : null));
  };

  const closeDrawer = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton sx={{ marginLeft: 1, marginRight: 1 }} aria-label='notifications' onClick={toggleDrawer}>
        {socketConnected && (
          <Badge badgeContent={unseenCount} color='error'>
            <NotificationsIcon sx={{ color: '#ffffff' }} />
          </Badge>
        )}

        {!socketConnected && (
          <Box sx={{ position: 'relative', display: 'inline-flex', flexShrink: 0, verticalAlign: 'middle' }}>
            <NotificationsIcon sx={{ color: '#ffffff' }} />
            <WarningIcon
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                transform: 'scale(1) translate(50%, -50%)',
                transformOrigin: '100% 0%',
                color: yellow[600],
                fontSize: 20,
              }}
            />
          </Box>
        )}
      </IconButton>

      {/* zIndex is set to 2 on the popper index due to the positioning nature of abolute positioning
      and how the stupid the popper component is */}
      <Popper style={{ zIndex: 2 }} anchorEl={anchorEl} open={open} disablePortal={true} placement='bottom-end'>
        <ClickAwayListener onClickAway={closeDrawer}>
          <Box
            sx={{
              zIndex: 'drawer',
              marginTop: '12px',
              width: drawerWidth,
              backgroundColor: 'white',
              borderRadius: '0 0 8px 8px',
              overflow: 'hidden',
              boxShadow: 3,
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                padding: '8px 16px',
              }}>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography fontWeight={500} variant='subtitle1' color='textPrimary'>
                  Notifications
                </Typography>

                {/* TODO (christian): Prevent spam clicking that could result in multiple
                 * successful connections, does this matter??
                 */}
                {retryState > attemptsBeforeManualRetryOption && (
                  <IconButton size='small' onClick={userNotificationManager?.connect}>
                    <SyncIcon fontSize='small' />
                  </IconButton>
                )}
              </Box>

              {retryState > attemptsBeforeManualRetryOption && (
                <Typography fontSize={10} variant='caption' color='textSecondary'>
                  Unable to communicate with notification server
                </Typography>
              )}
            </Box>

            <Divider />

            {notifications.length === 0 && (
              <Box
                sx={{
                  height: 400,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}>
                <img src={emptyInboxSvg} />

                <Box sx={{ width: '70%', textAlign: 'center', marginTop: '-24px' }}>
                  <Typography fontWeight={500} variant='body1' color='textPrimary'>
                    No notifications
                  </Typography>

                  <Typography variant='body2' color='textSecondary'>
                    All important notifications will be listed here
                  </Typography>
                </Box>
              </Box>
            )}

            {notifications.length > 0 && (
              <VariableSizeList width={drawerWidth} height={400} {...cellMeasurerProps}>
                {({ index, style }) => {
                  const item = notificationsNodeList[index];
                  return <div style={style}>{item}</div>;
                }}
              </VariableSizeList>
            )}
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default NotificationDrawer;

import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { Fragment, useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { DataItem } from '~components/DataItem';
import SectionCard from '~components/SectionCard';
import { CampaignTrunk } from '~pages/CampaignManagement/domain';

interface TrunksFormProps {
  trunks: CampaignTrunk[];
  isEdit: boolean;
  submitting: boolean;
  toggleEdit: () => void;
  onSubmit: (data: CampaignTrunk[] | null) => Promise<void>;
}

interface Form {
  trunks: CampaignTrunk[];
}

const TrunksForm = ({ trunks, isEdit, submitting, toggleEdit, onSubmit }: TrunksFormProps) => {
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
  } = useForm<Form>({
    defaultValues: {
      trunks: [],
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const { fields, append, remove } = useFieldArray({ control, name: 'trunks', shouldUnregister: true });

  useEffect(() => {
    if (isEdit) {
      setValue('trunks', trunks);
    } else {
      reset();
    }
  }, [trunks, isEdit]);

  const onSubmitFn = handleSubmit(async (data: Form) => {
    try {
      await onSubmit(data.trunks);

      reset();
    } catch (e) {
      // Just return on error as we only want to reset the form on a successful submission
      return;
    }
  });

  const trunkDataItems = trunks.map((trunk, index) => (
    <DataItem key={index} title={trunk.timezone} value={trunk.trunk} />
  ));

  const trunkFieldItems = fields.map((field: any, index: number) => (
    <Fragment key={index}>
      <Grid sx={{ display: 'flex' }} item xs={2}>
        <Button
          sx={{
            flex: 1,
            // Height of default sized input field
            maxHeight: 56,
          }}
          variant='contained'
          disableElevation
          color='secondary'
          onClick={() => remove(index)}>
          <DeleteIcon />
        </Button>
      </Grid>

      <Grid item xs={5}>
        <Controller
          name={`trunks.${index}.timezone` as const}
          control={control}
          rules={{ required: 'Timezone is required.' }}
          defaultValue={field.timezone}
          render={({ field }) => (
            <TextField
              variant='outlined'
              fullWidth
              label='Time Zone'
              required={true}
              error={Boolean(errors?.trunks?.[index]?.timezone)}
              helperText={errors?.trunks?.[index]?.timezone?.message}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={5}>
        <Controller
          name={`trunks.${index}.trunk` as const}
          control={control}
          rules={{ required: 'Trunk is required.' }}
          defaultValue={field.trunk}
          render={({ field }) => (
            <TextField
              variant='outlined'
              label='Trunk'
              fullWidth
              required={true}
              error={Boolean(errors?.trunks?.[index]?.trunk)}
              helperText={errors?.trunks?.[index]?.trunk?.message}
              {...field}
            />
          )}
        />
      </Grid>
    </Fragment>
  ));

  return (
    <SectionCard title='Timezone Trunks' onEdit={toggleEdit}>
      {isEdit && (
        <form onSubmit={onSubmitFn} noValidate>
          <Grid container spacing={2}>
            {trunkFieldItems}

            <Grid item xs={12}>
              <Button
                variant='contained'
                disableElevation
                fullWidth
                color='primary'
                onClick={() => append({ timezone: '', trunk: '' })}>
                + Add Timezone
              </Button>
            </Grid>

            <Grid sx={{ textAlign: 'right' }} item xs={12}>
              <Button onClick={toggleEdit}>Cancel</Button>

              <LoadingButton
                sx={{ marginLeft: 1 }}
                type='submit'
                variant='contained'
                disableElevation
                loading={submitting}
                color='primary'>
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      )}

      {!isEdit && (
        <>
          {trunkDataItems.length === 0 && (
            <Typography variant='body1' component='p'>
              No timezone trunks defined
            </Typography>
          )}

          {trunkDataItems.length > 0 && trunkDataItems}
        </>
      )}
    </SectionCard>
  );
};

export default TrunksForm;

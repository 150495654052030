import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import OberonDialog from '~components/OberonDialog';
import { getRoutingProfiles } from '~pages/CampaignManagement/api';

import { CampaignType, CreateCampaign, DiallerType, RoutingProfile } from '../../domain';

interface CreateCampaignModalProps {
  open: boolean;
  submitting: boolean;
  onAccept: (data: CreateCampaign) => void;
  onClose: () => void;
}

interface Form {
  name: string;
  description: string;
  routingProfiles: { label: string; value: string }[];
  diallerType: DiallerType | null;
  campaignType: CampaignType | null;
}

const fetchRoutingProfilesOrEmptyArray = async () => {
  try {
    return await getRoutingProfiles();
  } catch (e) {
    return [];
  }
};

const diallerTypeList = [
  {
    label: 'Connect',
    value: DiallerType.Connect,
  },
  {
    label: 'SIP',
    value: DiallerType.SIP,
  },
];

const asteriskCampaignTypeList = [
  {
    label: 'Predictive',
    value: CampaignType.Predictive,
  },
];

const connectCampaignTypeList = [
  {
    label: 'Preview',
    value: CampaignType.Preview,
  },
];

const CreateCampaignModal = ({ open, submitting, onAccept, onClose }: CreateCampaignModalProps) => {
  const [fetchingFormData, setFetchingFormData] = useState<boolean>(false);
  const [routingProfiles, setRoutingProfiles] = useState<RoutingProfile[]>([]);
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<Form>({
    defaultValues: {
      name: '',
      description: '',
      routingProfiles: [],
      diallerType: null,
      campaignType: null,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const diallerTypeWatch = watch('diallerType');
  const campaignTypeList = useMemo<{ label: string; value: string }[]>(() => {
    switch (diallerTypeWatch) {
      case DiallerType.SIP: {
        return asteriskCampaignTypeList;
      }
      case DiallerType.Connect: {
        return connectCampaignTypeList;
      }
      default: {
        return [];
      }
    }
  }, [diallerTypeWatch]);
  const isLoading = fetchingFormData || submitting;

  // Manages form based data
  useEffect(() => {
    const fetchRoutingProfiles = async () => {
      setFetchingFormData(true);

      const data = await fetchRoutingProfilesOrEmptyArray();
      setRoutingProfiles(data);

      setFetchingFormData(false);
    };

    if (open) {
      fetchRoutingProfiles();
    }

    return function cleanupCreateCampaignModal() {
      reset();
    };
  }, [open]);

  // Clears campaign type if dialler type changes
  useEffect(() => {
    setValue('campaignType', null);
  }, [diallerTypeWatch]);

  const onSubmit = handleSubmit(async (data: Form) => {
    try {
      await onAccept({
        name: data.name,
        description: data.description,
        diallerType: data.diallerType as DiallerType,
        campaignType: data.campaignType as CampaignType,
        routingProfiles: data.routingProfiles.map((item: { label: string; value: string }) => item.value),
      });
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
      return;
    }

    reset();
  });

  const routingProfilesList = routingProfiles.map((item: RoutingProfile) => ({
    label: item.name,
    value: item.id,
  }));

  const diallerTypeListDisplay = diallerTypeList.map((item, index) => (
    <MenuItem key={index} value={item.value}>
      {item.label}
    </MenuItem>
  ));

  const campaignTypeListDisplay = campaignTypeList.map((item, index) => (
    <MenuItem key={index} value={item.value}>
      {item.label}
    </MenuItem>
  ));

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title='Create Campaign'
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='name'
              control={control}
              rules={{
                required: 'Campaign name is required.',
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Campaign Name'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='description'
              control={control}
              rules={{
                required: 'Campaign description is required.',
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant='outlined'
                  label='Campaign Description'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='routingProfiles'
              control={control}
              rules={{ required: 'At least one routing profile must be selected.' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  value={field.value}
                  onChange={(e, data) => field.onChange(data)}
                  fullWidth
                  multiple
                  options={routingProfilesList}
                  filterSelectedOptions
                  disabled={isLoading}
                  renderInput={(params) => (
                    <TextField
                      label='Routing Profiles'
                      required={true}
                      error={Boolean(errors.routingProfiles)}
                      helperText={errors.routingProfiles?.message}
                      variant='outlined'
                      {...params}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='diallerType'
              control={control}
              rules={{
                required: 'Dialler type is required.',
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  select
                  variant='outlined'
                  label='Dialler Type'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.diallerType)}
                  helperText={errors.diallerType?.message}
                  value={field.value || ''}>
                  {diallerTypeListDisplay}
                </TextField>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='campaignType'
              control={control}
              rules={{
                required: 'Campaign type is required.',
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  select
                  variant='outlined'
                  label='Campaign Type'
                  disabled={isLoading || campaignTypeListDisplay.length === 0}
                  required={true}
                  error={Boolean(errors.campaignType)}
                  helperText={errors.campaignType?.message}
                  value={field.value || ''}>
                  {campaignTypeListDisplay}
                </TextField>
              )}
            />
          </Grid>
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            Create
          </LoadingButton>
        </>
      }
    />
  );
};

export default CreateCampaignModal;

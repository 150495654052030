import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AgentStatsBar, { agentStatsColors } from '~components/AgentStatsBar';
import AsyncLoader from '~components/AsyncLoader';
import EmptyState from '~components/EmptyState';
import ScreenRecordingCard from '~components/ScreenRecordingCard';
import SectionCard from '~components/SectionCard';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useSetPageTitleProps } from '~providers/PageTitleProvider';
import Routes from '~providers/RouteProvider/Routes';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { getAgentByUsername, getAgentRecordingInfo } from '../api';
import { Agent, RecordingInfo } from '../domain';

interface Error {
  text: string;
  subText: string;
}
const getDaysInPastFromNow = (daysInPast: number, format: string) => {
  const now = DateTime.fromJSDate(new Date());

  let array: string[] = [];
  for (let i = daysInPast; i >= 1; i--) {
    array = [...array, now.minus({ days: i }).toFormat(format)];
  }

  // append current day to the end and return
  return [...array, now.toFormat(format)];
};

// Formats dates to 2021-10-10
const SIMPLE_DATE_FORMAT = 'yyyy-MM-dd';
// Localized date with full month and weekday. Formats dates to Wednesday, August 6, 2014
const FULL_MONTH_AND_WEEKDAY_FORMAT = 'DDDD';

const AgentDetails = () => {
  const { extensions } = useAppConfiguration();
  const navigate = useNavigate();
  const setPageTitleProps = useSetPageTitleProps();
  const { username } = useParams() as { username: string };
  const [agent, setAgent] = useState<Agent | undefined>(undefined);
  const [recordingInfoList, setRecordingInfoList] = useState<RecordingInfo[]>([]);
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const screenRecordingExtensionEnabled = Boolean(extensions.screenRecordings);
  const agentStatsDates = useMemo(() => getDaysInPastFromNow(6, SIMPLE_DATE_FORMAT), []);

  // Set page title
  useEffect(() => {
    setPageTitleProps({ pageName: username });
  }, [username]);

  const errorAction = () => {
    navigate(Routes.system.path);
  };

  // Get Agent Details
  useEffect(() => {
    (async () => {
      try {
        setAgent(await getAgentByUsername(username));
      } catch (e) {
        handleError(e);
        return;
      } finally {
        setPageLoaded(true);
      }
    })();
  }, []);

  // Get all screen recording related data if extension is enabled
  useEffect(() => {
    (async () => {
      if (agent !== undefined && screenRecordingExtensionEnabled) {
        let recordings;

        try {
          recordings = await getAgentRecordingInfo(username);
        } catch (e) {
          return;
        }

        setRecordingInfoList(recordings);
      }
    })();
  }, [agent]);

  const handleError = (e: any) => {
    if (e instanceof APIError) {
      setError({ text: 'Unable to request data from backend', subText: e.message });
    }

    if (e instanceof UnsupportedStructureError) {
      setError({ text: 'Data from backend Invalid', subText: 'Unable to decode response' });
    }
  };

  const errorDisplay = error ? <EmptyState type='error' text={error.text} subText={error.subText} /> : null;

  return (
    <AsyncLoader isLoading={!pageLoaded} error={errorDisplay}>
      {agent === undefined && (
        <EmptyState
          type='not-found'
          text='Sorry!'
          subText='We are unable to find the page you are looking for. Click the button below to return to the agent list.'
          action={errorAction}
          actionText='Agent List'
        />
      )}

      {agent !== undefined && (
        <>
          <Typography variant='h4' component='h1' gutterBottom>
            {agent.firstName} {agent.lastName}
          </Typography>

          <SectionCard title='Agent Status Log'>
            <Typography variant='h6' component='h6' gutterBottom>
              Legend
            </Typography>

            <Box
              sx={{
                'marginLeft': '-4px',
                'marginBottom': 1,
                '&:last-of-type': {
                  marginBottom: 0,
                },
              }}>
              {Object.keys(agentStatsColors).map((key) => (
                <Chip
                  key={key}
                  style={{ margin: 4, color: '#ffffff', backgroundColor: agentStatsColors[key] }}
                  label={key}
                />
              ))}
            </Box>

            {agentStatsDates.map((date, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    'marginBottom': 1,
                    '&:last-of-type': {
                      marginBottom: 0,
                    },
                  }}>
                  <Typography variant='h6' component='h6' gutterBottom>
                    {DateTime.fromFormat(date, SIMPLE_DATE_FORMAT).toFormat(FULL_MONTH_AND_WEEKDAY_FORMAT)}
                  </Typography>

                  <AgentStatsBar date={date} username={username} />
                </Box>
              );
            })}
          </SectionCard>

          {screenRecordingExtensionEnabled && (
            <ScreenRecordingCard
              recordingRelativePath='/api/screen-recordings/recording/'
              recordingInfoList={recordingInfoList}
              canDownload={true}
            />
          )}
        </>
      )}
    </AsyncLoader>
  );
};

export default AgentDetails;

import React, { useEffect } from 'react';

import EmptyState from '~/components/EmptyState';
import { DiallerType } from '~/pages/CampaignManagement/domain';
import DetectMicrophone from '~components/DetectMicrophone';
import PopUpEnforcer from '~components/PopUpEnforcer';
import ConnectDialler from '~pages/Dialler/ConnectDialler';
import AgentStatesProvider from '~providers/AgentStatesProvider';
import AssignedCampaignProvider, { useAssignedCampaign } from '~providers/AssignedCampaignProvider';
import { AsyncProvider } from '~providers/AsyncProvider';
import { AttemptProvider } from '~providers/AttemptProvider';
import ConnectProvider from '~providers/ConnectProvider';
import { ContactProvider } from '~providers/ContactProvider';
import { useSetPageTitleProps } from '~providers/PageTitleProvider';
import ScreenShareProvider from '~providers/ScreenShareProvider';
import { useUserProfile } from '~providers/UserProfileProvider';

import AsteriskDialler from './AsteriskDialler';

const NoCampaignAssigned = () => (
  <EmptyState type='puzzle' text='User Configuration Error' subText='You are not assigned to a dialling campaign.' />
);

const DiallerSwitch = () => {
  const { campaign } = useAssignedCampaign();
  const { username: loggedInUserUsername } = useUserProfile();

  if (campaign.diallerType === DiallerType.Connect || campaign.diallerType === DiallerType.SIP) {
    return (
      <DetectMicrophone>
        <PopUpEnforcer>
          <ScreenShareProvider>
            <ContactProvider>
              <ConnectProvider>
                <AsyncProvider enabled={campaign.hasAsyncQueues && campaign.diallerType !== DiallerType.SIP}>
                  <AgentStatesProvider>
                    <AttemptProvider loggedInUserUsername={loggedInUserUsername}>
                      {campaign.diallerType === DiallerType.Connect ? <ConnectDialler /> : <AsteriskDialler />}
                    </AttemptProvider>
                  </AgentStatesProvider>
                </AsyncProvider>
              </ConnectProvider>
            </ContactProvider>
          </ScreenShareProvider>
        </PopUpEnforcer>
      </DetectMicrophone>
    );
  } else {
    return <NoCampaignAssigned />;
  }
};

const Dialler = () => {
  const setPageTitleProps = useSetPageTitleProps();

  // Set page title
  useEffect(() => {
    setPageTitleProps({ pageName: 'Dialler' });
  }, []);

  return (
    <AssignedCampaignProvider>
      <DiallerSwitch />
    </AssignedCampaignProvider>
  );
};

export default Dialler;

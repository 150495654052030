import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppConfiguration } from '../AppConfigurationProvider';
import { ExtensionsConfiguration } from '../AppConfigurationProvider/api';
import { isAllowedRole, useUserProfile } from '../UserProfileProvider';
import Page from './Page';
import Routes from './Routes';

interface RouteProviderProps {
  children: ReactNode;
}

export const RouteContext = createContext<Page[]>([]);

export const useRoutes = (): Page[] => {
  return useContext(RouteContext) as Page[];
};

const RouteProvider = ({ children }: RouteProviderProps) => {
  const appConfig = useAppConfiguration();
  const auth = useUserProfile();
  const location = useLocation();
  const [routes, setRoutes] = useState<Page[]>([]);
  const isExtensionEnabled = (ref: keyof ExtensionsConfiguration) => Boolean(appConfig.extensions[ref]);

  // Sets all routes that the current user has access to view
  useEffect(() => {
    const routeList: Page[] = [];

    for (let key in Routes) {
      const k = key as any as keyof Routes;
      const route: Page = Routes[k];

      // If an extension reference is present we want to make sure to check if the extension is enabled or not
      // as well as if the user has permission to view it and its related routes.
      // Else we just want to check if the user has permission to view the specific route.
      if (route.extensionRef) {
        if (isExtensionEnabled(route.extensionRef) && isAllowedRole(route.viewRoles, auth.policies)) {
          route.selected(location.pathname === route.path);
          routeList.push(route);
        }
      } else {
        if (isAllowedRole(route.viewRoles, auth.policies)) {
          route.selected(location.pathname === route.path);
          routeList.push(route);
        }
      }
    }

    setRoutes(routeList);
  }, [auth.policies, appConfig.extensions, location.pathname]);

  return <RouteContext.Provider value={routes}>{children}</RouteContext.Provider>;
};

export default RouteProvider;

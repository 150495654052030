import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent, useEffect, useState } from 'react';

import Selectbox from '~components/Form/Selectbox';
import OberonDialog from '~components/OberonDialog';
import useForm, { ValidatorType } from '~hooks/useForm';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useNotification } from '~providers/NotificationProvider';

import { getRoutingProfiles, getUnallocatedAgents } from '../../../api';
import { AssignCampaignAgent, RoutingProfile } from '../../../domain';

interface AssignAgentModalProps {
  open: boolean;
  submitting: boolean;
  campaignRoutingProfileIDList: string[];
  onAccept: (data: AssignCampaignAgent) => void;
  onClose: () => void;
}

interface AutoCompleteList {
  label: string;
  value: string;
}

const AssignAgentModal = ({
  open,
  submitting,
  campaignRoutingProfileIDList,
  onAccept,
  onClose,
}: AssignAgentModalProps) => {
  const appConfig = useAppConfiguration();
  const { pushNotification } = useNotification();
  const [agentList, setAgentList] = useState<AutoCompleteList[]>([]);
  const [fetchingFormData, setFetchingFormData] = useState<boolean>(false);
  const isLoading = submitting || fetchingFormData;
  const {
    fields,
    errors,
    handleInputChange,
    handleSubmit,
    resetForm,
    handleUnconventionalInputChange,
    setAsyncFields,
  } = useForm({
    agents: {
      validators: [{ type: ValidatorType.Required, message: 'At least one agent needs to be selected.' }],
      value: [],
    },
    routingProfile: {
      validators: [],
      value: '',
    },
  });
  const [routingProfilesUnfiltered, setRoutingProfiles] = useState<RoutingProfile[]>([]);
  // Only returns routing profiles that are currently associated with the campaign
  const campaignRoutingProfiles = routingProfilesUnfiltered.filter((routingProfile: RoutingProfile) => {
    const found = campaignRoutingProfileIDList.find((item) => routingProfile.id === item);
    return Boolean(found);
  });

  useEffect(() => {
    (async () => {
      if (open && !fetchingFormData) {
        setFetchingFormData(true);

        let unallocatedAgents;
        let routingProfileList;
        try {
          [unallocatedAgents, routingProfileList] = await Promise.all([getUnallocatedAgents(), getRoutingProfiles()]);
        } catch (e) {
          pushNotification('error', 'error retreiving agents and routing profiles');
          return;
        } finally {
          setFetchingFormData(false);
        }

        const agentList = unallocatedAgents.map((item) => ({
          label: item.name,
          value: item.username,
        }));
        setAgentList(agentList);
        setRoutingProfiles(routingProfileList);
        if (routingProfileList.length > 0) {
          setAsyncFields({
            routingProfile: routingProfileList[0].id,
          });
        }
      }
    })();
  }, [open]);

  const handleAutoCompleteInputChange = (e: ChangeEvent<{}>, value: AutoCompleteList[]) => {
    handleUnconventionalInputChange('agents', value);
  };

  const onSubmit = handleSubmit(async (formData: any) => {
    const data: { agents: AutoCompleteList[]; routingProfile: string } = formData;
    const agents = data.agents.map((agent: AutoCompleteList) => agent.value);

    try {
      await onAccept({
        agents: agents,
        routingProfile: data.routingProfile,
      });
    } catch (e) {
      return;
    }

    resetForm();
  });

  const routingProfilesList = campaignRoutingProfiles.map((item: RoutingProfile) => ({
    label: item.name,
    value: item.id,
  }));
  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title='Assign Agents'
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              fullWidth
              onChange={handleAutoCompleteInputChange}
              value={fields.agents.value}
              options={agentList}
              getOptionLabel={(option) => option?.label}
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id='agents'
                  name='agents'
                  label='Agents'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.agents)}
                  helperText={errors.agents && errors.agents[0]!}
                  variant='outlined'
                />
              )}
            />
          </Grid>

          {!appConfig.aws.externallyManagedRoutingProfile && (
            <Grid item xs={12}>
              <Selectbox
                id='routingProfile'
                name='routingProfile'
                title='Routing Profile'
                items={routingProfilesList}
                disabled={isLoading}
                required={true}
                value={fields.routingProfile.value}
                onChange={handleInputChange}
              />
            </Grid>
          )}
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            Assign
          </LoadingButton>
        </>
      }
    />
  );
};

export default AssignAgentModal;

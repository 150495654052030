import CancelIcon from '@mui/icons-material/Cancel';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import { BasicTable, ColumnData, RowData } from '~components/BasicTable';
import ContentSpacer from '~components/ContentSpacer';
import EmptyState from '~components/EmptyState';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import Routes from '~providers/RouteProvider/Routes';
import { AsteriskStatsManager } from '~services/AsteriskStatsManager';
import { AsteriskStats } from '~services/AsteriskStatsManager/domain';
import { exponentialDelay } from '~utils/Functions';

interface ListItem {
  label: string;
  value: string | number;
}

interface TableFilter {
  campaigns: ListItem[];
  statuses?: ListItem[];
  sortBy: string;
  sortOrder: 'asc' | 'desc';
}

const sortNumber = (a: number, b: number) => {
  return a - b;
};

const sortAlphanumericString = (a: string, b: string) => {
  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
};

const objectSearch = (sortBy: string, sortOrder: 'asc' | 'desc') => (a: any, b: any) => {
  const aVal = a[sortBy];
  const bVal = b[sortBy];
  const parsedAVal = Number(aVal);
  const parsedBVal = Number(bVal);

  if (!Number.isNaN(parsedAVal) && !Number.isNaN(parsedBVal)) {
    return sortOrder === 'asc' ? sortNumber(parsedAVal, parsedBVal) : sortNumber(parsedBVal, parsedAVal);
  } else {
    return sortOrder === 'asc' ? sortAlphanumericString(aVal, bVal) : sortAlphanumericString(bVal, aVal);
  }
};

const CampaignsDashboard = () => {
  const appConfig = useAppConfiguration();
  const navigate = useNavigate();
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [state, setState] = useState<AsteriskStats[]>([]);
  const [socketConnected, setSocketConnected] = useState<boolean>(false);
  const retryTimeoutRef = useRef<number | undefined>(undefined);
  const retryCount = useRef<number>(0);
  const predictiveConfig = appConfig.extensions.predictive ?? {
    diallerURL: '',
    sipWebsocket: '',
    sipHost: '',
  };
  const [agentsInStatusPerCampaignFilter, setAgentsInStatusPerCampaignFilter] = useState<TableFilter>({
    campaigns: [],
    sortBy: 'campaignName',
    sortOrder: 'desc',
  });
  const [metricsPerCampaignFilter, setMetricsPerCampaignFilter] = useState<TableFilter>({
    campaigns: [],
    sortBy: 'campaignName',
    sortOrder: 'desc',
  });
  const [globalAgentsInStatusFilter, setGlobalAgentsInStatusFilter] = useState<TableFilter>({
    campaigns: [],
    statuses: [],
    sortBy: 'username',
    sortOrder: 'desc',
  });
  const asteriskStatsManager = useMemo(() => {
    const socketUrl = `${predictiveConfig.diallerURL}/stats-ws`;

    const manager = new AsteriskStatsManager(socketUrl, null, {
      onConnected: () => {
        clearTimeout(retryTimeoutRef.current);
        setSocketConnected(true);
        retryCount.current = 0;
      },
      onMessage: (asteriskStats) => {
        setInitialLoad(false);

        if (Array.isArray(asteriskStats)) {
          setState(asteriskStats);
        }
      },
      onDisconnected: (reconnect) => {
        clearTimeout(retryTimeoutRef.current);
        setInitialLoad(false);
        setSocketConnected(false);

        retryTimeoutRef.current = exponentialDelay(retryCount.current, () => {
          retryCount.current += 1;
          reconnect();
        });
      },
    });

    return manager;
  }, []);

  // Handle the connection and disconnection of the socket
  useEffect(() => {
    asteriskStatsManager.connect();

    return () => {
      clearTimeout(retryTimeoutRef.current);
      asteriskStatsManager.disconnect();
    };
  }, []);

  // Logic to attempt a resend of any potential messages not send due to a network error
  useEffect(() => {
    const ref = setInterval(() => {
      asteriskStatsManager.sendQueuedMessages();
    }, 1_000);

    return () => {
      clearInterval(ref);
    };
  }, []);

  const onTableRowClick = useCallback((data: RowData) => {
    navigate(`${generatePath(Routes.viewCampaign.path, { campaignId: data.campaignId })}?show=LiveDash`);
  }, []);

  const onFilterChange = useCallback(
    (key: string, setState: Dispatch<SetStateAction<TableFilter>>) => (e: ChangeEvent<{}>, value: ListItem[]) => {
      setState((prev) => ({ ...prev, [key]: value }));
    },
    [],
  );

  const handleColumnSort = useCallback(
    (setState: Dispatch<SetStateAction<TableFilter>>, column: string) => () => {
      setState((prev) => {
        if (column === prev.sortBy) {
          return { ...prev, sortOrder: prev.sortOrder === 'asc' ? 'desc' : 'asc' };
        }

        return { ...prev, sortBy: column, sortOrder: 'asc' };
      });
    },
    [],
  );

  const campaignsList = state.map((asteriskStats) => ({
    label: asteriskStats.campaignName,
    value: asteriskStats.campaignId,
  }));

  const statusList =
    state !== undefined && state.length > 0
      ? state[0].agentStatusCounts.map((agentStatusCount) => ({
          label: agentStatusCount.status,
          value: agentStatusCount.status,
        }))
      : [];

  const agentsInStatusPerCampaignColumns: ColumnData[] = useMemo(() => {
    if (state === undefined || state.length === 0) {
      return [];
    }

    // NOTE: statuses should be the same for all objects, so we grab from the very first array object if it exists
    let columns: ColumnData[] = state[0].agentStatusCounts.map((item) => ({
      label: item.status,
      dataKey: item.status,
      align: 'center',
      sortDirection: agentsInStatusPerCampaignFilter.sortOrder,
      sortActive: agentsInStatusPerCampaignFilter.sortBy === item.status,
      onColumnSort: handleColumnSort(setAgentsInStatusPerCampaignFilter, item.status),
    }));

    return [
      {
        wrapText: true,
        width: 200,
        label: 'Campaign Name',
        dataKey: 'campaignName',
        align: 'left',
        sortDirection: agentsInStatusPerCampaignFilter.sortOrder,
        sortActive: agentsInStatusPerCampaignFilter.sortBy === 'campaignName',
        onColumnSort: handleColumnSort(setAgentsInStatusPerCampaignFilter, 'campaignName'),
      },
      {
        wrapText: true,
        label: 'Total Agents',
        dataKey: 'totalAgents',
        align: 'center',
        sortDirection: agentsInStatusPerCampaignFilter.sortOrder,
        sortActive: agentsInStatusPerCampaignFilter.sortBy === 'totalAgents',
        onColumnSort: handleColumnSort(setAgentsInStatusPerCampaignFilter, 'totalAgents'),
      },
      ...columns,
    ];
  }, [state, agentsInStatusPerCampaignFilter.sortBy, agentsInStatusPerCampaignFilter.sortOrder]);

  // Should only revalidate if the dataset changes
  const agentsInStatusPerCampaignRows = useMemo(() => {
    if (state === undefined || state.length === 0) {
      return [];
    }

    const filteredByCampaign = state.filter((asteriskStats) => {
      if (agentsInStatusPerCampaignFilter.campaigns.length === 0) return true;

      return (
        agentsInStatusPerCampaignFilter.campaigns.find((item) => item.value === asteriskStats.campaignId) !== undefined
      );
    });

    return filteredByCampaign
      .map((asteriskStats) => {
        const totalAgents = asteriskStats.agentStatusCounts.reduce((state, currentVal) => state + currentVal.count, 0);
        const row = asteriskStats.agentStatusCounts.reduce(
          (state, currentVal) => ({ ...state, [currentVal.status]: currentVal.count }),
          {},
        );

        return {
          campaignId: asteriskStats.campaignId,
          campaignName: asteriskStats.campaignName,
          totalAgents: totalAgents,
          ...row,
        };
      })
      .sort(objectSearch(agentsInStatusPerCampaignFilter.sortBy, agentsInStatusPerCampaignFilter.sortOrder));
  }, [state, agentsInStatusPerCampaignFilter]);

  const metricsPerCampaignColumns: ColumnData[] = useMemo(() => {
    return [
      {
        wrapText: true,
        width: 200,
        label: 'Campaign Name',
        dataKey: 'campaignName',
        align: 'left',
        sortDirection: metricsPerCampaignFilter.sortOrder,
        sortActive: metricsPerCampaignFilter.sortBy === 'campaignName',
        onColumnSort: handleColumnSort(setMetricsPerCampaignFilter, 'campaignName'),
      },
      {
        label: 'AWT',
        dataKey: 'Average Agent Wait Time',
        align: 'center',
        titleTooltip: 'Average Wait Time',
        sortDirection: metricsPerCampaignFilter.sortOrder,
        sortActive: metricsPerCampaignFilter.sortBy === 'Average Agent Wait Time',
        onColumnSort: handleColumnSort(setMetricsPerCampaignFilter, 'Average Agent Wait Time'),
      },
      {
        label: 'AHT',
        dataKey: 'Average Handling Time',
        align: 'center',
        titleTooltip: 'Average Handling Time ',
        sortDirection: metricsPerCampaignFilter.sortOrder,
        sortActive: metricsPerCampaignFilter.sortBy === 'Average Handling Time',
        onColumnSort: handleColumnSort(setMetricsPerCampaignFilter, 'Average Handling Time'),
      },
      {
        label: 'Drop %',
        dataKey: 'Drop Rate',
        align: 'center',
        titleTooltip: 'Drop Rate',
        sortDirection: metricsPerCampaignFilter.sortOrder,
        sortActive: metricsPerCampaignFilter.sortBy === 'Drop Rate',
        onColumnSort: handleColumnSort(setMetricsPerCampaignFilter, 'Drop Rate'),
      },
      {
        label: 'Drop #',
        dataKey: 'Drop Call Count',
        align: 'center',
        titleTooltip: 'Drop Call Count',
        sortDirection: metricsPerCampaignFilter.sortOrder,
        sortActive: metricsPerCampaignFilter.sortBy === 'Drop Call Count',
        onColumnSort: handleColumnSort(setMetricsPerCampaignFilter, 'Drop Call Count'),
      },
      {
        label: 'Answered Calls',
        dataKey: 'Answered Calls',
        align: 'center',
        titleTooltip: 'Answered Calls',
        sortDirection: metricsPerCampaignFilter.sortOrder,
        sortActive: metricsPerCampaignFilter.sortBy === 'Answered Calls',
        onColumnSort: handleColumnSort(setMetricsPerCampaignFilter, 'Answered Calls'),
      },
      {
        label: 'Available Agents',
        dataKey: 'Available Agents',
        align: 'center',
        titleTooltip: 'Available Agents',
        sortDirection: metricsPerCampaignFilter.sortOrder,
        sortActive: metricsPerCampaignFilter.sortBy === 'Available Agents',
        onColumnSort: handleColumnSort(setMetricsPerCampaignFilter, 'Available Agents'),
      },
      {
        label: 'IAR',
        dataKey: 'Inv Answer Rate',
        align: 'center',
        titleTooltip: 'Inverse Answer Rate',
        sortDirection: metricsPerCampaignFilter.sortOrder,
        sortActive: metricsPerCampaignFilter.sortBy === 'Inv Answer Rate',
        onColumnSort: handleColumnSort(setMetricsPerCampaignFilter, 'Inv Answer Rate'),
      },
      {
        label: 'Line',
        dataKey: 'maxLinesPerAgent',
        align: 'center',
        titleTooltip: 'Max Lines per Agent',
        sortDirection: metricsPerCampaignFilter.sortOrder,
        sortActive: metricsPerCampaignFilter.sortBy === 'maxLinesPerAgent',
        onColumnSort: handleColumnSort(setMetricsPerCampaignFilter, 'maxLinesPerAgent'),
      },
    ];
  }, [metricsPerCampaignFilter.sortBy, metricsPerCampaignFilter.sortOrder]);

  const metricsPerCampaignRows = useMemo(() => {
    if (state === undefined || state.length === 0) {
      return [];
    }

    const filteredByCampaign = state.filter((asteriskStats) => {
      if (metricsPerCampaignFilter.campaigns.length === 0) return true;

      return metricsPerCampaignFilter.campaigns.find((item) => item.value === asteriskStats.campaignId) !== undefined;
    });

    return filteredByCampaign
      .map((asteriskStats) => {
        return {
          ...asteriskStats.vars,
          campaignId: asteriskStats.campaignId,
          campaignName: asteriskStats.campaignName,
          maxLinesPerAgent: asteriskStats.systemConfig.maxLinesPerAgent,
        };
      })
      .sort(objectSearch(metricsPerCampaignFilter.sortBy, metricsPerCampaignFilter.sortOrder));
  }, [state, metricsPerCampaignFilter]);

  const globalAgentInStatusColumns: ColumnData[] = useMemo(() => {
    return [
      {
        wrapText: false,
        label: 'ID',
        dataKey: 'id',
        align: 'left',
        copyToClipboardIcon: true,
        sortDirection: globalAgentsInStatusFilter.sortOrder,
        sortActive: globalAgentsInStatusFilter.sortBy === 'id',
        onColumnSort: handleColumnSort(setGlobalAgentsInStatusFilter, 'id'),
      },
      {
        wrapText: false,
        label: 'Username',
        dataKey: 'username',
        align: 'left',
        sortDirection: globalAgentsInStatusFilter.sortOrder,
        sortActive: globalAgentsInStatusFilter.sortBy === 'username',
        onColumnSort: handleColumnSort(setGlobalAgentsInStatusFilter, 'username'),
      },
      {
        wrapText: true,
        label: 'Campaign Name',
        dataKey: 'campaignName',
        align: 'left',
        sortDirection: globalAgentsInStatusFilter.sortOrder,
        sortActive: globalAgentsInStatusFilter.sortBy === 'campaignName',
        onColumnSort: handleColumnSort(setGlobalAgentsInStatusFilter, 'campaignName'),
      },
      {
        wrapText: true,
        label: 'Status',
        dataKey: 'status',
        align: 'left',
        sortDirection: globalAgentsInStatusFilter.sortOrder,
        sortActive: globalAgentsInStatusFilter.sortBy === 'status',
        onColumnSort: handleColumnSort(setGlobalAgentsInStatusFilter, 'status'),
      },
      {
        wrapText: false,
        label: 'Time In Status',
        dataKey: 'timeInStatus',
        align: 'left',
        sortDirection: globalAgentsInStatusFilter.sortOrder,
        sortActive: globalAgentsInStatusFilter.sortBy === 'timeInStatus',
        onColumnSort: handleColumnSort(setGlobalAgentsInStatusFilter, 'timeInStatus'),
      },
    ];
  }, [globalAgentsInStatusFilter.sortBy, globalAgentsInStatusFilter.sortOrder]);

  const globalAgentInStatusRows = useMemo(() => {
    if (state === undefined || state.length === 0) {
      return [];
    }

    const filteredByCampaign = state.filter((asteriskStats) => {
      if (globalAgentsInStatusFilter.campaigns.length === 0) return true;

      return globalAgentsInStatusFilter.campaigns.find((item) => item.value === asteriskStats.campaignId) !== undefined;
    });

    // TODO: can we improve performance of this reduce to avoid O(n2)
    return filteredByCampaign
      .reduce((state, asteriskStats) => {
        const agents = asteriskStats.agents
          // Filter out agents whose statuses do not exist in the status filter list
          .filter((agent) => {
            if (globalAgentsInStatusFilter.statuses === undefined || globalAgentsInStatusFilter.statuses.length === 0) {
              return true;
            }

            return globalAgentsInStatusFilter.statuses.find((item) => item.value === agent.status) !== undefined;
          })
          .map((agent) => ({
            campaignId: asteriskStats.campaignId,
            campaignName: asteriskStats.campaignName,
            key: agent.key,
            username: agent.username,
            status: agent.status,
            timeInStatus: agent.timeInStatus,
          }));

        return [...state, ...agents];
      }, [] as Array<any>)
      .sort(objectSearch(globalAgentsInStatusFilter.sortBy, globalAgentsInStatusFilter.sortOrder));
  }, [state, globalAgentsInStatusFilter]);

  return (
    <AsyncLoader isLoading={initialLoad}>
      {!socketConnected && (
        <EmptyState
          type='error'
          text='Not Connected'
          subText={`Unable to connect to server: ${predictiveConfig.diallerURL}/stats-ws`}
        />
      )}

      {socketConnected && state.length === 0 && (
        <EmptyState type='no-items-2' text='No active predictive campaigns currently exist' />
      )}

      {socketConnected && state.length > 0 && (
        <>
          <ContentSpacer spacing={3}>
            <Typography fontWeight={500} marginBottom={2} variant='h5' component='h1'>
              Agents In Status Per Campaign
            </Typography>

            {state.length === 0 && (
              <Typography variant='body1' align='left'>
                <i>No agents connected to system.</i>
              </Typography>
            )}

            {state.length > 0 && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Autocomplete<ListItem, true, undefined, undefined>
                    multiple
                    fullWidth
                    onChange={onFilterChange('campaigns', setAgentsInStatusPerCampaignFilter)}
                    value={agentsInStatusPerCampaignFilter.campaigns}
                    options={campaignsList}
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          deleteIcon={<CancelIcon />}
                          label={option.label}
                          {...getTagProps({ index })}
                          variant='filled'
                          color='primary'
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} id='campaigns' name='campaigns' label='Campaigns' variant='outlined' />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <BasicTable
                    height={400}
                    columns={agentsInStatusPerCampaignColumns}
                    rows={agentsInStatusPerCampaignRows}
                    onRowClick={onTableRowClick}
                  />
                </Grid>
              </Grid>
            )}
          </ContentSpacer>

          <ContentSpacer spacing={3}>
            <Typography fontWeight={500} marginBottom={2} variant='h5' component='h1'>
              Metrics Per Campaign
            </Typography>

            {state.length === 0 && (
              <Typography variant='body1' align='left'>
                <i>No active campaigns to show metrics for.</i>
              </Typography>
            )}

            {state.length > 0 && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Autocomplete<ListItem, true, undefined, undefined>
                      multiple
                      fullWidth
                      onChange={onFilterChange('campaigns', setMetricsPerCampaignFilter)}
                      value={metricsPerCampaignFilter.campaigns}
                      options={campaignsList}
                      filterSelectedOptions
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            deleteIcon={<CancelIcon />}
                            label={option.label}
                            {...getTagProps({ index })}
                            variant='filled'
                            color='primary'
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} id='campaigns' name='campaigns' label='Campaigns' variant='outlined' />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <BasicTable
                      height={400}
                      columns={metricsPerCampaignColumns}
                      rows={metricsPerCampaignRows}
                      onRowClick={onTableRowClick}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </ContentSpacer>

          <ContentSpacer spacing={3}>
            <Typography fontWeight={500} marginBottom={2} variant='h5' component='h1'>
              Global Agents In Status
            </Typography>

            {state.length === 0 && (
              <Typography variant='body1' align='left'>
                <i>No agents connected to system.</i>
              </Typography>
            )}

            {state.length > 0 && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Autocomplete<ListItem, true, undefined, undefined>
                    multiple
                    fullWidth
                    onChange={onFilterChange('campaigns', setGlobalAgentsInStatusFilter)}
                    value={globalAgentsInStatusFilter.campaigns}
                    options={campaignsList}
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          deleteIcon={<CancelIcon />}
                          label={option.label}
                          {...getTagProps({ index })}
                          variant='filled'
                          color='primary'
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} id='campaigns' name='campaigns' label='Campaigns' variant='outlined' />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Autocomplete<ListItem, true, undefined, undefined>
                    multiple
                    fullWidth
                    onChange={onFilterChange('statuses', setGlobalAgentsInStatusFilter)}
                    value={globalAgentsInStatusFilter.statuses || []}
                    options={statusList}
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          deleteIcon={<CancelIcon />}
                          label={option.label}
                          {...getTagProps({ index })}
                          variant='filled'
                          color='primary'
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} id='statuses' name='statuses' label='Statuses' variant='outlined' />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <BasicTable
                    height={400}
                    columns={globalAgentInStatusColumns}
                    rows={globalAgentInStatusRows}
                    onRowClick={onTableRowClick}
                    noResultsMessage='No agents data available.'
                  />
                </Grid>
              </Grid>
            )}
          </ContentSpacer>
        </>
      )}
    </AsyncLoader>
  );
};

export default CampaignsDashboard;

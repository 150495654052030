import genericAdditionalFields from '~organisations/alinta/additionalOutcomeCaptureFields/generic';
import { Organisation } from '~organisations/domain';

import logo from './images/logo.png';

const alintaConfig: Organisation = {
  logo: logo,
  additionalOutcomeCaptureFields: {
    '4': genericAdditionalFields,
    '10': genericAdditionalFields,
    '11': genericAdditionalFields,
  },
  asyncAdditionalOutcomeCaptureFields: {},
  contactAssessmentForms: {},
};

export default alintaConfig;

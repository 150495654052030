import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import OberonDialog from '~components/OberonDialog';

import { CampaignFilter } from '../../../domain';

interface CreateEditFilterModalProps {
  open: boolean;
  submitting: boolean;
  filter?: CampaignFilter;
  onAccept: (data: CampaignFilter) => void;
  onClose: () => void;
}

interface Form {
  name: string;
  field: string;
  match: string;
}

const CreateEditFilterModal = ({ open, submitting, filter, onAccept, onClose }: CreateEditFilterModalProps) => {
  const isLoading = submitting;
  const isEdit = Boolean(filter);
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
  } = useForm<Form>({
    defaultValues: {
      name: '',
      field: '',
      match: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  useEffect(() => {
    if (open && filter) {
      setValue('name', filter.name);
      setValue('field', filter.field);
      setValue('match', filter.match);
    }

    return () => {
      reset();
    };
  }, [filter]);

  const onSubmit = handleSubmit(async (data: Form) => {
    try {
      await onAccept({
        name: data.name,
        field: data.field,
        match: data.match,
      });

      reset();
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
    }
  });

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : 'Create'} Filter`}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='name'
              control={control}
              rules={{ required: 'Filter name is required.' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant='outlined'
                  label='Filter Name'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='field'
              control={control}
              rules={{ required: 'Filter field is required.' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant='outlined'
                  label='Filter Field'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.field)}
                  helperText={errors.field?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='match'
              control={control}
              rules={{ required: 'Filter field is required.' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant='outlined'
                  label='Filter Match'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.match)}
                  helperText={errors.match?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={submitting} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={submitting}
            loading={submitting}>
            {isEdit ? 'Update' : 'Create'}
          </LoadingButton>
        </>
      }
    />
  );
};

export default CreateEditFilterModal;

import * as j from '@mojotech/json-type-validation';

export enum UserNotificationType {
  Init = 'init',
  New = 'new',
  Acknowledged = 'acknowledged',
}

export interface ServerMessage {
  type?: UserNotificationType.Init | UserNotificationType.New | UserNotificationType.Acknowledged;
}

export interface UserNotification {
  id: number;
  message: string;
  createdAt: string;
  requestAcknowledge: boolean;
  acknowledgedAt: string | null;
  acknowledgedBy: string | null;
}

export interface UserNotificationInitMessage {
  type: UserNotificationType.Init;
  notifications: UserNotification[];
}

export interface UserNotificationMessage {
  type: UserNotificationType.New | UserNotificationType.Acknowledged;
  notification: UserNotification;
}

export const ServerMessageDecoder: j.Decoder<ServerMessage> = j.union(
  j.object({
    type: j.optional(
      j.oneOf(
        j.constant(UserNotificationType.Init),
        j.constant(UserNotificationType.New),
        j.constant(UserNotificationType.Acknowledged),
      ),
    ),
  }),
  j.object({}),
);

export const UserNotificationInitMessageDecoder: j.Decoder<UserNotificationInitMessage> = j.object({
  type: j.constant(UserNotificationType.Init),
  notifications: j.array(
    j
      .object({
        notification_id: j.number(),
        message: j.string(),
        created_at: j.string(),
        request_acknowledge: j.boolean(),
        acknowledged_at: j.union(j.string(), j.constant(null)),
        acknowledged_by: j.union(j.string(), j.constant(null)),
      })
      .map((i) => ({
        id: i.notification_id,
        message: i.message,
        createdAt: i.created_at,
        requestAcknowledge: i.request_acknowledge,
        acknowledgedAt: (i.acknowledged_at as string) || null,
        acknowledgedBy: (i.acknowledged_by as string) ?? null,
      })),
  ),
});

export const UserNotificationMessageDecoder: j.Decoder<UserNotificationMessage> = j.object({
  type: j.union(j.constant(UserNotificationType.New), j.constant(UserNotificationType.Acknowledged)),
  notification: j
    .object({
      notification_id: j.number(),
      message: j.string(),
      created_at: j.string(),
      request_acknowledge: j.boolean(),
      acknowledged_at: j.union(j.string(), j.constant(null)),
      acknowledged_by: j.union(j.string(), j.constant(null)),
    })
    .map((i) => ({
      id: i.notification_id,
      message: i.message,
      createdAt: i.created_at,
      requestAcknowledge: i.request_acknowledge,
      acknowledgedAt: (i.acknowledged_at as string) || null,
      acknowledgedBy: (i.acknowledged_by as string) ?? null,
    })),
});

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent, useEffect, useState } from 'react';

import AsyncLoader from '~components/AsyncLoader';
import EmptyState from '~components/EmptyState';
import Selectbox from '~components/Form/Selectbox';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useNotification } from '~providers/NotificationProvider';
import { useSetPageTitleProps } from '~providers/PageTitleProvider';
import { PolicyType, isAllowedRole, useUserProfile } from '~providers/UserProfileProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { createCampaign, getCampaigns, triggerDiallerCampaignReload } from '../api';
import { CampaignListItem, CreateCampaign, DiallerType } from '../domain';
import CampaignCard from './CampaignCard';
import CreateCampaignModal from './CreateCampaignModal';

const enum FilterType {
  Empty = '',
  Active = 'active',
  Archived = 'archived',
}

interface Query {
  search: string;
  filter: FilterType;
}

interface Error {
  text: string;
  subText: string;
}

const filterList = [
  {
    label: 'None',
    value: FilterType.Empty,
  },
  {
    label: 'Active',
    value: FilterType.Active,
  },
  {
    label: 'Archived',
    value: FilterType.Archived,
  },
];

const CampaignList = () => {
  const { policies } = useUserProfile();
  const canCreateCampaign = isAllowedRole([PolicyType.DiallerAdmin], policies);

  const appConfig = useAppConfiguration();
  const { pushNotification } = useNotification();
  const setPageTitleProps = useSetPageTitleProps();
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [createModalSubmitting, setCreateModalSubmitting] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<CampaignListItem[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [query, setQuery] = useState<Query>({
    search: '',
    filter: FilterType.Active,
  });

  const displayList = campaigns
    // Filter by filter value
    .filter((item: CampaignListItem) => {
      // Return everything if filter empty
      if (query.filter === FilterType.Empty) return true;
      if (query.filter === FilterType.Archived && item.archived) return true;
      if (query.filter === FilterType.Active && !item.archived) return true;
      return false;
    })
    // Search Fuzzy matching
    .filter((item: CampaignListItem) => {
      if (query.search) {
        return item.name.toLowerCase().includes(query.search.toLowerCase());
      }

      return true;
    })
    // Component generation
    .map((item: CampaignListItem) => <CampaignCard key={item.id} {...item} />);

  // If any filter property is set and displayList is empty we assume no relevant search results
  const noSearchOrFilterResults = (query.search || query.filter !== FilterType.Empty) && displayList.length === 0;
  const noCampaigns = campaigns.length === 0;

  // Set page title
  useEffect(() => {
    setPageTitleProps({ pageName: 'Campaigns' });
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setCampaigns(await getCampaigns());
      } catch (e) {
        handleError(e);
      } finally {
        setPageLoaded(true);
      }
    })();
  }, []);

  const handleError = (e: any) => {
    if (e instanceof APIError) {
      setError({ text: 'Unable to request data from backend', subText: e.message });
    }

    if (e instanceof UnsupportedStructureError) {
      setError({ text: 'Data from backend Invalid', subText: 'Unable to decode response' });
    }
  };

  const toggleCreateCampaignModal = () => {
    setCreateModalOpen((prev) => !prev);
  };

  const onQueryChange = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value }));
  };

  const addCampaign = async (data: CreateCampaign) => {
    setCreateModalSubmitting(true);

    try {
      await createCampaign(data);

      if (appConfig.extensions.predictive !== undefined && data.diallerType === DiallerType.SIP) {
        // ask the predictive dialler to reload its copy of the campaigns after this was successfully created,
        // if the predictive extension is enabled.
        await triggerDiallerCampaignReload(appConfig.extensions.predictive.diallerURL);
      }

      pushNotification('success', `Created campaign ${data.name}`);
      setCreateModalOpen(false);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setCreateModalSubmitting(false);
    }

    try {
      setCampaigns(await getCampaigns());
    } catch (e) {
      handleError(e);
    }
  };

  const errorDisplay = error ? <EmptyState type='error' text={error.text} subText={error.subText} /> : null;

  return (
    <AsyncLoader isLoading={!pageLoaded} error={errorDisplay}>
      {noCampaigns && (
        <EmptyState
          type='no-items-1'
          text='No campaigns currently exist'
          subText='Create a campaign by clicking the button below'
          action={canCreateCampaign ? toggleCreateCampaignModal : undefined}
          actionText='Create Campaign'
        />
      )}

      {!noCampaigns && (
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              variant='outlined'
              label='Search'
              id='search'
              name='search'
              defaultValue={query.search}
              onChange={onQueryChange}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Selectbox
              id='filter'
              name='filter'
              title='Filter'
              items={filterList}
              onChange={onQueryChange}
              value={query.filter}
            />
          </Grid>

          <Hidden smDown>
            <Grid item md={3}></Grid>
          </Hidden>

          {canCreateCampaign && (
            <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
              <Button
                variant='contained'
                color='primary'
                disableElevation
                fullWidth
                startIcon={<AddIcon />}
                onClick={toggleCreateCampaignModal}>
                Create Campaign
              </Button>
            </Grid>
          )}

          <Grid item xs={12}>
            {!noSearchOrFilterResults && <List>{displayList}</List>}
            {noSearchOrFilterResults && (
              <EmptyState
                type='no-records-found'
                text='No campaigns found matching your search criteria'
                subText='Try alternate words or selections.'
              />
            )}
          </Grid>
        </Grid>
      )}

      <CreateCampaignModal
        open={createModalOpen}
        submitting={createModalSubmitting}
        onAccept={addCampaign}
        onClose={toggleCreateCampaignModal}
      />
    </AsyncLoader>
  );
};

export default CampaignList;

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useState } from 'react';
import { useEffect } from 'react';
import useMeasure from 'react-use-measure';

import { ConferenceTransferItem } from '~components/Transfers/domain';
import { Contact } from '~providers/ConnectProvider/domain';
import { useLogRocket } from '~providers/LogRocketProvider';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { getConferenceTransferList } from '../api';
import ConferenceTransferListItem from './ConferenceTransferListItem';

interface ConferenceTransferProps {
  contact?: Contact;
  maxConnectionCapacityReached: boolean;
  onCloseTransferModal: () => void;
}

const ConferenceTransfer = ({
  contact,
  maxConnectionCapacityReached,
  onCloseTransferModal,
}: ConferenceTransferProps): JSX.Element => {
  const logRocket = useLogRocket();
  const { pushNotification } = useNotification();
  const [searchText, setSearchText] = useState<string>('');
  const [conferenceTransferList, setConferenceTransferList] = useState<ConferenceTransferItem[]>([]);
  const [isTransferring, setIsTransferring] = useState<boolean>(false);
  const [boxRef, { height: boxHeight }] = useMeasure();

  // Resets all properties to default values
  const defaultReset = () => {
    setIsTransferring(false);
    setSearchText('');
  };

  useEffect(() => {
    (async () => {
      try {
        const list: ConferenceTransferItem[] = await getConferenceTransferList();
        setConferenceTransferList(list);
        console.log('fetched from getConferenceTransferList');
      } catch (e) {
        console.error('! error fetching conference transfer list ', e);
      }
    })();
  }, []);

  const triggerConferenceTransfer = async (conferenceName: string, quickConnectARN: string) => {
    if (contact === undefined) {
      console.error('onHandleConferenceTransfer: Contact does not exist');
      return;
    }

    if (maxConnectionCapacityReached) {
      console.error('Conference at capacity');
      pushNotification('error', 'Conference at capacity');
      return;
    }

    if (contact.conferenceTransfer === undefined) {
      console.error('onHandleConferenceTransfer: contact.conferenceTransfer does not exist');
      return;
    }

    try {
      await contact.conferenceTransfer(quickConnectARN);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return;
    }
    logRocket.trackEvent('transfer');
    logRocket.trackEvent('conference_transfer');

    onCloseTransferModal();
    defaultReset();
  };

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const searchTerm = searchText.split(/\s+/).filter((term) => term.length > 0);
  const filteredList = conferenceTransferList.filter((item): boolean => {
    // all search terms must match
    return searchTerm.every((term) => item.name.toLowerCase().includes(term.toLowerCase()));
  });

  const listItems = filteredList.map((item, index) => (
    <ConferenceTransferListItem
      key={item.name}
      name={item.name}
      disabled={isTransferring}
      divider={index !== filteredList.length - 1}
      onConference={() => triggerConferenceTransfer(item.name, item.quickConnectARN)}
    />
  ));

  return (
    <>
      <Box ref={boxRef}>
        <Grid sx={{ padding: 1 }} container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant='outlined'
              id='search'
              name='search'
              label='Search'
              onChange={onSearchChange}
            />
          </Grid>
        </Grid>

        <Divider component='hr' />
      </Box>

      <div style={{ height: `calc(100% - ${boxHeight}px)`, overflow: 'auto' }}>
        {listItems.length === 0 && (
          <Typography marginTop={1} variant='body1' component='p' align='center'>
            {searchTerm.length === 0
              ? 'There is currently no conference queue to transfer to'
              : 'No search results found.'}
          </Typography>
        )}

        {listItems.length > 0 && <List sx={{ padding: 0 }}>{listItems}</List>}
      </div>
    </>
  );
};

export default ConferenceTransfer;

import SendIcon from '@mui/icons-material/Send';
import { ButtonBase } from '@mui/material';
import blue from '@mui/material/colors/blue';
import red from '@mui/material/colors/red';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { SvgIconProps } from '@mui/material/SvgIcon';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/system/Box';
import React, {
  ChangeEvent,
  ComponentClass,
  Fragment,
  FunctionComponent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useRef,
} from 'react';

interface Toolbar {
  icon: ComponentClass<SvgIconProps> | FunctionComponent<SvgIconProps>;
  tooltip: string;
  action: () => void;
}

interface SendMessageProps {
  toolbar: Toolbar[];
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  sendMessage: (messageContent: string) => void;
}

// TextareaAutosize does not support sx system prop so we have to use a styled component so we can use
// pseudo class styling
const CustomizedTextarea = styled(TextareaAutosize)({
  'boxSizing': 'border-box',
  'width': '100%',
  'padding': '16px 66px 16px 16px',
  'border': 'none',
  'fontSize': 15,
  'fontWeight': 400,
  'fontFamily': '"Roboto","Helvetica","Arial",sans-serif',
  'color': '#292929',
  'outline': 'none',
  'resize': 'none',

  ':disabled': {
    opacity: 0.6,
    border: '1px solid #e6e6e6',
    background: '#ffffff',
  },
});

const MAX_MESSAGE_LENGTH = 2048;

const isKeypressEvent = (
  event: KeyboardEvent<HTMLTextAreaElement> | MouseEvent<HTMLButtonElement>,
): event is KeyboardEvent<HTMLTextAreaElement> => {
  return (event as KeyboardEvent).type === 'keypress';
};

const isMouseEvent = (
  event: KeyboardEvent<HTMLTextAreaElement> | MouseEvent<HTMLButtonElement>,
): event is MouseEvent<HTMLButtonElement> => {
  return (event as MouseEvent).type === 'click';
};

const MessageTextarea = ({ toolbar, value, onChange, sendMessage }: SendMessageProps) => {
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const isMessageTooLong = value.length > MAX_MESSAGE_LENGTH;
  const templateVariablePresent = value.includes('{{') && value.includes('}}');
  const disableSend = value.trim().length === 0 || templateVariablePresent || isMessageTooLong;

  const onSendMessage = (e: KeyboardEvent<HTMLTextAreaElement> | MouseEvent<HTMLButtonElement>) => {
    const msg = value.trim();

    if (msg.length === 0) {
      if (isKeypressEvent(e) && e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
      }
      return;
    }

    if ((isKeypressEvent(e) && e.key === 'Enter' && !e.shiftKey) || isMouseEvent(e)) {
      if (isKeypressEvent(e) && e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
      }

      sendMessage(msg);

      if (inputRef.current !== null) {
        inputRef.current.focus();
      }
    }
  };

  // Focus input on component mount
  useEffect(() => {
    if (inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <>
      {isMessageTooLong && (
        <span
          style={{
            borderRadius: '5px 5px 0 0',
            color: red[500],
            background: red[50],
            textAlign: 'center',
            padding: 8,
            fontSize: 14,
          }}>
          The message exceeds the {MAX_MESSAGE_LENGTH.toLocaleString()} character limit
        </span>
      )}

      <Divider />
      <Box sx={{ display: 'flex' }}>
        {toolbar.map((item, index) => {
          const Icon = item.icon;
          return (
            <Fragment key={index}>
              <Tooltip title={item.tooltip} arrow placement='bottom-start'>
                <IconButton
                  sx={{
                    'display': 'block',
                    'padding': '7px',
                    'borderRadius': 0,
                    '&.Mui-focusVisible': {
                      borderRadius: 'inherit',
                    },
                    '& .MuiTouchRipple-root .MuiTouchRipple-child': {
                      borderRadius: 'inherit',
                    },
                  }}
                  onClick={item.action}>
                  <Icon sx={{ display: 'block' }} />
                </IconButton>
              </Tooltip>
              {(index !== toolbar.length - 1 || index === 0) && <Divider orientation='vertical' />}
            </Fragment>
          );
        })}
      </Box>
      <Divider />

      <Box
        sx={{
          width: '100%',
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <CustomizedTextarea
          id='message'
          name='message'
          placeholder='message...'
          spellCheck={true}
          maxRows={4}
          minRows={4}
          ref={inputRef}
          value={value}
          onChange={onChange}
          onKeyPress={onSendMessage}
        />

        <Box sx={{ position: 'absolute', right: 8 }}>
          <ButtonBase
            sx={{
              'width': 50,
              'height': 50,
              'borderRadius': '50px',
              'border': 'none',
              'backgroundColor': blue[500],
              'transition': 'all .15s linear',
              'display': 'flex',
              'justifyContent': 'center',
              'alignItems': 'center',
              'userSelect': 'none',
              'cursor': 'pointer',
              'opacity': 1,
              '&:disabled': {
                cursor: 'not-allowed',
                opacity: 0.5,
                backgroundColor: 'grey',
                pointerEvents: 'auto',
              },
            }}
            disabled={disableSend}
            aria-label='send message'
            onClick={onSendMessage}>
            <SendIcon sx={{ marginLeft: '3px', color: '#ffffff' }} />
          </ButtonBase>
        </Box>
      </Box>
    </>
  );
};

export default MessageTextarea;

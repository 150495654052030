import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';

import { OutcomesFormProps } from '~components/OutcomeCapture';
import { ValidatorType } from '~hooks/useForm';

const OutcomesForm = ({ form, formSubmitting }: OutcomesFormProps) => {
  useEffect(() => {
    form.addSchemaProperties({
      opportunity_id: {
        value: '',
        validators: [
          {
            type: ValidatorType.OptionalRequireIf,
            fieldName: 'lead_id',
            message: 'Opportunity ID is required',
          },
          {
            type: ValidatorType.ErrorIfSetWith,
            fieldName: 'lead_id',
            message: 'Cannot be set when Lead ID is set',
          },
        ],
      },
      lead_id: {
        value: '',
        validators: [
          {
            type: ValidatorType.OptionalRequireIf,
            fieldName: 'opportunity_id',
            message: 'Lead ID is required',
          },
          {
            type: ValidatorType.ErrorIfSetWith,
            fieldName: 'opportunity_id',
            message: 'Cannot be set when Opportunity ID is set',
          },
        ],
      },
    });

    return () => {
      form.removeSchemaProperties(['opportunity_id', 'lead_id']);
    };
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant='outlined'
          id='opportunity_id'
          name='opportunity_id'
          label='Opportunity ID'
          disabled={formSubmitting}
          value={form.fields?.opportunity_id?.value || ''}
          error={Boolean(form.errors.opportunity_id)}
          helperText={form.errors.opportunity_id && form.errors.opportunity_id[0]!}
          onChange={form.handleInputChange}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          variant='outlined'
          id='lead_id'
          name='lead_id'
          label='Lead ID'
          disabled={formSubmitting}
          value={form.fields?.lead_id?.value || ''}
          error={Boolean(form.errors.lead_id)}
          helperText={form.errors.lead_id && form.errors.lead_id[0]!}
          onChange={form.handleInputChange}
        />
      </Grid>
    </>
  );
};

export default OutcomesForm;

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import blue from '@mui/material/colors/blue';
import deepPurple from '@mui/material/colors/deepPurple';
import teal from '@mui/material/colors/teal';
import yellow from '@mui/material/colors/yellow';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { ReactNode } from 'react';

import ContentSpacer from '~components/ContentSpacer';
import ConversationTranscript from '~components/ConversationTranscript';
import { DataItem } from '~components/DataItem';
import { Attempt, AttemptCreationContext } from '~providers/AttemptProvider/domain';
import { Contact, ContactAttribute, ContactAttributeType } from '~providers/ConnectProvider/domain';
import { flattenObject, generatePathNoErrorThrow, getDurationFromSeconds } from '~utils/Functions';

import CallCategories from './CallCategories';
import ContactContext from './ContactContext';

interface AttemptDetailsProps {
  attempt: Attempt;
  contact?: Contact;
  contactPopLinks: ContactAttribute[];
  previewTimeSeconds?: number;
  disableContactContext?: boolean;
  outcomeSection: ReactNode;
}

interface AttemptStatus {
  backgroundColor: string;
  label: string;
}

const attemptTypeData: { [key in AttemptCreationContext]: AttemptStatus } = {
  [AttemptCreationContext.Standard]: {
    backgroundColor: teal['500'],
    label: 'Outbound',
  },
  [AttemptCreationContext.ManualPrepared]: {
    backgroundColor: teal['500'],
    label: 'Manual Outbound',
  },
  [AttemptCreationContext.ReceivedTransfer]: {
    backgroundColor: deepPurple['500'],
    label: 'Transfer',
  },
  [AttemptCreationContext.Callback]: {
    backgroundColor: blue['500'],
    label: 'Callback',
  },
  [AttemptCreationContext.Inbound]: {
    backgroundColor: yellow['500'],
    label: 'Inbound',
  },
  [AttemptCreationContext.UnknownInbound]: {
    backgroundColor: yellow['500'],
    label: 'Inbound',
  },
  [AttemptCreationContext.ManagedInboundQueue]: {
    backgroundColor: yellow['500'],
    label: 'Inbound',
  },
};

const AttemptDetails = ({
  attempt,
  contact,
  contactPopLinks,
  previewTimeSeconds,
  disableContactContext,
  outcomeSection,
}: AttemptDetailsProps) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { conversation_uuid: conversationUUID, ...attemptAttributes } =
    (attempt.lead !== null && attempt.lead.attributes) || {};
  let dynamicContactAttributes = contact !== undefined ? contact.attributes : [];
  dynamicContactAttributes = [...dynamicContactAttributes, ...contactPopLinks];
  const leadName = attempt.lead !== null ? attempt.lead.name : 'Unknown Inbound Caller';
  const endpoint =
    attempt.lead !== null ? attempt.lead.endpoint : contact !== undefined ? contact.phoneNumber : 'anonymous';
  const endpointAttributes =
    (attempt.lead !== null &&
      attempt.lead.endpointAttributes &&
      attempt.lead.endpointAttributes[endpoint] &&
      attempt.lead.endpointAttributes[endpoint].attributes) ||
    {};
  const chipData = attempt.creationContext !== undefined ? attemptTypeData[attempt.creationContext] : undefined;
  const hasRealtimeAnalytics = contact !== undefined ? contact.hasRealtimeAnalytics : false;

  const additionalAttemptAttributesDisplay = Object.keys(attemptAttributes).map((key: string, index: number) => (
    <DataItem stacked={isExtraSmall} key={index} title={key} value={attemptAttributes[key]} />
  ));

  const additionalEndpointAttributesDisplay = Object.keys(endpointAttributes).map((key: string, index: number) => (
    <DataItem stacked={isExtraSmall} key={index} title={key} value={endpointAttributes[key]} />
  ));

  // TODO: Could benefit from from something similar to toml config like additional_contact_detail_attributes
  const dynamicContactAttributesDisplay = dynamicContactAttributes.map((item: ContactAttribute, index: number) => {
    let value: string | ReactNode;

    switch (item.type) {
      case ContactAttributeType.Img: {
        value = (
          <div
            style={{
              height: 60,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              overflow: 'hidden',
            }}>
            <img style={{ objectFit: 'cover', height: '100%' }} src={item.value} alt={item.value} />
          </div>
        );
        break;
      }
      case ContactAttributeType.Link: {
        // Updated url params with value if found within the flattened attempt
        const url = generatePathNoErrorThrow(item.value, flattenObject(attempt));

        value = (
          <Link underline='hover' href={url} target='_blank'>
            {url}
          </Link>
        );
        break;
      }
      case ContactAttributeType.Text:
      default: {
        value = item.value;
        break;
      }
    }

    return <DataItem stacked={isExtraSmall} key={index} title={item.key} value={value} />;
  });

  return (
    <Card elevation={3}>
      <CardHeader
        sx={{
          '& .MuiCardHeader-title': {
            fontSize: 20,
            fontWeight: theme.typography.fontWeightBold,
            lineHeight: 1.2,
          },
        }}
        title={leadName}
        subheader={
          chipData && (
            <Chip
              sx={{
                textTransform: 'uppercase',
                marginTop: 0.5,
                paddingTop: 0,
                paddingBottom: 0,
                color: theme.palette.getContrastText(chipData.backgroundColor),
                fontSize: 10,
                fontWeight: theme.typography.fontWeightBold,
                height: 20,
                lineHeight: '21px',
                backgroundColor: chipData.backgroundColor,
              }}
              label={chipData.label}
              color='primary'
            />
          )
        }
      />

      <Divider />

      <CardContent>
        <ContentSpacer spacing={2}>
          {attempt.lead !== null && (
            <DataItem
              stacked={isExtraSmall}
              disableMargin
              title='Time until auto dial'
              // since a zero value equals no preview time we only check for values greater than zero here
              value={
                previewTimeSeconds !== undefined && previewTimeSeconds > 0
                  ? getDurationFromSeconds(previewTimeSeconds)
                  : 'N/A'
              }
            />
          )}

          <DataItem stacked={isExtraSmall} title='Endpoint' value={endpoint} />

          {attempt.lead !== null && (
            <>
              <DataItem stacked={isExtraSmall} title='Lead ID' value={attempt.lead.id} />
              <DataItem stacked={isExtraSmall} title='External ID' value={attempt.lead.externalId} />
              <DataItem stacked={isExtraSmall} title='Timezone' value={attempt.lead.timezone} />
              <DataItem
                stacked={isExtraSmall}
                title='Required Skills'
                value={attempt.lead.requiredSkills.length > 0 ? attempt.lead.requiredSkills : 'N/A'}
              />
              {attempt.lead.callbackNotes && (
                <DataItem stacked={isExtraSmall} title='Callback Notes' value={attempt.lead.callbackNotes} />
              )}
            </>
          )}

          {additionalAttemptAttributesDisplay}
          {additionalEndpointAttributesDisplay}
          {dynamicContactAttributesDisplay}
        </ContentSpacer>

        {!disableContactContext && <ContactContext endpoint={endpoint} />}

        {conversationUUID && (
          <ContentSpacer spacing={2}>
            <Typography fontWeight={theme.typography.fontWeightBold} variant='h6' gutterBottom>
              Conversation Transcript
            </Typography>

            <Box
              sx={{
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: 2,
                boxShadow: '0 2px 4px inset rgb(0, 0, 0, .13)',
              }}>
              <ConversationTranscript conversationUUID={conversationUUID} />
            </Box>
          </ContentSpacer>
        )}

        {hasRealtimeAnalytics && <CallCategories campaignId={contact?.campaignId} contactId={contact?.contactId} />}

        <ContentSpacer spacing={2}>
          <Typography fontWeight={theme.typography.fontWeightBold} variant='h6' gutterBottom>
            Call Outcome
          </Typography>
          {outcomeSection}
        </ContentSpacer>
      </CardContent>
    </Card>
  );
};

export default AttemptDetails;

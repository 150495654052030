import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import EmptyState from '~components/EmptyState';
import useDebounce from '~hooks/useDebounce';
import { removeLeadById } from '~pages/CampaignManagement/api';
import useLeadSearch from '~pages/CampaignManagement/LeadListDetails/useLeadSearch';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';
import { findIndexByProperty } from '~utils/Functions';

import { Lead, LeadStatusType } from '../../domain';
import LeadCard from './LeadCard';

interface Query {
  search: string;
  filter: LeadStatusType | '';
}

const filterList = [
  {
    label: 'None',
    value: '',
  },
  {
    label: 'Contacted',
    value: LeadStatusType.Contacted,
  },
  {
    label: 'Washed',
    value: LeadStatusType.Washed,
  },
  {
    label: 'Filtered',
    value: LeadStatusType.Filtered,
  },
  {
    label: 'Opt-out',
    value: LeadStatusType.OptOut,
  },
  {
    label: 'No Endpoints',
    value: LeadStatusType.NoEndpoints,
  },
  {
    label: 'Excluded',
    value: LeadStatusType.Excluded,
  },
  {
    label: 'Duplicate',
    value: LeadStatusType.Duplicate,
  },
  {
    label: 'Building',
    value: LeadStatusType.Building,
  },
  {
    label: 'Awaiting Retry',
    value: LeadStatusType.AwaitingRetry,
  },
  {
    label: 'Awaiting Start',
    value: LeadStatusType.AwaitingStart,
  },
  {
    label: 'Ready',
    value: LeadStatusType.Ready,
  },
  {
    label: 'Out of Hours',
    value: LeadStatusType.OutOfHours,
  },
  {
    label: 'Finished',
    value: LeadStatusType.Finished,
  },
  {
    label: 'Finished Today',
    value: LeadStatusType.FinishedToday,
  },
  {
    label: 'Callback',
    value: LeadStatusType.Callback,
  },
  {
    label: 'No Skilled Agents',
    value: LeadStatusType.NoSkilledAgents,
  },
  {
    label: 'Assigned',
    value: LeadStatusType.Assigned,
  },
  {
    label: 'Initiated',
    value: LeadStatusType.Initiated,
  },
  {
    label: 'Connected',
    value: LeadStatusType.Connected,
  },
  {
    label: 'Invalid Endpoint',
    value: LeadStatusType.InvalidEndpoint,
  },
  {
    label: 'Disconnected',
    value: LeadStatusType.Disconnected,
  },
  {
    label: 'Removed',
    value: LeadStatusType.Removed,
  },
  {
    label: 'Expired',
    value: LeadStatusType.Expired,
  },
  {
    label: 'Awaiting SMS',
    value: LeadStatusType.AwaitingSMS,
  },
  {
    label: 'List Inactive',
    value: LeadStatusType.InactiveList,
  },
  {
    label: 'Missed Callback',
    value: LeadStatusType.MissedCallback,
  },
  {
    label: 'In Queue',
    value: LeadStatusType.InQueue,
  },
  {
    label: 'Awaiting Callback',
    value: LeadStatusType.AwaitingCallback,
  },
  {
    label: 'Replaced',
    value: LeadStatusType.Replaced,
  },
];

const LeadListLeads = () => {
  const { pushNotification } = useNotification();
  const { campaignId, listId } = useParams() as { campaignId: string; listId: string };
  const [query, setQuery] = useState<Query>({
    search: '',
    filter: '',
  });
  const debouncedSearch = useDebounce(query.search, 500);
  const { loading, error, leads, hasMore, getNextPage } = useLeadSearch(
    +campaignId,
    +listId,
    debouncedSearch,
    query.filter,
  );
  const observer = useRef<IntersectionObserver | undefined>(undefined);
  const downloadLink = useMemo(() => `/api/campaign/${campaignId}/list/${listId}/download`, [campaignId, listId]);
  const noSearchOrFilterSet = query.search === '' && query.filter === '';
  const lastDataElement = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          getNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, getNextPage],
  );

  const onQueryChange = useCallback(async (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value }));
  }, []);

  const onRemoveLead = useCallback(
    (leadId: number) => async () => {
      try {
        await removeLeadById(+campaignId, +listId, leadId);
      } catch (e) {
        pushNotification('error', (e as APIError | UnsupportedStructureError).message);
        return;
      }

      // Update local copy of the lead record with updated status
      const index = findIndexByProperty(leads, 'id', leadId);
      leads[index].leadStatus = LeadStatusType.Removed;

      pushNotification('success', 'You have successfully updated the list.');
    },
    [campaignId, listId, leads],
  );

  const displayList = useMemo(
    () =>
      leads.map((item: Lead, index: number) => {
        if (index === leads.length - 1) {
          return <LeadCard ref={lastDataElement} key={item.id} lead={item} onRemove={onRemoveLead(item.id)} />;
        } else {
          return <LeadCard key={item.id} lead={item} onRemove={onRemoveLead(item.id)} />;
        }
      }),
    [leads, lastDataElement, onRemoveLead],
  );

  const filterListsDisplay = useMemo(
    () =>
      filterList.map((item, index) => (
        <MenuItem key={index} value={item.value}>
          {item.label}
        </MenuItem>
      )),
    [],
  );

  return (
    <>
      <Grid sx={{ marginBottom: 2 }} container spacing={1} alignContent='center'>
        <Grid item xs={12} md={3}>
          <TextField fullWidth variant='outlined' label='Search' id='search' name='search' onChange={onQueryChange} />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            select
            variant='outlined'
            id='filter'
            name='filter'
            label='Lead Status'
            value={query.filter}
            onChange={onQueryChange}>
            {filterListsDisplay}
          </TextField>
        </Grid>

        <Hidden smDown>
          <Grid item md={3}></Grid>
        </Hidden>

        <Grid item xs={12} md={3} style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            fullWidth
            variant='contained'
            href={downloadLink}
            disableElevation
            startIcon={<CloudDownloadIcon />}
            color='primary'
            title='download list'>
            Download
          </Button>
        </Grid>
      </Grid>

      <AsyncLoader isLoading={loading && leads.length === 0}>
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12}>
            {leads.length > 0 && (
              <>
                <List>{displayList}</List>
                {loading && leads.length > 0 && <DotLoader align='center' />}
                {!loading && !hasMore && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    No more results to display
                  </Typography>
                )}
                {error && leads.length > 0 && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    Failed to load leads
                  </Typography>
                )}
              </>
            )}

            {leads.length == 0 && !noSearchOrFilterSet && (
              <EmptyState
                type='no-records-found'
                text='No leads found matching your search criteria'
                subText='Try alternate words or selections.'
              />
            )}

            {leads.length === 0 && noSearchOrFilterSet && <EmptyState type='no-items-2' text='No leads available' />}
          </Grid>
        </Grid>
      </AsyncLoader>
    </>
  );
};

export default LeadListLeads;

import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { MUIStyledCommonProps, Theme } from '@mui/system';

type OberonSwitchProps = MUIStyledCommonProps<Theme> & SwitchProps;

const OberonSwitch = styled(Switch)<OberonSwitchProps>({
  '& .MuiSwitch-switchBase': {
    'color': grey[300],
    '&.Mui-checked': {
      'color': green[500],
      '& + .MuiSwitch-track': {
        backgroundColor: green[500],
      },
    },
  },
});

export default OberonSwitch;

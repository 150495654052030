import SocketManagerBase from '~services/SocketManagerBase';
import { assertUnreachable } from '~utils/Functions';

import {
  ServerMessage,
  ServerMessageDecoder,
  UserNotification,
  UserNotificationInitMessageDecoder,
  UserNotificationMessageDecoder,
  UserNotificationType,
} from './domain';

interface UserNotificationCallbacks {
  onConnected: () => void;
  onInit: (messages: UserNotification[]) => void;
  onNewNotification: (message: UserNotification) => void;
  onAcknowledgedNotification: (message: UserNotification) => void;
  onDisconnected: (closeCode: number) => void;
}

export class UserNotificationManager extends SocketManagerBase {
  private heartbeatIntervalRef: number | undefined;
  private callbacks: UserNotificationCallbacks = {
    onConnected: () => {},
    onInit: () => {},
    onNewNotification: () => {},
    onAcknowledgedNotification: () => {},
    onDisconnected: () => {},
  };

  constructor(address: string, callbacks: Partial<UserNotificationCallbacks>) {
    super('UserNotificationManager', address);

    // Assign optional functions else default to empty default values
    this.callbacks.onConnected = callbacks.onConnected || this.callbacks.onConnected;
    this.callbacks.onInit = callbacks.onInit || this.callbacks.onInit;
    this.callbacks.onNewNotification = callbacks.onNewNotification || this.callbacks.onNewNotification;
    this.callbacks.onAcknowledgedNotification =
      callbacks.onAcknowledgedNotification || this.callbacks.onAcknowledgedNotification;
    this.callbacks.onDisconnected = callbacks.onDisconnected || this.callbacks.onDisconnected;
  }

  /** marks user notification as acknowledged */
  public markNotificationAsAcknowledged = (notificationId: number): void => {
    console.log('UserNotificationManager action mark notification as acknowledged triggered.');

    if (this.ws === undefined) {
      console.error('markNotificationAsAcknowledged: websocket missing.');
      return;
    }

    const req = {
      notification_acknowledged: {
        notification_id: notificationId,
      },
    };

    this.send(JSON.stringify(req));
  };

  /** Event that is triggered on the successful connection of the websocket */
  protected onOpen = (): void => {
    if (this.ws === undefined) {
      console.error('onOpen: websocket missing.');
      return;
    }

    const socket = this.ws;
    // Post heartbeat to let the socket know we are still here
    this.heartbeatIntervalRef = window.setInterval(() => {
      const req = {
        heartbeat: '',
      };

      socket.send(JSON.stringify(req));
    }, 15_000);

    this.callbacks.onConnected();
  };

  /** Event handler that manages all responses from the connected websocket */
  protected onMessage = (messageEvent: MessageEvent): void => {
    let serverMessage: ServerMessage;

    try {
      const rawMessage = JSON.parse(messageEvent.data);
      serverMessage = ServerMessageDecoder.runWithException(rawMessage);

      // Empty object denotes keep alive from the backend
      if (serverMessage.type === undefined || Object.keys(serverMessage).length === 0) {
        return;
      }

      switch (serverMessage.type) {
        case UserNotificationType.Init: {
          const msg = UserNotificationInitMessageDecoder.runWithException(rawMessage);
          this.callbacks.onInit(msg.notifications);
          break;
        }
        case UserNotificationType.New: {
          const msg = UserNotificationMessageDecoder.runWithException(rawMessage);
          this.callbacks.onNewNotification(msg.notification);
          break;
        }
        case UserNotificationType.Acknowledged: {
          const msg = UserNotificationMessageDecoder.runWithException(rawMessage);
          this.callbacks.onAcknowledgedNotification(msg.notification);
          break;
        }
        default: {
          assertUnreachable(serverMessage.type);
        }
      }
    } catch (e) {
      console.error('onMessage: Unable to decode message payload: ', e);
      return;
    }
  };

  /** Event handler that is triggered if the websocket is closed/ ended */
  protected onClose = (closeEvent: CloseEvent): void => {
    console.log('UserNotificationManager websocket closed with code: ', closeEvent.code);

    clearInterval(this.heartbeatIntervalRef);
    this.ws = undefined;
    this.callbacks.onDisconnected(closeEvent.code);
  };
}

import axios, { CancelTokenSource } from 'axios';

import { APIError, UnsupportedStructureError } from '~services/Errors';

import {
  AsyncQueue,
  AsyncQueueDecoder,
  CreateEditAsyncQueue,
  GetAsyncQueueDispositionsDecoder,
  GetAsyncQueuesDecoder,
  QueueDisposition,
  UpdateQueueDisposition,
} from './domain';

export const getAsyncQueues = async (cancelToken?: CancelTokenSource): Promise<AsyncQueue[] | undefined> => {
  const path = '/api/async/queues';
  let resp;
  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return undefined;
    }

    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetAsyncQueuesDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getAsyncQueue = async (queue: string, cancelToken?: CancelTokenSource): Promise<AsyncQueue> => {
  const path = `/api/async/queues/${queue}`;
  let resp;
  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = AsyncQueueDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const createAsyncQueue = async (data: CreateEditAsyncQueue): Promise<void> => {
  const path = '/api/async/queues';
  const body = {
    queue: data.queue,
    title: data.title,
    channel_type: data.channelType,
    description: data.description,
    is_public: data.isPublic,
    system_endpoint: data.systemEndpoint || undefined,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const updateAsyncQueue = async (queue: string, data: CreateEditAsyncQueue): Promise<void> => {
  const path = `/api/async/queues/${queue}`;
  const body = {
    title: data.title,
    description: data.description,
    is_public: data.isPublic,
    system_endpoint: data.systemEndpoint || undefined,
  };

  try {
    await axios.request({
      method: 'PUT',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const getAsyncQueueDispositions = async (
  queue: string,
  cancelToken?: CancelTokenSource,
): Promise<QueueDisposition[] | undefined> => {
  const path = `/api/async/queues/${queue}/dispositions`;
  let resp;
  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return undefined;
    }

    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetAsyncQueueDispositionsDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const removeAsyncQueueDisposition = async (
  queue: string,
  dispositionCode: string,
  dispositionSubCode: string,
  cancelToken?: CancelTokenSource,
) => {
  const path = `/api/async/queues/${queue}/dispositions/${dispositionCode}/${dispositionSubCode}`;

  try {
    await axios.request({
      method: 'DELETE',
      url: path,
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const createAsyncQueueDisposition = async (queue: string, data: QueueDisposition): Promise<void> => {
  const path = `/api/async/queues/${queue}/dispositions`;
  const body = {
    code: data.code,
    sub_code: data.subCode,
    title: data.title,
    outcome: data.outcome,
    description: data.description,
    attributes: data.attributes.map((attr) => ({
      attribute: attr.attribute,
      value: attr.value,
    })),
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const updateAsyncQueueDisposition = async (queue: string, data: UpdateQueueDisposition): Promise<void> => {
  const path = `/api/async/queues/${queue}/dispositions`;
  const body = {
    original_code: data.originalCode,
    original_sub_code: data.originalSubCode,
    disposition: {
      code: data.disposition.code,
      sub_code: data.disposition.subCode,
      title: data.disposition.title,
      outcome: data.disposition.outcome,
      description: data.disposition.description,
      attributes: data.disposition.attributes.map((attr) => ({
        attribute: attr.attribute,
        value: attr.value,
      })),
    },
  };

  try {
    await axios.request({
      method: 'PUT',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

import BugReportIcon from '@mui/icons-material/BugReport';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import EditIcon from '@mui/icons-material/Edit';
import FeedbackIcon from '@mui/icons-material/Feedback';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import blue from '@mui/material/colors/blue';
import blueGrey from '@mui/material/colors/blueGrey';
import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import red from '@mui/material/colors/red';
import Grid from '@mui/material/Grid/Grid';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { keyframes, styled } from '@mui/material/styles';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import Typography from '@mui/material/Typography';
import { MUIStyledCommonProps, Theme } from '@mui/system';
import React, { useEffect, useState } from 'react';

import useTimer from '~hooks/useTimer';
import { RoutingProfile } from '~pages/CampaignManagement/domain';
import {
  AgentAvailableStates,
  AgentState,
  AgentStateType,
  Contact,
  ContactStateType,
} from '~providers/ConnectProvider/domain';

import PhoneWarningIcon from './PhoneWarningIcon';
import RoutingProfileSelector from './RoutingProfileSelector';
import StatusSelector from './StatusSelector';

enum AdditionalStatusBadgeType {
  Hold = 'hold',
  Unknown = 'unknown',
}

interface StatusBadge {
  backgroundColor: string;
  statusName: string;
  statusIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
}

export interface StatisticItem {
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  text: string;
}

interface AgentStatusBarProps {
  agentStateList: string[];
  routingProfiles: RoutingProfile[];
  agentStatusType: AgentStateType;
  agentStatus: AgentState;
  agentStatusTimestamp: Date;
  currentVoiceContact?: Contact;
  isVoiceViewActive?: boolean;
  isMessagingViewActive?: boolean;
  isAsyncPendingStateChange?: boolean;
  isAsyncMissedConversation?: boolean;
  attemptHasPreviousInteraction?: boolean;
  unseenMessages?: boolean;
  statisticItems?: StatisticItem[];
  isStateChangeDisabled?: boolean;
  setAgentState: (statusName: string) => Promise<void>;
  setRoutingProfile: ((routingProfileID: string, routingProfileName: string) => Promise<void>) | undefined;
  downloadSessionLogs: () => void;
  onVoiceButtonClick?: () => void;
  onMessagingButtonClick?: () => void;
}

const statusBadgeData: { [key: string]: StatusBadge } = {
  [AgentStateType.Offline]: {
    backgroundColor: blueGrey[800],
    statusName: '',
    statusIcon: PhoneDisabledIcon,
  },
  [AgentStateType.NotRoutable]: {
    backgroundColor: blueGrey[800],
    statusName: '',
    statusIcon: PhoneDisabledIcon,
  },
  [AgentStateType.Routable]: {
    backgroundColor: green[800],
    statusName: '',
    statusIcon: PhoneIcon,
  },
  [AgentAvailableStates.PendingBusy]: {
    backgroundColor: green[800],
    statusName: 'Ringing',
    statusIcon: RingVolumeIcon,
  },
  [ContactStateType.Connecting]: {
    backgroundColor: green[800],
    statusName: 'Ringing',
    statusIcon: RingVolumeIcon,
  },
  [ContactStateType.Connected]: {
    backgroundColor: blue[800],
    statusName: 'Connected',
    statusIcon: PhoneInTalkIcon,
  },
  [ContactStateType.Ended]: {
    backgroundColor: grey[600],
    statusName: 'After Call Work',
    statusIcon: EditIcon,
  },
  // Inbound missed call state
  [ContactStateType.Missed]: {
    backgroundColor: red[800],
    statusName: 'Missed Call',
    statusIcon: PhoneMissedIcon,
  },
  // Outbound call no answer state
  [ContactStateType.Error]: {
    backgroundColor: red[800],
    statusName: 'No Answer',
    statusIcon: PhoneMissedIcon,
  },
  [AdditionalStatusBadgeType.Hold]: {
    backgroundColor: blueGrey[800],
    statusName: 'Hold',
    statusIcon: PhonePausedIcon,
  },
  [AdditionalStatusBadgeType.Unknown]: {
    backgroundColor: blueGrey[800],
    statusName: 'Unknown',
    statusIcon: BugReportIcon,
  },
};

type ClearButtonProps = MUIStyledCommonProps<Theme> &
  ButtonProps & {
    isActive?: boolean;
    isPulse?: boolean;
  };

const heartBeat = keyframes({
  '0%': {
    boxShadow: '0 0 0 0 rgba(255,255,255, 1)',
  },
  '70%': {
    boxShadow: '0 0 0 10px rgba(204,169,44, 0)',
  },
  '100%': {
    boxShadow: '0 0 0 0 rgba(204,169,44, 0)',
  },
});

const ClearButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'isPulse',
})<ClearButtonProps>(({ theme, isActive, isPulse }) => ({
  'minWidth': 100,
  'fontWeight': 400,
  'lineHeight': 1.334,
  'textTransform': 'none',
  'backgroundColor': isActive ? 'rgba(255, 255, 255, .40)' : 'rgba(255, 255, 255, .10)',
  'color': '#ffffff',
  ':active': {
    backgroundColor: isActive ? 'rgba(255, 255, 255, .40)' : 'initial',
  },
  ':hover': {
    backgroundColor: isActive ? 'rgba(255, 255, 255, .40)' : 'rgba(255, 255, 255, .20)',
  },
  '&.MuiButtonGroup-grouped:not(:last-of-type)': {
    borderColor: 'rgba(255, 255, 255, .65)',
  },
  'animation': isPulse ? `${heartBeat} 1.5s infinite` : undefined,
}));

const textColor = '#ffffff';

const AgentStatusBar = ({
  agentStateList,
  routingProfiles,
  agentStatusType,
  agentStatus,
  agentStatusTimestamp,
  currentVoiceContact,
  isVoiceViewActive,
  isMessagingViewActive,
  isAsyncPendingStateChange,
  isAsyncMissedConversation,
  attemptHasPreviousInteraction,
  unseenMessages,
  statisticItems,
  isStateChangeDisabled,
  setAgentState,
  setRoutingProfile,
  downloadSessionLogs,
  onVoiceButtonClick,
  onMessagingButtonClick,
}: AgentStatusBarProps) => {
  const [holdTimestamp, setHoldTimestamp] = useState<Date>(new Date());
  const holdTimer = useTimer(holdTimestamp);
  const timer = useTimer(agentStatusTimestamp);
  const isOnHold = currentVoiceContact?.isOnHold;
  let statusData;
  const voiceButtonPulse = attemptHasPreviousInteraction;
  const messagingButtonPulse = isAsyncMissedConversation || unseenMessages;
  let VoiceButtonIcon = PhoneIcon;
  let MessagingButtonIcon = ChatBubbleIcon;

  if (attemptHasPreviousInteraction) {
    VoiceButtonIcon = PhoneWarningIcon;
  }

  // If async has a missed conversation it takes priority over any new messages notifications that come through
  if (isAsyncMissedConversation) {
    MessagingButtonIcon = FeedbackIcon;
  } else if (unseenMessages) {
    MessagingButtonIcon = MarkChatUnreadIcon;
  }

  // Handles hold timer
  useEffect(() => {
    if (isOnHold) {
      setHoldTimestamp(new Date());
    }
  }, [isOnHold]);

  // Most important first
  if (currentVoiceContact && currentVoiceContact.isOnHold) {
    statusData = statusBadgeData[AdditionalStatusBadgeType.Hold];
  } else if (currentVoiceContact !== undefined) {
    statusData = statusBadgeData[currentVoiceContact.statusType];
    // We want to catch specific agent states then contact states
  } else if (
    agentStatusType === AgentStateType.Offline ||
    agentStatusType === AgentStateType.Routable ||
    agentStatusType === AgentStateType.NotRoutable
  ) {
    statusData = statusBadgeData[agentStatusType];
    // Update status name with dynamic custom status name's
    // We add a toString call on agentStatus here as we have custom states also coming through at run time and we are
    // unable to define them in the type interface
    statusData.statusName = agentStatus.toString();
    // We want to handle a specific agent available state
  } else if (agentStatusType === AgentStateType.System && agentStatus === AgentAvailableStates.PendingBusy) {
    statusData = statusBadgeData[AgentAvailableStates.PendingBusy];
  }

  // If not of the above conditions match OR one of them result in an undefined case
  if (!statusData) {
    statusData = statusBadgeData[AdditionalStatusBadgeType.Unknown];
  }

  const { backgroundColor, statusName, statusIcon: StatusIcon } = statusData;

  const statisticItemsDisplay =
    statisticItems === undefined
      ? []
      : statisticItems.map(({ icon: Icon, text }: StatisticItem, index: number) => (
          <Chip
            key={index}
            sx={{ backgroundColor: 'transparent', padding: 0, fontWeight: 500, fontSize: 12, color: grey[900] }}
            size='small'
            icon={<Icon />}
            label={text}
          />
        ));

  return (
    <Card
      sx={{ backgroundColor: backgroundColor, width: '100%', position: 'relative', overflow: 'initial' }}
      elevation={3}>
      <CardContent
        sx={{
          'display': 'flex',
          'justifyContent': 'space-between',
          'alignItems': 'center',
          'padding': 0,
          ':last-of-type': {
            paddingBottom: 0,
          },
        }}>
        <Grid container spacing={0}>
          <Grid
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              paddingTop: 1,
              paddingLeft: 2,
              paddingBottom: 1,
            }}
            item
            xs={6}>
            <StatusIcon sx={{ color: textColor, marginRight: 2 }} fontSize='large' />

            <span>
              <Typography color={textColor} variant='h6'>
                {isAsyncPendingStateChange ? `Pending: ${statusName}` : statusName}
              </Typography>

              <Typography color={textColor} variant='body2'>
                {isOnHold ? holdTimer : timer}
              </Typography>
            </span>
          </Grid>

          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              paddingTop: 1,
              paddingRight: 2,
              paddingBottom: 1,
            }}
            item
            xs={6}>
            {(onVoiceButtonClick || onMessagingButtonClick) && (
              <ButtonGroup sx={{ marginRight: 1, height: 30 }} variant='contained' disableElevation>
                {onVoiceButtonClick && (
                  <ClearButton
                    isActive={isVoiceViewActive}
                    isPulse={voiceButtonPulse}
                    variant='contained'
                    disableElevation
                    onClick={onVoiceButtonClick}
                    color='primary'
                    startIcon={<VoiceButtonIcon fontSize='small' />}>
                    Voice
                  </ClearButton>
                )}

                {onMessagingButtonClick && (
                  <ClearButton
                    isActive={isMessagingViewActive}
                    isPulse={messagingButtonPulse}
                    variant='contained'
                    disableElevation
                    onClick={onMessagingButtonClick}
                    color='primary'
                    startIcon={<MessagingButtonIcon fontSize='small' />}>
                    Messaging
                  </ClearButton>
                )}
              </ButtonGroup>
            )}

            {setRoutingProfile && (
              <RoutingProfileSelector
                onChange={setRoutingProfile}
                items={routingProfiles.map((item) => ({ label: item.name, value: item.id }))}
                disabled={Boolean(currentVoiceContact || isStateChangeDisabled)}
              />
            )}

            <StatusSelector onChange={setAgentState} items={agentStateList} disabled={Boolean(isStateChangeDisabled)} />
          </Grid>
        </Grid>
      </CardContent>

      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: '0 0 8px 8px',
          padding: '4px 16px',
          minHeight: 32,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>{statisticItemsDisplay}</Box>

        <div>
          <Button
            sx={{
              'fontSize': 12,
              'textTransform': 'none',
              'lineHeight': 1.43,
              'padding': '2px 16px',
              'color': 'rgba(0, 0, 0, 0.87)',
              'backgroundColor': '#e0e0e0',
              ':hover': {
                backgroundColor: '#d5d5d5',
              },
            }}
            variant='contained'
            disableElevation
            startIcon={<CloudDownloadIcon fontSize='small' />}
            onClick={downloadSessionLogs}>
            Session Logs
          </Button>
        </div>
      </Box>
    </Card>
  );
};

export default AgentStatusBar;

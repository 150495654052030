import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { DataItem } from '~components/DataItem';
import ControlledCheckbox from '~components/Form/ControlledCheckbox';
import ControlledNumberField from '~components/Form/ControlledNumberField';
import SectionCard from '~components/SectionCard';
import { PreviewSettings } from '~pages/CampaignManagement/domain';

// Preview campaign settings pushPreviewSeconds property defaults and ranges
enum PushPreviewSeconds {
  Min = 15,
  Max = 600,
  Infinite = 0,
}

// Preview campaign settings ringOutSeconds property defaults and ranges
enum RingOutSeconds {
  Min = 20,
  Max = 600,
  Infinite = 0,
}

const getHourFromSeconds = (seconds: number) => {
  return Math.floor(seconds / 3600);
};

interface PreviewSettingsFormProps {
  previewSettings: PreviewSettings;
  isEdit: boolean;
  submitting: boolean;
  toggleEdit: () => void;
  onSubmit: (data: PreviewSettings) => Promise<void>;
}

interface Form {
  allowPushPreviewSeconds: boolean;
  pushPreviewSeconds: number;
  allowRingOutSeconds: boolean;
  ringOutSeconds: number;
  agentAssignedTimeoutSeconds: number;
}

const PreviewSettingsForm = ({
  previewSettings,
  isEdit,
  submitting,
  toggleEdit,
  onSubmit,
}: PreviewSettingsFormProps) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    getValues,
    clearErrors,
  } = useForm<Form>({
    defaultValues: {
      allowPushPreviewSeconds: false,
      pushPreviewSeconds: PushPreviewSeconds.Min,
      allowRingOutSeconds: false,
      ringOutSeconds: RingOutSeconds.Min,
      agentAssignedTimeoutSeconds: 0,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const watchAllowPushPreviewSeconds = watch('allowPushPreviewSeconds');
  const watchAllowRingOutSeconds = watch('allowRingOutSeconds');

  useEffect(() => {
    if (isEdit === true) {
      setValue('allowPushPreviewSeconds', previewSettings.pushPreviewSeconds > 0);
      setValue('pushPreviewSeconds', previewSettings.pushPreviewSeconds);
      setValue('allowRingOutSeconds', previewSettings.ringOutSeconds > 0);
      setValue('ringOutSeconds', previewSettings.ringOutSeconds);
      setValue('agentAssignedTimeoutSeconds', previewSettings.agentAssignedTimeoutSeconds);
    } else {
      reset();
    }
  }, [isEdit]);

  // Update push preview seconds with the min default if allowed else of not allowed force to Infinite (0)
  // which indicates infinite preview time
  useEffect(() => {
    if (watchAllowPushPreviewSeconds === true) {
      setValue('pushPreviewSeconds', previewSettings.pushPreviewSeconds || PushPreviewSeconds.Min);
    } else {
      setValue('pushPreviewSeconds', PushPreviewSeconds.Infinite);
    }
  }, [watchAllowPushPreviewSeconds]);

  // Update ring out seconds with the min default if allowed else of not allowed force to Infinite (0)
  // which indicates that we do not auto ring out and leave it up to the contact service provider
  useEffect(() => {
    if (watchAllowRingOutSeconds === true) {
      setValue('ringOutSeconds', previewSettings.ringOutSeconds || RingOutSeconds.Min);
    } else {
      setValue('ringOutSeconds', RingOutSeconds.Infinite);
    }
  }, [watchAllowRingOutSeconds]);

  const onSubmitFn = handleSubmit(async (data: Form) => {
    try {
      await onSubmit({
        pushPreviewSeconds: data.pushPreviewSeconds,
        ringOutSeconds: data.ringOutSeconds,
        agentAssignedTimeoutSeconds: data.agentAssignedTimeoutSeconds,
      });
    } catch (e) {
      // Just return on error as we only want to reset the form on a successful submission
      return;
    }

    reset();
  });

  let agentAssignedTimeoutSecondsHelperText =
    getHourFromSeconds(getValues('agentAssignedTimeoutSeconds')) < 2
      ? 'Note: Reducing this time period below 2 hours may cause unintended consequences, including simultaneous calls to a customer, and agent assignment confusion. It is recommended to keep this value above 2 hours to ensure the agent has no chance of dialling this lead after its assignment has lapsed.'
      : '';

  if (errors.agentAssignedTimeoutSeconds) {
    agentAssignedTimeoutSecondsHelperText = errors.agentAssignedTimeoutSeconds?.message || '';
  }

  return (
    <SectionCard title='Preview Settings' onEdit={toggleEdit}>
      {!isEdit && (
        <>
          <DataItem
            stacked={isExtraSmall}
            disableMargin
            title='Push Preview Seconds'
            tooltip='(Optional) The amount of time an agent has to preview a lead before its auto dialed.'
            value={previewSettings.pushPreviewSeconds || 'N/A'}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Ring Out Seconds'
            tooltip='(Optional) The amount of time the preview dialler should ring before hanging up on a lead.'
            value={previewSettings.ringOutSeconds || 'N/A'}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Agent Assigned Timeout Seconds'
            tooltip='The amount of time the system will allow a lead to be allocated to an agent before it’s assigned to another agent.'
            value={previewSettings.agentAssignedTimeoutSeconds}
          />
        </>
      )}

      {isEdit && (
        <form onSubmit={onSubmitFn} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ControlledCheckbox
                name='allowPushPreviewSeconds'
                label='Allow Push Preview Seconds?'
                control={control}
                disabled={submitting}
              />
            </Grid>

            {watchAllowPushPreviewSeconds && (
              <Grid item xs={12}>
                <ControlledNumberField
                  name='pushPreviewSeconds'
                  control={control}
                  rules={{
                    required: 'push preview seconds is required.',
                    min: {
                      value: PushPreviewSeconds.Min,
                      message: `push preview seconds cannot be lower than ${PushPreviewSeconds.Min}.`,
                    },
                    max: {
                      value: PushPreviewSeconds.Max,
                      message: `push preview seconds cannot be greater than ${PushPreviewSeconds.Max}.`,
                    },
                    validate: {
                      greaterThan: (v) => {
                        if (v >= getValues('agentAssignedTimeoutSeconds')) {
                          return `push preview seconds cannot be greater or equal to agent assigned timeout seconds`;
                        }

                        clearErrors('agentAssignedTimeoutSeconds');
                        return v;
                      },
                    },
                  }}
                  label='Push Preview Seconds'
                  disabled={submitting}
                  required={true}
                  error={Boolean(errors.pushPreviewSeconds)}
                  helperText={errors.pushPreviewSeconds?.message}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <ControlledCheckbox
                name='allowRingOutSeconds'
                label='Allow Ring Out Seconds?'
                control={control}
                disabled={submitting}
              />
            </Grid>

            {watchAllowRingOutSeconds && (
              <Grid item xs={12}>
                <ControlledNumberField
                  name='ringOutSeconds'
                  control={control}
                  rules={{
                    required: 'ring out seconds is required.',
                    min: {
                      value: RingOutSeconds.Min,
                      message: `ring out seconds cannot be lower than ${RingOutSeconds.Min}.`,
                    },
                    max: {
                      value: RingOutSeconds.Max,
                      message: `ring out seconds cannot be greater than ${RingOutSeconds.Max}.`,
                    },
                  }}
                  label='Ring Out Seconds'
                  disabled={submitting}
                  required={true}
                  error={Boolean(errors.ringOutSeconds)}
                  helperText={errors.ringOutSeconds?.message}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <ControlledNumberField
                name='agentAssignedTimeoutSeconds'
                control={control}
                rules={{
                  required: 'agent assigned timeout seconds is required.',
                  validate: {
                    lessThan: (v) => {
                      if (v <= getValues('pushPreviewSeconds')) {
                        return `agent assigned timeout seconds cannot be less than or equal too push preview seconds`;
                      }

                      clearErrors('pushPreviewSeconds');
                      return v;
                    },
                  },
                }}
                label='Agent Assigned Timeout Seconds'
                disabled={submitting}
                required={true}
                error={Boolean(errors.agentAssignedTimeoutSeconds)}
                helperText={agentAssignedTimeoutSecondsHelperText}
              />
            </Grid>

            <Grid sx={{ textAlign: 'right' }} item xs={12}>
              <Button onClick={toggleEdit}>Cancel</Button>

              <LoadingButton
                sx={{ marginLeft: 1 }}
                type='submit'
                variant='contained'
                disableElevation
                loading={submitting}
                color='primary'>
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      )}
    </SectionCard>
  );
};

export default PreviewSettingsForm;

import ReplyIcon from '@mui/icons-material/Reply';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import React, { ChangeEvent, useEffect, useState } from 'react';
import useMeasure from 'react-use-measure';

import AsyncLoader from '~components/AsyncLoader';
import { getAsyncQueues } from '~pages/AsyncManagement/api';
import { AsyncQueue } from '~pages/AsyncManagement/domain';

interface QueueTransferPanelProps {
  onTransferToQueue: (queue: string) => void;
}

const CustomizedListItem = styled(ListItem)(({ theme }) => ({
  padding: 16,
}));

const CustomizedListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    userSelect: 'none',
    display: 'block',
    fontSize: 16,
    paddingRight: 50,
    minWidth: 0,
    textOverflow: 'ellipsis',

    /* Required for text-overflow to do anything */
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

const QueueTransferPanel = ({ onTransferToQueue }: QueueTransferPanelProps) => {
  const [search, setSearch] = useState<string>('');
  const [list, setList] = useState<AsyncQueue[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [boxRef, { height: boxHeight }] = useMeasure();

  // Initial fetch of the external transfer list
  useEffect(() => {
    setLoading(true);
    setError('');
    const axiosCancelRef = axios.CancelToken.source();

    (async () => {
      let resp: AsyncQueue[] | undefined;

      try {
        resp = await getAsyncQueues(axiosCancelRef);
      } catch (e) {
        console.error('! Failed to fetch async queues: ', e);
        setError('Unable to fetch queues at this time.');
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setList(resp);
      setLoading(false);
    })();

    return () => {
      axiosCancelRef.cancel();
    };
  }, []);

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const searchTerm = search.split(/\s+/).filter((term) => term.length > 0);
  const searchResultList = list.filter((item) =>
    searchTerm.every((term) => item.title.toLowerCase().includes(term.toLowerCase())),
  );
  const transferItemList = searchResultList.map((item, index) => (
    <CustomizedListItem key={index} divider={index !== searchResultList.length - 1}>
      <CustomizedListItemText primary={item.title} />
      <ListItemSecondaryAction>
        <IconButton
          onClick={() => onTransferToQueue(item.queue)}
          size='medium'
          aria-label={`Transfer to ${item.title}`}>
          <ReplyIcon sx={{ transform: 'scaleX(-1)' }} />
        </IconButton>
      </ListItemSecondaryAction>
    </CustomizedListItem>
  ));

  return (
    <>
      <Box ref={boxRef}>
        <Grid sx={{ padding: 1 }} container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant='outlined'
              id='search'
              name='search'
              label='Search'
              onChange={onSearchChange}
            />
          </Grid>
        </Grid>

        <Divider component='hr' />
      </Box>

      <Box sx={{ height: `calc(100% - ${boxHeight}px)`, overflow: 'auto' }}>
        <AsyncLoader isLoading={loading}>
          {!error && transferItemList.length === 0 && (
            <Typography marginTop={1} variant='body1' component='p' align='center'>
              {searchTerm.length === 0 ? "There are no queue's to transfer to." : 'No search results found.'}
            </Typography>
          )}

          {!error && transferItemList.length > 0 && <List sx={{ padding: 0 }}>{transferItemList}</List>}

          {error && (
            <Typography marginTop={1} variant='body1' component='p' align='center'>
              {error}
            </Typography>
          )}
        </AsyncLoader>
      </Box>
    </>
  );
};

export default QueueTransferPanel;

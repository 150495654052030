import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Lead } from '~pages/CampaignManagement/domain';

import { getLeads, getLeadsByNextUrl } from './api';
import { LeadsResponse } from './domain';

const useLeadSearch = (campaignId: number, listId: number, search: string, filter: string) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [leads, setLeads] = useState<Lead[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());

  const getNextPage = useCallback(async () => {
    if (nextUrl !== null) {
      setLoading(true);
      setError(false);

      let resp: LeadsResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getLeadsByNextUrl(nextUrl, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setLeads((prev) => [...prev, ...resp!.list]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    }
  }, [nextUrl]);

  useEffect(() => {
    setLeads([]);
  }, [search, filter]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setError(false);

      let resp: LeadsResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getLeads(campaignId, listId, search, filter, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setLeads((prev) => [...prev, ...resp!.list]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    })();

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, [search, filter]);

  return { loading, error, leads, hasMore, getNextPage };
};

export default useLeadSearch;

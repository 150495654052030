import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PhoneIcon from '@mui/icons-material/Phone';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import amber from '@mui/material/colors/amber';
import blue from '@mui/material/colors/blue';
import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import red from '@mui/material/colors/red';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React, { ForwardedRef, KeyboardEvent, MouseEvent, forwardRef, useState } from 'react';

import ActionDialog from '~components/ActionDialog';
import OberonCard from '~components/OberonCard';
import LeadDetailsModal from '~pages/CampaignManagement/LeadListDetails/LeadListLeads/LeadDetailsModal';

import { Lead, LeadStatusType } from '../../../domain';

interface Status {
  baseColor: string;
  lightColor: string;
  showMenu: boolean;
}

interface LeadCardProps {
  lead: Lead;
  onRemove: () => void;
}

const statusColorData: { [key in LeadStatusType]: Status } = {
  [LeadStatusType.Assigned]: {
    baseColor: green['600'],
    lightColor: green['100'],
    showMenu: true,
  },
  [LeadStatusType.AwaitingRetry]: {
    baseColor: amber['600'],
    lightColor: amber['100'],
    showMenu: true,
  },
  [LeadStatusType.AwaitingStart]: {
    baseColor: amber['600'],
    lightColor: amber['100'],
    showMenu: true,
  },
  [LeadStatusType.Building]: {
    baseColor: blue['600'],
    lightColor: blue['100'],
    showMenu: true,
  },
  [LeadStatusType.Callback]: {
    baseColor: amber['600'],
    lightColor: amber['100'],
    showMenu: true,
  },
  [LeadStatusType.Connected]: {
    baseColor: green['600'],
    lightColor: green['100'],
    showMenu: false,
  },
  [LeadStatusType.Contacted]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
    showMenu: false,
  },
  [LeadStatusType.Duplicate]: {
    baseColor: red['600'],
    lightColor: red['100'],
    showMenu: true,
  },
  [LeadStatusType.Excluded]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
    showMenu: false,
  },
  [LeadStatusType.Filtered]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
    showMenu: false,
  },
  [LeadStatusType.Finished]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
    showMenu: false,
  },
  [LeadStatusType.FinishedToday]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
    showMenu: false,
  },
  [LeadStatusType.Initiated]: {
    baseColor: green['600'],
    lightColor: green['100'],
    showMenu: false,
  },
  [LeadStatusType.NoEndpoints]: {
    baseColor: red['600'],
    lightColor: red['100'],
    showMenu: true,
  },
  [LeadStatusType.NoSkilledAgents]: {
    baseColor: red['600'],
    lightColor: red['100'],
    showMenu: true,
  },
  [LeadStatusType.OptOut]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
    showMenu: false,
  },
  [LeadStatusType.OutOfHours]: {
    baseColor: amber['600'],
    lightColor: amber['100'],
    showMenu: true,
  },
  [LeadStatusType.Ready]: {
    baseColor: green['600'],
    lightColor: green['100'],
    showMenu: true,
  },
  [LeadStatusType.Washed]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
    showMenu: false,
  },
  [LeadStatusType.InvalidEndpoint]: {
    baseColor: red['600'],
    lightColor: red['100'],
    showMenu: true,
  },
  [LeadStatusType.Disconnected]: {
    baseColor: red['600'],
    lightColor: red['100'],
    showMenu: true,
  },
  [LeadStatusType.Removed]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
    showMenu: false,
  },
  [LeadStatusType.Expired]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
    showMenu: false,
  },
  [LeadStatusType.AwaitingSMS]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
    showMenu: false,
  },
  [LeadStatusType.InactiveList]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
    showMenu: true,
  },
  [LeadStatusType.MissedCallback]: {
    baseColor: red['600'],
    lightColor: red['100'],
    showMenu: false,
  },
  [LeadStatusType.InQueue]: {
    baseColor: green['600'],
    lightColor: green['100'],
    showMenu: false,
  },
  [LeadStatusType.AwaitingCallback]: {
    baseColor: green['600'],
    lightColor: green['100'],
    showMenu: true,
  },
  [LeadStatusType.Replaced]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
    showMenu: false,
  },
};

const LeadCard = forwardRef(({ lead, onRemove }: LeadCardProps, ref: ForwardedRef<HTMLDivElement>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [removeConfirmOpen, setRemoveConfirmOpen] = useState<boolean>(false);
  const [performingRemoveAction, setPerformingRemoveAction] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const leadStatus = lead.leadStatus;
  const statusData = statusColorData[leadStatus];

  // If backgroundColor unknown default to grey
  const baseColor = statusData?.baseColor || grey['600'];
  // If lightColor unknown default to light grey variant
  const lightColor = statusData?.lightColor || grey['100'];
  const statusName = lead.leadStatus.replace(/-/gi, ' ');
  // Does the card display the menu options or not
  const hasMenuAccess = statusData?.showMenu ?? false;

  const handleMenuOpen = (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleRemoveConfirmationOpen = (e: MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
    setRemoveConfirmOpen(true);
  };

  const handleRemoveConfirmationClose = () => {
    setRemoveConfirmOpen(false);
  };

  const handleRemove = async () => {
    setPerformingRemoveAction(true);

    try {
      await onRemove();
    } catch (e) {
      return;
    } finally {
      setPerformingRemoveAction(false);
    }

    setRemoveConfirmOpen(false);
  };

  const onModalOpen = () => {
    setOpenModal(true);
  };

  const onModalClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <OberonCard
        ref={ref}
        onClick={onModalOpen}
        titleFontWeight={400}
        title={lead.name}
        subHeader={
          <Chip
            sx={{
              marginTop: 0.5,
              textTransform: 'uppercase',
              fontSize: 10,
              borderRadius: 1,
              height: 20,
              lineHeight: '21px',
              color: '#ffffff',
              fontWeight: 700,
              backgroundColor: baseColor,
            }}
            label={statusName}
          />
        }
        avatar={
          <Avatar style={{ backgroundColor: lightColor }}>
            <PhoneIcon style={{ color: baseColor, display: 'inline-block', marginTop: 4, fontSize: 27 }} />
          </Avatar>
        }
        action={
          hasMenuAccess && (
            <>
              <IconButton
                aria-controls='lead-menu'
                aria-haspopup='true'
                onKeyPress={handleMenuOpen}
                onClick={handleMenuOpen}>
                <MoreHorizIcon />
              </IconButton>
              <Menu id='lead-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={handleRemoveConfirmationOpen}>Remove</MenuItem>
              </Menu>
            </>
          )
        }
        footer={
          <>
            <Typography fontSize={12} fontWeight={400} variant='body1' component='span' color='textPrimary'>
              Endpoints:
            </Typography>{' '}
            <Typography fontSize={12} fontWeight={400} variant='body2' component='span' color='textSecondary'>
              {lead.endpoints.join(', ')}
            </Typography>
          </>
        }
        footerBorderColor={baseColor}
      />

      <LeadDetailsModal open={openModal} lead={lead} onClose={onModalClose} />

      <ActionDialog
        open={removeConfirmOpen}
        title='Are you sure you want to do this?'
        content={`You are about to remove the lead ${lead.name} from the dialling list, once you have completed this action it cannot be undone.`}
        onClose={handleRemoveConfirmationClose}
        onAccept={handleRemove}
        loading={performingRemoveAction}
        primaryActionTitle='Remove'
      />
    </>
  );
});

export default LeadCard;

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { DateTime } from 'luxon';
import React, { Fragment, useEffect, useState } from 'react';

import EmptyState from '~components/EmptyState';
import SectionCard from '~components/SectionCard';
import Video from '~components/Video';
import { ContactRecording } from '~pages/ContactLog/domain';
import { RecordingInfo } from '~pages/SystemManagement/domain';
import { getDurationFromSeconds } from '~utils/Functions';

interface ScreenRecordingCardProps {
  recordingRelativePath: string;
  recordingInfoList: (RecordingInfo | ContactRecording)[];
  canDownload: boolean;
}

const addTrailingSlashIfMissing = (url: string) => {
  // Dont want to mutate the passed in value
  let newUrl = url;
  // Selects the last character
  let lastChar = url.substr(-1);

  if (lastChar !== '/') {
    // If the last character is not a slash append a slash to it.
    newUrl = url + '/';
  }

  return newUrl;
};

// Guard for checking if object is type of interface RecordingInfo
const isRecordingInfo = (item: RecordingInfo | ContactRecording): item is RecordingInfo => {
  return (item as RecordingInfo).recordingId !== undefined;
};

// Guard for checking if object is type of interface ContactRecording
const isContactRecording = (item: RecordingInfo | ContactRecording): item is ContactRecording => {
  return (item as ContactRecording).screenId !== undefined;
};

const ScreenRecordingCard = ({ recordingRelativePath, recordingInfoList, canDownload }: ScreenRecordingCardProps) => {
  const [ref, setRef] = useState<string | number | undefined>(undefined);

  useEffect(() => {
    // TODO: hook this into additional query paramater logic???
    // If reocrdings exist select first one in the list
    if (recordingInfoList.length > 0) {
      if (isRecordingInfo(recordingInfoList[0])) {
        setRef(recordingInfoList[0].recordingId);
        return;
      }

      if (isContactRecording(recordingInfoList[0])) {
        setRef(recordingInfoList[0].screenId);
      }
    }
  }, [recordingInfoList]);

  const selectRecording = (recordingId: string | number) => () => {
    setRef(recordingId);
  };

  const listItems = recordingInfoList.map((item: RecordingInfo | ContactRecording, index: number) => {
    let refValue: string | number;

    if (isRecordingInfo(item)) {
      refValue = item.recordingId;
    } else if (isContactRecording(item)) {
      refValue = item.screenId;
    } else {
      throw new Error(
        'ScreenRecordingCard: Unexpected recording info list item structure does not match interfaces RecordingInfo | ContactRecording ',
      );
    }

    return (
      <Fragment key={index}>
        <ListItem button selected={ref === refValue} onClick={selectRecording(refValue)}>
          <ListItemText
            primary={DateTime.fromISO(item.date).toFormat('FFF')}
            secondary={`Duration: ${getDurationFromSeconds(item.durationInSeconds)}`}
          />
        </ListItem>
        {index !== recordingInfoList.length - 1 && <Divider component='li' />}
      </Fragment>
    );
  });

  return (
    <SectionCard title='Screen Recordings' disablePadding>
      <Grid container spacing={0}>
        <Grid
          sx={{
            // Mimics the color of the materialUI divider component
            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
          }}
          item
          xs={4}>
          {listItems.length === 0 && (
            <Box sx={{ padding: 3, height: '100%' }}>
              <Typography align='center' variant='body1' component='h1' gutterBottom>
                No recordings found
              </Typography>
            </Box>
          )}

          {listItems.length > 0 && (
            <List sx={{ padding: 0, overflow: 'auto', maxHeight: 0, minHeight: '100%' }}>{listItems}</List>
          )}
        </Grid>

        <Grid item xs={8}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 600, padding: 3 }}>
            {ref === undefined && (
              <EmptyState
                type='video'
                text='No recording selected'
                subText='Please select a recording in the list to the left'
                fixedHeightContainer
              />
            )}

            {ref !== undefined && (
              <Video src={`${addTrailingSlashIfMissing(recordingRelativePath)}${ref}`} canDownload={canDownload} />
            )}
          </Box>
        </Grid>
      </Grid>
    </SectionCard>
  );
};

export default ScreenRecordingCard;

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { DateTime } from 'luxon';
import React, { ForwardedRef, KeyboardEvent, MouseEvent, forwardRef, useCallback, useMemo, useState } from 'react';

import AgentStatsBar from '~components/AgentStatsBar';
import OberonCard from '~components/OberonCard';
import { convertToColor, toAvatarLetters } from '~utils/Functions';

interface AgentCardProps {
  fullname: string;
  username: string;
  campaign?: string;
  to?: string;
  menuItems: { action: () => void; name: string }[];
}

// Formats dates to 2021-10-10
const SIMPLE_DATE_FORMAT = 'yyyy-MM-dd';

const AgentCard = forwardRef(
  ({ fullname, username, campaign, to, menuItems }: AgentCardProps, ref: ForwardedRef<HTMLDivElement>) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuOpen = useCallback((e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setAnchorEl(e.currentTarget);
    }, []);

    const handleMenuClose = useCallback((e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setAnchorEl(null);
    }, []);

    const handleActionClick = useCallback((e: MouseEvent<HTMLLIElement>, callback: () => void) => {
      e.stopPropagation();
      setAnchorEl(null);
      callback();
    }, []);

    const menuList = useMemo(() => {
      return menuItems.map((item, index) => (
        <MenuItem key={index} onClick={(e) => handleActionClick(e, item.action)}>
          {item.name}
        </MenuItem>
      ));
    }, [menuItems]);

    return (
      <OberonCard
        ref={ref}
        titleFontWeight={700}
        title={fullname}
        subHeader={
          <>
            <div>{username}</div>
            {campaign && (
              <div>
                <strong>Campaign: </strong>
                <span>{campaign}</span>
              </div>
            )}
          </>
        }
        avatar={
          <Avatar alt={fullname} style={{ background: convertToColor(username) }}>
            {toAvatarLetters(fullname)}
          </Avatar>
        }
        to={to}
        action={
          <>
            <IconButton
              aria-controls='agent-menu'
              aria-haspopup='true'
              onKeyPress={handleMenuOpen}
              onClick={handleMenuOpen}>
              <MoreHorizIcon />
            </IconButton>
            <Menu id='agent-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
              {menuList}
            </Menu>
          </>
        }
        footer={
          <AgentStatsBar
            date={DateTime.fromJSDate(new Date()).toFormat(SIMPLE_DATE_FORMAT)}
            username={username}
            spinnerType='threeDot'
          />
        }
      />
    );
  },
);

export default AgentCard;

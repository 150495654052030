import MicOffIcon from '@mui/icons-material/MicOff';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect } from 'react';

import { Contact } from '~providers/ConnectProvider/domain';

interface MuteModalProps {
  open: boolean;
  onClose: () => void;
  contact?: Contact;
}

const MuteModal = ({ open, onClose, contact }: MuteModalProps) => {
  // Mute modal should be closed automatically if customer drops off from a call
  useEffect(() => {
    if (open && contact !== undefined && contact.hasActiveContactConnection === false) {
      console.log('+ Lost contact connection, closing MuteModal.');
      onClose();
    }
  }, [contact?.hasActiveContactConnection]);

  return (
    <Dialog open={open}>
      <DialogTitle>You are muted</DialogTitle>

      <DialogContent>
        <MicOffIcon sx={{ fontSize: 131 }} />
      </DialogContent>

      <DialogActions>
        <Button fullWidth variant='contained' color='primary' disableElevation onClick={onClose}>
          Unmute
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MuteModal;

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';

import { OutcomesFormProps } from '~components/OutcomeCapture';

const OutcomesForm = ({ form, formSubmitting }: OutcomesFormProps) => {
  useEffect(() => {
    form.addSchemaProperties({
      agent_assistance: {
        value: '',
        validators: [],
      },
    });

    return () => {
      form.removeSchemaProperties(['agent_assistance']);
    };
  }, [form.fields]);

  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant='outlined'
          id='agent_assistance'
          name='agent_assistance'
          label='Agent Assistance'
          disabled={formSubmitting}
          value={form.fields?.agent_assistance?.value || ''}
          error={Boolean(form.errors.agent_assistance)}
          helperText={form.errors.agent_assistance && form.errors.agent_assistance[0]!}
          onChange={form.handleInputChange}
        />
      </Grid>
    </>
  );
};

export default OutcomesForm;

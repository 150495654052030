import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TabPanel } from '~components/TabPanel';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useSetPageTitleProps } from '~providers/PageTitleProvider';

import AgentList from './AgentList';
import DispositionAttributesList from './DispositionAttributesList';
import ExclusionListList from './ExclusionListList';
import PredictiveAgentStatuses from './PredictiveAgentStatuses';
import SkillTypeList from './SkillTypeList';

// Used to manage visible tab selection
enum ViewTabsType {
  Agents,
  SkillTypes,
  ExclusionLists,
  DispositionAttributes,
  PredictiveAgentStates,
}

const a11yProps = (index: number) => ({
  'id': `tab-${index}`,
  'aria-controls': `tab-${index}`,
});

const SystemManagement = () => {
  const appConfig = useAppConfiguration();
  const setPageTitleProps = useSetPageTitleProps();
  const [searchParams, setSearchParams] = useSearchParams({
    show: ViewTabsType[0],
  });
  const tabIndex = +ViewTabsType[searchParams.get('show') as any];

  // Set page title
  useEffect(() => {
    setPageTitleProps({ pageName: 'System Configuration' });
  }, []);

  const onTabChange = (e: ChangeEvent<{}>, tabIndex: number): void => {
    setSearchParams({
      show: ViewTabsType[tabIndex],
    });
  };

  return (
    <>
      <Typography variant='h4' component='h1' gutterBottom>
        System Configuration
      </Typography>

      <Tabs
        orientation='horizontal'
        variant='scrollable'
        onChange={onTabChange}
        value={tabIndex}
        indicatorColor='primary'
        aria-label='Vertical tabs example'>
        <Tab label='Agents' {...a11yProps(ViewTabsType.Agents)} value={ViewTabsType.Agents} />
        <Tab label='Skill Types' {...a11yProps(ViewTabsType.SkillTypes)} value={ViewTabsType.SkillTypes} />
        <Tab label='Exclusion Lists' {...a11yProps(ViewTabsType.ExclusionLists)} value={ViewTabsType.ExclusionLists} />
        <Tab
          label='Disposition Attributes'
          {...a11yProps(ViewTabsType.DispositionAttributes)}
          value={ViewTabsType.DispositionAttributes}
        />

        {appConfig.extensions.predictive !== undefined && (
          <Tab
            label='Predictive Agent States'
            {...a11yProps(ViewTabsType.PredictiveAgentStates)}
            value={ViewTabsType.PredictiveAgentStates}
          />
        )}
      </Tabs>
      <Divider variant='fullWidth' component='hr' />

      <TabPanel value={tabIndex} index={ViewTabsType.Agents}>
        <AgentList />
      </TabPanel>

      <TabPanel value={tabIndex} index={ViewTabsType.SkillTypes}>
        <SkillTypeList />
      </TabPanel>

      <TabPanel value={tabIndex} index={ViewTabsType.ExclusionLists}>
        <ExclusionListList />
      </TabPanel>

      <TabPanel value={tabIndex} index={ViewTabsType.DispositionAttributes}>
        <DispositionAttributesList />
      </TabPanel>

      {appConfig.extensions.predictive !== undefined && (
        <TabPanel value={tabIndex} index={ViewTabsType.PredictiveAgentStates}>
          <PredictiveAgentStatuses />
        </TabPanel>
      )}
    </>
  );
};

export default SystemManagement;

import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateTime } from 'luxon';
import React from 'react';

import { DataItem } from '~components/DataItem';
import OberonDialog from '~components/OberonDialog';

import { Callback } from '../../../domain';

interface CallbackDetailsModalProps {
  open: boolean;
  callback?: Callback;
  onClose: () => void;
}

const CallbackDetailsModal = ({ open, callback, onClose }: CallbackDetailsModalProps) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const name = callback?.leadName || '';
  const callbackId = callback?.callbackId;
  const scheduledTimestamp = callback?.scheduled ? DateTime.fromISO(callback?.scheduled).toFormat('FFF') : 'N/A';
  const forAgent = callback?.forAgent || 'Assigned to group';
  const endpoint = callback?.endpoint || 'N/A';
  const timezone = callback?.timezone || 'N/A';
  const notes = callback?.notes || 'No notes provied.';

  return (
    <OberonDialog
      open={open}
      closeOnBackdropClick
      onClose={onClose}
      title={name}
      content={
        <Grid container sx={{ marginBottom: `calc(32px - ${theme.spacing(2)})` }} spacing={2}>
          <Grid item xs={12} sm={6}>
            <DataItem stacked disableMargin={!isExtraSmall} title='Callback ID' value={callbackId} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DataItem stacked disableMargin={!isExtraSmall} title='Scheduled' value={scheduledTimestamp} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DataItem stacked title='Timezone' value={timezone} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DataItem stacked title='For Agent' value={forAgent} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DataItem stacked title='Endpoint' value={endpoint} />
          </Grid>

          <Grid item xs={12}>
            <DataItem stacked title='Notes' value={notes} />
          </Grid>
        </Grid>
      }
    />
  );
};

export default CallbackDetailsModal;

import 'amazon-connect-streams';
import 'connect-rtc-js';

import { DtmfPlayer } from 'play-dtmf';

import { parseBoolean } from '~utils/Functions';

import {
  AgentState,
  AgentStateType,
  ConnectAgentStateDefinition,
  ConnectAgentStatus,
  ConnectContactType,
  ConnectVoiceConnection,
  ConnectionType,
  Contact,
  ContactAttribute,
  ContactAttributeType,
  ContactDirection,
  ContactStateType,
  ContactType,
  Message,
  MessageContentType,
  MessageRoleType,
  MessageType,
  SoftphoneStreamType,
  TimeSeriesStats,
} from './domain';

export const getIDForConnectContact = (connectContactId: string): string => {
  return ContactType.Connect + '__' + connectContactId;
};

export const getIDForTSAChatContact = (chatMessageGroupID: number): string => {
  return ContactType.TSAChat + '__' + chatMessageGroupID;
};

export const getLatestHumanMessage = (messages: Message[] | undefined | null): Message | undefined => {
  if (messages === undefined || messages === null) {
    return undefined;
  }
  let latestMessage: Message | undefined = undefined;
  for (let i = messages.length - 1; i >= 0; i--) {
    const message = messages[i];
    if (message.role !== MessageRoleType.System && message.type === MessageType.Message) {
      latestMessage = message;
      break;
    }
  }
  return latestMessage;
};

export const newConnectAgentStatusModel = (agentState: connect.AgentState): ConnectAgentStatus => {
  return {
    status: agentState.name as unknown as AgentState,
    type: agentState.type as unknown as AgentStateType,
    // Type definition in connect is wrong, should be string or null due to enqueued state not having a timestamp set
    startTimestamp: agentState.startTimestamp as unknown as string | null,
  };
};

/**
 * newContactModel will create a new contact object and initialize values to their defaults,
 * such as "messages", "lastTypeEventTime" and "lastViewedMessageTime".
 */
export const newContactModel = (contact: connect.Contact): Contact => {
  const contactId = contact.getContactId();
  const status = contact.getStatus();
  const statusType = status.type as unknown as ContactStateType;
  const statusTimestamp = status.timestamp;
  const contactType = contact.getType() as unknown as ConnectContactType;
  const queueInfo = contact.getQueue();
  const queueTimestamp = contact.getQueueTimestamp();

  let newContact: Contact = {
    // Shared
    id: getIDForConnectContact(contactId),
    type: ContactType.Connect,
    campaignId: undefined,
    messages: [],
    lastTypeEventTime: 0,
    lastViewedMessageTime: 0,
    // Connect
    contactId: contactId,
    contactType: contactType,
    connections: [],
    isMultiPartyCallEnabled: true, // should enable it for all instances
    isRecordingPaused: false,
    initialContactId: contact.getOriginalContactId() ?? contactId,
    attemptId: undefined,
    leadId: undefined,
    phoneNumber: '',
    name: null,
    statusType: statusType,
    statusTimestamp: statusTimestamp,
    direction: contact.isInbound() ? ContactDirection.Inbound : ContactDirection.Outbound,
    isOnHold: false,
    hasActiveContactConnection: false,
    hasActiveThirdPartyConnection: false,
    hasRealtimeAnalytics: false,
    brand: null,
    attributes: [],
    queueARN: queueInfo.queueARN,
    queue: queueInfo.name,
    queueTimestamp: queueTimestamp,
    // Implementation methods that should be set immediately after calling this function
    initiateInternalTransfer: undefined,
    cancelInternalTransfer: undefined,
    conferenceTransfer: undefined,
    transferTargetAgent: undefined,
    initiatePaymentConference: undefined,
    initiateExternalTransfer: undefined,
    endSession: endContactSession(contact),
    accept: undefined,
    sendMessage: undefined,
    sendIsTypingEvent: undefined,
    putOnHold: undefined,
    takeOffHold: undefined,
    complete: setContactComplete(contact),
    addThirdPartyConnection: addThirdPartyConnection(contact),
    // NOTE (christian)
    // Need to consider usage as its currently destroying currently active third party connection
    // Would we ever have more than one third party connection? if so it might be worth making this a connection level function
    // over a contact level function that's only set if the connection is a third party one
    destroyThirdPartyConnection: destroyThirdPartyConnection(contact),
    sendDTMF: undefined,
  };

  newContact = updateContactModel(newContact, contact);
  return newContact;
};

const createVoiceConnection = (
  contact: connect.Contact,
  connection: connect.VoiceConnection,
): ConnectVoiceConnection => {
  const connectEndpoint = connection.getEndpoint();
  const connectState = connection.getState();
  const connectionId = connection.connectionId;
  return {
    connectionId: connectionId,
    type: connection.getType() as unknown as ConnectionType,
    endpoint: connectEndpoint.phoneNumber || connectEndpoint.name,
    isActive: connection.isActive(),
    isConnected: connection.isConnected(),
    isConnecting: connection.isConnecting(),
    isInitialConnection: connection.isInitialConnection(),
    onHold: connection.isOnHold(),
    onMute: connection.isMute(),
    state: connectState.type,
    stateTimestamp: connectState.timestamp,
    putOnHold: getConnectionPutOnHold(contact, connectionId),
    takeOffHold: getConnectionTakeoffHold(contact, connectionId),
    mute: getConnectionMute(contact, connectionId),
    unmute: getConnectionUnmute(contact, connectionId),
    sendDTMF: getConnectionDTMF(contact, connectionId),
    // this uses the connection.destroy function within connect
    endConnection: getConnectionEndConnection(contact, connectionId),
  };
};

// leverage connection API, important: do not store connection object(store connection ID only)
function getConnectionPutOnHold(contact: connect.Contact, connectionId: string): () => Promise<void> {
  return () =>
    new Promise((resolve, reject) => {
      const connection = getConnectionFromId(contact, connectionId);
      if (!connection) {
        reject(`Could not find connection with connectionId ${connectionId}`);
      } else {
        connection.hold({
          success: () => {
            console.log(`+ Put on hold connection${getConnectionLogInfo(connection)}`);
            resolve();
          },
          failure: (err: string) => {
            console.log(`! Failed to put on hold connection${getConnectionLogInfo(connection)}`);
            reject(JSON.parse(err));
          },
        });
      }
    });
}

function getConnectionTakeoffHold(contact: connect.Contact, connectionId: string): () => Promise<void> {
  return () =>
    new Promise((resolve, reject) => {
      const connection = getConnectionFromId(contact, connectionId);
      if (!connection) {
        reject(`Could not find connection with connectionId ${connectionId}`);
      } else {
        connection.resume({
          success: () => {
            console.log(`+ Take off hold connection${getConnectionLogInfo(connection)}`);
            resolve();
          },
          failure: (err: string) => {
            console.log(`! Failed to take off hold connection${getConnectionLogInfo(connection)}`);
            reject(JSON.parse(err));
          },
        });
      }
    });
}

function getConnectionMute(contact: connect.Contact, connectionId: string): () => Promise<void> {
  return () =>
    new Promise((resolve, reject) => {
      const connection = getConnectionFromId(contact, connectionId);
      if (!connection) {
        reject(`Could not find connection with connectionId ${connectionId}`);
      } else {
        connection.muteParticipant({
          success: () => {
            console.log(`+ Muted connection${getConnectionLogInfo(connection)}`);
            resolve();
          },
          failure: (err: string) => {
            console.log(`! Failed to mute connection${getConnectionLogInfo(connection)}`);
            reject(JSON.parse(err));
          },
        });
      }
    });
}

function getConnectionUnmute(contact: connect.Contact, connectionId: string): () => Promise<void> {
  return () =>
    new Promise((resolve, reject) => {
      const connection = getConnectionFromId(contact, connectionId);
      if (!connection) {
        reject(`Could not find connection with connectionId ${connectionId}`);
      } else {
        connection.unmuteParticipant({
          success: () => {
            console.log(`+ Unmuted connection${getConnectionLogInfo(connection)}`);
            resolve();
          },
          failure: (err: string) => {
            console.log(`! Failed to unmute connection${getConnectionLogInfo(connection)}`);
            reject(JSON.parse(err));
          },
        });
      }
    });
}

function getConnectionDTMF(contact: connect.Contact, connectionId: string): (digit: string) => Promise<void> {
  return (digits: string) =>
    new Promise((resolve, reject) => {
      const connection = getConnectionFromId(contact, connectionId);
      if (!connection) {
        reject(`Could not find connection with connectionId ${connectionId}`);
      } else {
        const dtmfPlayer = new DtmfPlayer();
        // TODO: seems everyone can hear beep sound
        dtmfPlayer.play(digits);
        setTimeout(() => {
          dtmfPlayer.stop();
          dtmfPlayer.close();
        }, 100);
        const connectionInfo = getConnectionLogInfo(connection);
        connection.sendDigits(digits, {
          success: () => {
            console.log(`+ Sent DTMF ${digits} for connection${connectionInfo}`);
            resolve();
          },
          failure: (err: string) => {
            console.error(`! Failed to send DTMF ${digits} for connection${connectionInfo}`);
            reject(JSON.parse(err));
          },
        });
      }
    });
}

function getConnectionEndConnection(contact: connect.Contact, connectionId: string): () => Promise<void> {
  return () =>
    new Promise((resolve, reject) => {
      const connection = getConnectionFromId(contact, connectionId);
      if (!connection) {
        reject(`Could not find connection with connectionId ${connectionId}`);
      } else {
        connection.destroy({
          success: () => {
            console.log(`+ Destroyed connection${getConnectionLogInfo(connection)}`);
            resolve();
          },
          failure: (err: string) => {
            console.log(`! Failed to destroy connection${getConnectionLogInfo(connection)}`);
            reject(JSON.parse(err));
          },
        });
      }
    });
}

// get connection object from connection ID,
// to avoid unexpected error we should not persist connection object, rather do lazy loading
function getConnectionFromId(contact: connect.Contact, connectionId: string): connect.VoiceConnection | undefined {
  let connection;
  const filteredConnections = contact
    .getConnections()
    .filter((c) => c.getConnectionId() === connectionId)
    .map((c) => c as connect.VoiceConnection);
  if (filteredConnections.length === 1) {
    connection = filteredConnections[0];
  }
  return connection;
}

function getConnectionLogInfo(connection: connect.VoiceConnection): string {
  return `(contactId: ${connection.contactId}, connectionId: ${connection.connectionId}, type: ${connection.getType()}, 
  phoneNumber: ${connection.getEndpoint().phoneNumber})`;
}

/**
 * updateContactModel will update fields for the given contact and return a new copy of the object.
 *
 * This is different from "newContactModel" as it will not update some fields such as
 * - "messages",
 * - "lastViewedMessageTime"
 * - and more
 *
 * Those fields are not updated from connect.Contact but via other data streams and so we do not
 * want to erase/change that data with this operation.
 */
export const updateContactModel = (existingContact: Contact, contact: connect.Contact): Contact => {
  const contactId = contact.getContactId();
  if (existingContact.contactId !== contactId) {
    throw new Error(
      'Unexpected error. Invalid contact given, the contact id does not much the given contact. Have: ' +
        existingContact.contactId +
        ' but expected: ' +
        contactId,
    );
  }
  const {
    customer_name,
    campaign_id,
    attempt_id,
    lead_id,
    brand,
    transfer_target_agent,
    realtime_analytics,
    agent_paused_recording,
    ...others
  } = contact.getAttributes();
  const status = contact.getStatus();
  const statusType = status.type as unknown as ContactStateType;
  const timestamp = status.timestamp;
  const contactType = contact.getType() as unknown as ConnectContactType;
  const isMultiPartyCallEnabled = contact.isMultiPartyConferenceEnabled();
  const isRecordingPaused = agent_paused_recording?.value ? parseBoolean(agent_paused_recording.value) : false;
  const initialConnection = contact.getInitialConnection();
  const queueInfo = contact.getQueue();
  const queueTimestamp = contact.getQueueTimestamp();
  const phoneNumber = initialConnection?.getEndpoint()?.phoneNumber;
  const attemptId = +attempt_id?.value || undefined;
  const leadId = +lead_id?.value || undefined;
  const hasRealtimeAnalytics = realtime_analytics?.value ? parseBoolean(realtime_analytics.value) : false;
  const campaignId = campaign_id !== undefined ? +campaign_id.value : undefined;
  const transferTargetAgent = transfer_target_agent?.value || undefined;
  let attributes: ContactAttribute[] = [];

  // Retrieve list of display attributes
  for (const key of Object.keys(others)) {
    if (key.startsWith('cmi')) {
      const split = key.split('_');
      const aType = split[1];

      if (
        aType !== ContactAttributeType.Link &&
        aType !== ContactAttributeType.Img &&
        aType !== ContactAttributeType.Text
      ) {
        console.warn(`Unknown attribute type of ${aType}. Attribute skipped.`);
        continue;
      }

      const aValue = others[key].value;
      const aKey = split.slice(2).join(' ');

      const attr = {
        type: aType as ContactAttributeType,
        key: aKey,
        value: aValue,
      };

      // We want images to appear first, then move on to other property types
      attributes = aType === ContactAttributeType.Img ? [attr, ...attributes] : [...attributes, attr];
    }
  }

  let connections: ConnectVoiceConnection[] = [];
  const agentConnection = contact.getAgentConnection();
  if (contact.getType() === connect.ContactType.VOICE) {
    if (agentConnection && agentConnection.isActive()) {
      connections = contact
        .getConnections()
        // include active connections only, inactive connections can be removed when add new connection to contact
        .filter((c) => c.isActive())
        // there is only one agent connection which is myself, connection type of other agents are not agent
        .filter((c) => c.getType() !== connect.ConnectionType.AGENT)
        .map((c) => createVoiceConnection(contact, c as connect.VoiceConnection));
    }
  }

  return {
    ...existingContact,
    attemptId: attemptId,
    leadId: leadId,
    initialContactId: contact.getOriginalContactId() ?? contactId,
    campaignId: campaignId,
    phoneNumber: phoneNumber,
    contactType: contactType,
    isMultiPartyCallEnabled: isMultiPartyCallEnabled,
    isRecordingPaused: isRecordingPaused,
    name: customer_name?.value ?? null,
    statusType: statusType,
    statusTimestamp: timestamp,
    direction: contact.isInbound() ? ContactDirection.Inbound : ContactDirection.Outbound,
    // For some reason connect started leaving this value true for contact's within the ended
    // status for the current agent. (transfer and on hold scenarios)
    isOnHold: statusType === ContactStateType.Connected && agentConnection.isOnHold(),
    hasActiveContactConnection: Boolean(contact.getActiveInitialConnection()),
    hasActiveThirdPartyConnection: Boolean(contact.getSingleActiveThirdPartyConnection()),
    transferTargetAgent: transferTargetAgent,
    brand: brand?.value ?? null,
    hasRealtimeAnalytics: hasRealtimeAnalytics,
    attributes: attributes,
    queueARN: queueInfo.queueARN,
    queue: queueInfo.name,
    queueTimestamp: queueTimestamp,
    connections: connections,
  };
};

export const messageModel = (item: any): Message => {
  return {
    role: item.ParticipantRole as MessageRoleType,
    content: item.Content,
    name: item.DisplayName,
    date: item.AbsoluteTime,
    type: item.Type as MessageType,
    contentType: item.ContentType as MessageContentType,
  };
};

// conditionally call different mute api, based on how many parties are in the call
// as of amazon-connect-streams 2.1.1, single mute/unmute api does not work correctly in 2-party or multi-party calls
export const muteAgent = (agent: connect.Agent) => async (): Promise<void> => {
  try {
    const contacts = agent.getContacts(connect.ContactType.VOICE);
    if (contacts.length > 0) {
      const currentContact = contacts[0];
      const activeConnections = currentContact.getConnections().filter((c) => c.isActive());
      if (activeConnections.length > 2) {
        const agentConnection = currentContact.getAgentConnection() as connect.VoiceConnection;
        await agentConnection.muteParticipant();
      } else {
        await agent.mute();
      }
    }
  } catch (e) {
    return Promise.reject(e);
  }

  return Promise.resolve();
};

export const unmuteAgent = (agent: connect.Agent) => async (): Promise<void> => {
  try {
    const contacts = agent.getContacts(connect.ContactType.VOICE);
    if (contacts.length > 0) {
      const currentContact = contacts[0];
      const activeConnections = currentContact.getConnections().filter((c) => c.isActive());
      if (activeConnections.length > 2) {
        const agentConnection = currentContact.getAgentConnection() as connect.VoiceConnection;
        await agentConnection.unmuteParticipant();
      } else {
        await agent.unmute();
      }
    }
  } catch (e) {
    return Promise.reject(e);
  }

  return Promise.resolve();
};

export const getMessageUniqueKey = (message: Message): string => {
  return message.name + '|' + message.role + '|' + message.date + '|' + message.contentType;
};

export const mergeMessagesAndAvoidDuplication = (existingMessages: Message[], newMessages: Message[]): Message[] => {
  let messagesAlreadyAdded: { [key: string]: boolean } = {};
  const r: Message[] = [];

  for (const item of existingMessages) {
    const key = getMessageUniqueKey(item);

    r.push(item);
    messagesAlreadyAdded[key] = true;
  }

  for (const item of newMessages) {
    const key = getMessageUniqueKey(item);

    if (messagesAlreadyAdded[key] === true) {
      // skip if we already have this item added
      continue;
    }

    messagesAlreadyAdded[key] = true;
    r.push(item);
  }

  r.sort(function (a: Message, b: Message) {
    if (a.date < b.date) {
      return -1;
    }

    if (a.date > b.date) {
      return 1;
    }

    return 0;
  });

  return r;
};

export const setAgentState =
  (agent: connect.Agent) =>
  (stateName: string): Promise<ConnectAgentStateDefinition> => {
    return new Promise((resolve, reject) => {
      const state = agent.getAgentStates().find((state) => state.name === stateName);

      if (!state) {
        console.error(`! Unable to set agent state to ${stateName} as it does not exist.`);
        reject();
        return;
      }

      agent.setState(
        state,
        {
          success: () => {
            console.log(`+ agent state set to ${stateName}`);
            resolve({
              status: state.name as unknown as AgentState,
              type: state.type as unknown as AgentStateType,
            });
          },
          failure: (e) => {
            console.error(`! agent state failed to set to ${stateName}`);
            reject(JSON.parse(e));
          },
        },
        { enqueueNextState: true },
      );
    });
  };

export const dialPhoneNumber =
  (agent: connect.Agent) =>
  (phoneNumber: string, outboundQueueARN?: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const endpoint = connect.Endpoint.byPhoneNumber(phoneNumber);

      agent.connect(endpoint, {
        queueARN: outboundQueueARN,
        success: () => {
          console.log('+ agent dialled endpoint ');
          resolve();
        },
        failure: (e) => {
          const errorMessage = JSON.parse(e).message;
          console.error('! agent failed to dial endpoint: ', errorMessage);
          reject(errorMessage);
        },
      });
    });
  };

export const sendDtmfKeyInput =
  (contact: connect.Contact) =>
  (digit: string, isThirdPartyConnection?: boolean): Promise<void> => {
    return new Promise((resolve, reject) => {
      const dtmfPlayer = new DtmfPlayer();
      const contactId = contact.getContactId();

      let connection;

      if (isThirdPartyConnection) {
        connection = contact.getSingleActiveThirdPartyConnection();
      } else {
        connection = contact.getActiveInitialConnection();
      }

      if (!connection) {
        reject(new Error(`unable to send dtmf input as there are no connections for contact id of ${contactId}`));
        return;
      }

      dtmfPlayer.play(digit);

      setTimeout(() => {
        dtmfPlayer.stop();
        dtmfPlayer.close();
      }, 100);

      connection.sendDigits(digit, {
        success: () => {
          resolve();
        },
        failure: (err: string) => {
          reject(JSON.parse(err));
        },
      });
    });
  };

export const acceptContact = (contact: connect.Contact) => (): Promise<void> => {
  return new Promise((resolve, reject) => {
    contact.accept({
      success: () => {
        resolve();
      },
      failure: (err: string) => {
        reject(JSON.parse(err));
      },
    });
  });
};

export const setContactOnHold = (contact: connect.Contact) => (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const contactId = contact.getContactId();
    // should not be called(connect stream api 2.1) in two-person call, can put the call in bad state and cannot recover
    const activeConnection = contact.getAgentConnection();

    if (!activeConnection) {
      reject(
        new Error(
          `Unable to place contact with contact id of ${contactId} on hold as it does not have an existing connection.`,
        ),
      );
      return;
    }

    activeConnection.hold({
      success: () => {
        resolve();
      },
      failure: (err: string) => {
        reject(JSON.parse(err));
      },
    });
  });
};

export const setContactResume = (contact: connect.Contact) => (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const contactId = contact.getContactId();
    // should not be called(connect stream api 2.1) in multi-party calls only
    const activeConnection = contact.getAgentConnection();

    if (!activeConnection) {
      reject(
        new Error(
          `Unable to resume call for contact with contact id of ${contactId} as it does not have an existing connection.`,
        ),
      );
      return;
    }

    activeConnection.resume({
      success: () => {
        resolve();
      },
      failure: (err: string) => {
        reject(JSON.parse(err));
      },
    });
  });
};

// Hangs up call/ ends chat conversation and triggers After Call Work
export const endContactSession = (contact: connect.Contact) => (): Promise<void> => {
  return new Promise((resolve, reject) => {
    contact.getAgentConnection().destroy({
      success: () => {
        resolve();
      },
      failure: (err: string) => {
        reject(JSON.parse(err));
      },
    });
  });
};

// Marks the contact as complete ending After Call Work and clearing the contact from connect
export const setContactComplete = (contact: connect.Contact) => (): Promise<void> => {
  return new Promise((resolve, reject) => {
    contact.clear({
      success: () => {
        resolve();
      },
      failure: (err: string) => {
        reject(JSON.parse(err));
      },
    });
  });
};

export const addThirdPartyConnection =
  (contact: connect.Contact) =>
  (phoneNumberOrEndpoint: string | connect.Endpoint): Promise<void> => {
    return new Promise((resolve, reject) => {
      let endpoint;

      // if string we want to convert to endpoint else we expect it to be a type of endpoint
      if (typeof phoneNumberOrEndpoint === 'string') {
        endpoint = connect.Endpoint.byPhoneNumber(phoneNumberOrEndpoint);
      } else {
        endpoint = phoneNumberOrEndpoint;
      }

      contact.addConnection(endpoint, {
        success: () => {
          console.log('+ Added third party connection.');
          resolve();
        },
        failure: (err: string) => {
          console.error('! Failed to add third party connection', JSON.parse(err));
          reject(JSON.parse(err));
        },
      });
    });
  };

export const destroyThirdPartyConnection = (contact: connect.Contact) => (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const contactId = contact.getContactId();
    const connection = contact.getSingleActiveThirdPartyConnection();

    if (!connection) {
      console.error(
        `Unable to end third party connection for contact id of ${contactId} as third party connection does not exist.`,
      );
      reject();
      return;
    }

    connection.destroy({
      success: () => {
        console.log('+ Third party connection ended.');
        resolve();
      },
      failure: () => {
        console.error('! Unable to end third party connection.');
        reject();
      },
    });
  });
};

// We only want to destroy the agent session if we navigate away from the page that uses this provider
// OR if we do a full page refresh
export const destroyAgentSession = (agent: connect.Agent): Promise<void> => {
  return new Promise((resolve, reject) => {
    const offlineState = agent.getAgentStates().find((i) => i.type === connect.AgentStateType.OFFLINE);

    if (!offlineState) {
      console.error('! Unable to destroy agent session as offline state does not exist.');
      reject();
      return;
    }

    agent.setState(offlineState, {
      success: () => {
        console.log(`+ agent state set to ${offlineState.name}`);
        connect.core.terminate();
        resolve();
      },
      failure: () => {
        console.error(`! agent state failed to set to ${offlineState.name}`);
        reject();
      },
    });
  });
};

export const getEndpointByARN =
  (agent: connect.Agent) =>
  (arn: string): Promise<connect.Endpoint> => {
    console.log(`+ searching for endpoint with ARN ${arn}`);

    return new Promise((resolve, reject) => {
      agent.getEndpoints(agent.getAllQueueARNs(), {
        success: (data: any) => {
          const endpoint = data.endpoints.find(
            (ep: connect.Endpoint) => ep.endpointARN.toLowerCase() === arn.toLowerCase(),
          );

          if (!endpoint) {
            const message = 'failed to find endpoint by ARN';
            console.error(`! ${message}: ${arn}`);
            reject(message);
            return;
          }

          resolve(endpoint);
        },
        failure: (err: string) => {
          const message = 'failed getting getAllQueueARNs()';
          console.error(`! ${message}`);
          reject(message);
        },
      });
    });
  };

// used to switch between chat contacts
export const setActiveContact =
  (connectCore: connect.Core) =>
  (contactId: string): void => {
    connectCore.viewContact(contactId);
  };

export const getChatController = async (contact: connect.Contact): Promise<any> => {
  const agentConnection = contact.getAgentConnection() as connect.ChatConnection;

  try {
    const chatController = await agentConnection.getMediaController();

    return Promise.resolve(chatController);
  } catch (e) {
    console.error(JSON.stringify(e));
    return Promise.reject(e);
  }
};

// Usage with activeContactId so make sure to wrap this within a useCallback or useMemo to rebuild function on changes
// to activeContactId
export const sendChatMessage =
  (contact: connect.Contact) =>
  async (message: string): Promise<void> => {
    try {
      const chatController = await getChatController(contact);
      await chatController.sendMessage({ message: message, contentType: MessageContentType.TextPlain });
    } catch (e) {
      console.error('unable to send chat message', e);
      throw e;
    }
  };

export const downloadSessionLogs = () => {
  connect.getLog().download();
};

export const getTimeSeriesStats = (
  currentStats: connect.AudioStats,
  previousStats: connect.AudioStats | undefined,
  streamType: SoftphoneStreamType,
): TimeSeriesStats => {
  let packetsLostDelta = currentStats.packetsLost;
  let packetsCountDelta = currentStats.packetsCount;

  // Get the delta between states
  // Send as seperate fields with totals
  if (previousStats && currentStats) {
    packetsLostDelta =
      currentStats.packetsLost > previousStats.packetsLost ? currentStats.packetsLost - previousStats.packetsLost : 0;
    packetsCountDelta =
      currentStats.packetsCount > previousStats.packetsCount
        ? currentStats.packetsCount - previousStats.packetsCount
        : 0;
  }

  return {
    timestamp: currentStats.timestamp,
    packetsLost: currentStats.packetsLost,
    packetsLostDelta: packetsLostDelta,
    packetsCount: currentStats.packetsCount,
    packetsCountDelta: packetsCountDelta,
    softphoneStreamType: streamType,
    // Note (christian): raw stats wise jitter buffer is always an INT except a few places missed via the rtp-stats logic
    // so we fix it up here
    jitterBufferMillis: Math.ceil(currentStats.jbMilliseconds),
    // Note (christian): raw stats wise round trip time is always an INT except a few places missed via the rtp-stats logic
    // so we fix it up here
    roundTripTimeMillis: Math.ceil(currentStats.rttMilliseconds),
  };
};

export const setAgentOnline = (agent: connect.Agent) => (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const state = agent.getAgentStates().find((state) => state.type === connect.AgentStateType.ROUTABLE);
    const agentState = agent.getState();

    // No point switching them if they are already there
    if (agentState.type === connect.AgentStateType.ROUTABLE) {
      console.error('! Agent already routable do nothing.');
      return resolve();
    }

    // There should always be one set in connect, but we cannot trust that they wont remove this or change something
    // so we catch the error here and do nothing
    if (state === undefined) {
      console.error('! Unable to set agent to a routable state as one does not exist in connect.');
      return reject();
    }

    agent.setState(state, {
      success: () => {
        console.log(`+ agent state set to ${state.name}`);
        resolve();
      },
      failure: () => {
        console.error(`! agent state failed to set to ${state.name}`);
        reject();
      },
    });
  });
};

export const setAgentOffline = (agent: connect.Agent) => (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const state = agent.getAgentStates().find((state) => state.type === connect.AgentStateType.OFFLINE);
    const agentState = agent.getState();

    // No point switching them if they are already there
    if (agentState.type === connect.AgentStateType.OFFLINE) {
      console.error('! Agent already offline do nothing.');
      return resolve();
    }

    // There should always be one set in connect, but we cannot trust that they wont remove this or change something
    // so we catch the error here and do nothing
    if (state === undefined) {
      console.error('! Unable to set agent offline as an offline based state type does not exist in connect.');
      return reject();
    }

    agent.setState(state, {
      success: () => {
        console.log(`+ agent state set to ${state.name}`);
        resolve();
      },
      failure: () => {
        console.error(`! agent state failed to set to ${state.name}`);
        reject();
      },
    });
  });
};

export const getActiveAgentConnection = (agent: connect.Agent): connect.VoiceConnection | undefined => {
  const contacts = agent.getContacts(connect.ContactType.VOICE);
  if (contacts.length > 0) {
    const currentContact = contacts[0];
    const agentConnection = currentContact.getAgentConnection() as connect.VoiceConnection;
    return agentConnection.isActive() ? agentConnection : undefined;
  }
  return undefined;
};

import * as j from '@mojotech/json-type-validation';

import { Lead, LeadAttempt, LeadStatusType } from '~pages/CampaignManagement/domain';

export interface LeadsResponse {
  nextPageUrl: string | null;
  list: Lead[];
}

const GetLeadItemResponseDecoder: j.Decoder<Lead> = j
  .object({
    lead_id: j.number(),
    lead_external_id: j.string(),
    lead_name: j.string(),
    lead_status: j.oneOf(
      j.constant(LeadStatusType.Assigned),
      j.constant(LeadStatusType.AwaitingRetry),
      j.constant(LeadStatusType.AwaitingStart),
      j.constant(LeadStatusType.Building),
      j.constant(LeadStatusType.Callback),
      j.constant(LeadStatusType.Connected),
      j.constant(LeadStatusType.Contacted),
      j.constant(LeadStatusType.Duplicate),
      j.constant(LeadStatusType.Excluded),
      j.constant(LeadStatusType.Filtered),
      j.constant(LeadStatusType.Finished),
      j.constant(LeadStatusType.FinishedToday),
      j.constant(LeadStatusType.Initiated),
      j.constant(LeadStatusType.NoEndpoints),
      j.constant(LeadStatusType.NoSkilledAgents),
      j.constant(LeadStatusType.OptOut),
      j.constant(LeadStatusType.OutOfHours),
      j.constant(LeadStatusType.Ready),
      j.constant(LeadStatusType.Washed),
      j.constant(LeadStatusType.Disconnected),
      j.constant(LeadStatusType.InvalidEndpoint),
      j.constant(LeadStatusType.Removed),
      j.constant(LeadStatusType.Expired),
      j.constant(LeadStatusType.AwaitingSMS),
      j.constant(LeadStatusType.InactiveList),
      j.constant(LeadStatusType.MissedCallback),
      j.constant(LeadStatusType.InQueue),
      j.constant(LeadStatusType.AwaitingCallback),
      j.constant(LeadStatusType.Replaced),
    ),
    lead_status_detail: j.string(),
    priority: j.union(j.number(), j.constant(null)),
    timezone: j.string(),
    added_timestamp: j.union(j.string(), j.constant(null)),
    expiration_timestamp: j.union(j.string(), j.constant(null)),
    endpoints: j.array(j.string()),
    num_attempts: j.number(),
    attempts: j.array(
      j.object({
        timestamp: j.string(),
        outcome: j.union(j.string(), j.constant(null)),
      }),
    ),
    required_skills: j.array(j.string()),
    attributes: j.union(j.dict(j.string()), j.constant(null)),
  })
  .map((item: any) => ({
    id: item.lead_id,
    externalId: item.lead_external_id,
    name: item.lead_name,
    leadStatus: item.lead_status,
    leadStatusDetail: item.lead_status_detail,
    priority: item.priority,
    timezone: item.timezone,
    addedTimestamp: item.added_timestamp,
    expirationTimestamp: item.expiration_timestamp,
    endpoints: item.endpoints,
    numAttempts: item.num_attempts,
    attempts: item.attempts.map(
      (attempt: any): LeadAttempt => ({
        timestamp: attempt.timestamp,
        outcome: attempt.outcome,
      }),
    ),
    requiredSkills: item.required_skills,
    attributes: item.attributes,
  }));

export const GetLeadsResponseDecoder: j.Decoder<LeadsResponse> = j
  .object({
    next_page_url: j.union(j.string(), j.constant(null)),
    list: j.array(GetLeadItemResponseDecoder),
  })
  .map((item: any) => ({
    nextPageUrl: item.next_page_url,
    list: item.list,
  }));

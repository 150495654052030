import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import useTheme from '@mui/material/styles/useTheme';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { DataItem } from '~components/DataItem';
import SectionCard from '~components/SectionCard';
import useForm, { ValidatorType } from '~hooks/useForm';
import { moveLeadsListCampaign, updateLeadsListById } from '~pages/CampaignManagement/api';
import { CampaignListItem, LeadList, UpdateLeadListGeneralSettings } from '~pages/CampaignManagement/domain';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { getCampaigns } from '../../api';

const enum EditType {
  General = 'general',
}

interface LeadListSettingsProps {
  leadList: LeadList;
  triggerLeadListRefresh: () => Promise<void>;
}

const DATE_FORMAT = 'FFF';

const formatDate = (date: string, formatString: string) => {
  if (date) {
    return DateTime.fromISO(date).toFormat(formatString);
  }
  return '-';
};

const LeadListSettings = ({ leadList, triggerLeadListRefresh }: LeadListSettingsProps) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { pushNotification } = useNotification();
  const [edit, setEdit] = useState<EditType | undefined>(undefined);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submittingMove, setSubmittingMove] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<CampaignListItem[]>([]);
  const [moveToCampaign, setMoveToCampaign] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const [endTimeValidationError, setEndTimeValidationError] = useState<string | undefined>(undefined);
  const { campaignId, listId } = useParams() as { campaignId: string; listId: string };
  const {
    fields,
    errors,
    handleInputChange,
    handleUnconventionalInputChange,
    handleSubmit,
    addSchemaProperties,
    removeSchemaProperties,
    setAsyncFields,
  } = useForm({
    name: {
      validators: [{ type: ValidatorType.Required, message: 'List name is required.' }],
      value: leadList.name,
    },
    description: {
      validators: [{ type: ValidatorType.Required, message: 'List description is required.' }],
      value: leadList.description,
    },
    priority: {
      validators: [{ type: ValidatorType.Required, message: 'List priority is required.' }],
      value: leadList.priority,
    },
    setStartEndTime: {
      validators: [],
      value: false,
    },
    isActive: {
      validators: [],
      value: leadList.isActive,
    },
  });
  const fieldsDateToMinDate = useMemo(
    () => (fields.startTime === undefined ? undefined : DateTime.fromISO(fields.startTime.value)),
    [fields.startTime],
  );

  // Manages optional start and end time properties within schema
  useEffect(() => {
    if (fields.setStartEndTime.value === true) {
      addSchemaProperties({
        startTime: {
          validators: [],
          value: leadList.startTime || null,
        },
        endTime: {
          validators: [],
          value: leadList.endTime || null,
        },
      });

      return;
    }

    removeSchemaProperties(['startTime', 'endTime']);
    setEndTimeValidationError(undefined);
  }, [fields.setStartEndTime.value]);

  // Evaluates local form based proeprties based on dynamic values when the
  // particular form is opened
  useEffect(() => {
    if (edit === EditType.General) {
      setAsyncFields({
        setStartEndTime: Boolean(leadList.startTime) || Boolean(leadList.endTime),
      });
    }
  }, [edit]);

  useEffect(() => {
    (async () => {
      let campaigns: CampaignListItem[];
      try {
        campaigns = await getCampaigns();
      } catch (e) {
        pushNotification('error', 'Unable to fetch list of campaigns');
        return;
      }
      // We only want active campaigns, any archived should not be in the list
      setCampaigns(campaigns.filter((item) => !item.archived));
      // set initial value to current campaign
      setMoveToCampaign(campaignId);
    })();
  }, []);

  const onMoveToCampaignChange = useCallback(async (e: ChangeEvent<any>) => {
    setMoveToCampaign(e.target.value);
  }, []);

  const moveToCampaignListDisplay = useMemo(
    () =>
      campaigns.map((item, index) => (
        <MenuItem key={index} value={item.id + ''}>
          {item.name}
        </MenuItem>
      )),
    [campaigns],
  );

  const onMoveToCampaignSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    if (moveToCampaign === undefined || moveToCampaign == campaignId) {
      return;
    }
    setSubmittingMove(true);
    try {
      await moveLeadsListCampaign(+moveToCampaign, +listId);
    } catch (e) {
      if ((e as APIError).status === 400) {
        pushNotification('error', 'Unable to move only list in campaign or default list. Create a new list first.');
        return;
      }

      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return;
    } finally {
      setSubmittingMove(false);
    }
    pushNotification('success', 'You have successfully moved the list.');
    navigate(`/campaigns/${moveToCampaign}?show=LeadLists`);
  };

  const toggleEdit = (value: EditType) => () => {
    setEdit((prev) => (prev === value ? undefined : value));
  };

  // Hack of applying <DateTime> generic type on the DatePicker components as then it
  // inforces correct typing for this onChange function so it's typing is not messed up. This breaks visible
  // typing however even though it passed type checking. i.e. defers TDate generic from input value which is wrong
  // as minDate then also overrides this with its DateTime generic
  const handleDateTimeChange = (fieldName: string) => (date: DateTime | null) => {
    handleUnconventionalInputChange(fieldName, date);
  };

  const onGeneralSettingsSubmit = handleSubmit(
    async (formData: UpdateLeadListGeneralSettings & { setStartEndTime: boolean }) => {
      setSubmitting(true);
      const { setStartEndTime, ...data } = formData;

      // If this is false we want to set start and end time properties as undefined
      // else we want to define them as undefined if null
      if (!setStartEndTime) {
        data.startTime = undefined;
        data.endTime = undefined;
      } else {
        data.startTime = data.startTime || undefined;
        data.endTime = data.endTime || undefined;
      }

      try {
        await updateLeadsListById(+campaignId, +listId, data);
      } catch (e) {
        pushNotification('error', (e as APIError | UnsupportedStructureError).message);
        return;
      } finally {
        setSubmitting(false);
      }

      pushNotification('success', 'You have successfully updated the list.');
      await triggerLeadListRefresh();
      toggleEdit(EditType.General)();
    },
  );

  return (
    <>
      <SectionCard title='General' onEdit={toggleEdit(EditType.General)}>
        {edit !== EditType.General && (
          <>
            <DataItem stacked={isExtraSmall} disableMargin title='Is Active' value={leadList.isActive ? 'Yes' : 'No'} />
            <DataItem stacked={isExtraSmall} title='List Name' value={leadList?.name} />
            <DataItem stacked={isExtraSmall} title='Description' value={leadList?.description} />
            <DataItem stacked={isExtraSmall} title='Start Time' value={formatDate(leadList.startTime, DATE_FORMAT)} />
            <DataItem stacked={isExtraSmall} title='End Time' value={formatDate(leadList.endTime, DATE_FORMAT)} />
          </>
        )}

        {edit === EditType.General && (
          <form onSubmit={onGeneralSettingsSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  value='top'
                  control={
                    <Switch
                      id='isActive'
                      name='isActive'
                      color='primary'
                      checked={fields.isActive.value}
                      onChange={handleInputChange}
                    />
                  }
                  label='Is Active?'
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant='outlined'
                  id='name'
                  name='name'
                  label='List Name'
                  disabled={submitting}
                  required={true}
                  defaultValue={fields.name.value}
                  error={Boolean(errors.name)}
                  helperText={errors.name && errors.name[0]!}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant='outlined'
                  id='description'
                  name='description'
                  label='List Description'
                  disabled={submitting}
                  required={true}
                  defaultValue={fields.description.value}
                  error={Boolean(errors.description)}
                  helperText={errors.description && errors.description[0]!}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type='number'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputProps: { min: 1, max: 20 } }}
                  variant='outlined'
                  id='priority'
                  name='priority'
                  label='List Priority'
                  disabled={submitting}
                  required={true}
                  defaultValue={fields.priority.value}
                  error={Boolean(errors.priority)}
                  helperText={errors.priority && errors.priority[0]!}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id='setStartEndTime'
                      name='setStartEndTime'
                      checked={fields.setStartEndTime.value}
                      onChange={handleInputChange}
                    />
                  }
                  label='Set Start or End Time?'
                />
              </Grid>

              {fields.setStartEndTime.value && (
                <>
                  <Grid item xs={12} md={6}>
                    <DateTimePicker
                      disableMaskedInput
                      componentsProps={{
                        actionBar: {
                          actions: ['clear'],
                        },
                      }}
                      disabled={submitting}
                      label='Start Time'
                      inputFormat='dd/MM/yyyy hh:mm a'
                      value={fields.startTime?.value}
                      onChange={handleDateTimeChange('startTime')}
                      renderInput={(params) => <TextField {...params} fullWidth variant='outlined' />}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <DateTimePicker
                      disableMaskedInput
                      componentsProps={{
                        actionBar: {
                          actions: ['clear'],
                        },
                      }}
                      minDate={fieldsDateToMinDate}
                      onError={(reason) => {
                        if (reason === 'minDate') {
                          setEndTimeValidationError('List End Time should not be before Start Time.');
                          return;
                        }

                        setEndTimeValidationError(undefined);
                      }}
                      disabled={submitting}
                      label='End Time'
                      inputFormat='dd/MM/yyyy hh:mm a'
                      value={fields.endTime?.value}
                      onChange={handleDateTimeChange('endTime')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant='outlined'
                          error={Boolean(endTimeValidationError)}
                          helperText={endTimeValidationError}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}

              <Grid sx={{ textAlign: 'right' }} item xs={12}>
                <Button onClick={toggleEdit(EditType.General)}>Cancel</Button>

                <LoadingButton
                  sx={{ marginLeft: 1 }}
                  type='submit'
                  variant='contained'
                  disableElevation
                  loading={submitting}
                  color='primary'>
                  Update
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        )}
      </SectionCard>

      <SectionCard title='Move List to Different Campaign'>
        <form onSubmit={onMoveToCampaignSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                variant='outlined'
                id='moveToCampaign'
                name='moveToCampaign'
                label='Destination Campaign'
                value={moveToCampaign || ''}
                onChange={onMoveToCampaignChange}>
                {moveToCampaignListDisplay}
              </TextField>
            </Grid>

            <Grid sx={{ textAlign: 'right' }} item xs={12} md={6}>
              <LoadingButton
                sx={{ marginLeft: 1 }}
                type='submit'
                variant='contained'
                disableElevation
                loading={submittingMove}
                disabled={moveToCampaign === undefined || moveToCampaign === campaignId}
                color='primary'>
                Move List
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </SectionCard>
    </>
  );
};

export default LeadListSettings;

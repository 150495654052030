import * as j from '@mojotech/json-type-validation';

import { CampaignAssignRecord, RoutingProfileDecoder } from '~pages/CampaignManagement/domain';

export interface Agent {
  campaignId: number;
  campaignName: string;
  fullName: string;
  firstName: string;
  lastName: string;
  username: string;
  routingProfileId: string | null;
  /**
   * disabled is the timestamp when the user was disabled.
   * value is null if they're not disabled.
   */
  disabled: string | null;
  skills: string[];
  asyncQueues: string[];
}

/**
 * AgentUpdateRequest is used when adding / updating Agent records in the backend
 */
export interface AgentUpdateRequest {
  username: string;
  firstName: string;
  lastName: string;
  campaignId: number | null;
  routingProfileId: string | null;
  skills: string[];
  asyncQueues: string[];
}

export interface SkillType {
  skillType: string;
  description: string;
}

export interface ExclusionList {
  name: string;
  description: string;
  numEntries: string;
}

export interface CreateExclusionList {
  name: string;
  description: string;
}

export interface ExclusionEntry {
  number: string;
  reason: string;
}

export interface ExclusionListListedEndpoint {
  isListed: boolean;
  listName: string;
  phone: string;
}

export interface ExclusionListUploadResponse {
  badEndpoints: string[];
  importErrorCount: number;
  validCount: number;
}

export interface RecordingInfo {
  durationInSeconds: number;
  date: string;
  recordingId: string;
}

export interface DispositionAttribute {
  attribute: string;
  values: string[];
}

export interface PredictiveAgentStatus {
  agentStatus: string;
  description: string;
  position: number;
}

export const GetAgentResponseDecoder: j.Decoder<Agent> = j
  .object({
    full_name: j.string(),
    username: j.string(),
    disabled: j.union(j.string(), j.constant(null)),
    skills: j.array(j.string()),
    campaign_id: j.union(j.number(), j.constant(null)),
    campaign_name: j.union(j.string(), j.constant(null)),
    routing_profile_id: j.union(j.string(), j.constant(null)),
    async_queues: j.array(j.string()),
  })
  .map((item): Agent => {
    const nameSplit = item.full_name.split(' ');

    return {
      firstName: nameSplit[0],
      lastName: nameSplit[1],
      username: item.username,
      fullName: item.full_name,
      skills: item.skills,
      disabled: item.disabled,
      campaignName: item.campaign_name ?? '',
      campaignId: item.campaign_id ?? 0,
      routingProfileId: item.routing_profile_id,
      asyncQueues: item.async_queues,
    };
  });

const GetSkillTypeResponseDecoder: j.Decoder<SkillType> = j
  .object({
    skill_type: j.string(),
    description: j.string(),
  })
  .map((item: any) => ({
    skillType: item.skill_type,
    description: item.description,
  }));

export const GetSkillTypesResponseDecoder: j.Decoder<SkillType[]> = j
  .object({
    skill_types: j.array(GetSkillTypeResponseDecoder),
  })
  .map((item: any) => item.skill_types);

const GetExclusionListResponseDecoder: j.Decoder<ExclusionList> = j
  .object({
    name: j.string(),
    description: j.string(),
    num_entries: j.number(),
  })
  .map((item: any) => ({
    name: item.name,
    description: item.description,
    numEntries: item.num_entries,
  }));

export const GetExclusionListsResponseDecoder: j.Decoder<ExclusionList[]> = j
  .object({
    exclusion_lists: j.array(GetExclusionListResponseDecoder),
  })
  .map((item: any) => item.exclusion_lists);

export const GetExclusionListListedEndpointResponseDecoder: j.Decoder<ExclusionListListedEndpoint> = j
  .object({
    is_listed: j.boolean(),
    list_name: j.string(),
    phone: j.string(),
  })
  .map((item: any) => ({
    isListed: item.is_listed,
    listName: item.list_name,
    phone: item.phone,
  }));

export const GetExclusionListUploadResponseDecoder: j.Decoder<ExclusionListUploadResponse> = j
  .object({
    bad_endpoints: j.array(j.string()),
    import_error_count: j.number(),
    valid_count: j.number(),
  })
  .map((item: any) => ({
    badEndpoints: item.bad_endpoints,
    importErrorCount: item.import_error_count,
    validCount: item.valid_count,
  }));

const GetRecordingInfoItemResponseDecoder: j.Decoder<RecordingInfo> = j
  .object({
    duration_in_seconds: j.number(),
    date: j.string(),
    recording_id: j.string(),
  })
  .map((item: any) => ({
    durationInSeconds: item.duration_in_seconds,
    date: item.date,
    recordingId: item.recording_id,
  }));

export const GetRecordingInfoListResponseDecoder: j.Decoder<RecordingInfo[]> = j
  .object({
    recording_info_list: j.array(GetRecordingInfoItemResponseDecoder),
  })
  .map((item: any) => item.recording_info_list);

const GetDispositionAttributeResponseDecoder: j.Decoder<DispositionAttribute> = j
  .object({
    attribute: j.string(),
    values: j.array(j.string()),
  })
  .map((item: any) => ({
    attribute: item.attribute,
    values: item.values,
  }));

export const GetDispositionAttributesResponseDecoder: j.Decoder<DispositionAttribute[]> = j
  .object({
    attributes: j.union(j.array(GetDispositionAttributeResponseDecoder), j.constant(null)),
  })
  .map((item: any) => item.attributes || []);

const GetCampaignAssignListDecoder: j.Decoder<CampaignAssignRecord> = j
  .object({
    id: j.number(),
    name: j.string(),
    routing_profiles: j.array(RoutingProfileDecoder),
  })
  .map(
    (item): CampaignAssignRecord => ({
      id: item.id,
      name: item.name,
      routingProfiles: item.routing_profiles,
    }),
  );

export const GetCampaignAssignListResponseDecoder: j.Decoder<CampaignAssignRecord[]> = j
  .object({
    campaigns: j.union(j.array(GetCampaignAssignListDecoder), j.constant(null)),
  })
  .map((resp) => resp.campaigns ?? []);

const GetPredictiveAgentStatusListDecoder: j.Decoder<PredictiveAgentStatus> = j
  .object({
    agent_status: j.string(),
    description: j.string(),
    position: j.number(),
  })
  .map(
    (item): PredictiveAgentStatus => ({
      agentStatus: item.agent_status,
      description: item.description,
      position: item.position,
    }),
  );

export const GetPredictiveAgentStatusesDecoder: j.Decoder<PredictiveAgentStatus[]> = j
  .object({
    statuses: j.union(j.array(GetPredictiveAgentStatusListDecoder), j.constant(null)),
  })
  .map((resp) => resp.statuses ?? []);

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { FormEvent, useEffect } from 'react';

import OberonDialog from '~components/OberonDialog';
import useForm, { ValidatorType } from '~hooks/useForm';
import { assertUnreachable } from '~utils/Functions';

import { ExclusionEntry } from '../../domain';

export const enum EndpointActionType {
  Add = 'add',
  Check = 'check',
  Remove = 'remove',
}

interface EndpointActionModalProps {
  open: boolean;
  endpointActionType?: EndpointActionType;
  submitting: boolean;
  onClose: () => void;
  onAddEndpoint: (data: ExclusionEntry) => Promise<void>;
  onCheckEndpoint: (endpoint: string) => Promise<void>;
  onRemoveEndpoint: (endpoint: string) => Promise<void>;
}

const EndpointActionModal = ({
  open,
  endpointActionType,
  submitting,
  onClose,
  onAddEndpoint,
  onCheckEndpoint,
  onRemoveEndpoint,
}: EndpointActionModalProps) => {
  const isLoading = submitting;
  const addEndpoint = useForm({
    endpoint: {
      validators: [{ type: ValidatorType.Required, message: 'Endpoint is required.' }],
      value: '',
    },
    reason: {
      validators: [{ type: ValidatorType.Required, message: 'Reason is required.' }],
      value: '',
    },
  });
  const checkEndpoint = useForm({
    endpoint: {
      validators: [{ type: ValidatorType.Required, message: 'Endpoint is required.' }],
      value: '',
    },
  });
  const removeEndpoint = useForm({
    endpoint: {
      validators: [{ type: ValidatorType.Required, message: 'Endpoint is required.' }],
      value: '',
    },
  });
  // Will always be one of the three EndpointActionType Options if modal is open, else empty
  let title = '';

  if (endpointActionType === EndpointActionType.Add) {
    title = 'Add Endpoint';
  } else if (endpointActionType === EndpointActionType.Check) {
    title = 'Check endpoints in Exclusion List';
  } else if (endpointActionType === EndpointActionType.Remove) {
    title = 'Remove Endpoint';
  }

  useEffect(() => {
    return () => {
      addEndpoint.resetForm();
      checkEndpoint.resetForm();
      removeEndpoint.resetForm();
    };
  }, [open]);

  const onAddEndpointSubmit = addEndpoint.handleSubmit(async (data: { [key: string]: any }) => {
    try {
      await onAddEndpoint({
        number: data.endpoint,
        reason: data.reason,
      });
    } catch (e) {
      return;
    }

    addEndpoint.resetForm();
  });

  const onCheckEndpointSubmit = checkEndpoint.handleSubmit(async (data: { [key: string]: any }) => {
    try {
      await onCheckEndpoint(data.endpoint);
    } catch (e) {
      return;
    }

    checkEndpoint.resetForm();
  });

  const onRemoveEndpointSubmit = removeEndpoint.handleSubmit(async (data: { [key: string]: any }) => {
    try {
      await onRemoveEndpoint(data.endpoint);
    } catch (e) {
      return;
    }

    checkEndpoint.resetForm();
  });

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (endpointActionType === undefined) {
      console.error('EndpointActionType not specified');
      return;
    }

    switch (endpointActionType) {
      case EndpointActionType.Add: {
        onAddEndpointSubmit();
        break;
      }
      case EndpointActionType.Check: {
        onCheckEndpointSubmit();
        break;
      }
      case EndpointActionType.Remove: {
        onRemoveEndpointSubmit();
        break;
      }
      default: {
        assertUnreachable(endpointActionType);
      }
    }
  };

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title={title}
      content={
        <Grid container spacing={2}>
          {endpointActionType === EndpointActionType.Add && (
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant='outlined'
                  id='endpoint'
                  name='endpoint'
                  label='Endpoint'
                  disabled={isLoading}
                  required={true}
                  value={addEndpoint.fields.endpoint.value}
                  error={Boolean(addEndpoint.errors.endpoint)}
                  helperText={addEndpoint.errors.endpoint && addEndpoint.errors.endpoint[0]!}
                  onChange={addEndpoint.handleInputChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant='outlined'
                  id='reason'
                  name='reason'
                  label='Reason'
                  disabled={isLoading}
                  required={true}
                  value={addEndpoint.fields.reason.value}
                  error={Boolean(addEndpoint.errors.reason)}
                  helperText={addEndpoint.errors.reason && addEndpoint.errors.reason[0]!}
                  onChange={addEndpoint.handleInputChange}
                />
              </Grid>
            </>
          )}

          {endpointActionType === EndpointActionType.Check && (
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant='outlined'
                  id='endpoint'
                  name='endpoint'
                  label='Endpoint'
                  disabled={isLoading}
                  required={true}
                  value={checkEndpoint.fields.endpoint.value}
                  error={Boolean(checkEndpoint.errors.endpoint)}
                  helperText={checkEndpoint.errors.endpoint && checkEndpoint.errors.endpoint[0]!}
                  onChange={checkEndpoint.handleInputChange}
                />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  type='submit'
                  variant='contained'
                  disableElevation
                  color='primary'
                  disabled={isLoading}
                  loading={isLoading}>
                  Check Endpoint
                </LoadingButton>
              </Grid>
            </>
          )}

          {endpointActionType === EndpointActionType.Remove && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant='outlined'
                id='endpoint'
                name='endpoint'
                label='Endpoint'
                disabled={isLoading}
                required={true}
                value={removeEndpoint.fields.endpoint.value}
                error={Boolean(removeEndpoint.errors.endpoint)}
                helperText={removeEndpoint.errors.endpoint && removeEndpoint.errors.endpoint[0]!}
                onChange={removeEndpoint.handleInputChange}
              />
            </Grid>
          )}
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          {(endpointActionType === EndpointActionType.Add || endpointActionType === EndpointActionType.Remove) && (
            <LoadingButton
              type='submit'
              variant='contained'
              disableElevation
              color='primary'
              disabled={isLoading}
              loading={isLoading}>
              Submit
            </LoadingButton>
          )}
        </>
      }
    />
  );
};

export default EndpointActionModal;

import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TabPanel } from '~components/TabPanel';
import { useSetPageTitleProps } from '~providers/PageTitleProvider';

import CampaignList from './CampaignList';
import CampaignsDashboard from './CampaignsDashboard';

// Used to manage visible tab selection
enum ViewTabsType {
  Campaigns,
  Dashboard,
}

// default tab, fallback to this tab when no permission on specified tab
const defaultViewTab = ViewTabsType.Campaigns;

const a11yProps = (index: number) => ({
  'id': `tab-${index}`,
  'aria-controls': `tab-${index}`,
});

const CampaignManagement = () => {
  const setPageTitleProps = useSetPageTitleProps();
  const [searchParams, setSearchParams] = useSearchParams({
    show: ViewTabsType[defaultViewTab],
  });
  const tabIndex = +ViewTabsType[searchParams.get('show') as any];

  // Set page title
  useEffect(() => {
    setPageTitleProps({ pageName: 'Campaign Configuration' });
  }, []);

  const onTabChange = (e: ChangeEvent<{}>, tabIndex: number): void => {
    setSearchParams({
      show: ViewTabsType[tabIndex],
    });
  };

  return (
    <>
      <Typography variant='h4' component='h1' gutterBottom>
        Campaign Configuration
      </Typography>

      <Tabs
        orientation='horizontal'
        variant='scrollable'
        onChange={onTabChange}
        value={tabIndex}
        indicatorColor='primary'
        aria-label='Vertical tabs example'>
        <Tab label='Campaigns' {...a11yProps(ViewTabsType.Campaigns)} value={ViewTabsType.Campaigns} />
        <Tab label='Dashboard' {...a11yProps(ViewTabsType.Dashboard)} value={ViewTabsType.Dashboard} />
      </Tabs>
      <Divider variant='fullWidth' component='hr' />

      <TabPanel value={tabIndex} index={ViewTabsType.Campaigns}>
        <CampaignList />
      </TabPanel>

      <TabPanel value={tabIndex} index={ViewTabsType.Dashboard}>
        <CampaignsDashboard />
      </TabPanel>
    </>
  );
};

export default CampaignManagement;

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useState } from 'react';
import useMeasure from 'react-use-measure';

import TransferableAgentListItem from '~components/TransferableAgentListItem';
import { useAgentStates } from '~providers/AgentStatesProvider';
import { ConnectContactType, Contact } from '~providers/ConnectProvider/domain';
import { useLogRocket } from '~providers/LogRocketProvider';
import { useNotification } from '~providers/NotificationProvider';

interface InternalTransferProps {
  contact?: Contact;
  maxConnectionCapacityReached: boolean;
  onCloseTransferModal: () => void;
}

const InternalTransfer = ({
  contact,
  maxConnectionCapacityReached,
  onCloseTransferModal,
}: InternalTransferProps): JSX.Element => {
  const { agentVoiceList } = useAgentStates();
  const logRocket = useLogRocket();
  const { pushNotification } = useNotification();
  const [searchText, setSearchText] = useState<string>('');
  const [isTransferring, setIsTransferring] = useState<boolean>(false);
  const [boxRef, { height: boxHeight }] = useMeasure();

  // Resets all properties to default values
  const defaultReset = () => {
    setIsTransferring(false);
    setSearchText('');
  };

  const triggerInternalTransfer = async (agentUsername: string) => {
    if (contact === undefined) {
      console.error('triggerInternalTransfer: Contact does not exist');
      return;
    }

    if (maxConnectionCapacityReached) {
      console.error('Conference at capacity');
      pushNotification('error', 'Conference at capacity');
      return;
    }

    if (contact.initiateInternalTransfer === undefined) {
      console.error('triggerInternalTransfer: contact.initiateInternalTransfer does not exist');
      return;
    }

    try {
      await contact.initiateInternalTransfer(agentUsername);

      // If contact is a chat contact we force clear them as there is no warm chat capabilities
      if (contact.contactType === ConnectContactType.Chat) {
        await contact.endSession();
        onCloseTransferModal();
      }
    } catch (e) {
      pushNotification('error', (e as Error).message);
      return;
    }

    logRocket.trackEvent('transfer');
    logRocket.trackEvent('internal_transfer');

    // Only want these actions to trigger if the contact is a voice transfer
    if (contact.contactType === ConnectContactType.Voice) {
      onCloseTransferModal();
      defaultReset();
    }
  };

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const searchTerm = searchText.split(/\s+/).filter((term) => term.length > 0);
  const searchResultList = agentVoiceList
    .filter(
      (item) =>
        searchTerm.every((term) => item.name.toLowerCase().includes(term.toLowerCase())) ||
        searchTerm.every((term) => item.username.toLowerCase().includes(term.toLowerCase())),
    )
    .sort((x, y) => {
      if (x.username < y.username) {
        return -1;
      } else if (y.username < x.username) {
        return 1;
      }
      return 0;
    });
  const agentItemList = searchResultList.map((item, index) => (
    <TransferableAgentListItem
      key={item.username}
      variant='voice'
      agent={item}
      divider={index !== searchResultList.length - 1}
      disabled={isTransferring}
      onTransferToAgent={() => triggerInternalTransfer(item.username)}
    />
  ));

  return (
    <>
      <Box ref={boxRef}>
        <Grid sx={{ padding: 1 }} container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant='outlined'
              id='search'
              name='search'
              label='Search'
              onChange={onSearchChange}
            />
          </Grid>
        </Grid>

        <Divider component='hr' />
      </Box>

      <div style={{ height: `calc(100% - ${boxHeight}px)`, overflow: 'auto' }}>
        {agentItemList.length === 0 && (
          <Typography marginTop={1} variant='body1' component='p' align='center'>
            {searchTerm.length === 0
              ? 'There is currently no one to internally transfer to.'
              : 'No search results found.'}
          </Typography>
        )}

        {agentItemList.length > 0 && <List sx={{ padding: 0 }}>{agentItemList}</List>}
      </div>
    </>
  );
};

export default InternalTransfer;

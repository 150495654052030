import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import useTheme from '@mui/material/styles/useTheme';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/system/Box';
import React from 'react';

import { DataItem } from '~components/DataItem';
import SelectboxMulti from '~components/Form/SelectboxMulti';
import SectionCard from '~components/SectionCard';
import useForm, { ValidatorType } from '~hooks/useForm';
import {
  Campaign,
  DiallerType,
  RoutingProfile,
  UpdateCampaignGeneralSettings,
  typeIdsToDisplayText,
} from '~pages/CampaignManagement/domain';
import { findIndexByProperty } from '~utils/Functions';

interface GeneralSettingsFormProps {
  campaign: Campaign;
  isEdit: boolean;
  submitting: boolean;
  toggleEdit: () => void;
  onSubmit: (data: UpdateCampaignGeneralSettings) => Promise<void>;
  routingProfiles: RoutingProfile[];
}

const GeneralSettingsForm = ({
  campaign,
  isEdit,
  submitting,
  toggleEdit,
  onSubmit,
  routingProfiles,
}: GeneralSettingsFormProps) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const routingProfileList = routingProfiles.map((item: RoutingProfile) => ({
    label: item.name,
    value: item.id,
  }));
  const defaultList = campaign.lists.find((list) => list.id === campaign.defaultListId);

  const { fields, errors, handleInputChange, handleSubmit } = useForm({
    name: {
      validators: [{ type: ValidatorType.Required, message: 'Campaign name is required.' }],
      value: campaign.name,
    },
    description: {
      validators: [{ type: ValidatorType.Required, message: 'Campaign description is required.' }],
      value: campaign.description,
    },
    defaultListId: {
      validators: [],
      value: defaultList?.id,
    },
    enableManualOutbound: {
      validators: [],
      value: campaign.enableManualOutbound,
    },
    manualOutboundRequireDetails: {
      validators: [],
      value: campaign.manualOutboundRequireDetails,
    },
    enablePreparedOutboundHangup: {
      validators: [],
      value: campaign.enablePreparedOutboundHangup,
    },
    enableManualOutboundWhenOffline: {
      validators: [],
      value: campaign.enableManualOutboundWhenOffline,
    },
    routingProfiles: {
      validators: [{ type: ValidatorType.Required, message: 'At least one routing profile must be selected.' }],
      value: campaign.routingProfiles.map((item) => item),
    },
  });

  const onSubmitFn = handleSubmit((formData: any) => {
    const data: UpdateCampaignGeneralSettings = formData;

    onSubmit(data);
  });

  const routingProfileChips = campaign.routingProfiles.map((item: any) => {
    const index = findIndexByProperty(routingProfileList, 'value', item);
    const label = routingProfileList[index]?.label || 'Unknown';

    return (
      <Chip
        key={item}
        sx={{ minWidth: 100, maxWidth: 200, margin: '4px' }}
        title={item}
        variant='filled'
        color='primary'
        label={label}
      />
    );
  });

  // We do this so that if we have zero results we allow the DataItem to add '-' for an undefined or null value
  const routingProfileDisplay =
    routingProfileChips.length > 0 ? <Box sx={{ marginLeft: '-4px' }}>{routingProfileChips}</Box> : null;

  const leadListsDisplay = campaign.lists.map((item, index) => (
    <MenuItem key={index} value={item.id}>
      {item.name}
    </MenuItem>
  ));

  return (
    <SectionCard title='General' onEdit={toggleEdit}>
      {!isEdit && (
        <>
          <DataItem stacked={isExtraSmall} disableMargin title='Campaign Name' value={campaign.name} />
          <DataItem stacked={isExtraSmall} title='Dialler Type' value={typeIdsToDisplayText[campaign.diallerType]} />
          <DataItem stacked={isExtraSmall} title='Campaign Type' value={typeIdsToDisplayText[campaign.campaignType]} />
          <DataItem stacked={isExtraSmall} title='Description' value={campaign.description} />
          <DataItem stacked={isExtraSmall} title='Default Lead List' value={defaultList?.name || '-'} />
          {/* TODO: Remove check when we support manual outbound within predictive */}
          {campaign.diallerType !== DiallerType.SIP && (
            <>
              <DataItem
                stacked={isExtraSmall}
                title='Enable Manual Outbound'
                value={campaign.enableManualOutbound ? 'Yes' : 'No'}
              />

              {campaign.enableManualOutbound === true && (
                <>
                  <DataItem
                    stacked={isExtraSmall}
                    title='Manual Outbound Require Details'
                    value={campaign.manualOutboundRequireDetails ? 'Yes' : 'No'}
                  />

                  <DataItem
                    stacked={isExtraSmall}
                    title='Enable Prepared Outbound Hangup'
                    tooltip='Allows agents to hangup calls they have manually prepared before it has been connected.'
                    value={campaign.enablePreparedOutboundHangup ? 'Yes' : 'No'}
                  />

                  <DataItem
                    stacked={isExtraSmall}
                    title='Enable Manual Outbound When Offline'
                    tooltip='Allows agents to perform manual outbound calls when in an offline or not routable state.'
                    value={campaign.enableManualOutboundWhenOffline ? 'Yes' : 'No'}
                  />
                </>
              )}
            </>
          )}
          <DataItem stacked={isExtraSmall} title='Routing Profiles' value={routingProfileDisplay} />
        </>
      )}

      {isEdit && (
        <form onSubmit={onSubmitFn} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant='outlined'
                id='name'
                name='name'
                label='Campaign Name'
                disabled={submitting}
                required={true}
                defaultValue={fields.name.value}
                error={Boolean(errors.name)}
                helperText={errors.name && errors.name[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant='outlined'
                id='description'
                name='description'
                label='Campaign Description'
                disabled={submitting}
                required={true}
                defaultValue={fields.description.value}
                error={Boolean(errors.description)}
                helperText={errors.description && errors.description[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                variant='outlined'
                id='defaultListId'
                name='defaultListId'
                label='Default Lead List'
                disabled={submitting}
                required={true}
                value={fields.defaultListId.value}
                error={Boolean(errors.defaultListId)}
                helperText={errors.defaultListId && errors.defaultListId[0]!}
                onChange={handleInputChange}>
                {leadListsDisplay}
              </TextField>
            </Grid>

            {/* TODO: Remove check when we support manual outbound within predictive */}
            {campaign.diallerType !== DiallerType.SIP && (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id='enableManualOutbound'
                        name='enableManualOutbound'
                        disabled={submitting}
                        checked={fields.enableManualOutbound.value}
                        onChange={handleInputChange}
                      />
                    }
                    label='Enable Manual Outbound?'
                  />
                </Grid>

                {fields.enableManualOutbound.value === true && (
                  <>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='manualOutboundRequireDetails'
                            name='manualOutboundRequireDetails'
                            disabled={submitting}
                            checked={fields.manualOutboundRequireDetails.value}
                            onChange={handleInputChange}
                          />
                        }
                        label='Manual outbound require details?'
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='enablePreparedOutboundHangup'
                            name='enablePreparedOutboundHangup'
                            disabled={submitting}
                            checked={fields.enablePreparedOutboundHangup.value}
                            onChange={handleInputChange}
                          />
                        }
                        label='Enable Prepared Outbound Hangup?'
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='enableManualOutboundWhenOffline'
                            name='enableManualOutboundWhenOffline'
                            disabled={submitting}
                            checked={fields.enableManualOutboundWhenOffline.value}
                            onChange={handleInputChange}
                          />
                        }
                        label='Enable Manual Outbound When Offline?'
                      />
                    </Grid>
                  </>
                )}
              </>
            )}

            <Grid item xs={12}>
              <SelectboxMulti
                id='routingProfiles'
                name='routingProfiles'
                title='Routing Profiles'
                items={routingProfileList}
                value={fields.routingProfiles.value}
                disabled={submitting}
                required={true}
                error={Boolean(errors.routingProfiles)}
                helperText={errors.routingProfiles && errors.routingProfiles[0]!}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid sx={{ textAlign: 'right' }} item xs={12}>
              <Button onClick={toggleEdit}>Cancel</Button>

              <LoadingButton
                sx={{ marginLeft: 1 }}
                type='submit'
                variant='contained'
                disableElevation
                loading={submitting}
                color='primary'>
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      )}
    </SectionCard>
  );
};

export default GeneralSettingsForm;

import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import OberonDialog from '~components/OberonDialog';
import { getAsyncQueues } from '~pages/AsyncManagement/api';
import { AsyncQueue } from '~pages/AsyncManagement/domain';
import { CampaignAssignRecord, RoutingProfile } from '~pages/CampaignManagement/domain';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useNotification } from '~providers/NotificationProvider';

import { getAgentByUsername, getCampaignAssignList, getSkillTypes } from '../../api';
import { Agent, AgentUpdateRequest, SkillType } from '../../domain';

interface CreateEditAgentModalProps {
  open: boolean;
  submitting: boolean;
  // Dictates if this is an editable view or not
  agentUsername?: string;
  onAccept: (data: AgentUpdateRequest) => void;
  onClose: () => void;
}

interface AgentForm {
  username: string;
  firstName: string;
  lastName: string;
  campaignId: number | '';
  routingProfileId: string;
  asyncQueues: AsyncQueue[];
}

interface SkillForm {
  skillType: string;
  skillValue: string;
}

const regex = {
  email:
    /^[a-zA-Z0-9.!#$%&''*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
};

const CreateEditAgentModal = ({ open, submitting, agentUsername, onAccept, onClose }: CreateEditAgentModalProps) => {
  const appConfig = useAppConfiguration();
  const { pushNotification } = useNotification();
  const [skillTypes, setSkillTypes] = useState<SkillType[]>([]);
  const [agentSkills, setAgentSkills] = useState<string[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [editAgent, setEditAgent] = useState<Agent | undefined>(undefined);
  const [assignableCampaigns, setAssignableCampaigns] = useState<CampaignAssignRecord[]>([]);
  const [asyncQueues, setAsyncQueues] = useState<AsyncQueue[]>([]);
  const isLoading = submitting || dataLoading;
  const isEdit = Boolean(agentUsername);
  const agentForm = useForm<AgentForm>({
    defaultValues: {
      username: '',
      firstName: '',
      lastName: '',
      campaignId: '',
      routingProfileId: '',
      asyncQueues: [],
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const watchCampaignId = agentForm.watch('campaignId');

  const agentSkillForm = useForm<SkillForm>({
    defaultValues: {
      skillType: '',
      skillValue: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const getRoutingProfilesForCampaignId = (campaignId: number | ''): RoutingProfile[] => {
    if (campaignId === '') {
      return [];
    }

    for (const campaign of assignableCampaigns) {
      if (campaign.id === campaignId && campaign.routingProfiles.length > 0) {
        return campaign.routingProfiles;
      }
    }
    return [];
  };

  // Manages agent edit based data
  // Note: Event though we already pass in the agent object, we do a fetch for an individual agent
  // so that we can get routing profile information. The reason for this is that the list view is incapable
  // of querying connect for each agent's routing profile due to rate limiting mechanisms.
  useEffect(() => {
    if (editAgent !== undefined && dataLoading === false) {
      agentForm.setValue('username', editAgent.username);
      agentForm.setValue('firstName', editAgent.firstName);
      agentForm.setValue('lastName', editAgent.lastName);
      agentForm.setValue('campaignId', editAgent.campaignId);

      // Manage the case were a routing profile might have been removed from a campaign, or that someone was messing with
      // agent routing profiles in connect and have the agent assigned to mismatched campaign/ routing profiles
      const currentCampaignRoutingProfiles = getRoutingProfilesForCampaignId(editAgent.campaignId);
      const currentRoutingProfile = currentCampaignRoutingProfiles.find(
        (item) => editAgent.routingProfileId === item.id,
      );

      agentForm.setValue('routingProfileId', currentRoutingProfile === undefined ? '' : currentRoutingProfile.id);

      let editValue: AsyncQueue[] = [];
      for (let q of editAgent.asyncQueues) {
        const queue = asyncQueues.find((item) => item.queue === q);
        if (queue !== undefined) {
          editValue = [...editValue, queue];
        }
      }

      agentForm.setValue('asyncQueues', editValue);

      // Assign agent skills to a local state variable for manipulation
      setAgentSkills(editAgent.skills);
      return;
    }
  }, [editAgent, dataLoading]);

  // Manages routing profile selection defaults
  useEffect(() => {
    if (editAgent !== undefined && dataLoading === false) {
      const currentCampaignRoutingProfiles = getRoutingProfilesForCampaignId(watchCampaignId);

      if (editAgent.campaignId === watchCampaignId) {
        const currentRoutingProfile = currentCampaignRoutingProfiles.find(
          (item) => editAgent.routingProfileId === item.id,
        );

        agentForm.setValue('routingProfileId', currentRoutingProfile === undefined ? '' : currentRoutingProfile.id);
      } else {
        agentForm.setValue(
          'routingProfileId',
          currentCampaignRoutingProfiles.length > 0 ? currentCampaignRoutingProfiles[0].id : '',
        );
      }
    }
  }, [editAgent, dataLoading, watchCampaignId]);

  // Manages form based data
  useEffect(() => {
    const createViewData = async () => {
      setDataLoading(true);
      let skills;
      let campaigns;
      let asyncQueues;

      try {
        [skills, campaigns, asyncQueues] = await Promise.all([
          getSkillTypes(),
          getCampaignAssignList(),
          getAsyncQueues(),
        ]);
      } catch (e) {
        pushNotification('error', 'unable to load skill types and assignable campaigns');
        setDataLoading(false);
        return;
      }

      setSkillTypes(skills);
      setAssignableCampaigns(campaigns);
      setAsyncQueues(asyncQueues || []);
      setDataLoading(false);
    };

    const editViewData = async (agentUsername: string) => {
      setDataLoading(true);
      let agent;
      let skills;
      let campaigns;
      let asyncQueues;

      try {
        [agent, skills, campaigns, asyncQueues] = await Promise.all([
          getAgentByUsername(agentUsername),
          getSkillTypes(),
          getCampaignAssignList(),
          getAsyncQueues(),
        ]);
      } catch (e) {
        pushNotification('error', 'unable to load skill types and assignable campaigns');
        setDataLoading(false);
        return;
      }

      setEditAgent(agent);
      setSkillTypes(skills);
      setAssignableCampaigns(campaigns);
      setAsyncQueues(asyncQueues || []);
      setDataLoading(false);
    };

    if (open) {
      if (agentUsername === undefined) {
        createViewData();
      } else {
        editViewData(agentUsername);
      }
    }

    // Reset form on close
    return function cleanupCreateEditAgentModal() {
      agentForm.reset();
      agentSkillForm.reset();
      setEditAgent(undefined);
      setAgentSkills([]);
      setSkillTypes([]);
      setAssignableCampaigns([]);
      setAsyncQueues([]);
    };
  }, [open, agentUsername]);

  const onSkillDelete = (index: number) => () => {
    setAgentSkills((prev) => {
      const newArray = [...prev];

      newArray.splice(index, 1);
      return newArray;
    });
  };

  const onAgentSubmit = agentForm.handleSubmit(async (data: AgentForm) => {
    try {
      await onAccept({
        username: data.username,
        firstName: data.firstName,
        lastName: data.lastName,
        campaignId: data.campaignId || null,
        routingProfileId: data.routingProfileId,
        skills: agentSkills,
        asyncQueues: data.asyncQueues.map((item) => item.queue),
      });
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
      return;
    }

    agentForm.reset();
  });

  const onAgentSkillSubmit = agentSkillForm.handleSubmit(async (data: SkillForm) => {
    const skill = `${data.skillType}:${data.skillValue}`;
    setAgentSkills((prev) => [...prev, skill]);

    agentSkillForm.reset();
  });

  const usernamePattern = appConfig.validations.username
    ? new RegExp(appConfig.validations.username.pattern)
    : regex.email;
  const usernamePatternErrorMessage = appConfig.validations.username
    ? appConfig.validations.username.error
    : 'Username must be a valid email address';
  const usernameHelperText = agentForm.formState.errors.username
    ? agentForm.formState.errors.username.message
    : `Must match user's username from IdP`;

  const agentSkillsDisplay = agentSkills.map((item: any, index: number) => (
    <Chip key={item} sx={{ minWidth: 100, maxWidth: 200, margin: 0.5 }} label={item} onDelete={onSkillDelete(index)} />
  ));

  const skillTypeListDisplay = skillTypes.map((item, index) => (
    <MenuItem key={index} value={item.skillType}>
      {item.skillType}
    </MenuItem>
  ));

  let campaignListDisplay = assignableCampaigns.map((item, index) => (
    <MenuItem key={index} value={item.id}>
      {item.name}
    </MenuItem>
  ));

  // Add None selection as agents can exist without having an assigned campaign
  campaignListDisplay = [
    <MenuItem key='none' value=''>
      None
    </MenuItem>,
    ...campaignListDisplay,
  ];

  const routingProfileDisplay = getRoutingProfilesForCampaignId(watchCampaignId).map((item, index) => (
    <MenuItem key={index} value={item.id}>
      {item.name}
    </MenuItem>
  ));

  return (
    <OberonDialog
      open={open}
      onSubmit={onAgentSubmit}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : 'Create'} Agent`}
      content={
        <Grid container spacing={2}>
          {!isEdit && (
            <Grid item xs={12}>
              <Controller
                name='username'
                control={agentForm.control}
                rules={{
                  required: 'Agent username is required.',
                  pattern: {
                    value: usernamePattern,
                    message: usernamePatternErrorMessage,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant='outlined'
                    label='Username'
                    disabled={isLoading}
                    required={true}
                    error={Boolean(agentForm.formState.errors.username)}
                    helperText={usernameHelperText}
                    {...field}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Controller
              name='firstName'
              control={agentForm.control}
              rules={{
                required: 'Agent first name is required.',
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='First Name'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(agentForm.formState.errors.firstName)}
                  helperText={agentForm.formState.errors.firstName?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='lastName'
              control={agentForm.control}
              rules={{
                required: 'Agent last name is required.',
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Last Name'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(agentForm.formState.errors.lastName)}
                  helperText={agentForm.formState.errors.lastName?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider style={{ marginBottom: 8 }} variant='fullWidth' component='hr' />

            <Typography variant='h6' component='h3'>
              Assign Campaign
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='campaignId'
              control={agentForm.control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  select
                  variant='outlined'
                  label='Campaign'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(agentForm.formState.errors.campaignId)}
                  helperText={agentForm.formState.errors.campaignId?.message}
                  {...field}>
                  {campaignListDisplay}
                </TextField>
              )}
            />
          </Grid>

          {!appConfig.aws.externallyManagedRoutingProfile && (
            <Grid item xs={12}>
              <Controller
                name='routingProfileId'
                control={agentForm.control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    select
                    variant='outlined'
                    label='Routing Profile'
                    disabled={isLoading || routingProfileDisplay.length === 0}
                    required={true}
                    error={Boolean(agentForm.formState.errors.routingProfileId)}
                    helperText={agentForm.formState.errors.routingProfileId?.message}
                    {...field}>
                    {routingProfileDisplay}
                  </TextField>
                )}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Divider style={{ marginBottom: 8 }} variant='fullWidth' component='hr' />

            <Typography variant='h6' component='h3'>
              Assign Messaging Queues
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='asyncQueues'
              control={agentForm.control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  fullWidth
                  multiple
                  onChange={(e, data) => {
                    field.onChange(data);
                  }}
                  options={asyncQueues}
                  filterSelectedOptions
                  isOptionEqualToValue={(option, value) => option.queue === value.queue}
                  disabled={isLoading}
                  getOptionLabel={(option) => option.title || ''}
                  renderOption={(props, option) => (
                    <li {...props} key={option.queue}>
                      <Box>
                        <Typography variant='body1' color='textPrimary' component='p'>
                          {option.title}
                        </Typography>
                        <Chip
                          sx={{
                            textTransform: 'uppercase',
                            fontSize: 10,
                            borderRadius: 1,
                            height: 'auto',
                            lineHeight: '21px',
                            color: '#ffffff',
                            fontWeight: 700,
                          }}
                          color='primary'
                          label={option.channelType}
                        />
                      </Box>
                    </li>
                  )}
                  renderInput={(params) => <TextField label='Async Queues' variant='outlined' {...params} />}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider style={{ marginBottom: 8 }} variant='fullWidth' component='hr' />

            <Typography variant='h6' component='h3'>
              Assign Skills
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='skillType'
              rules={{ required: 'Skill type is required.' }}
              control={agentSkillForm.control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  select
                  variant='outlined'
                  label='Skill Type'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(agentSkillForm.formState.errors.skillType)}
                  helperText={agentSkillForm.formState.errors.skillType?.message}
                  {...field}>
                  {skillTypeListDisplay}
                </TextField>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='skillValue'
              control={agentSkillForm.control}
              rules={{ required: 'Skill value is required.' }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Skill Value'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(agentSkillForm.formState.errors.skillValue)}
                  helperText={agentSkillForm.formState.errors.skillValue?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant='contained'
              disableElevation
              color='primary'
              onClick={onAgentSkillSubmit}
              disabled={isLoading}>
              Add Skill
            </Button>
          </Grid>

          <Grid item xs={12}>
            {agentSkillsDisplay.length > 0 && <>{agentSkillsDisplay}</>}

            {agentSkillsDisplay.length === 0 && (
              <Typography variant='body1' component='p'>
                No Skills currently assigned to an agent.
              </Typography>
            )}
          </Grid>
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            {isEdit ? 'Update' : 'Create'}
          </LoadingButton>
        </>
      }
    />
  );
};

export default CreateEditAgentModal;

import axios, { CancelTokenSource } from 'axios';

import { Disposition, ListRoutingProfileResponseDecoder, RoutingProfile } from '~pages/CampaignManagement/domain';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import {
  AttemptStatusType,
  ContactOutcome,
  DiallableLead,
  GetCampaignDispositionResponseDecoder,
  GetTimezonesResponseDecoder,
  OutcomeDisposition,
} from './domain';

export const getCampaignRoutingProfiles = async (campaignID: number): Promise<RoutingProfile[]> => {
  const path = `/api/routing-profiles/by-campaign/${campaignID}`;
  let response;

  try {
    response = await axios.request({
      url: path,
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = ListRoutingProfileResponseDecoder.run(response.data);
  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const updateLeadAttemptStatus = async (
  campaignId: number,
  attemptId: number,
  status: AttemptStatusType,
  contactId: string | undefined,
  screenRecordingNames: string | undefined,
  assignee: string | undefined,
): Promise<void> => {
  const path = `/api/campaign/${campaignId}/attempt/${attemptId}/status`;

  const body = {
    status: status,
    contact_id: contactId || undefined,
    screen_recording_names: screenRecordingNames || undefined,
    assignee: assignee || undefined,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const postLeadAttemptOutcome = async (
  campaignId: number,
  attemptId: number,
  disposition: OutcomeDisposition,
  newLead: Pick<DiallableLead, 'name' | 'endpoint' | 'externalId' | 'timezone'> | undefined,
): Promise<void> => {
  const path = `/api/campaign/${campaignId}/attempt/${attemptId}/outcome`;

  const body = {
    contact_id: disposition.contactId || undefined,
    disposition: {
      disposition_code: disposition.dispositionCode,
      disposition_sub_code: disposition.dispositionSubCode,
      has_system_issue: disposition.hasSystemIssue,
      system_issue_description: disposition.systemIssueDescription || undefined,
      metadata: disposition.metadata || undefined,
      exclusion_list: disposition.exclusionList || undefined,
      callback:
        disposition.callback !== undefined
          ? {
              for_agent: disposition.callback.forAgent,
              scheduled: disposition.callback.scheduled,
              endpoint: disposition.callback.endpoint,
              notes: disposition.callback.notes,
            }
          : undefined,
    },
    new_lead:
      newLead !== undefined
        ? {
            name: newLead.name,
            external_id: newLead.externalId,
            endpoint: newLead.endpoint,
            timezone: newLead.timezone,
          }
        : undefined,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const getInboundCampaignDispositions = async (campaignId: number): Promise<Disposition[]> => {
  const path = `/api/campaign/${campaignId}/dispositions`;
  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetCampaignDispositionResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const postContactStatus = async (
  campaignId: number,
  contactId: string,
  screenRecordingNames: string | undefined,
): Promise<void> => {
  const path = `/api/campaign/${campaignId}/contact/${contactId}/status`;

  const body = {
    screen_recording_names: screenRecordingNames || undefined,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const postContactOutcome = async (
  campaignId: number,
  contactId: string,
  data: ContactOutcome,
): Promise<void> => {
  const path = `/api/campaign/${campaignId}/contact/${contactId}/outcome`;

  const body = {
    disposition_code: data.dispositionCode,
    disposition_sub_code: data.dispositionSubCode,
    has_system_issue: data.hasSystemIssue,
    system_issue_description: data.systemIssueDescription || undefined,
    attributes: data.attributes,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const postCallRecordingAction = async (
  contactId: string,
  initialContactId: string,
  action: 'suspend' | 'resume',
) => {
  const path = `/api/contacts/call-recording`;

  const body = {
    contact_id: contactId,
    initial_contact_id: initialContactId,
    action: action,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const postChangeRoutingProfile = async (routingProfile: string) => {
  const path = `/api/routing-profiles/set-routing-profile`;
  const body = {
    routing_profile_id: routingProfile,
  };

  try {
    await axios.request({
      method: 'PUT',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const postAgentMetadataUpdate = async (campaignId: number) => {
  const path = `/api/campaign/${campaignId}/agent-metadata-update`;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  try {
    await axios.request({
      method: 'PUT',
      url: path,
      headers: {
        Accept: 'application/json',
      },
      params: { timezone: timezone },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const getTimezoneList = async (prefixes: string[], cancelToken?: CancelTokenSource): Promise<string[]> => {
  const path = `/api/timezones/`;
  let resp;

  let params = new URLSearchParams();

  for (let prefix of prefixes) {
    params.append('prefix', prefix);
  }

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      params: params,
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetTimezonesResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};
